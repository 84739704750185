import React from 'react';
import PropTypes from 'prop-types';
import usePrevious from 'react-use/lib/usePrevious';

import { downloadFile } from '../../../lib/download-file';
import { UploadedFileList, ProcessingFileList } from '../../../molecules';
import { useFileUploading } from '../../../hooks';

/**
 *
 * @param {{ accepted: File[], list: { originalName: string, url: string }[], onUpload: Function }}
 */
export function CommentAttachments({
  accepted = [],
  list,
  onUpload,
  onDelete,
}) {
  const prevAccepted = usePrevious(accepted);
  const {
    isUploading,
    processing,
    result,
    onUpload: upload,
    onCancelUploads,
  } = useFileUploading();

  React.useEffect(() => {
    if (accepted.length > 0 && accepted !== prevAccepted) {
      upload(accepted);
    }
  }, [accepted, prevAccepted, upload]);

  React.useEffect(() => {
    if (isUploading && result.length > 0) {
      onUpload(result);
    }
  }, [isUploading, onUpload, result]);

  return (
    <React.Fragment>
      {isUploading && (
        <ProcessingFileList
          processing={processing}
          items={accepted}
          onDelete={onDelete}
          onCancel={id => onCancelUploads([id])}
        />
      )}
      {list.length > 0 && (
        <UploadedFileList
          items={list}
          onDelete={onDelete}
          onDownload={downloadFile}
        />
      )}
    </React.Fragment>
  );
}

CommentAttachments.propTypes = {
  accepted: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      originalName: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onUpload: PropTypes.func,
};

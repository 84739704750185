import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { useStore } from 'effector-react';
import {
  VariousCalendar,
  MeetingsSection,
  MeetingsList,
  MeetingsListSkeleton,
} from '@features/meetings';
import { Divider, Button } from '@oca/ui';
import { Trans } from '@lingui/macro';
import { Add } from '@oca/icons';
import {
  meetingsFilters,
  $meetingsByDay,
  $meetingFetching,
  ProjectMeetingsGate,
} from '../model/project-view-flow';

export const ViewMeetingsSection = React.memo(function ViewTasksSection({
  isLoading,
  project,
}) {
  const meetingsByDay = useStore($meetingsByDay);
  const fetching = useStore($meetingFetching);
  const date = useStore(meetingsFilters.$date);
  const variant = useStore(meetingsFilters.$mode);

  return (
    <Box width={1}>
      <Flex mt={-2} justifyContent="space-between">
        <VariousCalendar
          value={date}
          onVariantSelect={({ value }) => meetingsFilters.setMode(value)}
          onDateChange={meetingsFilters.setDate}
          variant={variant}
        />
        <Button
          variant="primary"
          as={RouterLink}
          to={`/meetings/create?project_id=${project ? project.id : -1}`}
          disabled={!project}
        >
          <Add />
          <Trans>Create meeting</Trans>
        </Button>
      </Flex>
      <Divider mt={2} mb={3} />
      {project && <ProjectMeetingsGate id={project.id} />}
      <MeetingsListContainer>
        {(isLoading || fetching.isLoading) && <MeetingsListSkeleton />}
        {!(isLoading || fetching.isLoading) && (
          <MeetingsList
            items={meetingsByDay}
            renderItem={item => (
              <MeetingsSection
                key={item.date}
                date={item.date}
                list={item.list}
                linkTo="/meetings"
              />
            )}
          />
        )}
      </MeetingsListContainer>
    </Box>
  );
});

ViewMeetingsSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    description: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

const MeetingsListContainer = styled.div`
  min-height: calc(100vh - 350px);
`;

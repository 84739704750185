import React from 'react';
import PropTypes from 'prop-types';
import { combine } from 'effector';
import { useStore } from 'effector-react';
import { DataTable } from '@oca/ui';

import { $filteredFileList } from '../model/file-browser.view';
import { fetchFilesRequest } from '../model/file-browser.events';

const $state = combine({
  fetching: fetchFilesRequest.pending,
  list: $filteredFileList,
});

export const FileBrowser = React.forwardRef(
  /**
   *
   * @param {import('@oca/ui').DataTableProps} props
   */
  function FileBrowser(props, ref) {
    const { fetching, list } = useStore($state);

    return (
      <DataTable
        data={list}
        {...props}
        loading={props.loading || fetching}
        ref={ref}
      />
    );
  },
);

FileBrowser.propTypes = {
  loading: PropTypes.bool,
};

FileBrowser.defaultProps = {
  loading: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import { Typography } from '@oca/ui';

import { getUrlParameter } from '@lib/get-url-parameter';

import { ResetPasswordForm } from '../../organisms';
import { AuthPageTemplate } from '../../templates';
import { pageUnmounted } from '../../model/auth';

export function ResetPasswordPage({ location }) {
  const token = getUrlParameter('token', location);

  React.useEffect(() => pageUnmounted, []);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <AuthPageTemplate>
      <Typography paragraph>
        <Trans>Please enter your new password</Trans>
      </Typography>
      <ResetPasswordForm token={token} />
    </AuthPageTemplate>
  );
}

ResetPasswordPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import dayjs from 'dayjs';

export function DueDate({ highlight, format: formatDate, value }) {
  const date = new Date(value);
  const isOverdue = dayjs().isAfter(date);

  return (
    <Wrapper
      p="4px"
      bg={isOverdue && highlight ? '#FF5B40' : 'transparent'}
      color={isOverdue && highlight ? 'white' : 'text'}
      alignItems="center"
    >
      {dayjs(date).format(formatDate)}
    </Wrapper>
  );
}

DueDate.propTypes = {
  highlight: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

DueDate.defaultProps = {
  highlight: false,
  format: 'L LT',
  value: Date.now(),
};

const Wrapper = styled(Flex)`
  display: inline-flex;
  position: relative;
  line-height: 1;
  border-radius: 4px;
`;

import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';

export const reminderTimeSelect = [
  {
    enumVal: '15min',
    value: '00:15:00',
    label: i18n._(t`15 minutes before`),
  },
  {
    enumVal: '30min',
    value: '00:30:00',
    label: i18n._(t`30 minutes before`),
  },
  {
    enumVal: '1hour',
    value: '01:00:00',
    label: i18n._(t`1 hour before`),
  },
  {
    enumVal: '1day',
    value: '24:00:00',
    label: i18n._(t`1 day before`),
  },
];

export const durationTimeSelect = [
  {
    value: '00:30',
    label: i18n._(t`30 minutes`),
  },
  {
    value: '01:00',
    label: i18n._(t`1 hour`),
  },
  {
    value: '01:30',
    label: i18n._(t`1 hour 30 minutes`),
  },
  {
    value: '02:00',
    label: i18n._(t`2 hours`),
  },
  {
    value: '02:30',
    label: i18n._(t`2 hours 30 minutes`),
  },
  {
    value: '03:00',
    label: i18n._(t`3 hours`),
  },
];

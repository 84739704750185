import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useToggle from 'react-use/lib/useToggle';
import { NavLink as Link } from 'react-router-dom';

import { Tooltip } from '@oca/ui';
import { createTransition } from '@oca/ui/theme';
import { ArrowRight } from '@oca/icons';
import { getFirstLetters } from '@lib/help-fns';
import { AccessControl } from './access-control';

const activeClassName = 'nav-link-active';
const topLevelActiveClassName = `${activeClassName}--top-level`;

export const NavList = React.memo(function NavList({
  depth,
  collapsed,
  location,
  onItemClick,
  pages = [],
}) {
  return (
    <List depth={depth} collapsed={collapsed}>
      {pages.map(page => {
        const hasSubPages = page.routes && page.routes.length > 0;

        if (!page.navigation) {
          return null;
        }

        const { icon, name, shortName, onlyTopLevelLink } =
          page.navigation || {};
        const shortLabel =
          shortName ||
          getFirstLetters(name || '')
            .slice(0, 2)
            .join('');

        if (!hasSubPages) {
          // Handle the only descendant. Move it to the top level.
          // eslint-disable-next-line no-param-reassign
          page =
            page.routes && page.routes.length === 1 ? page.routes[0] : page;
        }

        const isChildPageActive =
          location.pathname.startsWith(page.path) && depth === 0;

        return (
          <AccessControl key={page.path} allowed={page.access || []}>
            {hasSubPages && !onlyTopLevelLink ? (
              <NavItem
                className={isChildPageActive && activeClassName}
                icon={icon}
                collapsed={collapsed}
                label={name}
                shortLabel={shortLabel}
                initialOpen={isChildPageActive}
                target={page.target}
              >
                <NavList
                  depth={depth + 1}
                  pages={page.routes}
                  collapsed={collapsed}
                  location={location}
                  onItemClick={onItemClick}
                />
              </NavItem>
            ) : (
              <NavItem
                icon={icon}
                collapsed={collapsed}
                href={page.path}
                search={page.search}
                label={name}
                shortLabel={shortLabel}
                // Let active child routes highlight top level link
                exact={!onlyTopLevelLink}
                target={page.target}
                onClick={onItemClick}
              />
            )}
          </AccessControl>
        );
      })}
    </List>
  );
});

NavList.propTypes = {
  depth: PropTypes.number.isRequired,
  collapsed: PropTypes.bool.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

function NavItem({
  children,
  collapsed,
  exact,
  href,
  search,
  icon,
  initialOpen,
  label,
  onClick,
  shortLabel,
  target,
}) {
  const [on, toggle] = useToggle(initialOpen);

  const handleItemClick = event => {
    if (!href) {
      event.preventDefault();
      toggle(!on);
    } else {
      onClick();
    }
  };

  return (
    <ListItem>
      <Tooltip title={label} tooltipShown={collapsed ? undefined : false}>
        <NavLink
          className={initialOpen ? topLevelActiveClassName : undefined}
          to={{ pathname: href || '#', search }}
          onClick={handleItemClick}
          exact={exact}
          target={target}
        >
          <Adornment>{icon || shortLabel}</Adornment>
          <TextContainer hidden={collapsed}>{label}</TextContainer>
          {children && (
            <ExpandIcon right={collapsed ? 0 : 8} fontSize={20} expanded={on} />
          )}
        </NavLink>
      </Tooltip>
      {on && children}
    </ListItem>
  );
}

NavItem.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  exact: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  href: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  shortLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  // eslint-disable-next-line react/require-default-props
  initialOpen: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  search: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  target: PropTypes.string,
};

NavItem.defaultProps = {
  exact: true,
};

const List = styled.ul`
  padding: 0;
  margin-left: ${getListOffset}px;
`;

const ListItem = styled.li`
  display: block;
  list-style: none;
  & > div {
    display: block;
  }
`;

const NavLink = styled(Link).attrs({
  activeClassName,
})`
  position: relative;
  display: block;
  width: auto;
  padding: 8px 16px;
  margin: 12px 8px;
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  line-height: 1.5rem;
  transition: ${createTransition(['background-color'])};
  text-decoration: none;
  color: white;
  outline: none;
  :focus,
  &.${topLevelActiveClassName} {
    background-color: rgba(0, 0, 0, 0.1);
  }
  :hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &.${activeClassName} {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Adornment = styled.div`
  color: inherit;
  width: 30px;
  margin-right: 14px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 14px;
  /* text-align: center; */
  text-transform: uppercase;
  font-weight: 500;
`;

const TextContainer = styled.div`
  color: inherit;
  margin: 0;
  height: auto;
  display: block;
  position: relative;
  font-size: 14px;
  transition: transform 300ms ease 0s, opacity 300ms ease 0s;
  white-space: nowrap;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transform: translate3d(${({ hidden }) => (hidden ? -25 : 0)}px, 0, 0);
`;

const ExpandIcon = styled(ArrowRight)`
  position: absolute;
  right: ${({ right }) => right}px;
  top: 12px;
  transition: ${createTransition('transform')};
  transform: rotate(${({ expanded }) => (expanded ? 270 : 90)}deg);
`;

function getListOffset({ collapsed, depth }) {
  if (collapsed) {
    return 0;
  }
  const nested = depth > 0;
  const extraOffset = nested ? 6 : 0;

  return depth * 8 + extraOffset;
}

import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'effector-react';
import { Form, Field } from 'react-final-form';
import { Box } from 'rebass';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Trans, t } from '@lingui/macro';
import { Button } from '@oca/ui';
import {
  TextFieldWrapper,
  SelectWrapper,
  DayPickerInputWrapper,
  TextAreaFieldWrapper,
} from '@lib/final-form-oca-ui';
import {
  FileUploaderField,
  $projectList,
  EmployeeOptionLabel,
  filterEmployeeOption,
} from '@features/common';

import { i18n } from '@lib/i18n';
import { OnChange } from '@lib/react-final-form-on-change';
import { $updateTaskStatus } from '../models/main';
import { priorities } from '../datas';
import {
  $projectAssignees,
  $projectFetching,
  fetchProjectAssignees,
} from '../models/employees-by-project-flow';

export const TaskUpdateForm = React.memo(({ initialValues, onSubmit }) => {
  const projectList = useStore($projectList);
  const projectFetching = useStore($projectFetching);
  const assignees = useStore($projectAssignees);
  const { isLoading } = useStore($updateTaskStatus);

  return (
    <FormWrap>
      <Form
        validate={validate}
        subscription={{
          submitting: true,
          pristine: true,
        }}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <OnChange name="project">
                {(value, prevValue) => {
                  if (prevValue) {
                    form.change('assigneeEmployees', []);
                  }

                  if (value && value !== prevValue) {
                    fetchProjectAssignees({
                      id: value.id || value,
                      params: { task: initialValues.id },
                    });
                  }
                }}
              </OnChange>
              <Field
                name="title"
                label={<Trans>Title</Trans>}
                placeholder={i18n._(t`Write a name to your task`)}
                component={TextFieldWrapper}
                helperText=" "
                spaceAfter
                required
              />
              <Field
                name="description"
                label={<Trans>Description</Trans>}
                placeholder={i18n._(t`Describe your project`)}
                component={TextAreaFieldWrapper}
                helperText=" "
                spaceAfter
                rows={3}
              />
              <Field
                name="priority"
                label={<Trans>Priority</Trans>}
                placeholder={i18n._(t`Type priority title...`)}
                spaceAfter
                helperText=" "
                options={priorities}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                parse={val => val && val.value}
                format={val =>
                  val &&
                  priorities.find(({ value }) => val.value || val === value)
                }
                component={SelectWrapper}
              />
              <Field
                name="project"
                label={<Trans>Project</Trans>}
                placeholder={i18n._(t`Type project title...`)}
                spaceAfter
                helperText=" "
                options={projectList}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.name}
                parse={val => val && val.id}
                format={val =>
                  val && projectList.find(({ id }) => (val.id || val) === id)
                }
                component={SelectWrapper}
                required
              />
              <Field
                name="assigneeEmployees"
                label={<Trans>Assignees</Trans>}
                placeholder={i18n._(t`Type name...`)}
                spaceAfter
                helperText=" "
                isMulti
                options={assignees}
                getOptionValue={option => option.id}
                getOptionLabel={option => (
                  <EmployeeOptionLabel employee={option} />
                )}
                filterOption={filterEmployeeOption}
                parse={val => {
                  return val ? val.map(({ id }) => id) : [];
                }}
                format={val => {
                  return val
                    ? assignees.filter(({ id }) =>
                        val.some(item => id === (item.id || item)),
                      )
                    : [];
                }}
                isLoading={projectFetching.isLoading}
                isDisabled={projectFetching.isLoading}
                component={SelectWrapper}
                required
              />
              <Box mb={25}>
                <FileUploaderField
                  name="attachmentFiles"
                  label={<Trans>Attachments</Trans>}
                />
              </Box>
              <Box>
                <Field
                  type="date"
                  name="dueDate"
                  placeholder="April 11 2019 09:00"
                  label={<Trans>Due date</Trans>}
                  spaceAfter
                  helperText=" "
                  format={v => (v ? new Date(v) : null)}
                  component={DayPickerInputWrapper}
                  options={{
                    defaultHour: 12,
                    enableTime: true,
                    formatDate: date => dayjs(date).format('lll'),
                  }}
                  required
                />
              </Box>
              <Button
                variant="primary"
                type="submit"
                fullWidth
                disabled={isLoading}
              >
                <Trans>Update Task</Trans>
              </Button>
            </form>
          );
        }}
      />
    </FormWrap>
  );
});

TaskUpdateForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  initialValues: PropTypes.shape({
    attachmentFiles: PropTypes.array,
    priority: PropTypes.string,
    status: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = <Trans>This is required field</Trans>;
  } else if (values.title.length > 250) {
    errors.title = <Trans>Title length should less than 255 symbols</Trans>;
  }

  if (!values.assigneeEmployees || !values.assigneeEmployees.length) {
    errors.assigneeEmployees = <Trans>This is required field</Trans>;
  }

  if (!values.project) {
    errors.project = <Trans>This is required field</Trans>;
  }

  if (!values.dueDate) {
    errors.dueDate = <Trans>This is required field</Trans>;
  } else if (dayjs().isAfter(new Date(values.dueDate))) {
    errors.dueDate = <Trans>Due date should be future</Trans>;
  }

  return errors;
};

const FormWrap = styled(Box)`
  min-width: 500px;
  max-width: 500px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Flatpickr } from '@lib/flatpickr';

import { TextField } from '../molecules/text-field';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps}
 */
export function DayPickerInput({ inputProps, options, ...props }) {
  return (
    <Flatpickr
      {...props}
      options={options}
      render={(_, ref) => <TextField {...inputProps} ref={ref} />}
    />
  );
}

DayPickerInput.propTypes = {
  inputProps: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.shape({
    dateFormat: PropTypes.string,
  }),
};

DayPickerInput.defaultProps = {
  inputProps: {},
};

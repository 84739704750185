import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useGate } from 'effector-react';

import { i18n } from '@lib/i18n';

import { useRouteBreadcrumb } from '../lib/use-route-breadcrumb';
import { useTableColumns } from '../lib/use-table-columns';
import { CommonDocumentsTemplate } from '../templates';
import {
  Header,
  FileBrowser,
  PrimaryActions,
  FileMoveDialog,
} from '../organisms';
import { FileBrowserCategories } from '../constants';
import { FileBrowserGate } from '../model/file-browser.view';

export function CompanyFilesPage({ route, match }) {
  const { id = null } = match.params;
  const menuVisibilityPredicate = React.useCallback(() => id !== null, [id]);
  const rootBreadcrumb = useRouteBreadcrumb(route);
  const columns = useTableColumns({
    rootPath: rootBreadcrumb.path,
    menuVisibilityPredicate,
    canMoveFile: true,
  });

  useGate(FileBrowserGate, {
    id,
    category: FileBrowserCategories.COMPANY,
  });

  return (
    <CommonDocumentsTemplate
      title={i18n._(t`Documents: Company files`)}
      actions={id ? <PrimaryActions /> : null}
    >
      <Header rootBreadcrumb={rootBreadcrumb} />
      <FileBrowser columns={columns} />
      <FileMoveDialog
        category={FileBrowserCategories.COMPANY}
        rootBreadcrumb={{ id: null, name: rootBreadcrumb.name }}
      />
    </CommonDocumentsTemplate>
  );
}

CompanyFilesPage.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'rebass';
import styled from 'styled-components';

export function CenterContentTemplate({ children }) {
  return (
    <Container
      width={1}
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flex="1 0 auto"
    >
      {children}
    </Container>
  );
}

CenterContentTemplate.propTypes = {
  children: PropTypes.node,
};

CenterContentTemplate.defaultProps = {
  children: null,
};

const Container = styled(Flex)`
  height: 100vh;
`;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const UnknownFile = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
  </SvgIcon>
);

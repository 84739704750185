import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

let dragLayerRef = null;

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

/**
 *
 * @param {import('react-dnd').DragLayerMonitor} monitor
 */
const dragLayerCollector = monitor => {
  const item = monitor.getItem();
  const itemType = monitor.getItemType();
  const isDragging = monitor.isDragging();

  if (dragLayerRef) {
    const offset =
      monitor.getClientOffset() || monitor.getInitialClientOffset();

    if (offset && isDragging) {
      const { x, y } = offset;
      dragLayerRef.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    } else {
      dragLayerRef.style.display = `none`;
    }
  }

  return {
    item,
    itemType,
    isDragging,
  };
};

export const CustomDragLayer = DragLayer(dragLayerCollector)(Layer);

/**
 *
 * @param {{ children: (props: { item: any, itemType: string }) => React.ReactNode, isDragging: boolean, item: { type: string }, itemType: string }}
 */
function Layer({ children, isDragging, item, itemType }) {
  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div
        ref={ref => {
          dragLayerRef = ref;
        }}
      >
        {children({ item, itemType })}
      </div>
    </div>
  );
}

Layer.propTypes = {
  children: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  isDragging: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  item: PropTypes.shape({
    type: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  itemType: PropTypes.string,
};

Layer.defaultProps = {
  children: () => null,
};

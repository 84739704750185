/**
 *
 * @param {HTMLInputElement} input
 * @param {string} value
 */
export function triggerInputEvent(input, value = '') {
  const lastValue = input.value;
  // eslint-disable-next-line no-param-reassign
  input.value = value;
  const event = new Event('input', { bubbles: true });
  // hack React15
  event.simulated = true;
  // hack React16 内部定义了descriptor拦截value，此处重置状态
  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(lastValue);
  }
  input.dispatchEvent(event);
}

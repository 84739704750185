import React from 'react';
import { SvgIcon } from './svg-icon';

export const Card = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0)">
      <path d="M16.4204 0.00012207H6.16418C4.75032 0.00012207 3.6001 1.15034 3.6001 2.56421V21.0255C3.6001 22.4393 4.75032 23.5895 6.16418 23.5895H15.3948V22.5639H6.16418C5.316 22.5639 4.62574 21.8736 4.62574 21.0255V2.56421C4.62574 1.71602 5.316 1.02577 6.16418 1.02577H16.4205C17.2686 1.02577 17.9589 1.71602 17.9589 2.56421V17.4358H18.9845V2.56421C18.9845 1.15034 17.8343 0.00012207 16.4204 0.00012207Z" />
      <path d="M6.1642 23.5893L16.4204 23.5893C17.8343 23.5893 18.9845 22.4391 18.9845 21.0252L18.9845 2.56396C18.9845 1.15016 17.8343 -0.000123877 16.4204 -0.000123754L7.18978 -0.000122947L7.18978 1.02552L16.4204 1.02552C17.2686 1.02552 17.9589 1.71577 17.9589 2.56396L17.9589 21.0252C17.9589 21.8734 17.2686 22.5636 16.4204 22.5636L6.16414 22.5637C5.31596 22.5637 4.6257 21.8734 4.6257 21.0252L4.6257 6.15363L3.60012 6.15363L3.60012 21.0252C3.60012 22.4391 4.75034 23.5893 6.1642 23.5893Z" />
      <path d="M15.0002 0H13.5002V22.5H15.0002V0Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </SvgIcon>
);

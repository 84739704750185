import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { SectionBlock, Label, Typography } from '@oca/ui';
import { Trans } from '@lingui/macro';

export function ProfileBlock({ children, title, list, blockWidth }) {
  return (
    <SectionBlock title={title} className="content">
      <Flex flexWrap="wrap">
        {list.map(({ label, value }, index) => (
          <Box key={index} width={blockWidth} paddingTop={3}>
            <Label color="textSecondary" gutterBottom>
              {label}
            </Label>
            <Typography variant="body2">
              {value || <Trans>Not specified</Trans>}
            </Typography>
          </Box>
        ))}
      </Flex>
      {children}
    </SectionBlock>
  );
}

ProfileBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  blockWidth: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
};

ProfileBlock.defaultProps = {
  children: null,
  title: null,
  list: [],
  blockWidth: [1 / 3, 1 / 4],
};

import {
  createEffect,
  createStore,
  forward,
  guard,
  sample,
  combine,
} from 'effector';

import { createFetching } from '@lib/effector-utils';
import { createGate } from 'effector-react';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { createMeetingsFilters, transformByDay } from '@features/meetings';
import dayjs from 'dayjs';
import { $projectList, projectListFetchRequesting } from '@features/common';
import * as projectApi from '../api';
import { projectUpdateRequesting } from './project-flow';

export const ProjectViewGate = createGate("Project's view");
export const ProjectMeetingsGate = createGate("Project's meetings view gate");
export const ProjectAttachmentsGate = createGate(
  "Project's attachments view gate",
);

// Events
export const fetchProjectRequesting = createEffect({
  name: 'project fetch requested',
  handler: projectApi.fetchProjectSingle,
});
export const fetchProjectTasksRequesting = createEffect({
  name: "project's task list requested",
  handler: projectApi.fetchTaskList,
});
export const fetchProjectMeetingsRequesting = createEffect({
  name: "project's meeting list requested",
  handler: projectApi.fetchMeetingList,
});
export const fetchProjectAttachmentsRequesting = createEffect({
  name: "project's meeting list requested",
  handler: projectApi.fetchAttachments,
});

// Stores
export const $project = createStore(null);
export const $projectId = $project.map(project => (project ? project.id : -1));
export const $projectFetching = createFetching(fetchProjectRequesting);
// Tasks
export const $taskListFetching = createFetching(fetchProjectTasksRequesting);
export const $taskList = createStore([]);
export const $tasksByStatus = $taskList.map(list =>
  list.reduce(reduceTasks, {}),
);
// Meetings
export const meetingsFilters = createMeetingsFilters();
export const $meetingsByDay = createStore([]);
export const $meetingFetching = createFetching(fetchProjectMeetingsRequesting);
// Attachments
export const $attachments = createStore([]);
export const $attachmentsFetching = createFetching(
  fetchProjectAttachmentsRequesting,
);

const $query = combine({
  date_from: meetingsFilters.$range.map(([start]) =>
    dayjs(start).format('YYYY-MM-DD'),
  ),
  date_to: meetingsFilters.$range.map(([_, end]) =>
    dayjs(end).format('YYYY-MM-DD'),
  ),
  project: $projectId,
});

// Reducers
$project
  .on(fetchProjectRequesting.done.map(mapProject), (_, data) => data)
  .on(projectUpdateRequesting.done.map(mapProject), (state, data) => data)
  .reset(ProjectViewGate.close);

$meetingsByDay
  .on(fetchProjectMeetingsRequesting.done, (_, { result }) =>
    transformByDay(result.data.results),
  )
  .reset(ProjectViewGate.close);

$taskList
  .on(fetchProjectTasksRequesting.done, (_, { result }) => result.data.tasks)
  .reset(ProjectViewGate.close);

$attachments
  .on(fetchProjectAttachmentsRequesting.done, (_, { result }) =>
    transformByDay(result.data, 'createdAt'),
  )
  .reset(ProjectViewGate.close);

guard({
  source: sample({ completed: false }, ProjectViewGate.open),
  filter: $projectList.map(list => list.length === 0),
  target: projectListFetchRequesting,
});

guard({
  source: sample($query, ProjectMeetingsGate.open),
  filter: $meetingsByDay.map(list => list.length === 0),
  target: fetchProjectMeetingsRequesting,
});

forward({
  from: ProjectViewGate.open,
  to: fetchProjectRequesting.prepend(({ id }) => id),
});

forward({
  from: fetchProjectRequesting.done.map(({ params }) => params),
  to: [fetchProjectTasksRequesting],
});

forward({
  from: sample($query, meetingsFilters.$range.updates),
  to: fetchProjectMeetingsRequesting,
});

guard({
  source: sample({ id: $projectId }, ProjectAttachmentsGate.open),
  filter: $attachments.map(list => list.length === 0),
  target: fetchProjectAttachmentsRequesting,
});

function mapProject({ result: { data: project } }) {
  return {
    ...project,
    members: [
      { ...project.creator, role: i18n._(t`Creator`) },
      { ...project.projectLead, role: i18n._(t`Project lead`) },
    ].concat(
      project.attendees.map(item => ({
        ...item.employee,
        role: i18n._(t`Attendee`),
      })),
    ),
  };
}

function reduceTasks(acc, item) {
  if (acc[item.status]) {
    acc[item.status] = acc[item.status].concat([item]);
  } else {
    acc[item.status] = [item];
  }

  return acc;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@oca/ui';
import { ChecklistItemTextarea } from '../atoms';

export function ChecklistItemContent({
  style,
  draft,
  editing,
  text,
  onClick,
  onChange,
  onBlur,
}) {
  if (editing) {
    return (
      <ChecklistItemTextarea
        value={draft}
        onChange={onChange}
        onBlur={onBlur}
        selectTextOnMount
      />
    );
  }

  return (
    <Typography lineHeight={1.4} onClick={onClick} style={style}>
      {text}
    </Typography>
  );
}

ChecklistItemContent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.objectOf(PropTypes.string),
  draft: PropTypes.string,
  editing: PropTypes.bool,
  text: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onBlur: PropTypes.func,
};

ChecklistItemContent.defaultProps = {
  draft: '',
  editing: false,
  text: '',
};

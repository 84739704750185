import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { PaginationItem, Previous, Next, Ellipsis } from './pagination-item';

export const Pagination = props => {
  const { children } = props;
  return <PaginationWrapper>{children}</PaginationWrapper>;
};

Pagination.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const PaginationWrapper = styled.div`
  display: inline-flex;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  color: #88909d;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
`;

Pagination.Item = PaginationItem;
Pagination.Prev = Previous;
Pagination.Next = Next;
Pagination.Ellipsis = Ellipsis;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Eps = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F7B84E"
    />
    <path
      d="M5.46599 18C5.25599 18 5.09399 17.838 5.09399 17.628V14.2222C5.09399 14.0062 5.25599 13.8502 5.46599 13.8502H7.61849C8.17049 13.8502 8.15849 14.6295 7.61849 14.6295H5.89199V15.5295H7.40324C7.93649 15.5295 7.93649 16.3155 7.40324 16.3155H5.89199V17.2147H7.72124C8.26724 17.2147 8.30849 18 7.72124 18H5.46599Z"
      fill="white"
    />
    <path
      d="M8.92877 14.2102C8.92877 14.0122 9.08477 13.7954 9.33677 13.7954H10.722C11.502 13.7954 12.204 14.3174 12.204 15.3187C12.204 16.2667 11.502 16.7939 10.722 16.7939H9.72077V17.5859C9.72077 17.8507 9.55277 17.9999 9.33677 17.9999C9.13877 17.9999 8.92877 17.8507 8.92877 17.5859V14.2102ZM9.72077 14.5514V16.0447H10.722C11.124 16.0447 11.442 15.6907 11.442 15.3187C11.442 14.8987 11.124 14.5514 10.722 14.5514H9.72077Z"
      fill="white"
    />
    <path
      d="M12.8813 14.7495C13.0193 13.5862 14.7758 13.377 15.6038 14.013C16.0118 14.3422 15.5798 14.8642 15.2198 14.6002C14.7758 14.3182 13.7685 14.1862 13.6425 14.8095C13.4805 15.7935 16.089 15.2302 16.047 16.8247C16.005 18.348 13.7985 18.384 12.9705 17.7C12.7725 17.5387 12.7785 17.2755 12.8865 17.1127C13.0425 16.9575 13.2158 16.9035 13.4205 17.0707C13.9118 17.4067 15.1778 17.6587 15.2678 16.806C15.1898 15.9195 12.6953 16.4535 12.8813 14.7495Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import styled from 'styled-components';

import { Label } from '../atoms/label';

export const FormControl = React.forwardRef(function FormControl(props, ref) {
  const {
    children,
    color: colorProp,
    disabled,
    error,
    fullWidth,
    helperText,
    label,
    required,
    spaceAfter,
  } = props;
  const color = (error && 'error') || (disabled && 'disabled') || colorProp;

  return (
    <Box
      width={fullWidth ? 1 : undefined}
      marginBottom={spaceAfter ? 2 : 0}
      ref={ref}
    >
      {label && (
        <Label required={required} color={color}>
          {label}
        </Label>
      )}
      {React.cloneElement(children, { error })}
      {helperText && (
        <HelperText
          as="p"
          lineHeight={1}
          margin={0}
          marginTop={1}
          fontSize={0}
          color={color}
        >
          {helperText}
        </HelperText>
      )}
    </Box>
  );
});

FormControl.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  label: PropTypes.node,
  required: PropTypes.bool,
  spaceAfter: PropTypes.bool,
};

FormControl.defaultProps = {
  children: null,
  color: 'text',
  disabled: false,
  error: false,
  fullWidth: false,
  helperText: null,
  label: null,
  required: false,
  spaceAfter: false,
};

const HelperText = styled(Text)`
  min-height: 1em;
`;

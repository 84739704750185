import React from 'react';
import { createPortal } from 'react-dom';
import { Flex, Box, Image, Text } from 'rebass';
import styled from 'styled-components';
import { useKey } from 'react-use';
import { useSpring, animated } from 'react-spring';
import { saveAs } from 'file-saver';

import { fileIcon, fetcher } from '@features/common';
import { signUrl } from '@features/common/lib/sign-url';
import { notify } from '@lib/notifier';
import { ArrowLeft2, Download, StarDefault, Print } from '@oca/icons';
import { IconButton, Typography } from '../atoms';

// eslint-disable-next-line react/prop-types
export const Previewer = ({ fileName, file, fileExt, starFn, onClose }) => {
  const bodyNode = React.useRef();
  const headerStyleProps = useSpring({
    transform: 'translateY(0)',
    from: { transform: 'translateY(-30px)' },
  });
  const bodyStyleProps = useSpring({
    transform: 'translateY(0)',
    opacity: 1,
    from: { transform: 'translateY(15px)', opacity: 0 },
  });
  useKey('Escape', onClose);

  function handleBodyClick(e) {
    e.persist();

    if (e.target === bodyNode.current) {
      onClose();
    }
  }

  const fileInfo = fileIcon.find(
    item => item.ext === fileExt || item.ext === 'default',
  );

  if (!fileName && !file && !fileExt) {
    return null;
  }

  return createPortal(
    <ViewerWrap>
      <Header
        style={headerStyleProps}
        padding={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <IconButton color="white" mr={10} onClick={onClose}>
            <ArrowLeft2 />
          </IconButton>
          <Box>
            <Flex alignItems="center">
              <Flex mr={10} alignItems="center">
                {React.createElement(fileInfo.icon, {
                  color: '#0076F4',
                  fontSize: 24,
                })}
              </Flex>
              <Typography variant="h3" color="white">
                {fileName}
              </Typography>
            </Flex>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <IconButton
            color="white"
            onClick={() => downloadFile(fileName, file)}
          >
            <Download />
          </IconButton>
          {starFn && (
            <IconButton color="white" onClick={starFn}>
              <StarDefault />
            </IconButton>
          )}
          <IconButton color="white" onClick={() => window.print()}>
            <Print />
          </IconButton>
        </Flex>
      </Header>
      <Body style={bodyStyleProps} onClick={handleBodyClick} ref={bodyNode}>
        <Content type={fileInfo.type}>
          <PreviewerFile
            url={signUrl(file)}
            type={fileInfo.type}
            ext={fileInfo.ext}
          />
        </Content>
      </Body>
    </ViewerWrap>,
    document.body,
  );
};

const ViewerWrap = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  align-items: center;
`;

const HeaderStyled = styled(Flex)`
  max-width: 1372px;
  width: 100%;
`;

const Header = animated(HeaderStyled);

const BodyStyled = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 1372px;
  width: 100%;
  overflow: auto;
`;

const Body = animated(BodyStyled);

const Content = styled(Flex)`
  width: ${({ type }) => (type === 'doc' ? '70%' : 'auto')};
  height: ${({ type }) => (type === 'doc' ? '100%' : 'auto')};
  max-width: 80%;
  & * {
    outline: none;
  }
  & iframe {
    width: 100%;
    height: 100%;
  }
  & img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`;

// eslint-disable-next-line react/prop-types
const PreviewerFile = ({ type, url, ext }) => {
  let result = (
    <Text color="white">Previewer not supports this kind of files</Text>
  );

  switch (type) {
    case 'doc':
      result = (
        <iframe
          src={`https://docs.google.com/gview?url=${url}&embedded=true`}
          style={{ border: 'none' }}
        />
      );
      break;

    case 'img':
      result = <Image src={url} alt="image" />;
      break;

    case 'audio':
      result = (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls>
          <source src={url} type={`audio/${ext}`} />
          Your browser does not support the audio element.
        </audio>
      );
      break;

    case 'video':
      result = (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video width="600" height="400" controls>
          <source src={url} type={`video/${ext}`} />
          Your browser does not support the video tag.
        </video>
      );
      break;

    default:
      break;
  }

  return result;
};

const downloadFile = (fileName, file) =>
  fetcher
    .get(file, { responseType: 'blob' })
    .then(response => {
      const fileBlob = new Blob([response.data], { type: 'text/plain' });
      saveAs(fileBlob, fileName);
    })
    .catch(error => {
      notify.error('Unable to download file! Try again later.');
    });

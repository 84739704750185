import React from 'react';
import PropTypes from 'prop-types';

import { CommonPageTemplate } from '@features/common';

/**
 *
 * @param {import('rebass').BoxProps & { title: string }}
 */
export function CommonEmployeePageTemplate({
  actions,
  children,
  heading,
  title,
  ...rest
}) {
  return (
    <CommonPageTemplate
      title={title}
      heading={heading}
      actions={actions}
      contentWidth={1}
    >
      {children}
    </CommonPageTemplate>
  );
}

CommonEmployeePageTemplate.propTypes = {
  // eslint-disable-next-line react/require-default-props
  actions: PropTypes.node,
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
};

CommonEmployeePageTemplate.defaultProps = {
  children: null,
  title: null,
};

import saveFile from 'file-saver';
import { fetcher } from '../fetcher';

/**
 *
 * @param {{ url: string, filename: string}}
 */
export function downloadFile({ url, filename }) {
  fetcher
    .get(url, { responseType: 'blob' })
    .then(res => saveFile(res.data, filename));
}

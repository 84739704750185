import { fetcher } from '@features/common';

const endpoint = '/api/v1/meetings/';

export function fetchMeetingsList(config) {
  return fetcher.get(endpoint, config);
}

export function fetchMeeting(id) {
  return fetcher.get(`${endpoint + id}/`);
}

export function createMeeting(data) {
  return fetcher.post(endpoint, data);
}

export function updateMeeting(data) {
  return fetcher.patch(`${endpoint + data.id}/`, data);
}

export function removeMeeting(id) {
  return fetcher.delete(`${endpoint + id}/`);
}

export function addMeetingComment(id, data) {
  return fetcher.post(`${endpoint + id}/create_comment/`, data);
}

// -------------------------------------------

const prjectEndPoint = '/api/v1/projects/';
export function fetchProjectList() {
  return fetcher.get(prjectEndPoint);
}

const employeesEndPoint = '/api/v1/employees/';
export function fetchEmployeesList() {
  return fetcher.get(employeesEndPoint);
}

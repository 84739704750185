import { createEvent, createStore } from 'effector';
import debounce from 'debounce';

export const debounceStore = (time, store) => {
  const setState = createEvent('Update debounced state');
  const debounced = createStore(store.getState()).on(
    setState,
    (state, next) => next,
  );
  const ev = debounce(setState, time);
  store.watch(ev);

  return debounced;
};

import React from 'react';
import { useStore } from 'effector-react';
import { t, Trans } from '@lingui/macro';

import { i18n } from '@lib/i18n';
import { Dialog, Typography, Button } from '@oca/ui';

import { $moveConfirmation } from '../model/file-browser.view';
import {
  onEntityMoveAccept,
  onEntityMoveReject,
} from '../model/file-browser.events';

export function FileMoveConfirmation() {
  const { open, selected, moveTo } = useStore($moveConfirmation);

  if (open) {
    return (
      <Dialog
        title={i18n._(t`Move files`)}
        onClose={onEntityMoveReject}
        underlayClickExits={false}
      >
        <Typography marginBottom={20}>
          <Trans>
            This action will move selected ({selected.length}) files to{' '}
            <strong>{moveTo.name}</strong> folder
          </Trans>
        </Typography>
        <Button variant="primaryOutlined" mr={20} onClick={onEntityMoveAccept}>
          <Trans>Move</Trans>
        </Button>
        <Button onClick={onEntityMoveReject}>
          <Trans>Cancel</Trans>
        </Button>
      </Dialog>
    );
  }

  return null;
}

import { createGlobalStyle } from 'styled-components';
import { breakpointsMap, colors } from './ui/theme';

export const GlobalStyles = createGlobalStyle`
  :root {
    --header-height: 56px;
    --nav-collapsed-width: 80px;
    --nav-expanded-width: 240px;
    --content-width: 1100px;
  }

  @media (min-width: ${breakpointsMap.xlarge}px) {
    :root {
      --content-width: 1280px;
    }
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  } 

  body {
    position: relative;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  body {
    .splide__arrow--next,
    .splide__arrow--prev {
      background: none;
      color: ${colors.primary};
      &:hover:not(:disabled) {
        opacity: 0.9;
      }
      &:disabled {
        color: ${colors.textSecondary};
        cursor: default;
      }
      & svg {
        fill: currentColor;
      }
    }

    .splide__arrow--next {
      right: -2em !important; 
    }

    .splide__arrow--prev {
      left: -2em !important; 
    }

    .ReactCrop__image {
      max-height: fill-available;
    }

  }

  html[dir='rtl'] {
    .flatpickr-calendar {
      .flatpickr-months {
        /* @noflip */
        direction: rtl;
      }
      .flatpickr-next-month,
      .flatpickr-prev-month {
        transform: scaleX(-1);
      }
      .numInputWrapper {
        .arrowUp,
        .arrowDown {
          /* @noflip */
          right: auto;
          /* @noflip */
          left: 0;
        }
      }
    }

    .mode-control {
      flex-direction: row-reverse;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card as CardBase } from 'rebass';
import styled from 'styled-components';
import { Typography, AssigneeList } from '@oca/ui';
import { Location, PencilRuler } from '@oca/icons';
import dayjs from 'dayjs';

export const Card = React.forwardRef(
  /**
   *
   * @param {CardProps} props
   * @param {React.Ref} ref
   */
  function Card(props, ref) {
    const {
      endTime,
      formatStr,
      location,
      project,
      startTime,
      status,
      title,
      assignees,
      ...rest
    } = props;
    const range = [new Date(startTime), new Date(endTime)]
      .map(date => dayjs(date).format(formatStr))
      .join(' - ');

    return (
      <CardContainer
        width={260}
        {...rest}
        pt={24}
        px={3}
        pb={2}
        bg="greys.1"
        ref={ref}
      >
        <StatusIndicator status={status} />
        <Typography fontSize={24} fontWeight={100}>
          {range}
        </Typography>
        <Box mt={24}>
          <Typography title={title} variant="h3" gutterBottom noWrap>
            {title}
          </Typography>
          <Typography
            title={location}
            fontSize={14}
            lineHeight="24px"
            gutterBottom
            noWrap
          >
            <Location />
            &nbsp;
            {location}
          </Typography>
          <Typography
            title={project}
            fontSize={14}
            lineHeight="24px"
            gutterBottom
            noWrap
          >
            <PencilRuler />
            &nbsp;
            {project}
          </Typography>
        </Box>
        <AssigneeList assignees={assignees} />
      </CardContainer>
    );
  },
);

Card.propTypes = {
  endTime: PropTypes.string,
  formatStr: PropTypes.string,
  location: PropTypes.string,
  project: PropTypes.string,
  startTime: PropTypes.string,
  status: PropTypes.oneOf(['upcoming', 'completed']),
  title: PropTypes.string,
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
    }),
  ),
};

Card.defaultProps = {
  endTime: null,
  formatStr: 'LT',
  location: null,
  project: null,
  startTime: null,
  status: 'upcoming',
  title: '',
  assignees: [],
};

const CardContainer = styled(CardBase).attrs(({ onClick }) => ({
  'data-clickable': !!onClick,
}))`
  display: block;
  position: relative;
  min-height: 210px;
  max-width: 260px;
  overflow: hidden;
  border-radius: 8px;
  text-decoration: none;
  &[data-clickable='true'] {
    cursor: pointer;
  }
`;

const StatusIndicator = styled.div.attrs(p => ({ 'data-status': p.status }))`
  position: absolute;
  left: 0;
  top: 30px;
  width: 4px;
  height: 24px;
  &[data-status='upcoming'] {
    background-color: ${p => p.theme.colors.danger};
  }
  &[data-status='completed'] {
    background-color: ${p => p.theme.colors.success};
  }
  &[data-status='cancelled'] {
    background-color: ${p => p.theme.colors.error};
  }
`;

/**
 * @typedef {import('../model/types').Meeting & { assignees: [] }} CardProps
 */

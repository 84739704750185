import React from 'react';
import { SvgIcon } from './svg-icon';

export const Mov = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#50BEE8"
    />
    <path
      d="M4.54352 15.3674V17.5859C4.54352 17.8507 4.32752 17.9999 4.11152 17.9999C3.91952 17.9999 3.75152 17.8507 3.75152 17.5859V14.2102C3.75152 13.8982 4.01552 13.7954 4.11152 13.7954C4.28477 13.7954 4.38677 13.8982 4.48952 14.0122L5.80802 15.7927L7.17527 13.9454C7.37402 13.7002 7.85927 13.7954 7.85927 14.2102V17.5859C7.85927 17.8507 7.69202 17.9999 7.50002 17.9999C7.28402 17.9999 7.11602 17.8507 7.11602 17.5859V15.3674L6.12002 16.6267C5.90477 16.8914 5.63477 16.8914 5.43677 16.6267L4.54352 15.3674Z"
      fill="white"
    />
    <path
      d="M10.9733 18.0599C9.86404 18.1079 8.71204 17.3699 8.71204 15.9007C8.71204 14.4254 9.86329 13.7002 10.9733 13.7002C12.0225 13.7534 13.1198 14.4794 13.1198 15.9007C13.1198 17.3227 12.0225 18.0599 10.9733 18.0599ZM10.9073 14.4802C10.2353 14.4802 9.50404 14.9542 9.50404 15.9014C9.50404 16.8434 10.236 17.3234 10.9073 17.3234C11.5973 17.3234 12.3345 16.8434 12.3345 15.9014C12.3345 14.9534 11.5973 14.4802 10.9073 14.4802Z"
      fill="white"
    />
    <path
      d="M15.1717 17.7307L13.5885 14.4142C13.3545 13.9402 14.0625 13.551 14.3257 14.0482L14.8597 15.2535L15.549 16.8367L16.2262 15.2535L16.7602 14.0482C17.0002 13.593 17.6955 13.8802 17.4922 14.3782L15.963 17.7307C15.825 18.1087 15.399 18.198 15.1717 17.7307Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import { createMediaMatcher } from '@lib/effector-utils';
import { breakpointsMap } from '@oca/ui/theme';
import { createStoreObject } from 'effector';

const { small, medium, large, xlarge } = breakpointsMap;

export const $mediaQuery = createStoreObject({
  xsmall: createMediaMatcher(`(max-width: ${small - 1}px)`),
  small: createMediaMatcher(createRange(small, medium)),
  medium: createMediaMatcher(createRange(medium, large)),
  large: createMediaMatcher(createRange(large, xlarge)),
  xlarge: createMediaMatcher(`(min-width: ${xlarge}px)`),
  portrait: createMediaMatcher('(orientation: portrait)'),
});

function createRange(min, max, threshold = 1) {
  return `(min-width: ${min}px) and (max-width: ${max - threshold}px)`;
}

import React from 'react';
import { Flex, Box } from 'rebass';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { maxWidth as maxWidthFn } from 'styled-system';
import { Trans } from '@lingui/macro';

import { Account } from '@features/common';
import {
  Avatar,
  Typography,
  Spinner,
  File,
  Menu,
  IconButton,
  Previewer,
  Tooltip,
  Slider,
  Slide,
} from '@oca/ui';
import { Person, Edit, Delete, MenuDots } from '@oca/icons';
import { deleteCommentRequesting, setCommentText } from '../model/comment-flow';

// eslint-disable-next-line react/prop-types
export const Comment = ({ comment, maxWidth }) => {
  const [deletingIsOn, setDeleteStatus] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const isEdited = dayjs(new Date(comment.updatedAt)).isAfter(
    new Date(comment.createdAt),
  );

  const attachmentsSliderOptions = React.useMemo(
    () => ({
      gap: '1rem',
      breakpoints: {
        768: { perPage: 2 },
        1100: { perPage: 3 },
      },
      arrows: comment.attachmentFiles && comment.attachmentFiles.length > 3,
    }),
    [comment],
  );

  return (
    <Flex mb={20}>
      <Link to={`/hr/employees/${comment.author.username}`}>
        <Avatar src={comment.author.avatar} size={30} mr={10}>
          <Person color="#aaa" />
        </Avatar>
      </Link>
      <Box flex={1}>
        <Flex alignItems="center">
          <Box mr={3}>
            <Link
              to={`/hr/employees/${comment.author.username}`}
              style={{ textDecoration: 'none' }}
            >
              <Typography fontWeight={500}>
                {`${comment.author.firstName} ${comment.author.lastName}`}
              </Typography>
            </Link>
          </Box>
          <Typography as="div" variant="caption" color="textSecondary">
            {dayjs(new Date(comment.createdAt)).format('LLL')}
            &nbsp;
            {isEdited && (
              <Tooltip
                title={dayjs(new Date(comment.editedAt)).format('LLL')}
                placement="bottom"
              >
                (edited)
              </Tooltip>
            )}
          </Typography>
          <Account
            render={({ user }) => {
              return (
                user.id === comment.author.id && (
                  <Flex ml="auto">
                    {deletingIsOn ? (
                      <Flex
                        style={{ height: 30, width: 30 }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Spinner size={16} />
                      </Flex>
                    ) : (
                      <Menu
                        placement="bottom-start"
                        anchor={
                          <IconButton size="small" padding="5px" color="text">
                            <MenuDots />
                          </IconButton>
                        }
                      >
                        <Menu.Item
                          icon={<Edit />}
                          onClick={() => setCommentText(comment)}
                        >
                          <Typography>
                            <Trans>Edit</Trans>
                          </Typography>
                        </Menu.Item>
                        <Menu.Item
                          icon={<Delete />}
                          onClick={() => {
                            setDeleteStatus(true);
                            deleteCommentRequesting(comment.id).then(() => {
                              setDeleteStatus(false);
                            });
                          }}
                        >
                          <Typography>
                            <Trans>Delete</Trans>
                          </Typography>
                        </Menu.Item>
                      </Menu>
                    )}
                  </Flex>
                )
              );
            }}
          />
        </Flex>

        <Typography gutterBottom>{replaceLinks(comment.text)}</Typography>

        {comment.attachmentFiles.length > 0 && (
          <CommentAttachmentsContainer width={1} maxWidth={maxWidth}>
            {preview && (
              <Previewer {...preview} onClose={() => setPreview(null)} />
            )}

            <Slider
              id={`files-glider-${comment.id}`}
              key={comment.attachmentFiles.length}
              options={attachmentsSliderOptions}
            >
              {comment.attachmentFiles.map(
                ({ id, fileName, fileExt, file, size }) => (
                  <Slide key={id}>
                    <File
                      name={fileName}
                      ext={fileExt}
                      size={size}
                      onClick={() => setPreview({ fileName, file, fileExt })}
                    />
                  </Slide>
                ),
              )}
            </Slider>
          </CommentAttachmentsContainer>
        )}
      </Box>
    </Flex>
  );
};

const CommentAttachmentsContainer = styled(Box)`
  ${maxWidthFn};
`;

// Replace links in text to HTML links
const replaceLinks = text => {
  const matched = urlsAsArray(text);

  if (matched) {
    const components = linkLessTextArray(text, matched).map((el, i) => {
      return (
        <React.Fragment>
          <Typography as="span">{el}</Typography>
          <ExternalLink url={matched[i]} />
        </React.Fragment>
      );
    });

    return components;
  }
  return <Typography as="span">{text}</Typography>;
};

const urlsAsArray = text =>
  (text || '').match(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi);

const linkLessTextArray = (text, urls) => {
  let linkLess = text;
  urls.forEach(el => {
    linkLess = linkLess.replace(el, ',');
  });
  return linkLess.split(',');
};

const ExternalLink = ({ url }) => {
  const trimmedUrl = url.trim();
  return url ? (
    <React.Fragment>
      {' '}
      <a
        href={!url.match('^https?://') ? `http://${trimmedUrl}` : trimmedUrl}
        target="_blank"
        style={{
          color: 'primary',
          textDecoration: 'none',
        }}
      >
        {trimmedUrl}
      </a>
    </React.Fragment>
  ) : (
    ''
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string,
};

ExternalLink.defaultProps = {
  url: '',
};

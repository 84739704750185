import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import {
  FileShareDialog,
  FileUploadProgress,
  CreateFolderDialog,
  FilePreview,
} from '../organisms';

export const DocumentsRootPage = React.memo(function DocumentsRootPage({
  route,
  match,
}) {
  if (match.isExact) {
    const [firstRoute] = route.routes || [];

    return <Redirect to={firstRoute ? firstRoute.path : match.path} />;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      {renderRoutes(route.routes)}
      <FilePreview />
      <FileShareDialog />
      <FileUploadProgress />
      <CreateFolderDialog />
    </DndProvider>
  );
});

DocumentsRootPage.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
      }),
    ),
  }).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
  }).isRequired,
};

import dayjs from 'dayjs';

import { reminderTimeSelect } from '../data';

export const remindTimeOptions = startTime => {
  if (startTime) {
    return reminderTimeSelect.filter(item => {
      const [hour, minutes] = item.value.split(':');

      return dayjs(new Date(startTime)).isAfter(
        dayjs()
          .add(hour, 'hour')
          .add(minutes, 'minute'),
      );
    });
  }
  return reminderTimeSelect;
};

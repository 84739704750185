import React from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Typography } from '../atoms';
import { StatusIcon } from './status-icon';

export const MeetingPreview = ({ data, fullDate, ...props }) => {
  return (
    <MeetingWrap {...props}>
      <Typography variant="h3" fontSize={18}>
        <BtnLink to={`/meetings/${data.id}`}>{data.title}</BtnLink>
      </Typography>
      <Flex my={10} alignItems="center">
        <Flex alignItems="center" mr={20}>
          <Flex mr="5px">{StatusIcon(data.status)}</Flex>
          <Typography
            as="span"
            fontSize={12}
            style={{ textTransform: 'capitalize' }}
          >
            {data.status ? data.status : 'n/a'}
          </Typography>
        </Flex>
        <Typography as="span" fontSize={12}>
          {fullDate && dayjs(new Date(data.startTime)).format('dddd, LL')}
          &nbsp;
          {timeRange(data.startTime, data.durationTime)}
        </Typography>
      </Flex>
      {data.description ? (
        <Typography lineHeight={1.5} color="textSecondary">
          {data.description}
        </Typography>
      ) : (
        ''
      )}
    </MeetingWrap>
  );
};
MeetingPreview.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    startTime: PropTypes.string,
  }),
  fullDate: PropTypes.bool,
};
MeetingPreview.defaultProps = {
  data: {},
  fullDate: false,
};

const timeRange = (startTime, duration) => {
  const start = dayjs(new Date(startTime)).format('LL');
  if (duration) {
    const [hours, minutes] = duration.split(':').map(Number);

    if (Number.isInteger(hours) && Number.isInteger(minutes)) {
      const finish = dayjs(new Date(startTime))
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .format('LT');

      return `${start} - ${finish}`;
    }

    return start;
  }
  return start;
};

const MeetingWrap = styled(Box)({
  marginTop: 40,
  marginBottom: 40,
  '&:first-child': {
    marginTop: 0,
  },
  '&:last-child': {
    marginBottom: 0,
  },
});

const BtnLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

import { createEffect } from 'effector';
import { createFetching } from '@lib/effector-utils';

import { $commentList } from '@features/common';
import { addMeetingComment } from '../api';

// Events
export const addMeetingCommentRequest = createEffect('add meeting comment');

// Set thunks
addMeetingCommentRequest.use(({ id, data }) => {
  return addMeetingComment(id, data);
});

// Stores
export const $commentFetching = createFetching(addMeetingCommentRequest);

$commentList.on(addMeetingCommentRequest.done, (state, { result }) => [
  result.data,
  ...state,
]);

import React from 'react';
import { useStore } from 'effector-react';
import { Box } from 'rebass';
import { t } from '@lingui/macro';

import { Dialog, TextField, Button } from '@oca/ui';
import { i18n } from '@lib/i18n';

import {
  $folderCreateDialogOpen,
  toggleFolderCreateDialog,
} from '../model/file-browser.view';
import { onFolderCreateSubmit } from '../model/file-browser.events';

export function CreateFolderDialog() {
  const [name, setName] = React.useState('');
  const open = useStore($folderCreateDialogOpen);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      minWidth={400}
      title={i18n._(t`Create folder`)}
      onClose={() => {
        setName('');
        toggleFolderCreateDialog(false);
      }}
    >
      <Box mb={3}>
        <TextField
          maxLength={140}
          label={i18n._(t`Name`)}
          placeholder={i18n._(t`Enter folder name`)}
          onChange={e => setName(e.target.value)}
          value={name}
          autoFocus
        />
      </Box>
      <Button
        variant="primary"
        onClick={() => onFolderCreateSubmit(name)}
        disabled={!name}
        fullWidth
      >
        {i18n._(t`Submit`)}
      </Button>
    </Dialog>
  );
}

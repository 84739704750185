import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@oca/ui';
import { TaskCreateForm, createTaskRequest } from '@features/tasks';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { resolveRejectedPromise, toISOWithTimezone } from '@lib/help-fns';
import { FORM_ERROR } from 'final-form';
import dayjs from 'dayjs';
import { fetchProjectTasksRequesting } from '../model/project-view-flow';

export function CreateProjectTask({ project, onClose }) {
  return (
    <Dialog
      title={i18n._(t`Create task for ${project.name}`)}
      onClose={onClose}
    >
      <TaskCreateForm
        initialValues={{
          attachmentFiles: [],
          priority: 'low',
          status: 'todo',
          project: project.id,
        }}
        onSubmit={values =>
          handleSubmit(values).then(() => {
            onClose();
            fetchProjectTasksRequesting(project.id);
          })
        }
      />
    </Dialog>
  );
}

CreateProjectTask.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

async function handleSubmit(data) {
  const dataObject = { ...data };

  if (data.dueDate) {
    dataObject.dueDate = toISOWithTimezone(new Date(data.dueDate));
  }

  if (typeof data.project === 'object') {
    dataObject.project = data.project.id;
  }

  const error = await resolveRejectedPromise(createTaskRequest(dataObject));

  if (error && error.message) {
    return {
      [FORM_ERROR]: i18n._(
        t`Something went wrong with a server, please try again later`,
      ),
    };
  }

  return error && error.data ? error.data : null;
}

import { merge, forward, sample } from 'effector';
import {
  $search,
  $breadcrumbs,
  $filesRaw,
  $filePreviewId,
  $selectedFileIds,
  $pivotRow,
  $folderMoveId,
} from './file-browser.stores';
import {
  changeSearchInput,
  fetchFilesRequest,
  setFilePreviewId,
  setMoveFileId,
  clearSelectedFiles,
  toggleSelectAllFiles,
  downloadFileRequest,
  downloadEntity,
  toggleSelectFile,
  toggleSelectOne,
  toggleSelectDefault,
  toggleSelectGroup,
  moveEntityTo,
  onEntityMoveReject,
  onEntityMoveAccept,
  moveFilesToFolderRequest,
} from './file-browser.events';
import { normalizeBreadcrumbs } from '../lib/helpers';
import {
  FileBrowserGate,
  toggleMoveEntityConfirmationDialog,
  toggleEntityMoveDialog,
} from './file-browser.view';
import { folderClicked, FolderBrowserGate } from './folder-browser.model';
import * as api from '../api';

// Reducers //
$filesRaw
  .on(fetchFilesRequest.doneData, (_, { data }) => data)
  .reset(FileBrowserGate.close);

$breadcrumbs
  .on(fetchFilesRequest.done, (_, { result }) =>
    normalizeBreadcrumbs(result.data),
  )
  .reset(FileBrowserGate.close);

$search
  .on(changeSearchInput, (_, value) => value)
  .reset(fetchFilesRequest, FileBrowserGate.close);

$filePreviewId.on(setFilePreviewId, (_, id) => id).reset(FileBrowserGate.close);

$pivotRow
  .on(merge([toggleSelectOne, toggleSelectDefault]), (_, { id }) => id)
  .reset(clearSelectedFiles);

$selectedFileIds
  .on(toggleSelectOne, (_, { id }) => new Set([id]))
  .on(toggleSelectDefault, (selected, { id }) => {
    if (selected.has(id)) {
      selected.delete(id);
      return new Set([...selected]);
    }

    return new Set([...selected.add(id)]);
  })
  .on(
    sample($pivotRow, toggleSelectGroup, (pivotId, params) => ({
      ...params,
      pivotId,
    })),
    onToggleSelectGroup,
  )
  .on(toggleSelectAllFiles, (_, ids = []) => new Set(ids))
  .on(clearSelectedFiles, prev => (prev.size > 0 ? new Set([]) : prev));

$folderMoveId
  .on(moveEntityTo, (_, id) => id)
  .on(folderClicked, (_, id) => id)
  .reset([FileBrowserGate.close, FolderBrowserGate.close]);

// Side effects //
// Fetch files on FileBrowser start up and if params are changed
forward({
  from: FileBrowserGate.state,
  to: fetchFilesRequest,
});

// Request download when user click "download"
forward({
  from: downloadEntity,
  to: downloadFileRequest,
});

// Open confirmation dialog when user drops files to folder
forward({
  from: moveEntityTo,
  to: toggleMoveEntityConfirmationDialog.prepend(() => true),
});

// Open move file dialog when user click "move" button
forward({
  from: setMoveFileId,
  to: [
    toggleEntityMoveDialog.prepend(() => true),
    toggleSelectFile.prepend(id => ({ id, isRow: true })),
  ],
});

// Close confirmation dialog when user either accept or reject it
forward({
  from: [onEntityMoveReject, onEntityMoveAccept],
  to: [
    toggleMoveEntityConfirmationDialog.prepend(() => false),
    toggleEntityMoveDialog.prepend(() => false),
  ],
});

forward({
  from: [
    FileBrowserGate.close,
    moveFilesToFolderRequest.done,
    fetchFilesRequest,
  ],
  to: clearSelectedFiles,
});

// Connect thunks
downloadFileRequest.use(api.downloadFile);
fetchFilesRequest.use(api.fetchFiles);

// Helpers //
/**
 *
 * @param {Set<number>} selected
 * @param {{ id: number, sortedData: [], pivotId: number }} next
 * @returns {Set<number>}
 */
function onToggleSelectGroup(
  selected,
  { pivotId, id: nextHeadId, sortedData = [] },
) {
  const [startIndex, endIndex] = sortedData
    .reduce(
      (acc, item, index) =>
        [pivotId, nextHeadId].includes(item.id) ? acc.concat([index]) : acc,
      [],
    )
    .sort((a, b) => a - b);

  const nextSelected = sortedData
    .slice(startIndex, endIndex + 1)
    .map(item => item.id);

  return new Set(nextSelected);
}

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Check = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12ZM11.444 14.9036L16.444 9.4036L15.556 8.5964L10.9426 13.6712L8.38411 11.5391L7.61589 12.4609L10.6159 14.9609L11.0574 15.3288L11.444 14.9036Z"
    />
  </SvgIcon>
);

import React from 'react';
import { Box } from 'rebass';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { useStore } from 'effector-react';

import { UploadItem } from '@oca/ui';
import { LightClose } from '@oca/icons';

import { $uploadFileModel } from '../model/file-browser.view';
import { cancelFileUploading } from '../model/file-browser.events';

export function FileUploadProgress() {
  const { file, progress, uploading } = useStore($uploadFileModel);

  if (file && uploading) {
    return (
      <Container width={[280, 320]} padding={1} bg="blues.3">
        <UploadItem
          bg="greys.0"
          marginBottom={0}
          progress={progress}
          title={file.name}
          statusText={
            <small>
              <Trans>Uploading {progress}%</Trans>
            </small>
          }
          renderActions={() => (
            <UploadItem.Action onClick={cancelFileUploading}>
              <LightClose />
            </UploadItem.Action>
          )}
        />
      </Container>
    );
  }

  return null;
}

const Container = styled(Box)`
  position: fixed;
  z-index: 100;
  bottom: 12px;
  right: 30px;
  border-radius: 4px;
`;

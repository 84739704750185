import React from 'react';
import { t } from '@lingui/macro';
import { FileEdit } from '@oca/icons';
import { i18n } from '@lib/i18n';
import { routes as assetsRoutes } from './features/assets';
import { routes as attendanceRoutes } from './features/attendance';
import { routes as leavesRoutes } from './features/leaves';
import { routes as employeeRoutes } from './features/employee';
import { routes as eventsRoutes } from './features/events';
import { routes as holidayssRoutes } from './features/holidays';

const MainPage = React.lazy(() =>
  import('./pages/main').then(module => ({ default: module.MainPage })),
);

export const routes = [
  {
    path: '/hr',
    component: MainPage,
    routes: [
      ...employeeRoutes,
      ...attendanceRoutes,
      ...leavesRoutes,
      ...assetsRoutes,
      ...eventsRoutes,
      ...holidayssRoutes,
    ],
    navigation: {
      icon: <FileEdit />,
      name: i18n._(t`HR`),
    },
  },
];

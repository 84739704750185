import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useSpring, animated } from 'react-spring';

export const BasicSidebarTemplate = React.forwardRef(
  /**
   *
   * @param {BasicSidebarTemplateProps} props
   */
  function BasicSidebarTemplate(props, ref) {
    const { animateOnMount, ...rest } = props;
    const styleProps = useSpring({
      transform: 'translateX(0)',
      opacity: 1,
      from: { transform: 'translateX(30px)', opacity: 0 },
    });

    return (
      <AnimatedBox
        ref={ref}
        style={animateOnMount ? styleProps : null}
        ml={[0, 3]}
        mt={[3, 0]}
        {...rest}
      />
    );
  },
);

BasicSidebarTemplate.propTypes = {
  animateOnMount: PropTypes.bool,
};

BasicSidebarTemplate.defaultProps = {
  animateOnMount: true,
};

const AnimatedBox = animated(Box);

/**
 * @typedef {import('rebass').BoxProps & { animateOnMount: Boolean }} BasicSidebarTemplateProps
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { boxShadow, position } from 'styled-system';
import { Box } from 'rebass';
import { useLocation } from 'react-router-dom';
import useClickAway from 'react-use/lib/useClickAway';

import { IconButton } from '@oca/ui';
import { Hamburger } from '@oca/icons';

import { ScrollShadow } from '@lib/scroll-shadow';
import { NavList } from './nav-list';

export const Navigation = React.memo(function Navigation(props) {
  const { routes, open, toggle, onClose } = props;
  const navigationRef = useRef(null);
  const location = useLocation();

  useClickAway(navigationRef, onClose);

  return (
    <NavigationWrapper ref={navigationRef} boxShadow={4} data-is-open={open}>
      <Box px={3} py={1}>
        <MenuButton onClick={toggle} />
      </Box>
      <ScrollShadow
        topShadowColors={{
          active:
            'linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 70%)',
          inactive: '#0097fa',
        }}
        bottomShadowColors={{
          active:
            'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(255,255,255,0) 70%)',
          inactive: '#0097fa',
        }}
        shadowSize={12}
        hideScrollbar
      >
        <NavList
          collapsed={!open}
          depth={0}
          location={location}
          pages={routes}
          onItemClick={onClose}
        />
      </ScrollShadow>
    </NavigationWrapper>
  );
});

Navigation.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
      routes: PropTypes.array,
    }),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  toggle: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};

const NavigationWrapper = styled(Box)`
  height: 100%;
  background: #0097fa;
  display: flex;
  flex-direction: column;
  transition: width 100ms;
  will-change: width;

  &[data-is-open='true'] {
    width: var(--nav-expanded-width);
  }
  &[data-is-open='false'] {
    width: var(--nav-collapsed-width);
  }
  ${boxShadow}
  ${position}
`;

// eslint-disable-next-line react/prop-types
const MenuButton = ({ onClick }) => (
  <IconButton color="white" onClick={onClick}>
    <Hamburger />
  </IconButton>
);

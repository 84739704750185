import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useGate } from 'effector-react';

import { i18n } from '@lib/i18n';

import { useRouteBreadcrumb } from '../lib/use-route-breadcrumb';
import { useTableColumns } from '../lib/use-table-columns';
import { CommonDocumentsTemplate } from '../templates';
import { Header, FileBrowser } from '../organisms';
import { FileBrowserCategories } from '../constants';
import { FileBrowserGate } from '../model/file-browser.view';

export function FavoritePage({ route, match }) {
  const rootBreadcrumb = useRouteBreadcrumb(route);
  const columns = useTableColumns({ rootPath: rootBreadcrumb.path });
  const { id = null } = match.params;

  useGate(FileBrowserGate, {
    id,
    category: FileBrowserCategories.FAVORITES,
  });

  return (
    <CommonDocumentsTemplate title={i18n._(t`Documents: Favorite files`)}>
      <Header rootBreadcrumb={rootBreadcrumb} />
      <FileBrowser columns={columns} />
    </CommonDocumentsTemplate>
  );
}

FavoritePage.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

import { fetcher } from '@features/common';

const endpoint = '/api/v1/comments/';

export const addTaskComment = (id, data) => {
  return fetcher.post(`/api/v1/tasks/${id}/create_comment/`, data);
};

export const addMeetingComment = (id, data) => {
  return fetcher.post(`/api/v1/meetings/${id}/create_comment/`, data);
};

export const deleteComment = id => {
  return fetcher.delete(`${endpoint + id}/`);
};

export const editComment = (id, data) => {
  return fetcher.patch(`${endpoint + id}/`, data);
};

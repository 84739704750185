import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@oca/ui';
import { StarDefault, StarChecked } from '@oca/icons';

export const Favorite = React.forwardRef(function Favorite(props, ref) {
  const { selected, ...rest } = props;

  return (
    <IconButton
      padding={1}
      color={selected ? 'danger' : 'primary'}
      {...rest}
      ref={ref}
    >
      {selected ? <StarChecked /> : <StarDefault />}
    </IconButton>
  );
});

Favorite.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  selected: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from './typography';

export const Label = ({ children, color, fontSize, required, ...props }) => {
  return (
    <Typography {...props} fontSize={fontSize} color={color}>
      <span>
        {children} {required && <strong>*</strong>}
      </span>
    </Typography>
  );
};

Label.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
};

Label.defaultProps = {
  as: 'label',
  children: null,
  color: 'text',
  fontSize: '13px',
  required: false,
};

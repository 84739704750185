import React from 'react';
import { SkeletonLoader } from '@lib/skeleton-loader';

export function MeetingsListSkeleton(props) {
  return (
    <SkeletonLoader width={812} height={228} responsive={false} {...props}>
      <rect x="0" y="0" rx="4" ry="4" width="200" height="20" />
      <rect x="0" y="36" rx="4" ry="4" width="260" height="210" />
      <rect x="276" y="36" rx="4" ry="4" width="260" height="210" />
    </SkeletonLoader>
  );
}

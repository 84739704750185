import React from 'react';
import { t } from '@lingui/macro';
import { FileCheck } from '@oca/icons';
import { i18n } from '@lib/i18n';

const TasksListPage = React.lazy(() =>
  import('./pages/list').then(module => ({
    default: module.TasksListPage,
  })),
);

const TaskViewPage = React.lazy(() =>
  import('./pages/view').then(module => ({
    default: module.TaskViewPage,
  })),
);

export const routes = [
  {
    path: '/tasks/:id',
    component: TaskViewPage,
    exact: true,
  },
  {
    path: '/tasks',
    search: '?type=for_me',
    component: TasksListPage,
    exact: true,
    navigation: {
      name: i18n._(t`Tasks`),
      icon: <FileCheck />,
      onlyTopLevelLink: true,
    },
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';

import { Previewer } from './previewer';
import { File } from '../molecules/file';

export const DownloadFiles = ({ files }) => {
  const [viewData, setViewData] = React.useState(false);
  return (
    <React.Fragment>
      {viewData && (
        <Previewer
          fileName={viewData.fileName}
          file={viewData.file}
          fileExt={viewData.fileExt}
          onClose={() => setViewData(false)}
        />
      )}

      <Flex flexWrap="wrap">
        {files.map(({ id, fileName, file, fileExt }, index) => {
          const margin = index !== files.length - 1 ? 2 : 0;

          return (
            <Box key={id} mr={margin} mb={margin}>
              <File
                name={fileName}
                ext={fileExt}
                onClick={() => setViewData({ fileName, file, fileExt })}
              />
            </Box>
          );
        })}
      </Flex>
    </React.Fragment>
  );
};
DownloadFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      file: PropTypes.string,
    }),
  ).isRequired,
};

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Wind = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0)">
      <path
        d="M0.327136 12.7117C1.74689 13.9295 3.35601 15.3098 6.46856 15.3098C9.5812 15.3097 11.1904 13.9295 12.6102 12.7117C13.9529 11.5601 15.1125 10.5654 17.5311 10.5654C19.9499 10.5654 21.1094 11.5601 22.4521 12.7117C22.8451 13.0488 23.437 13.0035 23.7741 12.6105C24.1111 12.2174 24.0659 11.6256 23.6728 11.2885C22.253 10.0708 20.6438 8.69043 17.5311 8.69043C14.4185 8.69043 12.8093 10.0707 11.3895 11.2885C10.0468 12.4401 8.88721 13.4348 6.46856 13.4348C4.04995 13.4348 2.89049 12.4402 1.5479 11.2885C1.1549 10.9514 0.563058 10.9967 0.225933 11.3897C-0.111192 11.7827 -0.0658642 12.3746 0.327136 12.7117Z"
        fill="#0076F4"
      />
      <path
        d="M0.327136 7.58377C1.74684 8.80158 3.35596 10.1819 6.46856 10.1819C9.5812 10.1819 11.1904 8.80158 12.6102 7.58377C13.9529 6.43214 15.1124 5.4375 17.5312 5.4375C19.9498 5.4375 21.1094 6.43209 22.4521 7.58377C22.8451 7.92094 23.4369 7.87552 23.774 7.48247C24.1111 7.08947 24.0657 6.49753 23.6727 6.1605C22.2529 4.94269 20.6437 3.5625 17.5312 3.5625C14.4185 3.5625 12.8093 4.94278 11.3895 6.16059C10.0468 7.31222 8.88721 8.30686 6.46856 8.30686C4.05 8.30686 2.8905 7.31227 1.5479 6.16059C1.1549 5.82347 0.563011 5.86875 0.225933 6.2618C-0.111192 6.6548 -0.0658642 7.24669 0.327136 7.58377Z"
        fill="#0076F4"
      />
      <path
        d="M23.6728 16.4162C22.253 15.1984 20.6438 13.8181 17.5311 13.8181C14.4185 13.8181 12.8093 15.1984 11.3895 16.4162C10.0468 17.5678 8.88721 18.5625 6.46855 18.5625C4.05008 18.5625 2.89053 17.5679 1.54794 16.4163L1.54785 16.4162C1.15485 16.0792 0.563049 16.1245 0.225924 16.5175C-0.111154 16.9105 -0.0658259 17.5023 0.327174 17.8394C1.74692 19.0572 3.3561 20.4375 6.46855 20.4375C9.58119 20.4375 11.1904 19.0572 12.6102 17.8394C13.9529 16.6878 15.1125 15.6931 17.5311 15.6931C19.9498 15.6931 21.1094 16.6878 22.4521 17.8394C22.8451 18.1765 23.437 18.1312 23.774 17.7382C24.1111 17.3451 24.0658 16.7533 23.6728 16.4162Z"
        fill="#0076F4"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

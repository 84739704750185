import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';

import { Trans } from '@lingui/macro';

import { Typography } from '@oca/ui';
import { Earth } from '@oca/icons';

import { LoginForm, LocaleSelect } from '../../organisms';
import { AuthPageTemplate } from '../../templates';

export function LoginPage() {
  return (
    <AuthPageTemplate>
      <Typography color="textSecondary" paragraph>
        <Trans>Welcome back, please login to your account</Trans>
      </Typography>
      <LoginForm />
      <Flex mt={5} alignItems="center">
        <Box width={1 / 2}>
          <Typography>
            <Earth color="grey" />
            &nbsp;
            <Trans>Language</Trans>
          </Typography>
        </Box>
        <Flex flex="1">
          <LocaleSelect />
        </Flex>
      </Flex>
    </AuthPageTemplate>
  );
}

LoginPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any,
  }).isRequired,
};

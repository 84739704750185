import React from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { Flex, Text, Card } from 'rebass';
import { useClickAway } from 'react-use';

import {
  closeNotificationsPanel,
  $isNotificationsOpened,
} from '@features/common/model/panel-toggle-flow';
import { ArrowRight2, Bell, Delete, DoubleCheck } from '@oca/icons';
import { IconButton, Typography } from '@oca/ui';

import NoNotifications from '@lib/illustrations/notifications-not-found.svg';
import { useDocumentVisibility } from '@lib/use-document-visibility';

import { Trans } from '@lingui/macro';
import { firebaseConfigs } from '../models/firebase-configs';

import { NotificationView } from './notification-view';
import {
  getNotifications,
  $notificationList,
  readAllNotifications,
  deleteAllNotifications,
  $readAllStatus,
  $deleteAllStatus,
} from '../models/notifications-flow';

export const NotificationsPanel = props => {
  const readAllStatus = useStore($readAllStatus).isLoading;
  const deleteAllStatus = useStore($deleteAllStatus).isLoading;
  const node = React.useRef();

  useClickAway(node, closeNotificationsPanel);
  useDocumentVisibility(hidden => {
    if (!hidden) {
      getNotifications();
    }
  });

  React.useEffect(() => {
    firebaseConfigs();
    getNotifications();
  }, []);

  const isNotificationsOpened = useStore($isNotificationsOpened);
  const notificationList = useStore($notificationList);

  return (
    <Wrapper isOpened={isNotificationsOpened} boxShadow={8} ref={node}>
      <Flex
        color="#0076F4"
        px={15}
        py={13}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center">
          <Bell fontSize={22} />
          <Typography color="primary" fontWeight={500} ml={10}>
            <Trans>Notifications</Trans>
          </Typography>
        </Flex>
        <IconButton
          size="small"
          padding="5px"
          onClick={() => closeNotificationsPanel()}
        >
          <ArrowRight2 />
        </IconButton>
      </Flex>
      <ListWrap>
        {notificationList.results && notificationList.results.length ? (
          notificationList.results.map(item => (
            <NotificationView
              key={item.id}
              data={item}
              closeNotificationsPanel={closeNotificationsPanel}
            />
          ))
        ) : (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            flex={1}
          >
            <img src={NoNotifications} alt="no notifications" />
            <Text fontSize={18} color="#424B68" mt={20}>
              <Trans>There is no notifications</Trans>
            </Text>
          </Flex>
        )}
      </ListWrap>
      {notificationList.results && notificationList.results.length ? (
        <Flex
          px={15}
          py={10}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            alignItems="center"
            color={!deleteAllStatus ? '#FE2400' : '#ccc'}
            style={{ cursor: 'pointer' }}
            onClick={deleteAllNotifications}
          >
            <Delete fontSize={16} />
            <Text ml="5px" lineHeight={1} fontSize={12}>
              <Trans>Delete all</Trans>
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            color={
              !readAllStatus &&
              notificationList.results.some(item => !item.isRead)
                ? '#009031'
                : '#ccc'
            }
            style={{ cursor: 'pointer' }}
            onClick={() =>
              !readAllStatus &&
              notificationList.results.some(item => !item.isRead) &&
              readAllNotifications()
            }
          >
            <DoubleCheck fontSize={16} />
            <Text ml="5px" lineHeight={1} fontSize={12}>
              <Trans>Mark all as read</Trans>
            </Text>
          </Flex>
        </Flex>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Card)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  max-width: 340px;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  transform: translateX(${props => (props.isOpened ? 0 : '150%')});
  transition: transform 0.25s ease-out;
  display: flex;
  flex-direction: column;
`;

const ListWrap = styled.div`
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Flex, Box, Image } from 'rebass';
import { Trans } from '@lingui/macro';
import styled, { ThemeContext } from 'styled-components';
import { transparentize } from 'polished';
import dayjs from 'dayjs';
import {
  Typography,
  AssigneeList,
  Button,
  Avatar,
  Divider,
  DownloadFiles,
} from '@oca/ui';
import { Screen, CommentsList, CommentForm, Account } from '@features/common';
import { SkeletonLoader } from '@lib/skeleton-loader';
import { Edit, Comments } from '@oca/icons';
import { getFullName } from '@lib/help-fns';
import noDataImage from '@lib/illustrations/no-data-found.svg';

import {
  addMeetingCommentRequest,
  $commentFetching,
} from '../model/add-meeting-comment-flow';

export function MeetingView({
  agenda,
  comments,
  creator,
  description,
  endTime,
  id,
  invitedEmployees,
  isLoading,
  location,
  path,
  project,
  startTime,
  status,
  title,
}) {
  return (
    <Box bg="white" style={{ minHeight: 'calc(100vh - 160px)' }}>
      <Account
        render={({ user }) => (
          <Header
            editPath={`/meetings/${id}/edit`}
            title={title}
            members={invitedEmployees}
            comments={comments}
            isLoading={isLoading}
            status={status}
            canEdit={creator && creator.id === user.id}
          />
        )}
      />
      <Divider />
      <Meta
        creator={creator}
        startTime={startTime}
        endTime={endTime}
        isLoading={isLoading}
        status={status}
        location={location}
        project={project}
      />
      <Divider />
      <Content
        id={id}
        agenda={agenda}
        comments={comments}
        description={description}
        isLoading={isLoading}
      />
    </Box>
  );
}

MeetingView.propTypes = {
  title: PropTypes.node,
  invitedEmployees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  agenda: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  creator: PropTypes.shape({
    avatar: PropTypes.string,
  }),
  description: PropTypes.string,
  endTime: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  location: PropTypes.string,
  path: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number,
    avatar: PropTypes.string,
    name: PropTypes.string,
  }),
  startTime: PropTypes.string,
  status: PropTypes.oneOf(['upcoming', 'cancelled', 'completed']),
};

MeetingView.defaultProps = { invitedEmployees: [], agenda: [], comments: [] };

/** **************** HEADER SECTION ************** */
function Header({
  canEdit,
  isLoading,
  title,
  members = [],
  comments = [],
  editPath,
  status,
}) {
  const theme = React.useContext(ThemeContext);
  const statusColor = getStatusColor(theme, status);

  return (
    <HeaderContainer
      paddingTop={24}
      paddingLeft={4}
      paddingRight={3}
      paddingBottom={2}
      flexDirection="column"
      backgroundColor={transparentize(0.95, statusColor)}
    >
      <Indicator color={statusColor} />
      <Preloader
        isLoading={isLoading}
        render={() => (
          <Typography variant="h2" marginBottom={2}>
            {title}
          </Typography>
        )}
        width={200}
        height={20}
        boxProps={{ marginBottom: 2 }}
      />
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Flex alignItems="center">
          <Box marginRight={2}>
            <Preloader
              isLoading={isLoading}
              render={() => <AssigneeList assignees={members} />}
              width={60}
              height={26}
              viewBox="0 0 60 26"
            >
              <circle cx="13" cy="13" r="13" />
              <circle cx="26" cy="13" r="13" />
              <circle cx="39" cy="13" r="13" />
            </Preloader>
          </Box>
          <Typography mr={120}>
            <Trans>Members</Trans>
            :&nbsp;
            {members && !isLoading ? members.length : '-'}
          </Typography>
          <Typography>
            <Comments fontSize={24} color="#0075f4" />
            &nbsp;
            <Trans>Comments</Trans>
            :&nbsp;
            {comments && !isLoading ? comments.length : '-'}
          </Typography>
        </Flex>
        {canEdit && (
          <Button as={Link} to={editPath} variant="primary">
            <Edit />
            <Trans>Edit</Trans>
          </Button>
        )}
      </Flex>
    </HeaderContainer>
  );
}

/** *********** META SECTION ************** */
function Meta({ creator, isLoading, location, project, startTime, status }) {
  return (
    <Flex backgroundColor="greys.0" py={20} paddingLeft={4}>
      <Box width={1 / 3}>
        <Box mb={16}>
          <Preloader
            width={160}
            height={14}
            isLoading={isLoading}
            render={() => (
              <Descriptor
                field={<Trans>Date</Trans>}
                render={() => (
                  <Typography lineHeight={1}>
                    {startTime && (
                      <Screen>
                        {({ xsmall }) =>
                          dayjs(new Date(startTime)).format(
                            xsmall ? 'dddd LL' : 'ddd ll',
                          )
                        }
                      </Screen>
                    )}
                  </Typography>
                )}
              />
            )}
          />
        </Box>
        <Preloader
          width={100}
          height={14}
          isLoading={isLoading}
          render={() => (
            <Descriptor
              field={<Trans>Time</Trans>}
              render={() => (
                <Typography lineHeight={1}>
                  {startTime ? dayjs(new Date(startTime)).format('LT') : '---'}
                </Typography>
              )}
            />
          )}
        />
      </Box>
      <Box width={1 / 3}>
        <Box mb={16}>
          <Preloader
            width={140}
            height={14}
            isLoading={isLoading}
            render={() => (
              <Descriptor
                field={<Trans>Status</Trans>}
                render={() =>
                  status && (
                    <Typography lineHeight={1}>
                      {status.substr(0, 1).toUpperCase()}
                      {status.substr(1).toLowerCase()}
                    </Typography>
                  )
                }
              />
            )}
          />
        </Box>
        <Preloader
          width={140}
          height={14}
          isLoading={isLoading}
          render={() => (
            <Descriptor
              field={<Trans>Location</Trans>}
              render={() => <Typography lineHeight={1}>{location}</Typography>}
            />
          )}
        />
      </Box>
      <Box width={1 / 3}>
        <Box mb={12}>
          <Preloader
            width={180}
            height={14}
            isLoading={isLoading}
            render={() => (
              <Descriptor
                field={<Trans>Project</Trans>}
                render={() => (
                  <Flex alignItems="center" mb="-6px">
                    <Avatar
                      src={project ? project.avatar : undefined}
                      size={24}
                      mr={2}
                      mt={-2}
                    />
                    <Typography noWrap>
                      {project ? project.name : '---'}
                    </Typography>
                  </Flex>
                )}
              />
            )}
          />
        </Box>
        <Preloader
          width={180}
          height={14}
          isLoading={isLoading}
          render={() => (
            <Descriptor
              field={<Trans>Creator</Trans>}
              render={() => (
                <Flex alignItems="center" mb="-6px">
                  <Avatar
                    src={creator ? creator.avatar : undefined}
                    size={24}
                    mr={2}
                  />
                  <Typography noWrap>
                    {creator ? getFullName(creator) : '---'}
                  </Typography>
                </Flex>
              )}
            />
          )}
        />
      </Box>
    </Flex>
  );
}

/** ***************** CONTENT SECTION *********************** */
function Content({ agenda, description, id, comments, isLoading }) {
  return (
    <Flex px={4} py={24} justifyContent="space-between">
      <Box width={[8 / 12, 6 / 12]} marginRight={2}>
        <SectionTitle>
          <Trans>Description</Trans>
        </SectionTitle>
        <Box marginBottom={3}>
          <Preloader
            width={1}
            height={70}
            viewBox="0 0 290 70"
            isLoading={isLoading}
            render={() => (
              <Box style={{ minHeight: 70 }}>
                <Typography>
                  {description || <Trans>No description</Trans>}
                </Typography>
              </Box>
            )}
          >
            <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
            <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
            <rect x="190" y="0" rx="3" ry="3" width="10" height="10" />
            <rect x="0" y="20" rx="3" ry="3" width="130" height="10" />
            <rect x="155" y="20" rx="3" ry="3" width="130" height="10" />
            <rect x="0" y="40" rx="3" ry="3" width="90" height="10" />
            <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
            <rect x="185" y="40" rx="3" ry="3" width="60" height="10" />
            <rect x="0" y="60" rx="3" ry="3" width="30" height="10" />
          </Preloader>
        </Box>
        <SectionTitle>
          <Trans>Comments</Trans>
        </SectionTitle>
        <Box marginBottom={4}>
          <Preloader
            width="100%"
            height={40}
            isLoading={isLoading}
            render={() => (
              <CommentForm
                id={id}
                onSubmit={addMeetingCommentRequest}
                submitStatus={$commentFetching}
              />
            )}
          />
        </Box>
        <CommentsList comments={comments} />
      </Box>
      <Box width={[4 / 12]}>
        <SectionTitle>
          <Trans>Agenda</Trans>
        </SectionTitle>
        <Preloader
          width={150}
          height={69}
          isLoading={isLoading}
          render={() =>
            agenda && agenda.length > 0 ? (
              <DownloadFiles files={agenda} />
            ) : (
              <Flex alignItems="center" flexDirection="column">
                <Image width={150} src={noDataImage} />
                <Typography>
                  <Trans>No agenda attached</Trans>
                </Typography>
              </Flex>
            )
          }
        />
      </Box>
    </Flex>
  );
}

function Preloader({ isLoading, render, ...rest }) {
  return isLoading ? <SkeletonLoader {...rest} /> : render();
}

function Descriptor({ field, render, ...rest }) {
  return (
    <Flex alignItems="flex-end" {...rest}>
      <Typography
        color="textSecondary"
        fontSize={[12, 14]}
        lineHeight={1}
        mr={2}
      >
        {field}:
      </Typography>
      {render()}
    </Flex>
  );
}

function SectionTitle({ children, ...rest }) {
  return (
    <Box marginBottom={3}>
      <Typography variant="h2" marginBottom={12} {...rest}>
        {children}
      </Typography>
      <Divider />
    </Box>
  );
}

const HeaderContainer = styled(Flex)`
  position: relative;
`;

const Indicator = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  background: repeating-linear-gradient(
    26deg,
    currentColor,
    currentColor 5px,
    white 5px,
    white 10px
  );
`;

function getStatusColor(theme, status) {
  switch (status) {
    case 'upcoming':
      return theme.colors.danger;
    case 'completed':
      return theme.colors.success;
    case 'cancelled':
      return theme.colors.error;
    default:
      return theme.colors.disabled;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Flatpickr } from '@lib/flatpickr';
import { isRTL } from '@lib/i18n';
import { RangeText } from '../atoms/range-text';
import { CalendarInlineTrigger } from '../molecules/calendar-inline-trigger';

export function InlineRangeCalendar({ format: formatStr, options, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      options={{ ...options, mode: 'range' }}
      render={(state, ref) => {
        const [from, to] = state.value;

        return (
          <CalendarInlineTrigger
            text={
              <RangeText
                left={maybeDate(from, formatStr)}
                right={maybeDate(to, formatStr)}
                separator={isRTL() ? ' ← ' : ' → '}
              />
            }
            ref={ref}
          />
        );
      }}
    />
  );
}
InlineRangeCalendar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.objectOf(PropTypes.any),
  format: PropTypes.string,
};

InlineRangeCalendar.defaultProps = {
  format: 'll',
};

function maybeDate(date, formatStr) {
  const placeholder = dayjs(new Date())
    .format(formatStr)
    .replace(/\w/gi, '--');

  return date ? dayjs(new Date(date)).format(formatStr) : placeholder;
}

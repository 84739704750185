import { Box } from 'rebass';
import styled from 'styled-components';
import { height } from 'styled-system';

/**
 * @param {import('rebass').BoxProps & import('styled-system').HeightProps} props
 */
export const Divider = styled(Box)`
  border: 0;
  background-color: currentColor;
  ${height}
`;

Divider.defaultProps = {
  as: 'hr',
  width: 1,
  margin: 0,
  color: 'greys.2',
  height: '1px',
};

import { setupI18n } from '@lingui/core';
import dayjs from 'dayjs';
import flatpickr from 'flatpickr';
import { Arabic } from 'flatpickr/dist/l10n/ar';
import LocaleData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import MinMax from 'dayjs/plugin/minMax';
import ObjectSupport from 'dayjs/plugin/objectSupport';
import 'dayjs/locale/ar';

import en from '../../locales/en/messages';
import ar from '../../locales/ar/messages';
import pseudoLocale from '../../locales/pseudo-LOCALE/messages';

export const locales = {
  ar: {
    name: 'اللغة العربية‎',
    code: 'ar',
    flatpickr: { ...Arabic, time_24hr: true },
  },
  en: { name: 'English', code: 'en', flatpickr: flatpickr.l10ns.en },
};

export const dateTokens = {
  ISO_DATE: 'YYYY-MM-DD',
  ISO_YEAR_MONTH: 'YYYY-MM',
};

const rtl = [locales.ar.code, 'pseudo-LOCALE'];

const catalogs = {
  en,
  ar,
  'pseudo-LOCALE': pseudoLocale,
};

const lastLocale = localStorage.getItem('i18n/locale');
export const i18n = setupI18n({ catalogs, language: lastLocale || 'en' });

// Extend dayjs
dayjs.extend(LocalizedFormat);
dayjs.extend(LocaleData);
dayjs.extend(ObjectSupport);
dayjs.extend(IsBetween);
dayjs.extend(MinMax);

export function isRTL() {
  return rtl.includes(i18n.language);
}

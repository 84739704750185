import {
  createEffect,
  createStore,
  createEvent,
  forward,
  sample,
} from 'effector';
import { createFetching } from '@lib/effector-utils';
import { history, $location } from '@lib/routing';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { changeTokens, eraseTokens } from './token';
import {
  loginUser,
  logoutUser,
  fetchUserInfo,
  resetPassword,
  setNewPassword,
} from '../api';

// Events
export const pageUnmounted = createEvent('page unmounted');
export const dropSession = createEvent('session dropped');
export const loginRequesting = createEffect('login requested');
export const logoutRequesting = createEffect('logout requested');
export const sessionFetchRequesting = createEffect('session fetch requested');
export const resetPasswordRequesting = createEffect('reset password requested');
export const setNewPasswordRequesting = createEffect(
  'set new password requested',
);

// Stores
export const $session = createStore(null);
export const $isAuthenticated = $session.map(user => user !== null);
export const $loginFetching = createFetching(loginRequesting);
export const $sessionFetching = createFetching(sessionFetchRequesting);
export const $resetPasswordFetching = createFetching(resetPasswordRequesting, {
  reset: pageUnmounted,
});
export const $setNewPasswordFetching = createFetching(resetPasswordRequesting, {
  reset: pageUnmounted,
});

// Thunks
loginRequesting.use(loginUser);
logoutRequesting.use(logoutUser);
sessionFetchRequesting.use(fetchUserInfo);
resetPasswordRequesting.use(resetPassword);
setNewPasswordRequesting.use(setNewPassword);

// Reducers

$session
  .on(sessionFetchRequesting.done, (_, { result }) => {
    const roles = getRoles(result.data);

    return {
      ...result.data,
      roles,
      permissions: roles,
      topLevelPermission: roles.some(perm =>
        ['director', 'admin'].includes(perm),
      ),
    };
  })
  .on(logoutRequesting.done, () => null);

// Side Effects
sample($location, loginRequesting.done, ({ state }, { result }) => ({
  data: result.data,
  referrer: state ? state.referrer : '/',
})).watch(({ data, referrer }) => {
  changeTokens(data);
  sessionFetchRequesting(data.accessToken);
  history.replace(referrer);
});

setNewPasswordRequesting.done.watch(() => {
  notify.success(i18n._(t`You've successfully changed your password!`));

  setTimeout(() => history.push('/login'), 1000);
});

const tokenEraseEvents = [logoutRequesting.done, dropSession];

tokenEraseEvents.forEach(event =>
  forward({
    from: event,
    to: eraseTokens,
  }),
);

export function isDirector(user = {}) {
  return user.position === 'director';
}

export function isManager(user = { department: {} }) {
  return user.position === 'manager';
}

export function isAdmin(user = { department: {} }) {
  return isManager(user) && user.department.id === 1;
}

export function isUser(user = { department: {} }) {
  return user.position === 'expert';
}

export function getRoles(user) {
  const roles = [];

  if (isDirector(user)) {
    roles.push('director');
  } else if (isAdmin(user)) {
    roles.push('admin');
  } else if (isManager(user)) {
    roles.push('manager');
  } else if (isUser(user)) {
    roles.push('user');
  }
  return roles;
}

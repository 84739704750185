import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { Trans } from '@lingui/macro';
import { Box } from 'rebass';
import styled from 'styled-components';

import { Button, WizardStep } from '@oca/ui';

import { OnChange } from '@lib/react-final-form-on-change';
import { createDocumentModel, DOCUMENTS } from '../model/static';

export function EmployeeEditForm({
  editable,
  initialValues,
  steps,
  validate,
  onSubmit,
}) {
  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      subscription={{ pristine: true, submitting: true }}
      render={({ handleSubmit, form, ...finalFormProps }) => (
        <form onSubmit={handleSubmit} noValidate>
          <OnTypeChange form={form} />
          {steps.map(({ component: StepForm }, index) => (
            <WizardStep key={index}>
              {({ isActive }) =>
                isActive && (
                  // Temporary: do not disable documents section,
                  // because it prevents from downloading file
                  <Fieldset disabled={editable === false && index !== 2}>
                    <StepForm
                      editable={editable}
                      form={form}
                      initialValues={initialValues}
                      {...finalFormProps}
                    />
                  </Fieldset>
                )
              }
            </WizardStep>
          ))}
          {editable && (
            <Box paddingTop={4}>
              <Button
                type="submit"
                variant="primary"
                disabled={finalFormProps.submitting}
              >
                <Trans>Save</Trans>
              </Button>
            </Box>
          )}
        </form>
      )}
    />
  );
}

EmployeeEditForm.propTypes = {
  editable: PropTypes.bool.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.func,
    }),
  ).isRequired,
  validate: PropTypes.func.isRequired,
};

EmployeeEditForm.defaultProps = {
  initialValues: {},
};

// eslint-disable-next-line react/prop-types
function OnTypeChange({ form }) {
  return (
    <OnChange name="isResident">
      {(value, prevValue) => {
        if (value === false && prevValue === true) {
          form.mutators.push('documents', createDocumentModel(DOCUMENTS.VISA));
          form.mutators.push(
            'documents',
            createDocumentModel(DOCUMENTS.WORK_PERM),
          );
        } else if (value === true && prevValue === false) {
          form.mutators.pop('documents');
          form.mutators.pop('documents');
        }
      }}
    </OnChange>
  );
}

const Fieldset = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.9 : 1)};
  padding: 0;
  margin: 0;
  border: none;
`;

import { Box } from 'rebass';
import styled from 'styled-components';
import { maxWidth } from 'styled-system';

/**
 *
 * @param {import('rebass').BoxProps & import('styled-system').MaxWidthProps} props
 */
export const ContentBlock = styled(Box)`
  border-radius: 6px;
  ${maxWidth};
`;

ContentBlock.defaultProps = {
  bg: 'white',
};

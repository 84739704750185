import React from 'react';
import { Flex } from 'rebass';
import { TopBarProgress, Spinner } from '@oca/ui';
import styled from 'styled-components';

export function PageLoader() {
  return (
    <Container justifyContent="center" alignItems="center">
      <TopBarProgress />
      <Spinner size={56} />
    </Container>
  );
}

const Container = styled(Flex)`
  height: calc(100vh - var(--header-height));
`;

import { isNil } from '../help-fns';

/**
 * Creates FormData object from mapping
 * @param {Object<string, any>} mapping
 * @param {boolean} replaceNilValues
 * @returns {FormData} FormData object
 */
export function createFormData(mapping = {}, replaceNilValues = false) {
  const data = new FormData();

  Object.keys(mapping).forEach(key => {
    let value = mapping[key];

    if (replaceNilValues && isNil(value)) {
      value = '';
    }

    data.append(key, value);
  });

  return data;
}

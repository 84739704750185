import React from 'react';
import PropTypes from 'prop-types';
import { Search, CloseX } from '@oca/icons';
import { triggerInputEvent } from '@lib/trigger-input-event';
import { TextField } from '../molecules';
import { InputAdornment, IconButton } from '../atoms';

export function SearchField(props) {
  const inputRef = React.useRef(null);

  function handleClear(e) {
    triggerInputEvent(inputRef.current, '');
  }

  return (
    <TextField
      ref={inputRef}
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
      endAdornment={
        props.value && (
          <InputAdornment position="end">
            <IconButton
              padding={1}
              size="small"
              color="text"
              onClick={handleClear}
            >
              <CloseX />
            </IconButton>
          </InputAdornment>
        )
      }
      {...props}
    />
  );
}

SearchField.propTypes = TextField.propTypes;

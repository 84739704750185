import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from 'rebass';
import { useStore } from 'effector-react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { ConditionalList, File, Typography, Previewer } from '@oca/ui';
import noAttachmentsImage from '@lib/illustrations/no-attachments.svg';
import { SkeletonLoader } from '@lib/skeleton-loader';

import {
  ProjectAttachmentsGate,
  $attachments,
  $attachmentsFetching,
} from '../model/project-view-flow';

export const ViewAttachmentsSection = React.memo(function ViewTasksSection({
  isLoading,
  project,
}) {
  const [preview, setPreview] = React.useState(null);
  const fetching = useStore($attachmentsFetching);
  const loading = isLoading || fetching.isLoading;

  return (
    <Box width={1} paddingTop={2}>
      {preview && <Previewer {...preview} onClose={() => setPreview(null)} />}
      {project && <ProjectAttachmentsGate id={project.id} />}
      <AttachmentListContainer>
        <AttachmentList loading={loading} onPreview={setPreview} />
      </AttachmentListContainer>
    </Box>
  );
});

ViewAttachmentsSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    description: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

// eslint-disable-next-line react/no-multi-comp
const AttachmentList = React.memo(function AttachmentList({
  loading,
  onPreview,
}) {
  const attachments = useStore($attachments);

  if (loading) {
    return <AttachmentListSkeleton />;
  }

  return (
    <ConditionalList
      list={attachments}
      renderExists={items =>
        items.map(({ date, list = [] }) => (
          <Box mb={4}>
            <Typography variant="h2" marginBottom={3}>
              {dayjs(new Date(date)).format('LL')}
            </Typography>
            <Flex flexWrap="wrap">
              {list.map(item => (
                <Box
                  key={item.fileName}
                  width={[1 / 3, 1 / 4]}
                  marginBottom={3}
                  paddingRight={3}
                >
                  <File
                    ext={item.fileExt}
                    name={item.fileName}
                    size={item.size}
                    variant="large"
                    onClick={() => onPreview(item)}
                  />
                </Box>
              ))}
            </Flex>
          </Box>
        ))
      }
      renderEmpty={() => (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Image width={300} src={noAttachmentsImage} />
          <Typography>
            <Trans>No attachments</Trans>
          </Typography>
        </Flex>
      )}
    />
  );
});

AttachmentList.propTypes = {
  loading: PropTypes.bool.isRequired,
  onPreview: PropTypes.func.isRequired,
};

function AttachmentListSkeleton() {
  return (
    <Box>
      <SkeletonLoader width={160} height={20} boxProps={{ mb: 3 }} />
      <Flex flexWrap="wrap">
        {Array.from({ length: 3 }, (v, k) => (
          <Box key={k} width={[1 / 3, 1 / 4]} marginBottom={3} paddingRight={3}>
            <SkeletonLoader width={1} height={50} boxProps={{ mb: 3 }} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

const AttachmentListContainer = styled.div`
  min-height: calc(100vh - 303px);
`;

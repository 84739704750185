import React from 'react';
import WeekSelectPlugin from 'flatpickr/dist/plugins/weekSelect/weekSelect';

import { Flatpickr } from '@lib/flatpickr';

/**
 *
 * @param {import('./types').WeekPickerProps}
 */
export function WeekPicker({ onChange, options, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      options={{
        locale: { firstDayOfWeek: 1 },
        ...options,
        plugins: [new WeekSelectPlugin({})],
      }}
      onChange={(date, _, instance) => {
        const week = date ? instance.config.getWeek(new Date(date)) : null;
        if (onChange) {
          onChange(
            instance.weekStartDay,
            [instance.weekStartDay, instance.weekEndDay],
            week,
            instance,
          );
        }
      }}
    />
  );
}

WeekPicker.propTypes = Flatpickr.propTypes;

import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Image as Img } from 'rebass';
import styled from 'styled-components';

import { useDropzone } from 'react-dropzone';
import { cropCanvasImage } from '@lib/crop-canvas-image';
import { Upload, Edit, Camera } from '@oca/icons';
import { Trans } from '@lingui/macro';
import { Dialog, Crop } from '../molecules';
import { Button, Typography } from '../atoms';

export function ImageCropDialog({ onClose, onCrop, src, title }) {
  const [state, dispatch] = React.useReducer(cropReducer, {
    source: src,
    preview: src,
    isInitial: true,
    image: null,
  });

  const imageRef = React.useRef(null);
  const { getInputProps, getRootProps, open, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: handleImageDrop,
    noClick: true,
    multiple: false,
  });

  function handleImageDrop(files) {
    const [image] = files;

    if (image) {
      const reader = new FileReader();
      reader.onload = () => {
        const { result: url } = reader;

        dispatch({ type: 'upload', payload: { url, image } });
      };
      reader.readAsDataURL(image);
    }
  }

  function handleCropComplete(pixelCrop, percentCrop) {
    cropCanvasImage(imageRef.current, percentCrop, 'cropped.jpeg').then(
      imageBlob => {
        if (state.preview) {
          URL.revokeObjectURL(state.preview);
        }
        dispatch({
          type: 'setPreview',
          payload: URL.createObjectURL(imageBlob),
        });
      },
    );
  }

  function handleSave() {
    onCrop({
      url: state.preview,
      filename: state.image
        ? state.image.name
        : `cropped-image-${Date.now()}.jpg`,
    });
  }

  return (
    <Dialog title={title} onClickOnClose={onClose}>
      <Box width={600}>
        <Button type="button" variant="primaryOutlined" onClick={open} mr={2}>
          <Upload fontSize={16} /> <Trans>Upload photo</Trans>
        </Button>
        {state.isInitial && src && (
          <Button
            type="button"
            variant="primaryOutlined"
            onClick={() => dispatch({ type: 'setIsInitial', payload: false })}
          >
            <Edit fontSize={16} />
            Crop image
          </Button>
        )}

        <input {...getInputProps()} />
        <Box my={3}>
          <Box
            width={state.source ? 2 / 3 : 1}
            style={{
              display: 'inline-block',
              height: 300,
              verticalAlign: 'top',
            }}
          >
            {state.source ? (
              <SrcImgWrap>
                <Crop
                  key={state.isInitial ? 0 : 1}
                  crossorigin="anonymous"
                  disabled={state.isInitial}
                  crop={{ aspect: 1, unit: '%' }}
                  src={state.source}
                  onComplete={handleCropComplete}
                  onImageLoaded={image => {
                    imageRef.current = image;
                  }}
                  keepSelection
                />
              </SrcImgWrap>
            ) : (
              <Container
                {...getRootProps()}
                highlighted={isDragActive}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Camera fontSize={74} color="#dadada" />
                <Typography variant="h3">
                  <Trans>Please upload a new photo</Trans>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <Trans>You can drag and drop image here</Trans>
                </Typography>
              </Container>
            )}
          </Box>
          {state.preview && (
            <Box
              width={1 / 3}
              px={3}
              style={{ display: 'inline-block', height: 300 }}
            >
              <Preview
                width={128}
                height={128}
                borderRadius="50%"
                marginBottom={3}
                src={state.preview}
              />
              <Preview
                width={64}
                height={64}
                borderRadius="50%"
                src={state.preview}
              />
            </Box>
          )}
        </Box>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Dialog>
  );
}

ImageCropDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  initialCrop: PropTypes.shape({
    aspect: PropTypes.number,
  }),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string,
};

ImageCropDialog.defaultProps = {
  initialCrop: { aspect: 1 },
};

const SrcImgWrap = styled(Box)`
  img {
    max-height: 300px !important;
  }
`;

const Container = styled(Flex)`
  min-height: 300px;
  border-width: 2px;
  border-color: ${({ highlighted, theme }) =>
    highlighted ? theme.colors.primary : theme.colors.disabled};
  border-style: ${({ highlighted }) => (highlighted ? 'dashed' : 'solid')};
  outline: none;
`;

const Preview = styled(Img)`
  object-fit: cover;
`;

const cropActionMap = {
  upload: (state, { url, image }) => ({
    ...state,
    isInitial: false,
    source: url,
    preview: url,
    image,
  }),
  setIsInitial: (state, payload) => ({ ...state, isInitial: payload }),
  setPreview: (state, payload) => ({ ...state, preview: payload }),
};

function cropReducer(state, { type, payload }) {
  const actionFn = cropActionMap[type];

  return actionFn ? actionFn(state, payload) : state;
}

import React from 'react';
import styled from 'styled-components';
import { Flex, Link } from 'rebass';
import ReactDropzone from 'react-dropzone';
import { opacity } from 'styled-system';
import { Trans } from '@lingui/macro';

import { Upload as UploadIcon } from '@oca/icons';
import { Typography } from '../atoms';
import { createTransition } from '../theme';

/**
 *
 * @param {import('react-dropzone').DropzoneProps} props
 */
export function Dropzone(props) {
  return (
    <ReactDropzone {...props}>
      {({ getRootProps, getInputProps, isDragActive, isFocused }) => (
        <Wrapper
          {...getRootProps()}
          color="primary"
          px={3}
          py={2}
          error={props.error}
          highlighted={isDragActive || isFocused}
          opacity={props.disabled ? 0.5 : 1}
        >
          <input {...getInputProps()} />
          <UploadIcon fontSize={32} />
          <TextBlock alignItems="center">
            <Typography>
              <Trans>Drop your files here or</Trans>
            </Typography>
            &nbsp;
            <BrowseLink color="primary">
              <Trans>Browse</Trans>
            </BrowseLink>
          </TextBlock>
        </Wrapper>
      )}
    </ReactDropzone>
  );
}

Dropzone.propTypes = { ...ReactDropzone.propTypes };

const Wrapper = styled(Flex)`
  ${opacity};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed
    ${({ theme, highlighted, error }) => {
      if (error) {
        return theme.colors.error;
      }
      return highlighted ? theme.colors.primary : theme.colors.textSecondary;
    }};
  border-radius: ${({ theme }) => theme.radii[1]}px;
  outline: none;
  transition: ${createTransition('border-color')};
`;

const TextBlock = styled(Flex)`
  user-select: none;
`;
const BrowseLink = styled(Link)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';

import { Calendar } from '@oca/icons';
import { Typography } from '../atoms';
import { createTransition } from '../theme';

export const CalendarInlineTrigger = React.forwardRef(
  /**
   *
   * @param {{ text: string } & import('rebass').FlexProps} props
   * @param {React.Ref} ref
   */
  function CalendarInlineTrigger(props, ref) {
    const { text, ...rest } = props;

    return (
      <Wrapper tabIndex="0" alignItems="center" {...rest} ref={ref}>
        <Typography as="span" color="inherit" lineHeight={1} mr={1} noWrap>
          {text}
        </Typography>
        <Calendar color="#0076F4" />
      </Wrapper>
    );
  },
);

CalendarInlineTrigger.propTypes = {
  text: PropTypes.node,
  ...Flex.propTypes,
};

CalendarInlineTrigger.defaultProps = {
  text: '',
  color: 'text',
};

const Wrapper = styled(Flex)`
  cursor: pointer;
  transition: ${createTransition(['color'])};
  outline: none;
  & svg {
    transition: ${createTransition(['transform'])};
    transform-origin: right bottom;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
    svg {
      transform: rotate(20deg);
    }
  }
`;

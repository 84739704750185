/**
 *
 * @param {string} name
 * @param {Location | { search: string }} location
 */
export function getUrlParameter(name, location = window.location) {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(location.search);

  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

import CircleGreen from '@lib/illustrations/circle-green.svg';
import CircleCyan from '@lib/illustrations/circle-cyan.svg';
import CircleOrange from '@lib/illustrations/circle-orange.svg';
import CircleRed from '@lib/illustrations/circle-red.svg';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';

export const statuses = [
  { label: i18n._(t`To do,`), value: 'todo' },
  { label: i18n._(t`In progress,`), value: 'in_progress' },
  { label: i18n._(t`In review,`), value: 'in_review' },
  { label: i18n._(t`Done,`), value: 'done' },
];

export const priorities = [
  {
    value: 'low',
    avatar: CircleGreen,
    color: '#2BBF11',
    label: i18n._(t`Low`),
  },
  {
    value: 'medium',
    avatar: CircleCyan,
    color: '#009EFF',
    label: i18n._(t`Medium`),
  },
  {
    value: 'high',
    avatar: CircleOrange,
    color: '#FEA201',
    label: i18n._(t`High`),
  },
  {
    value: 'highest',
    avatar: CircleRed,
    color: '#FE2400',
    label: i18n._(t`Highest`),
  },
];

import React from 'react';

import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';
import { BaseSelect, FormControl } from '../molecules';

export const SelectBox = React.forwardRef(function SelectBox(props, ref) {
  const {
    disabled,
    error,
    helperText,
    label,
    spaceAfter,
    required,
    ...rest
  } = props;

  return (
    <FormControl
      ref={ref}
      error={error}
      label={label}
      helperText={helperText}
      required={required}
      spaceAfter={spaceAfter}
    >
      <BaseSelect placeholder={i18n._(t`Select...`)} {...rest} />
    </FormControl>
  );
});

SelectBox.propTypes = {
  ...BaseSelect.propTypes,
  ...FormControl.propTypes,
};

SelectBox.defaultProps = {
  closeMenuOnSelect: true,
  isMulti: false,
  options: [],
  getOptionValue: option => option.id || option.value || '',
  getOptionLabel: option => option.label || option.name || '',
};

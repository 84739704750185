import React from 'react';
import PropTypes from 'prop-types';
import { Text as TextBase } from 'rebass';
import styled from 'styled-components';

export const RangeText = React.forwardRef(function RangeText(props, ref) {
  const { left, right, separator, ...rest } = props;

  return (
    <Text ref={ref} {...rest}>
      {left}
      {separator && <span className="RangeText__separator">{separator}</span>}
      {right}
    </Text>
  );
});

RangeText.propTypes = {
  // eslint-disable-next-line react/require-default-props
  left: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  right: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  separator: PropTypes.node,
};

RangeText.defaultProps = {
  separator: '\u2192',
};

const Text = styled(TextBase)`
  & .RangeText__separator {
    font-size: 1.25em;
  }
`;

import { createEffect, createStore, createEvent, forward } from 'effector';
import { t } from '@lingui/macro';

import { createFetching } from '@lib/effector-utils';
import { history } from '@lib/routing';
import { notify } from '@lib/notifier';
import { hasOwnProperty } from '@lib/help-fns';
import { i18n } from '@lib/i18n';
import { $employeeByDepartmentList } from '@features/common';
import * as projectApi from '../api';

// Events
export const toggleProjectEditDialog = createEvent();
export const completeProject = createEvent();
export const archiveProject = createEvent();
export const projectCreateRequesting = createEffect('create project requested');
export const projectUpdateRequesting = createEffect('create update requested');
export const projectDeleteRequesting = createEffect('create delete requested');
export const projectListRequesting = createEffect('project list requested');

// Set thunks
projectCreateRequesting.use(credentials =>
  projectApi.createProject(credentials),
);

projectUpdateRequesting.use(data => projectApi.updateProject(data));
projectDeleteRequesting.use(({ id }) => projectApi.deleteProject(id));

projectListRequesting.use(data => projectApi.fetchProjectList(data));

// Stores
export const $isProjectEditDialogOpen = createStore(false);
export const $projectListFetching = createFetching(projectListRequesting);
export const $projectListStore = createStore([])
  .on(projectListRequesting.done, (_, { result }) => result.data)
  .reset(projectListRequesting.fail);

projectCreateRequesting.done.watch(() =>
  projectListRequesting({ completed: 0 }),
);

export const $employeeByDepartmentOptions = $employeeByDepartmentList.map(
  list =>
    list.map(item => ({
      id: item.id,
      label: item.name,
      options: item.employees,
      value: item.id,
    })),
);

$isProjectEditDialogOpen.on(toggleProjectEditDialog, (prev, nextValue) => {
  if (typeof nextValue === 'boolean') {
    return nextValue;
  }
  return !prev;
});

projectCreateRequesting.done.watch(({ params }) =>
  notify.success(`Projects: ${params.name} project is created`),
);

projectUpdateRequesting.done.watch(({ params }) => {
  if (hasOwnProperty(params, 'completed') && params.completed) {
    notify.success(i18n._(t`Projects: ${params.name} project is completed`));
  } else {
    // TODO: Decide on which side (back\front) notifications should come
    notify.success(i18n._(t`Projects: ${params.name} project is updated`));
  }
});

projectDeleteRequesting.done.watch(({ params }) => {
  notify.success(i18n._(t`Projects: ${params.name} project is archived`));
  history.replace('/projects');
});

forward({
  from: projectUpdateRequesting.done,
  to: toggleProjectEditDialog.prepend(() => false),
});

forward({
  from: completeProject,
  to: projectUpdateRequesting.prepend(({ id, name }) => ({
    id,
    name,
    completed: true,
  })),
});

forward({
  from: archiveProject,
  to: projectDeleteRequesting,
});

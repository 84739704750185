/* eslint-disable no-undef */
import * as firebase from 'firebase/app';
import 'firebase/messaging';

import { notify } from '@lib/notifier';
import { employeeDeviceToken } from '@features/common/api';
import { getNotifications } from './notifications-flow';

const config = {
  apiKey: 'AIzaSyBJJ0Zt9gA7g5xNFLeSHDdJXkEmCCMhq1s',
  authDomain: 'oca-internal.firebaseapp.com',
  databaseURL: 'https://oca-internal.firebaseio.com',
  projectId: 'oca-internal',
  storageBucket: 'oca-internal.appspot.com',
  messagingSenderId: '406642648730',
  appId: '1:406642648730:web:faf9bb6ff02f8b61',
};

export const firebaseConfigs = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);

    if ('serviceWorker' in navigator && firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      messaging.usePublicVapidKey(
        'BGysg5ge4CyzjWwUi2_reTzyYDb_40Ep3yc-zbY3x4ycWyJ9bLwz-mRDgPkvlOFU-zOZ_Bz2_StcQEnZHS0pWCM',
      );

      messaging
        .requestPermission()
        .then(() => messaging.getToken())
        .then(token => employeeDeviceToken(token))
        .catch(error => console.log('Error occured:', error));

      messaging.onMessage(({ data }) => {
        // const { notificationTitle } = notificationMsg(
        //   data.msg_title,
        //   data.action,
        //   data.domain,
        //   data.domain_obj_id,
        // );

        getNotifications().then(() => {
          notify.success(data.msg_body);
        });
      });
    } else {
      console.log('Push not supported.');
    }
  }
};

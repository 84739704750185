import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  alignItems,
  justifyContent,
  height,
  overflow,
  borderRadius,
} from 'styled-system';

import { Card as BaseBox, Image, Text } from 'rebass';
import { SecuredImage } from '@features/common';

import { BrokenImage } from '@oca/icons';

export const Avatar = React.forwardRef(
  /**
   * @param {{ background: string, size: number, children: ReactNode | string, src: string, borderRadius: number | string | number[] | string[] }} props
   */
  (props, ref) => {
    const {
      background,
      children: childrenProp,
      size,
      src,
      enableUrlSigning,
      borderRadius: borderRadiusProp,
      ...rest
    } = props;
    const [error, setError] = React.useState(null);
    const Img = enableUrlSigning ? SecuredImage : Image;
    const child = React.isValidElement(childrenProp) ? (
      childrenProp
    ) : (
      <Text as="span" fontSize="inherit">
        {childrenProp}
      </Text>
    );

    let children = src ? <Img src={src} onError={e => setError(e)} /> : child;

    if (src && error) {
      children = <BrokenImage color="#FF5B40" />;
    }

    return (
      <Box
        alignItems="center"
        bg={background || (childrenProp && 'disabled')}
        borderRadius={borderRadiusProp}
        fontSize={getNearestEvenNumber(size / 2)}
        height={size}
        justifyContent="center"
        overflow="hidden"
        ref={ref}
        width={size}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

Avatar.propTypes = {
  color: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  background: PropTypes.string,
  borderRadius: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string,
  enableUrlSigning: PropTypes.bool,
};

Avatar.defaultProps = {
  children: null,
  size: 48,
  color: 'text',
  enableUrlSigning: true,
  borderRadius: '50%',
};

const Box = styled(BaseBox)`
  display: flex;
  box-shadow: 0 0 1px #aaa;
  ${alignItems}
  ${justifyContent}
  ${height}
  ${overflow}
  ${borderRadius}
  & > svg {
    font-size: inherit;
  }
`;

function getNearestEvenNumber(number) {
  return 2 * Math.floor(number / 2);
}

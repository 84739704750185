import { createStore } from 'effector';

export const $filesRaw = createStore({});
export const $breadcrumbs = createStore([]);
export const $search = createStore('');
export const $fileRenameId = createStore(null);
export const $fileShareId = createStore(null);
export const $filePreviewId = createStore(null);
export const $folderMoveId = createStore(null);
export const $uploadFile = createStore(null);
export const $uploadProgress = createStore(0);
export const $selectedFileIds = createStore(new Set([]));
export const $pivotRow = createStore(-1);

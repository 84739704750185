import React from 'react';
import { SvgIcon } from './svg-icon';

export const Tab = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F15642"
    />
    <path
      d="M7.86788 18.0023C7.67588 17.8936 7.55588 17.6784 7.67588 17.4264L9.39713 14.0626C9.55913 13.7446 9.99038 13.7326 10.1404 14.0626L11.8316 17.4264C12.0776 17.8936 11.3516 18.2656 11.1356 17.7976L10.8716 17.2704H8.65913L8.40188 17.7984C8.30588 18.0024 8.08388 18.0444 7.86788 18.0023ZM10.4464 16.4791L9.76838 14.9979L9.03113 16.4791H10.4464Z"
      fill="white"
    />
    <path
      d="M5.44003 14.6003H4.39153C3.86953 14.6003 3.86953 13.833 4.39153 13.833H7.22728C7.76128 13.833 7.76128 14.6003 7.22728 14.6003H6.23203V17.628C6.23203 18.1493 5.44003 18.162 5.44003 17.628V14.6003Z"
      fill="white"
    />
    <path
      d="M12.9151 18.0001C12.6991 18.0001 12.5491 17.8381 12.5491 17.6281V14.2223C12.5491 14.0063 12.6991 13.8503 12.9151 13.8503H14.6004C15.3864 13.8503 15.8116 14.3843 15.8116 14.9956C15.8116 15.2648 15.7336 15.5296 15.4824 15.7516C15.9556 15.9376 16.1656 16.3028 16.1656 16.7468C16.1649 17.4361 15.6969 18.0001 14.7976 18.0001H12.9151ZM14.5996 14.5816H13.3344V15.4748H14.5996C14.8576 15.4748 15.0196 15.3128 15.0196 14.9948C15.0196 14.7976 14.8576 14.5816 14.5996 14.5816ZM13.3344 17.2681H14.7976C15.4936 17.2681 15.4936 16.2068 14.7976 16.2068C14.3424 16.2068 13.8084 16.2068 13.3344 16.2068V17.2681Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

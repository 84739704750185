import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Flatpickr } from '@lib/flatpickr';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps}
 */
export function DayPickerCalendar(props) {
  return (
    <Wrapper>
      <Flatpickr
        onChange={([date]) => props.onDayClick(date)}
        value={props.selectedDays}
        {...props}
        render={(_, ref) => <div ref={ref} />}
      />
    </Wrapper>
  );
}

DayPickerCalendar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onDayClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  selectedDays: PropTypes.instanceOf(Date),
  options: PropTypes.shape({
    inline: PropTypes.bool,
  }),
};

DayPickerCalendar.defaultProps = {
  options: {
    inline: true,
  },
};

const Wrapper = styled.div`
  .flatpickr-calendar.open,
  .flatpickr-calendar.inline {
    box-shadow: none;
  }
`;

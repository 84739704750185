import {
  createEffect,
  createStore,
  sample,
  merge,
  combine,
  forward,
} from 'effector';
import { createGate } from 'effector-react';
import dayjs from 'dayjs';

import { createFetching } from '@lib/effector-utils';
import { dateTokens } from '@lib/i18n';
import { fetchMeetingsList } from '../api';
import { createMeetingsFilters } from '../helpers';

export const MeetingsGate = createGate('Meetings gate');

// Events
export const fetchMeetingsListRequesting = createEffect({
  name: 'todays meetings list',
  handler: fetchMeetingsList,
});

// Stores
export const meetingFilters = createMeetingsFilters({
  mode: localStorage.getItem('meetingsDateMode'),
  date: new Date(),
  resetEvents: [MeetingsGate.close],
});

// export const setDateRange = meetingFilters.setRange.prepend(value => {})
export const $meetingsListFetching = createFetching(
  fetchMeetingsListRequesting,
);
export const $meetingsList = createStore([]);
export const $meetingsByDate = $meetingsList.map(list => transformByDay(list));

meetingFilters.setMode.watch(value =>
  localStorage.setItem('meetingsDateMode', value),
);

// Reducers
$meetingsList
  .on(fetchMeetingsListRequesting.done, (_, { result }) => result.data.results)
  .reset(MeetingsGate.close);

// Side effects

forward({
  from: MeetingsGate.close,
  to: meetingFilters.setMode.prepend(() =>
    localStorage.getItem('meetingsDateMode'),
  ),
});

sample({
  source: combine({
    range: meetingFilters.$range,
    type: meetingFilters.$type,
  }),
  clock: merge([
    MeetingsGate.open,
    meetingFilters.$range.updates,
    meetingFilters.$type.updates,
  ]),
  target: fetchMeetingsListRequesting.prepend(prepareQuery),
});

function prepareQuery({ range, type }) {
  const [start, end] = range;
  const query = {
    date_from: dayjs(start).format(dateTokens.ISO_DATE, 'en'),
    date_to: dayjs(end).format(dateTokens.ISO_DATE, 'en'),
  };
  if (type === 'by_me') {
    query.meetings_type = type;
  }
  if (type === 'by_department') {
    query.by_department = true;
  }
  return { params: query };
}

/**
 *
 * @param {Object[]} data
 * @returns {{date: string, list: []}[]>}
 */
export function transformByDay(data = [], dateKey = 'startTime') {
  const byDay = data.reduce((acc, item) => {
    const key = dayjs(new Date(item[dateKey])).format(
      dateTokens.ISO_DATE,
      'en',
    );
    acc[key] = acc[key] ? acc[key].concat(item) : [item];
    return acc;
  }, {});

  return Object.keys(byDay).map(key => ({ date: key, list: byDay[key] }));
}

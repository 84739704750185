import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Flex, Image } from 'rebass';

import { ConditionalList, Typography } from '@oca/ui';
import noMeetingsImage from '@lib/illustrations/no-meetings-found.svg';

/**
 *
 * @param {{ items: [], renderItem: ItemRenderer }}
 */
export function MeetingsList({ items, renderItem }) {
  return (
    <ConditionalList
      list={items}
      renderExists={meetings => meetings.map(renderItem)}
      renderEmpty={() => (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Image width={300} height={300} src={noMeetingsImage} />
          <Typography>
            <Trans>No planned meetings</Trans>
          </Typography>
        </Flex>
      )}
    />
  );
}

MeetingsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  renderItem: PropTypes.func.isRequired,
};

MeetingsList.defaultProps = { items: [] };

/**
 * @callback ItemRenderer
 * @param {{ date: string, list: [] }} item
 */

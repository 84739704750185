import React from 'react';
import { SvgIcon } from './svg-icon';

export const CloudSun = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0)">
      <path
        d="M18.9317 15.1566C21.7658 14.9928 24.1961 17.1575 24.36 19.9917C24.5238 22.8258 22.3591 25.2561 19.5249 25.42C19.3273 25.4314 19.1293 25.4314 18.9317 25.42H4.01062C1.79474 25.4191 -0.000927743 23.622 3.59611e-07 21.4061C0.000928462 19.1915 1.79602 17.3964 4.01062 17.3955H4.29619C3.82307 13.9801 6.20815 10.8279 9.6235 10.3547C13.0388 9.88162 16.1911 12.2667 16.6642 15.682C17.3695 15.3348 18.1455 15.155 18.9317 15.1566Z"
        fill="#80C1F3"
      />
      <path
        d="M15.3649 5.14451C15.4502 5.41859 15.297 5.70987 15.0229 5.79511C14.7489 5.88035 14.4576 5.72722 14.3723 5.45314C14.3673 5.43708 14.3631 5.4208 14.3597 5.40438L13.7886 3.24547C13.7033 2.97139 13.8565 2.68011 14.1305 2.59487C14.4046 2.50963 14.6959 2.66276 14.7811 2.93684C14.7861 2.9529 14.7903 2.96918 14.7938 2.9856L15.3649 5.14451ZM11.524 7.02356C11.7267 7.22782 11.7254 7.55765 11.5211 7.76033C11.3169 7.96302 10.987 7.96173 10.7844 7.75748L9.21373 6.16971C8.99306 5.98616 8.963 5.65839 9.14655 5.43779C9.3301 5.21712 9.65786 5.18706 9.87846 5.37061C9.90595 5.39353 9.93108 5.41916 9.95328 5.44722L11.524 7.02356ZM24.7659 15.9276C24.4891 15.8487 24.3286 15.5605 24.4075 15.2837C24.4864 15.0069 24.7747 14.8464 25.0514 14.9253L27.2046 15.5136C27.4814 15.5924 27.6419 15.8807 27.563 16.1575C27.4841 16.4343 27.1959 16.5948 26.9191 16.5159L24.7659 15.9276ZM25.0514 11.6612C24.7704 11.7195 24.4953 11.5389 24.437 11.2579C24.3826 10.9958 24.5361 10.7354 24.7916 10.656L26.9505 10.0849C27.2316 10.0265 27.5066 10.2072 27.565 10.4882C27.6193 10.7503 27.4659 11.0107 27.2104 11.0901L25.0514 11.6612ZM23.1781 7.82316C22.9739 8.02663 22.6434 8.02598 22.4399 7.82173C22.2364 7.61748 22.2371 7.287 22.4413 7.08353L24.0291 5.5129C24.2334 5.31021 24.5632 5.3115 24.7659 5.51575C24.9686 5.72001 24.9673 6.04984 24.763 6.25252L23.1781 7.82316ZM19.6285 5.44722C19.5496 5.724 19.2613 5.88449 18.9845 5.80561C18.7077 5.72672 18.5472 5.43843 18.6261 5.16165L19.2144 3.00845C19.2933 2.73166 19.5816 2.57117 19.8584 2.65006C20.1351 2.72895 20.2956 3.01723 20.2167 3.29402L19.6285 5.44722ZM19.0573 8.01735C21.8497 9.13057 23.2109 12.2966 22.0977 15.089C21.9479 15.4648 21.7567 15.8227 21.5275 16.1561C19.98 15.04 17.9417 14.8729 16.233 15.722C15.8949 13.4236 14.3102 11.5022 12.118 10.7331C13.3389 8.12936 16.3793 6.93054 19.0488 8.00021L19.0573 8.01735Z"
        fill="#FEA201"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

import { Link as BaseLink } from 'rebass';
import styled from 'styled-components';
import { lineHeight } from 'styled-system';

export const Link = styled(BaseLink).attrs(({ as, wrap, ...rest }) => ({
  'data-presentation': typeof as === 'string' ? as : '',
  'data-wrap': wrap,
  ...rest,
}))`
  cursor: pointer;
  text-decoration: none;
  ${lineHeight};
  &:hover {
    text-decoration: underline;
  }
  &[data-presentation='button'] {
    position: relative;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: none;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    ::-moz-focus-inner {
      border-style: none;
    }
  }
  &[data-wrap='false'] {
    white-space: nowrap;
  }
`;

Link.propTypes = {
  ...BaseLink.propTypes,
};

Link.defaultProps = {
  as: 'a',
  color: 'primary',
  fontSize: 1,
  wrap: true,
  lineHeight: 1,
};

import React from 'react';
import { SvgIcon } from './svg-icon';

export const DoubleCheck = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.56944 11.2089L6.64553 15.235L17.0139 5L18.4583 6.35814L6.64553 18L1.125 12.5671L2.56944 11.2089ZM10.875 16.6644L12.2439 15.235L22.4528 5L23.875 6.35814L12.2439 18L10.875 16.6644Z"
    />
  </SvgIcon>
);

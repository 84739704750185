import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { themeGet } from 'styled-system';
import { transparentize } from 'polished';
import { createTransition } from '../theme';
import { Divider } from '../atoms/divider';

export const Tabs = React.forwardRef(function Tabs(props, ref) {
  const {
    centered,
    children: childrenProp,
    color,
    compact,
    fullWidth,
    onChange,
    value,
  } = props;

  let childIndex = 0;
  const children = React.Children.map(childrenProp, child => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const childValue =
      child.props.value === undefined ? childIndex : child.props.value;

    const selected = childValue === value;

    childIndex += 1;

    return (
      <React.Fragment>
        {React.cloneElement(child, {
          compact,
          fullWidth,
          onChange,
          stretched: Boolean(fullWidth),
          activeColor: color,
          index: childIndex,
          active: selected,
          value: childValue,
        })}
        {childIndex < React.Children.count(childrenProp) && (
          <Divider as="div" width="2px" mx="1px" height="inherit" />
        )}
      </React.Fragment>
    );
  });

  return (
    <TabsContainer
      padding="2px"
      role="tablist"
      ref={ref}
      centered={centered}
      fullWidth={fullWidth || centered}
    >
      {children}
    </TabsContainer>
  );
});

Tabs.propTypes = {
  /**
   * If `true` tabs will be centered
   */
  centered: PropTypes.bool,
  children: PropTypes.node,
  /** Active color value, can be one of the theme's color or any valid color  */
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.string,
  /**
   * If `true`, will show icons without text.
   * Only active tab will have icon and text
   */
  compact: PropTypes.bool,
  /**
   * If `true`, the tab will take up all the place
   */
  fullWidth: PropTypes.bool,
  /**
   * Callback fired when the value changes.
   */
  onChange: PropTypes.func, // eslint-disable-line
  value: PropTypes.any, // eslint-disable-line
};

Tabs.defaultProps = {
  centered: false,
  children: null,
  compact: false,
  fullWidth: false,
};

const TabsContainer = styled(Flex).attrs(({ centered, fullWidth }) => ({
  'data-full-width': fullWidth,
  'data-centered': centered,
}))`
  display: inline-flex;
  overflow: hidden;
  min-height: 32px;
  user-select: none;
  -webkit-overflow-scrolling: touch;
  &[data-centered='true'] {
    justify-content: center;
  }
  &[data-full-width='true'] {
    width: 100%;
  }
`;

// TAB
// eslint-disable-next-line react/no-multi-comp
export const Tab = React.forwardRef(function Tab(props, ref) {
  const {
    active,
    activeColor,
    compact,
    disabled,
    filled,
    icon,
    index,
    label,
    onChange,
    onClick,
    stretched,
    value,
  } = props;

  const handleClick = React.useCallback(
    event => {
      if (onClick) {
        onClick(value, event);
      }

      if (onChange) {
        onChange(value, event);
      }
    },
    [onChange, onClick, value],
  );

  return (
    <TabContainer
      as="button"
      role="tab"
      active={active}
      activeColor={activeColor}
      ref={ref}
      // remove left padding on first tab
      paddingLeft={index > 1 ? 3 : 0}
      paddingRight={3}
      onClick={handleClick}
      filled={filled}
      disabled={disabled}
      stretched={stretched}
    >
      {icon && <TabIconContainer>{icon}</TabIconContainer>}
      {compact && !active ? null : <Text>{label}</Text>}
    </TabContainer>
  );
});

Tab.propTypes = {
  active: PropTypes.bool,
  activeColor: PropTypes.string,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  icon: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  index: PropTypes.number,
  label: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  stretched: PropTypes.bool,
  value: PropTypes.any, // eslint-disable-line
};

Tab.defaultProps = {
  active: false,
  compact: false,
  disabled: false,
  filled: false,
  activeColor: 'primary',
  icon: null,
  label: null,
};

const TabIconContainer = styled.div`
  margin-right: 8px;
  & > svg {
    fill: currentColor;
  }
`;

const TabContainer = styled(Flex).attrs(({ active, filled, stretched }) => ({
  'data-active': active,
  'data-filled': filled,
  'data-stretched': stretched,
}))`
  background: none;
  border: none;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  transition: ${createTransition(['color'], { duration: 100 })};
  color: ${({ theme }) => theme.colors.textSecondary};
  &:disabled {
    color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
  &:focus {
    outline-color: ${({ theme }) => theme.colors.blues[0]};
    outline-style: dotted;
    outline-width: 1px;
  }
  &:hover:not(:disabled):not([data-active='true']) {
    color: ${({ theme }) => theme.colors.text};
  }
  &[data-active='true']:not(:disabled) {
    color: ${props => getActiveColor(props)};
  }
  &[data-filled='true']:not([data-active='true']) {
    color: ${props => getActiveColor(props, 0.4)};
  }
  &[data-stretched='true'] {
    flex: 1;
    justify-content: center;
  }
  ${Text} {
    white-space: nowrap;
  }
`;

function getActiveColor(props, transparency) {
  const color = themeGet(`colors.${props.activeColor}`, props.activeColor)(
    props,
  );

  return transparency !== undefined
    ? transparentize(transparency, color)
    : color;
}

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Cad = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#50BEE8"
    />
    <path
      d="M4.01477 15.8947C4.01477 14.7375 4.74077 13.7422 6.12002 13.7422C6.64127 13.7422 7.05527 13.8982 7.49327 14.2762C7.65527 14.4255 7.67252 14.6902 7.51052 14.8575C7.34852 15.0007 7.10327 14.9835 6.95927 14.8395C6.71327 14.5815 6.46127 14.5102 6.12002 14.5102C5.19677 14.5102 4.75277 15.1642 4.75277 15.8955C4.75277 16.6395 5.19077 17.3227 6.12002 17.3227C6.46127 17.3227 6.77927 17.1847 7.05527 16.9387C7.24127 16.7955 7.49927 16.8667 7.60652 17.0107C7.70252 17.1427 7.75052 17.3647 7.54052 17.5747C7.12052 17.9647 6.61727 18.0427 6.11927 18.0427C4.66877 18.0427 4.01477 17.0527 4.01477 15.8947Z"
      fill="white"
    />
    <path
      d="M8.48702 18C8.29502 17.892 8.17502 17.676 8.29502 17.424L10.0155 14.0602C10.1775 13.7422 10.6095 13.731 10.7595 14.0602L12.45 17.424C12.696 17.892 11.97 18.2632 11.7548 17.796L11.49 17.268H9.27752L9.01952 17.796C8.92427 18 8.70302 18.0427 8.48702 18ZM11.0655 16.4775L10.3883 14.9955L9.65102 16.4775H11.0655Z"
      fill="white"
    />
    <path
      d="M13.5593 18C13.3613 18 13.1453 17.892 13.1453 17.6288V14.2223C13.1453 14.0063 13.3613 13.8503 13.5593 13.8503H14.9325C17.673 13.8503 17.613 18 14.9865 18H13.5593ZM13.9373 14.5815V17.268H14.9325C16.5518 17.268 16.6238 14.5815 14.9325 14.5815H13.9373Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

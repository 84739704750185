import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled from 'styled-components';
import { useToggle } from 'react-use';

export const BasicTemplate = React.memo(function BasicTemplate(props) {
  const { nav, header, children, isLargeScreen } = props;
  const [navExpanded, toggleNav] = useToggle(isLargeScreen);
  const onNavClose = React.useCallback(() => {
    if (navExpanded && !isLargeScreen) {
      toggleNav(false);
    }
  }, [isLargeScreen, navExpanded, toggleNav]);

  return (
    <Container
      data-is-nav-expanded={navExpanded}
      data-is-nav-static={isLargeScreen}
    >
      {nav && (
        <Nav>
          {React.cloneElement(nav, {
            open: navExpanded,
            onClose: onNavClose,
            toggle: toggleNav,
          })}
        </Nav>
      )}
      <Main>
        {header && <Header>{header}</Header>}
        {children}
      </Main>
    </Container>
  );
});

BasicTemplate.propTypes = {
  header: PropTypes.node,
  nav: PropTypes.node,
  children: PropTypes.node.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
};

BasicTemplate.defaultProps = {
  header: null,
  nav: null,
};

const Header = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0 2px 0px #ddd;
  background-color: white;
`;

const Nav = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
`;

const Main = styled.main`
  min-height: inherit;
`;

const Container = styled(Box)`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-left: var(--nav-collapsed-width);
  transition: padding-left 100ms;
  will-change: padding-left;
  background-color: #f2f2f2;
  &[data-is-nav-expanded='true'][data-is-nav-static='true'] {
    padding-left: var(--nav-expanded-width);
  }
`;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Avi = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F15642"
    />
    <path
      d="M5.69103 18.0023C5.49903 17.8936 5.37903 17.6784 5.49903 17.4264L7.22028 14.0626C7.38228 13.7446 7.81353 13.7326 7.96353 14.0626L9.65478 17.4264C9.90078 17.8936 9.17478 18.2656 8.95878 17.7976L8.69478 17.2704H6.48228L6.22503 17.7984C6.12903 18.0024 5.90703 18.0444 5.69103 18.0023ZM8.26953 16.4791L7.59153 14.9979L6.85428 16.4791H8.26953Z"
      fill="white"
    />
    <path
      d="M11.325 17.7307L9.74177 14.415C9.50777 13.941 10.2158 13.5517 10.479 14.049L11.013 15.2542L11.7023 16.8367L12.3795 15.2542L12.9135 14.049C13.1535 13.5937 13.8488 13.881 13.6455 14.379L12.1163 17.7307C11.9783 18.1087 11.5523 18.1987 11.325 17.7307Z"
      fill="white"
    />
    <path
      d="M14.385 14.2102C14.385 13.719 15.177 13.7002 15.177 14.2102V17.634C15.177 18.132 14.385 18.144 14.385 17.634V14.2102Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import saveFile from 'file-saver';
import { getDateRange } from '@lib/help-fns';
import Axios from 'axios';
import { fetcher } from './lib/fetcher';

const reportUrl = '/api/v1/reports/';

export function loginUser(credentials) {
  return fetcher.post('/api/v1/login/', credentials);
}

export function logoutUser() {
  return fetcher.post('/api/v1/logout/', {});
}

export function fetchUserInfo(token) {
  return fetcher.get('/api/v1/whoami/', {
    headers: { Authorization: `Token ${token}` },
  });
}

export function fetchDepartmentList() {
  return fetcher.get('/api/v1/departments/');
}

/**
 *
 * @param {FormData} file
 * @param {import('axios').AxiosRequestConfig} config
 */
export function uploadFile(file, config) {
  return fetcher.post('/api/v1/upload-file/', file, {
    headers: { 'Content-Type': 'multipart/form-data' },
    ...config,
  });
}

export function fetchEmployeeList(params) {
  return fetcher.get('/api/v1/employees/', { params });
}

export function fetchMeetingList() {
  return fetcher.get('/api/v1/meetings/');
}

export function fetchProjectList(params) {
  return fetcher.get('/api/v1/projects/', { params });
}

// Sendging firebase token to server
export function employeeDeviceToken(token) {
  return fetcher.post('/api/v1/employee-device-token/', {
    device_type: 'desktop',
    token,
  });
}

export function resetPassword(data) {
  return fetcher.post('/api/v1/accounts/password-reset/', data);
}

export function setNewPassword({ token, ...data }) {
  return fetcher.post(`/api/v1/accounts/password-set/${token}/`, data);
}

export async function fetchAttendanceListReport({ date, cancelToken }) {
  try {
    const range = getDateRange(date);
    const { data } = await fetcher.get(
      `${reportUrl}employee-attendance-custom/`,
      {
        params: range,
        responseType: 'blob',
        cancelToken,
      },
    );

    return saveFile(data, `Attendance-${Object.values(range).join('__')}.xlsx`);
  } catch (error) {
    if (Axios.isCancel(error)) {
      console.log(error);
    }
    return error;
  }
}

export async function fetchAttendanceSingleReport({
  id,
  fullName,
  cancelToken,
  date,
}) {
  try {
    const range = getDateRange(date);
    const { data } = await fetcher.get(
      `${reportUrl}employee-attendance-custom/${id}/`,
      {
        params: range,
        responseType: 'blob',
        cancelToken,
      },
    );

    return saveFile(
      data,
      `${fullName}-attendance-${Object.values(range).join('__')}.xlsx`,
    );
  } catch (error) {
    if (Axios.isCancel(error)) {
      console.log(error);
    }
    return error;
  }
}

export async function fetchEmployeeListReport(params) {
  const { data } = await fetcher.get(`${reportUrl}employee-list/`, {
    params,
    responseType: 'blob',
  });
  saveFile(data, `Employee-List.xlsx`);
}

export async function fetchEmployeeLeavesReport(params) {
  const { data } = await fetcher.get(`${reportUrl}employee-leaves/`, {
    params,
    responseType: 'blob',
  });
  saveFile(data, `Employee-Leaves-${Object.values(params).join('-')}.xlsx`);
}

export function fetchOfficeList() {
  return fetcher.get('/api/v1/offices/');
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import styled from 'styled-components';

import { Typography } from '@oca/ui';

export const FormSection = ({ title, children }) => (
  <SectionWrap>
    <Box mb={20}>
      <Typography variant="h3" fontSize={16}>
        {title}
      </Typography>
    </Box>
    {children}
  </SectionWrap>
);

FormSection.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
};

FormSection.defaultProps = {
  title: null,
};

const SectionWrap = styled(Box)`
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
`;

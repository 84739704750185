import React from 'react';
import { SvgIcon } from './svg-icon';

export const Xls = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#84BD5A"
    />
    <path
      d="M6.76575 15.2902L7.809 13.9829C8.121 13.5749 8.727 14.0962 8.385 14.4862C8.02575 14.9122 7.64775 15.3742 7.28775 15.8474L8.511 17.3707C8.841 17.8207 8.18175 18.2519 7.85775 17.8079L6.75375 16.3927L5.6685 17.8387C5.3625 18.2759 4.6845 17.7847 5.0265 17.3767L6.23175 15.8474C5.85375 15.3734 5.48775 14.9122 5.1225 14.4862C4.74525 14.0362 5.4465 13.5749 5.7225 13.9957L6.76575 15.2902Z"
      fill="white"
    />
    <path
      d="M9.25124 14.2102C9.25124 14.0122 9.41924 13.8442 9.62924 13.8442C9.82124 13.8442 9.98324 14.013 9.98324 14.2102V17.2147H11.6145C12.2017 17.2147 12.2145 18 11.6145 18H9.62999C9.41999 18 9.25199 17.8507 9.25199 17.6347V14.2102H9.25124Z"
      fill="white"
    />
    <path
      d="M12.7515 14.7502C12.8895 13.5862 14.646 13.377 15.474 14.013C15.882 14.3422 15.45 14.8642 15.09 14.6002C14.646 14.319 13.6387 14.187 13.5127 14.8095C13.3507 15.7935 15.9592 15.2302 15.9172 16.8254C15.8752 18.3487 13.6687 18.3839 12.8407 17.7007C12.642 17.5387 12.6487 17.2754 12.7567 17.1127C12.9127 16.9574 13.086 16.9034 13.2907 17.0714C13.782 17.4067 15.048 17.6587 15.1372 16.8067C15.06 15.9202 12.5655 16.4534 12.7515 14.7502Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import { createComponent } from 'effector-react';
import { $mediaQuery } from '../model/media-query';

export const Screen = createComponent(
  $mediaQuery,
  /**
   * @param {ScreenProps} props
   */
  (props, queries) => {
    const orientationAllowed = orientationCheck(props, queries);
    const screenSizeAllowed = screenSizeCheck(props, queries);
    if (orientationAllowed && screenSizeAllowed) {
      return typeof props.children === 'function'
        ? props.children(queries)
        : props.children;
    }
    return null;
  },
);

Screen.defaultProps = {
  children: null,
  small: false,
  medium: false,
  large: false,
  portrait: false,
  landscape: false,
};

function orientationCheck(props, queries) {
  // if there no constraint on orientation
  if (!props.portrait && !props.landscape) return true;
  return (
    (props.portrait && queries.portrait) ||
    (props.landscape && !queries.portrait)
  );
}

function screenSizeCheck(props, queries) {
  // if there no constraint on screen size
  if (!props.xsmall && !props.small && !props.medium && !props.large)
    return true;

  return (
    (props.xsmall && queries.xsmall) ||
    (props.small && queries.small) ||
    (props.medium && queries.medium) ||
    (props.large && queries.large)
  );
}

/**
 * @typedef {Object} MatchedMedia
 * @property {Boolean} xsmall
 * @property {Boolean} small
 * @property {Boolean} medium
 * @property {Boolean} large
 */

/**
 * @typedef {MatchedMedia & { children: (props: MatchedMedia) => JSX.Element | import('react').ReactNode }} ScreenProps
 */

import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, Field } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import { useStore } from 'effector-react';

import { Button, Typography, Link } from '@oca/ui';
import { i18n } from '@lib/i18n';

import { FORM_ERROR } from 'final-form';
import { resolveRejectedPromise } from '@lib/help-fns';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';
import { emailRegex } from '@lib/regex';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { $resetPasswordFetching, resetPasswordRequesting } from '../model/auth';

const initialFormValues = { email: '' };

export function ForgotPasswordForm() {
  const fetching = useStore($resetPasswordFetching);
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Form
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={values => handleFormSubmit(values, executeRecaptcha)}
      render={({ handleSubmit, submitting, submitError }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Field
              name="email"
              label={<Trans>Email</Trans>}
              component={TextFieldWrapper}
              placeholder={i18n._(t`Example: j.smith@oca.com`)}
              helperText=" "
              autoComplete="nope"
              required
              fullWidth
              spaceAfter
              autoFocus
            />
            <Box my={3}>
              {submitError && (
                <Box paddingBottom={2}>
                  <Typography variant="caption" color="error">
                    {submitError}
                  </Typography>
                </Box>
              )}
              {!submitting && fetching.isDone && (
                <Box paddingBottom={2}>
                  <Typography variant="caption" color="green">
                    <Trans>
                      An email with instructions has been sent to you
                    </Trans>
                  </Typography>
                </Box>
              )}
              <Button
                variant="primary"
                type="submit"
                disabled={submitting}
                fullWidth
              >
                <Trans>Reset password</Trans>
              </Button>
              <Flex marginTop={4} justifyContent="center">
                <Link as={RouterLink} to="/login">
                  <Trans>Back to login</Trans>
                </Link>
              </Flex>
            </Box>
          </form>
        );
      }}
    />
  );
}

/**
 *
 * @param {Object} values
 * @param {(string) => Promise<string>} executeRecaptcha
 */
async function handleFormSubmit(values, executeRecaptcha) {
  try {
    const captcha = await executeRecaptcha('forgot_password');
    const error = await resolveRejectedPromise(
      resetPasswordRequesting({ ...values, captcha }),
    );

    if (error) {
      if (error.message || error.data.nonFieldErrors) {
        return {
          [FORM_ERROR]:
            error.message ||
            (error.data.nonFieldErrors && (
              <Trans>Email not found or password is wrong</Trans>
            )),
        };
      }

      return error.data;
    }
    return null;
  } catch (error) {
    return { [FORM_ERROR]: 'Something went wrong, please try again later' };
  }
}

function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = <Trans>This is required field</Trans>;
  }
  if (values.email && !emailRegex().test(values.email)) {
    errors.email = <Trans>Please enter valid email address</Trans>;
  }

  return errors;
}

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Info = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4ZM11 18L11 11L13 11L13 18L11 18ZM11 7L11 9L13 9L13 7L11 7Z"
    />
  </SvgIcon>
);

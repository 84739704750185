import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'rebass';
import { Label, TextArea } from '../atoms';

const Field = styled(Text)`
  display: block;
`;

const HelperText = styled(Text)`
  min-height: 1em;
`;

export const TextAreaField = React.forwardRef(
  (
    {
      disabled,
      error,
      fullWidth,
      helperText,
      label,
      name,
      onChange,
      required,
      spaceAfter,
      ...other
    },
    ref,
  ) => {
    return (
      <Field as="label" marginBottom={spaceAfter ? 2 : 0} htmlFor={name}>
        {label && (
          <Label
            as="span"
            color={(disabled && 'disabled') || (error && 'error') || 'text'}
            required={required}
            marginBottom={1}
            fullWidth={fullWidth}
          >
            {label}
          </Label>
        )}
        <TextArea
          name={name}
          onChange={onChange}
          error={error}
          disabled={disabled}
          ref={ref}
          required={required}
          {...other}
        />
        {helperText && (
          <HelperText
            as="p"
            lineHeight={1}
            margin={0}
            marginTop={1}
            fontSize={0}
            color={error ? 'error' : 'textSecondary'}
          >
            {helperText}
          </HelperText>
        )}
      </Field>
    );
  },
);

TextAreaField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  spaceAfter: PropTypes.bool,
  required: PropTypes.bool,
};

TextAreaField.defaultProps = {
  disabled: false,
  error: false,
  fullWidth: false,
  helperText: '',
  label: '',
  name: '',
  spaceAfter: false,
  required: false,
};

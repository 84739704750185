import React from 'react';
import { SvgIcon } from './svg-icon';

export const Html = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#84BD5A"
    />
    <path
      d="M2.60992 17.6282V14.2224C2.60992 14.0064 2.76592 13.8504 3.03517 13.8504C3.24517 13.8504 3.40117 14.0064 3.40117 14.2224V15.5297H5.35566V14.2224C5.35566 14.0064 5.54766 13.8504 5.76366 13.8504C5.97366 13.8504 6.13566 14.0064 6.13566 14.2224V17.6282C6.13566 17.8382 5.97366 18.0002 5.76366 18.0002C5.54766 18.0002 5.35566 17.8382 5.35566 17.6282V16.3157H3.40117V17.6282C3.40117 17.8382 3.24517 18.0002 3.03517 18.0002C2.76592 18.0002 2.60992 17.8382 2.60992 17.6282Z"
      fill="white"
    />
    <path
      d="M8.09909 14.6003H7.04984C6.52784 14.6003 6.52784 13.8331 7.04984 13.8331H9.88559C10.4196 13.8331 10.4196 14.6003 9.88559 14.6003H8.89034V17.6281C8.89034 18.1493 8.09834 18.1621 8.09834 17.6281V14.6003H8.09909Z"
      fill="white"
    />
    <path
      d="M11.6571 15.3676V17.5861C11.6571 17.8508 11.4411 18.0001 11.2258 18.0001C11.0338 18.0001 10.8658 17.8508 10.8658 17.5861V14.2103C10.8658 13.8983 11.1306 13.7956 11.2258 13.7956C11.3991 13.7956 11.5011 13.8983 11.6031 14.0123L12.9231 15.7928L14.2895 13.9456C14.4883 13.7003 14.9735 13.7956 14.9735 14.2103V17.5861C14.9735 17.8508 14.8048 18.0001 14.6135 18.0001C14.3983 18.0001 14.2295 17.8508 14.2295 17.5861V15.3676L13.2343 16.6268C13.0191 16.8916 12.7491 16.8916 12.5511 16.6268L11.6571 15.3676Z"
      fill="white"
    />
    <path
      d="M15.8092 14.2103C15.8092 14.0123 15.9772 13.8443 16.1872 13.8443C16.3792 13.8443 16.5412 14.0131 16.5412 14.2103V17.2148H18.1725C18.7597 17.2148 18.7725 18.0001 18.1725 18.0001H16.188C15.978 18.0001 15.81 17.8508 15.81 17.634V14.2103H15.8092Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import { createStore, createEvent, sample, combine } from 'effector';
import debounce from 'debounce';

import { createFetching } from '@lib/effector-utils';

import {
  $employeeList as $employeeRawList,
  employeeListFetchRequesting,
} from '@features/common';

// Events
export const pageUnmounted = createEvent('employee list page unmounted');
export const toggleShowTerminated = createEvent('show terminated toggled');
export const changeEmployeeSearch = createEvent('employee search changed');
export const changeDepartmentFilter = createEvent('department filter changed');
const changeEmployeeSearchWithDebounce = createEvent(
  'employee search changed with debounce',
);
const debouncedChangeEmployeeSearch = debounce(
  changeEmployeeSearchWithDebounce,
  420,
);

// Stores
export const $departmentFilter = createStore('');
export const $search = createStore('');
export const $showTerminatedEmployees = createStore(false);
export const $employeeFilteredList = combine(
  $departmentFilter,
  $employeeRawList,
  $showTerminatedEmployees,
  (department, list, showTerminatedEmployees) => {
    const filteredByStatus = list.filter(item =>
      showTerminatedEmployees
        ? item.status === 'terminate'
        : item.status !== 'terminate',
    );

    if (department) {
      return filteredByStatus.filter(item => item.department.id === department);
    }

    return filteredByStatus;
  },
);
export const $employeeList = $employeeFilteredList.map(i => i);
export const $employeeListFetching = createFetching(
  employeeListFetchRequesting,
);

changeEmployeeSearch.watch(value => debouncedChangeEmployeeSearch(value));

const onSearchChange = sample(
  $employeeFilteredList,
  changeEmployeeSearchWithDebounce,
  (list, search) => ({
    list,
    search,
  }),
);

// Reducers
$search
  .on(changeEmployeeSearch, (_, searchStr) => searchStr)
  .reset(changeDepartmentFilter)
  .reset(pageUnmounted);

$departmentFilter
  .on(changeDepartmentFilter, (_, filter) => filter)
  .reset(pageUnmounted);

$showTerminatedEmployees.on(toggleShowTerminated, state => !state);

$employeeList
  .on(sample($employeeFilteredList, pageUnmounted), (_, list) => list)
  .on(onSearchChange, (_, { list, search }) =>
    search === ''
      ? list
      : list.filter(item =>
          item.fullName.toLowerCase().includes(search.toLowerCase()),
        ),
  );

import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'effector-react';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { history } from '@lib/routing';
import { Avatar, Typography, Spinner } from '@oca/ui';
import { Person, CloseX } from '@oca/icons';

import { Trans } from '@lingui/macro';
import { notificationMsg } from '../models/notification-msg';
import {
  readNotification,
  deleteNotification,
  $getListStatus,
} from '../models/notifications-flow';

export const NotificationView = React.memo(
  ({ data: { initiator, ...data }, closeNotificationsPanel }) => {
    const [deleteStatus, setDeleteStatus] = React.useState(false);
    const [readStatus, setReadStatus] = React.useState(false);
    const { isLoading } = useStore($getListStatus);
    const { notificationRoute, icon, ...other } = notificationMsg(
      data.title,
      data.action,
      data.domain,
      data.domainObjId,
    );

    return (
      <Wrapper
        py={10}
        px={15}
        alignItems="flex-start"
        bg={!data.isRead && 'rgba(0, 118, 244, 0.15)'}
        opacity={deleteStatus || readStatus ? 0.5 : 1}
      >
        <Flex
          flex={1}
          alignItems="flex-start"
          mr={15}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (!readStatus && !isLoading) {
              if (!data.isRead) {
                setReadStatus(true);
                readNotification([data.id]).then(() => setReadStatus(false));
              }

              if (notificationRoute !== '/') {
                history.replace(notificationRoute);
                closeNotificationsPanel();
              }
            }
          }}
        >
          <AvatarWarp>
            <Avatar
              src={initiator && initiator.avatar}
              size={40}
              title={
                initiator && `${initiator.firstName} ${initiator.lastName}`
              }
            >
              <Person color="#aaa" />
            </Avatar>

            <TypeWrap>
              <Avatar size={20} background="#0076F4" title={data.domain}>
                {React.createElement(icon, {
                  color: '#fff',
                })}
              </Avatar>
            </TypeWrap>
          </AvatarWarp>
          <Box flex={1} ml={15}>
            <Typography color="primary" lineHeight={1}>
              {data.content}
            </Typography>
            {initiator && (
              <Text fontSize={12} color="#424B68" mt="4px">
                <Trans>
                  by {`${initiator.firstName} ${initiator.lastName}`}
                </Trans>
              </Text>
            )}
            <Text fontSize={10} mt={10} color="rgba(68, 75, 104, 0.7)">
              {dayjs(new Date(data.createdAt)).format('L LT')}
            </Text>
          </Box>
        </Flex>
        <Box style={{ cursor: 'pointer' }}>
          {deleteStatus && isLoading ? (
            <Spinner size={14} />
          ) : (
            <CloseX
              fontSize={14}
              color="#424B68"
              onClick={() => {
                setDeleteStatus(true);
                deleteNotification([data.id]);
              }}
            />
          )}
        </Box>
      </Wrapper>
    );
  },
);
NotificationView.propTypes = {
  data: PropTypes.shape({
    isRead: PropTypes.bool,
  }).isRequired,
  closeNotificationsPanel: PropTypes.func.isRequired,
};

const Wrapper = styled(Flex)`
  border-bottom: 1px solid rgba(68, 75, 104, 0.1);
  opacity: ${({ opacity }) => opacity};

  &:hover {
    background-color: ${props => !props.bg && 'rgba(0, 118, 244, 0.05)'};
  }

  &:last-child {
    border-bottom: none;
  }

  & a {
    text-decoration: none;
  }
`;

const AvatarWarp = styled(Box)`
  position: relative;
`;

const TypeWrap = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(10%, 15%);
`;

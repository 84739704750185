import React from 'react';
import { Previewer } from '@oca/ui';
import { useStore } from 'effector-react';
import { $filePreview } from '../model/file-browser.view';
import { setFilePreviewId } from '../model/file-browser.events';

export function FilePreview() {
  const preview = useStore($filePreview);

  if (preview) {
    return <Previewer {...preview} onClose={() => setFilePreviewId(null)} />;
  }
  return null;
}

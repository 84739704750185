import React from 'react';
import { SvgIcon } from './svg-icon';

export const FingerPrint = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0)">
      <path d="M13.896 11.817C13.4552 10.5095 12.038 9.80684 10.7305 10.2476C9.505 10.6607 8.79733 11.9406 9.09914 13.1981C9.99854 16.3251 13.2883 19.1312 16.3013 21.5156C16.5177 21.6869 16.832 21.6505 17.0034 21.4341C17.1747 21.2178 17.1383 20.9035 16.9219 20.7321C14.0298 18.4436 10.877 15.7644 10.0595 12.9223C9.8307 12.1261 10.2907 11.2953 11.0868 11.0665C11.883 10.8377 12.7138 11.2977 12.9426 12.0938C13.4882 13.9926 16.2014 16.339 18.7827 18.3816C18.9991 18.553 19.3133 18.5166 19.4847 18.3002C19.6561 18.0838 19.6197 17.7695 19.4033 17.5981C18.0402 16.5219 14.4306 13.6658 13.896 11.817Z" />
      <path d="M17.6835 10.529C16.5972 7.19837 13.0776 5.31698 9.7048 6.26379C6.25649 7.25322 4.26319 10.8508 5.25267 14.2991C5.25337 14.3015 5.25408 14.304 5.25478 14.3064C6.07525 17.1566 4.3164 17.947 3.75578 18.1209C3.49252 18.2037 3.34622 18.4842 3.429 18.7475C3.51177 19.0107 3.79232 19.157 4.05558 19.0743C5.58358 18.5966 7.06759 16.9897 6.21512 14.0296C5.37475 11.1127 7.05812 8.06685 9.97499 7.22648C9.97654 7.22605 9.97809 7.22559 9.97959 7.22516C12.8637 6.41449 15.8686 8.04901 16.7551 10.9107C16.7669 10.9497 16.7837 10.987 16.8051 11.0216C16.857 11.1076 16.9639 11.2884 20.1758 13.8947C20.3899 14.0688 20.7047 14.0364 20.8788 13.8222C21.0529 13.6081 21.0205 13.2933 20.8064 13.1192C19.6392 12.1719 17.9333 10.7638 17.6835 10.529Z" />
      <path d="M15.8186 11.2644C15.1284 8.88123 12.6412 7.50468 10.2553 8.18546C7.91258 8.90873 6.55284 11.3465 7.16832 13.7198C8.17767 18.3167 7.42315 19.378 5.35252 20.0256C5.08926 20.1083 4.94296 20.3889 5.02574 20.6521C5.10851 20.9154 5.38906 21.0617 5.65232 20.9789C8.54539 20.0795 9.17399 18.1888 8.15064 13.5049C7.65349 11.6476 8.70457 9.7277 10.537 9.1458C12.3938 8.61867 14.3277 9.69031 14.8652 11.5442C15.0651 12.2257 15.5957 13.1611 19.688 16.4C19.9044 16.5713 20.2186 16.5349 20.39 16.3185C20.5614 16.1022 20.5249 15.7879 20.3086 15.6165C16.594 12.6785 15.9585 11.7481 15.8186 11.2644Z" />
      <path d="M17.1938 18.2197C15.5029 16.9365 12.6728 14.79 11.9762 12.3696C11.8955 12.1057 11.6161 11.9573 11.3522 12.0381C11.0948 12.1169 10.9461 12.3854 11.0159 12.6454C11.8033 15.3856 14.7993 17.6581 16.5892 19.0162C16.9529 19.2781 17.3031 19.5582 17.6385 19.8556C17.8369 20.0473 18.1533 20.0419 18.345 19.8434C18.532 19.6497 18.532 19.3427 18.345 19.1491C17.9791 18.8179 17.5947 18.5076 17.1938 18.2197Z" />
      <path d="M14.4895 21.6955C13.9845 20.7586 13.0542 20.1268 11.9972 20.0026C10.2983 20.0026 10.0355 22.8907 9.99847 23.4693C9.98138 23.7447 10.1908 23.9818 10.4662 23.999H10.4982C10.7625 23.9995 10.9814 23.7941 10.9978 23.5303C11.0608 22.501 11.4545 21.002 11.9972 21.002C12.7377 21.002 13.2633 21.7155 13.685 22.2881C13.8244 22.4868 13.9769 22.6757 14.1417 22.8538C14.3402 23.0455 14.6565 23.04 14.8483 22.8415C15.0353 22.6479 15.0353 22.3409 14.8483 22.1472C14.719 22.0046 14.5992 21.8537 14.4895 21.6955Z" />
      <path d="M9.97558 17.3524C9.88166 17.0929 9.59516 16.9587 9.33569 17.0526C9.09164 17.141 8.95583 17.4015 9.02324 17.6522C9.45097 18.9883 8.6105 21.6105 7.34638 22.0212C7.08302 22.1037 6.93635 22.384 7.01875 22.6474C7.1012 22.9107 7.38151 23.0574 7.64486 22.975C7.64833 22.9739 7.65175 22.9728 7.65517 22.9716C9.57783 22.3521 10.5052 19.0123 9.97558 17.3524Z" />
      <path d="M20.6455 10.5329C20.0209 10.333 19.5302 9.5336 18.9616 8.62118C17.7584 6.67646 16.1085 4.01324 11.4976 4.01324C6.80854 4.01877 3.00872 7.8186 3.00323 12.5076C3.00323 12.7836 3.22696 13.0073 3.50292 13.0073C3.77887 13.0073 4.0026 12.7836 4.0026 12.5076C4.007 8.37005 7.36004 5.01701 11.4976 5.01261C15.5519 5.01261 16.972 7.3051 18.1132 9.14784C18.7848 10.2321 19.3644 11.1685 20.3377 11.4843C20.5993 11.5724 20.8827 11.4318 20.9708 11.1703C21.0589 10.9088 20.9183 10.6254 20.6568 10.5373C20.6534 10.5361 20.65 10.535 20.6465 10.5339L20.6455 10.5329Z" />
      <path d="M4.00251 16.5049C4.27847 16.5049 4.50219 16.2812 4.50215 16.0052C4.50215 15.9275 4.48402 15.8509 4.44921 15.7814C4.23598 15.3817 4.08528 14.9517 4.00251 14.5063C4.00251 14.2303 3.77878 14.0066 3.50283 14.0066C3.22687 14.0066 3.00314 14.2303 3.00314 14.5063C3.08334 15.1089 3.27053 15.6923 3.55576 16.2291C3.64055 16.3982 3.81345 16.5049 4.00251 16.5049Z" />
      <path d="M3.74262 5.93897C3.97825 6.0826 4.28573 6.00807 4.42935 5.77244C4.42964 5.77198 4.42992 5.77155 4.43015 5.77109C4.86388 5.05555 7.00042 3.01391 10.9978 3.01391C14.8392 3.01391 17.5724 4.95961 18.0651 5.77109C18.2083 6.00704 18.5157 6.08218 18.7516 5.93897C18.9876 5.79577 19.0627 5.48838 18.9195 5.25243C18.2339 4.12317 15.207 2.01459 10.9978 2.01459C6.52177 2.01459 4.12239 4.35205 3.57474 5.25342C3.4321 5.48913 3.50723 5.79582 3.74262 5.93897Z" />
      <path d="M6.70064 1.97059C6.92947 1.87067 7.12336 1.77071 7.30722 1.67079C8.05874 1.29206 8.59936 1.01521 10.9978 1.01521C12.6172 0.925971 14.237 1.1853 15.7476 1.77572C15.9385 1.84669 16.1304 1.91761 16.3312 1.98656C16.3836 2.00474 16.4386 2.0142 16.4941 2.01453C16.7701 2.01504 16.9942 1.79174 16.9947 1.51578C16.995 1.30189 16.8593 1.11152 16.657 1.04219C16.4631 0.975251 16.2792 0.90728 16.0954 0.842308C14.4737 0.20987 12.7363 -0.0718019 10.9978 0.0158897C8.35559 0.0158897 7.69401 0.355648 6.85457 0.786376C6.6857 0.873319 6.50582 0.965273 6.29595 1.05919C6.03953 1.16113 5.91427 1.45165 6.01616 1.70808C6.11809 1.9645 6.40861 2.08976 6.66504 1.98787C6.67815 1.98267 6.69104 1.97691 6.70364 1.97059H6.70064Z" />
      <path d="M20.015 8.66217C20.1089 8.92164 20.3954 9.05585 20.6549 8.96193C20.899 8.87358 21.0348 8.61303 20.9673 8.36237C20.6838 7.62828 20.3322 6.9223 19.9171 6.25379C19.7725 6.01868 19.4646 5.94528 19.2295 6.08988C18.9944 6.23449 18.921 6.5423 19.0656 6.77741C19.4376 7.37617 19.7554 8.00692 20.015 8.66217Z" />
      <path d="M16.608 21.6235C16.4955 21.6234 16.3863 21.5854 16.2982 21.5156C13.2852 19.1302 9.9984 16.3251 9.09899 13.1982C8.77694 11.8565 9.60355 10.5078 10.9452 10.1857C12.2028 9.88394 13.4827 10.5916 13.8958 11.8171C14.4274 13.6648 18.037 16.5219 19.3921 17.5972C19.6137 17.7617 19.6601 18.0747 19.4956 18.2962C19.3311 18.5178 19.0182 18.5642 18.7966 18.3997C18.7884 18.3936 18.7803 18.3872 18.7725 18.3807C16.1912 16.3381 13.476 13.9926 12.9314 12.0929C12.7026 11.297 11.872 10.8373 11.0762 11.0661C10.2803 11.2949 9.82058 12.1255 10.0494 12.9213C10.8668 15.7634 14.0197 18.4427 16.9108 20.7311C17.1272 20.9024 17.1637 21.2167 16.9924 21.433C16.8975 21.5529 16.7529 21.6227 16.6 21.6225L16.608 21.6235Z" />
      <path d="M3.9024 19.0943C3.62645 19.0938 3.40314 18.8697 3.40366 18.5937C3.40403 18.3758 3.54564 18.1833 3.75353 18.1179C4.31215 17.9431 6.07099 17.1526 5.25253 14.3035C4.26085 10.8558 6.25181 7.25701 9.69946 6.26533C9.70115 6.26486 9.70288 6.26435 9.70457 6.26388C13.0778 5.31642 16.5979 7.19794 17.6843 10.5291C17.9331 10.7629 19.639 12.172 20.8062 13.1193C21.015 13.2997 21.0381 13.6152 20.8577 13.8241C20.6851 14.024 20.3866 14.055 20.1766 13.8948C16.9637 11.2875 16.8568 11.1067 16.8048 11.0207C16.7842 10.9862 16.7677 10.9492 16.7559 10.9108C15.8694 8.04858 12.8638 6.41392 9.9794 7.22525C7.06188 8.06352 5.37634 11.1082 6.21461 14.0257C6.21471 14.026 6.2148 14.0264 6.21489 14.0267C7.06633 16.9857 5.5833 18.5936 4.05432 19.0713C4.00518 19.0869 3.95393 19.0946 3.9024 19.0943Z" />
      <path d="M5.50143 21.002C5.22548 21.0015 5.00217 20.7774 5.00269 20.5015C5.00306 20.2835 5.14467 20.091 5.35256 20.0257C7.4232 19.3781 8.17668 18.3148 7.16734 13.7199C6.55237 11.3466 7.91192 8.90925 10.2543 8.18556C12.6402 7.50651 15.1266 8.88232 15.8186 11.2645C15.9575 11.7472 16.5921 12.6776 20.3006 15.6136C20.5222 15.7781 20.5686 16.0911 20.4041 16.3126C20.2396 16.5343 19.9267 16.5806 19.7051 16.4161C19.6969 16.41 19.6888 16.4037 19.681 16.3971C15.5837 13.1572 15.0531 12.2209 14.8582 11.5413C14.3227 9.68546 12.3865 8.61278 10.5291 9.14291C8.69454 9.72527 7.64336 11.6484 8.14366 13.507C9.17197 18.1919 8.54338 20.0777 5.64534 20.981C5.59868 20.9951 5.5502 21.0022 5.50143 21.002Z" />
      <path d="M17.9931 20.0026C17.8605 20.0028 17.7332 19.9504 17.6393 19.8567C17.3039 19.5591 16.9536 19.2786 16.59 19.0163C14.8002 17.6582 11.8042 15.3857 11.0167 12.6455C10.9359 12.3816 11.0843 12.1023 11.3482 12.0215C11.6121 11.9407 11.8915 12.0891 11.9723 12.353C11.974 12.3585 11.9755 12.3641 11.977 12.3697C12.6766 14.79 15.5027 16.9366 17.1936 18.2197C17.5948 18.5081 17.9794 18.8187 18.3458 19.1501C18.5406 19.3455 18.5402 19.6619 18.3447 19.8567C18.2513 19.9499 18.1249 20.0023 17.9931 20.0026Z" />
      <path d="M10.4982 24H10.4662C10.1908 23.9829 9.98138 23.7457 9.99847 23.4704C10.0345 22.8917 10.2983 20.0037 11.9972 20.0037C13.0564 20.1268 13.989 20.7591 14.4955 21.6975C14.6045 21.856 14.724 22.0069 14.8532 22.1493C15.0449 22.3477 15.0394 22.6641 14.8409 22.8558C14.6473 23.0429 14.3403 23.0429 14.1467 22.8558C13.9815 22.6784 13.829 22.4897 13.69 22.2912C13.2643 21.7156 12.7386 21.002 11.9971 21.002C11.4555 21.002 11.0607 22.5011 10.9978 23.5314C10.9814 23.7951 10.7624 24.0005 10.4982 24Z" />
      <path d="M7.50019 23.0007C7.22424 23.0012 7.00009 22.778 6.99957 22.502C6.99915 22.2846 7.13936 22.0919 7.34631 22.0254C8.60946 21.6147 9.44491 18.9924 9.02318 17.6563C8.92439 17.3986 9.05321 17.1097 9.31085 17.0109C9.56849 16.9121 9.85747 17.0409 9.95626 17.2986C9.96352 17.3175 9.96961 17.3368 9.97453 17.3565C10.5052 19.0164 9.57481 22.3532 7.65408 22.9808C7.60414 22.9954 7.55224 23.0021 7.50019 23.0007Z" />
      <path d="M3.50289 13.0073C3.22693 13.0073 3.0032 12.7836 3.0032 12.5076C3.00873 7.81862 6.80851 4.01879 11.4975 4.01331C16.1085 4.01331 17.7583 6.67652 18.9626 8.62124C19.5312 9.53765 20.0218 10.3301 20.6465 10.533C20.9098 10.6154 21.0565 10.8957 20.9741 11.1591C20.8917 11.4225 20.6113 11.5691 20.348 11.4867C20.3445 11.4857 20.3411 11.4845 20.3377 11.4834C19.3643 11.1676 18.7847 10.2312 18.1131 9.14692C16.9719 7.30516 15.5519 5.01267 11.4975 5.01267C7.35996 5.01708 4.00693 8.37011 4.00252 12.5077C4.00252 12.7836 3.77884 13.0073 3.50289 13.0073Z" />
      <path d="M4.00252 16.505C3.81309 16.5054 3.63972 16.3985 3.55484 16.2292C3.2697 15.6924 3.08289 15.1089 3.0032 14.5063C3.0032 14.2303 3.22693 14.0066 3.50289 14.0066C3.77884 14.0066 4.00257 14.2303 4.00257 14.5063C4.08558 14.9516 4.23622 15.3817 4.44927 15.7814C4.57289 16.0282 4.47311 16.3284 4.22639 16.452C4.15687 16.4868 4.08024 16.505 4.00252 16.505Z" />
      <path d="M18.4927 6.01197C18.3178 6.01206 18.1556 5.92072 18.065 5.77115C17.5733 4.95967 14.8391 3.01397 10.9977 3.01397C7.00039 3.01397 4.8638 5.05561 4.42909 5.77115C4.29446 6.01202 3.99002 6.09816 3.74915 5.96349C3.50828 5.82886 3.42214 5.52442 3.55681 5.28355C3.56272 5.27296 3.56904 5.26261 3.57569 5.25249C4.12231 4.35211 6.52174 2.01465 10.9978 2.01465C15.207 2.01465 18.234 4.12323 18.9195 5.25348C19.0624 5.48952 18.9869 5.79677 18.7509 5.93969C18.673 5.98686 18.5837 6.01188 18.4927 6.01197Z" />
      <path d="M16.4941 2.01467C16.4387 2.01476 16.3837 2.00568 16.3312 1.98769C16.1314 1.91873 15.9375 1.84678 15.7466 1.77684C14.2363 1.18633 12.617 0.926629 10.9978 1.01535C8.59937 1.01535 8.05875 1.29215 7.31225 1.67491C7.12838 1.76986 6.93351 1.8688 6.70566 1.97471C6.4529 2.08648 6.15736 1.97218 6.04559 1.71937C5.93382 1.4666 6.04812 1.17106 6.30093 1.05929C6.51178 0.964339 6.69067 0.872431 6.86058 0.785489C7.69402 0.355791 8.35658 0.0159858 10.9978 0.0159858C12.7358 -0.0726426 14.4728 0.207999 16.0944 0.839453C16.2783 0.907423 16.4622 0.976377 16.656 1.03934C16.9188 1.12365 17.0635 1.40505 16.9791 1.66779C16.9117 1.87775 16.7146 2.01875 16.4941 2.01467Z" />
      <path d="M20.4915 9.00996C20.2742 9.00982 20.0819 8.86925 20.0158 8.6622C19.7562 8.00563 19.4385 7.37352 19.0665 6.77345C18.9219 6.53834 18.9952 6.23053 19.2304 6.08593C19.4655 5.94132 19.7733 6.01468 19.9179 6.24983C20.3326 6.91844 20.6839 7.62437 20.9672 8.35841C21.0511 8.6213 20.906 8.90246 20.6431 8.98631C20.5941 9.00195 20.543 9.00996 20.4915 9.00996Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

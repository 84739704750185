import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { MonthPicker } from '../molecules/month-picker';
import { CalendarInlineTrigger } from '../molecules/calendar-inline-trigger';

export function InlineMonthCalendar({
  date,
  format: formatStr,
  onChange,
  ...rest
}) {
  return (
    <MonthPicker
      {...rest}
      onChange={onChange}
      render={(_, ref) => (
        <CalendarInlineTrigger text={dayjs(date).format(formatStr)} ref={ref} />
      )}
    />
  );
}
InlineMonthCalendar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  format: PropTypes.string,
};

InlineMonthCalendar.defaultProps = {
  date: new Date(),
  format: 'MMMM, YYYY',
};

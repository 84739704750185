import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from '@lingui/macro';

import { Typography } from '@oca/ui';

import { ForgotPasswordForm } from '../../organisms';
import { AuthPageTemplate } from '../../templates';
import { pageUnmounted } from '../../model/auth';

export function ForgotPasswordPage() {
  React.useEffect(() => pageUnmounted, []);

  return (
    <AuthPageTemplate>
      <Typography>
        <Trans>Please enter your registered email</Trans>
      </Typography>
      <Typography variant="caption" color="textSecondary" paragraph>
        <Trans>We will send you link to reset your password </Trans>
      </Typography>
      <ForgotPasswordForm />
    </AuthPageTemplate>
  );
}

ForgotPasswordPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.any,
  }).isRequired,
};

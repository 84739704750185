import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { Box } from 'rebass';
import { lighten } from 'polished';

export const Progress = React.forwardRef(function Progress(props, ref) {
  const { className, value, variant, thickness, color } = props;
  const style =
    variant === 'determinate'
      ? { transform: `translateX(${value - 100}%)` }
      : {};

  return (
    <Wrapper
      className={className}
      color={color}
      thickness={thickness}
      ref={ref}
    >
      <Bar variant={variant} bg={color} style={style} />
    </Wrapper>
  );
});

Progress.propTypes = {
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.number,
  variant: PropTypes.oneOf(['indeterminate', 'determinate']),
  thickness: PropTypes.number,
  color: PropTypes.string,
};

Progress.defaultProps = {
  variant: 'indeterminate',
  thickness: 2,
  color: 'primary',
  value: 0,
};

const indeterminate = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`;
const Wrapper = styled(Box)`
  position: relative;
  height: ${({ thickness }) => thickness}px;
  overflow: hidden;
  background-color: ${({ color, theme }) =>
    lighten(0.3, theme.colors[color] || color)};
`;

const Bar = styled(Box)`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  transition: transform 0.2s linear;
  transform-origin: left;
  ${({ variant }) =>
    variant === 'indeterminate' &&
    css`
      width: auto;
      animation: ${indeterminate} 1.5s linear infinite;
    `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStore } from 'effector-react';
import { Flex, Box } from 'rebass';

import { CustomDragLayer } from '@lib/react-dnd-drag-layer';
import { Folder, UnknownFile } from '@oca/icons';

import { $selectedFileIds } from '../model/file-browser.stores';
import { FileBrowserTypes } from '../constants';

const $selectedCount = $selectedFileIds.map(set => set.size);

const iconsByType = {
  [FileBrowserTypes.FOLDER]: Folder,
  [FileBrowserTypes.FILE]: UnknownFile,
};

export const FileBrowserDndPreview = React.memo(
  function FileBrowserDndPreview() {
    return (
      <CustomDragLayer>
        {({ item }) => (
          <PreviewContainer color="textSecondary">
            <Counter />
            <Box>
              <PreviewIcon type={item.type} fontSize={36} />
            </Box>
          </PreviewContainer>
        )}
      </CustomDragLayer>
    );
  },
);

function PreviewIcon({ type, ...props }) {
  const Icon = iconsByType[type] || Folder;

  return <Icon {...props} stroke="#555" />;
}

PreviewIcon.propTypes = {
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.oneOf(Object.values(FileBrowserTypes)),
};

function Counter() {
  const count = useStore($selectedCount);

  return (
    <Badge color="white" bg="primary">
      {count}
    </Badge>
  );
}

const PreviewContainer = styled(Box)`
  position: relative;
  display: inline-block;
`;

const Badge = styled(Flex)`
  height: 20px;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  top: 0;
  right: -5px;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
`;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const CautionCircleOutlined = props => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0002 18.3333C14.6002 18.3333 18.3335 14.5999 18.3335 9.99992C18.3335 5.39992 14.6002 1.66659 10.0002 1.66659C5.40016 1.66658 1.66683 5.39992 1.66683 9.99992C1.66683 14.5999 5.40016 18.3333 10.0002 18.3333ZM10.0002 3.33325C13.6835 3.33325 16.6668 6.31659 16.6668 9.99992C16.6668 13.6833 13.6835 16.6666 10.0002 16.6666C6.31683 16.6666 3.33349 13.6833 3.33349 9.99992C3.33349 6.31658 6.31683 3.33325 10.0002 3.33325ZM9.16683 14.9999L9.16683 9.16659L10.8335 9.16659L10.8335 14.9999L9.16683 14.9999ZM9.16683 5.83325L9.16683 7.49992L10.8335 7.49992L10.8335 5.83325L9.16683 5.83325Z"
    />
  </SvgIcon>
);

import React from 'react';
import { useStore } from 'effector-react';
import styled from 'styled-components';
import { Flex, Box, Text } from 'rebass';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { $notificationList } from '@features/notifications/models/notifications-flow';

import { DefaultAvatar, Bell, Person, Logout } from '@oca/icons';
import { Avatar, Typography, IconButton, Menu } from '@oca/ui';

import { history } from '@lib/routing';
import { createTransition } from '@oca/ui/theme';
import { getOrganizationMeta } from '../lib/get-meta';
import { $session, logoutRequesting } from '../model/auth';

import { openNotificationsPanel } from '../model/panel-toggle-flow';
import { headerWidgetAreaId } from '../constants';

export function Header() {
  const meta = getOrganizationMeta(process.env.REACT_APP_ORGANIZATION);
  const notificationList = useStore($notificationList);
  const notificationCount = (notificationList.results || []).filter(
    item => !item.isRead,
  ).length;
  const user = useStore($session) || {};
  const Logo = meta.logo;

  return (
    <Wrapper
      alignItems="center"
      justifyContent="space-between"
      px={[4, 0, 0, 0]}
      py={1}
      mx="auto"
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        <CenteredFlex>
          <Logo fontSize={42} />
          {/* <Typography fontWeight={300} className="logoText">
            {ORGANIZATION.toUpperCase()}
          </Typography> */}
          <Typography
            variant="h2"
            color="text"
            fontWeight={400}
            fontFamily="Roboto Condensed"
          >
            <Trans>Welcome</Trans>,{' '}
            <UserName>
              {user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.username}
            </UserName>
          </Typography>
        </CenteredFlex>
      </Link>
      <CenteredFlex>
        <div id={headerWidgetAreaId} />
        <NotificationWrap onClick={() => openNotificationsPanel()}>
          <IconButton size="big" padding={8}>
            <Bell color="#009EFF" />
          </IconButton>
          {Boolean(notificationCount) && (
            <NotificationCount>
              <Text color="#fff" fontSize={10}>
                {notificationCount < 20 ? notificationCount : '20+'}
              </Text>
            </NotificationCount>
          )}
        </NotificationWrap>
        <User />
      </CenteredFlex>
    </Wrapper>
  );
}

function User() {
  const user = useStore($session) || {};

  return (
    <React.Fragment>
      <Menu
        placement="bottom-end"
        anchor={
          <AvatarWrapper tabIndex={0}>
            <Avatar src={user.avatar} size={44}>
              <DefaultAvatar />
            </Avatar>
          </AvatarWrapper>
        }
        usePortal
      >
        <Menu.Item
          icon={<Person />}
          onClick={() => history.push(`/hr/employees/${user.username}`)}
        >
          <Typography>
            <Trans>Profile</Trans>
          </Typography>
        </Menu.Item>
        <Menu.Item icon={<Logout />} onClick={logoutRequesting}>
          <Typography>
            <Trans>Logout</Trans>
          </Typography>
        </Menu.Item>
      </Menu>
    </React.Fragment>
  );
}

const UserName = styled.span`
  color: ${({ theme }) => theme.colors.danger};
  font-family: 'Roboto Condensed';
  font-weight: 400;
`;

const Wrapper = styled(Flex)`
  position: relative;
  max-width: var(--content-width);
  margin: 0 auto;
`;

const CenteredFlex = styled(Flex)`
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const NotificationWrap = styled(Box)`
  position: relative;
`;

const NotificationCount = styled(Flex)`
  position: absolute;
  right: 2px;
  top: 4px;
  width: 25px;
  height: 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  background-color: #ff5414;
`;

const AvatarWrapper = styled.div`
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: ${createTransition(['border-color'])};
  outline: none;
  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

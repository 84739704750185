import React from 'react';
import PropTypes from 'prop-types';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';

import { Flatpickr } from '@lib/flatpickr';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps}
 */
export function MonthPicker({ onChange, options, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      options={{
        ...options,
        plugins: [
          ...(options && options.plugins ? options.plugins : []),
          // eslint-disable-next-line new-cap
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'm.y',
            altFormat: 'F Y',
          }),
        ],
        // eslint-disable-next-line react/prop-types
      }}
      onChange={([date], _, instance) => onChange && onChange(date, instance)}
    />
  );
}
MonthPicker.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.shape({ minDate: PropTypes.string }),
};

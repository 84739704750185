import { t } from '@lingui/macro';
import { lazy } from 'react';
import { i18n } from '@lib/i18n';

const EventsPage = lazy(() =>
  import('./pages' /* webpackChunkName: "events" */).then(module => ({
    default: module.Events,
  })),
);

export const routes = [
  {
    path: '/hr/events',
    component: EventsPage,
    navigation: {
      name: i18n._(t`Events`),
      shortName: i18n._(t`EV`),
      onlyTopLevelLink: true,
    },
  },
];

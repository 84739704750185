import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@lib/i18n';
import { t, Trans } from '@lingui/macro';
import { Box, Flex } from 'rebass';
import { useStore } from 'effector-react';
import { combine } from 'effector';

import { Dialog, Button, Typography, BlockHead, SearchField } from '@oca/ui';

import { $selectedFileIds } from '../model/file-browser.stores';
import { onEntityMoveAccept } from '../model/file-browser.events';
import {
  $entityMoveDialogOpen,
  toggleEntityMoveDialog,
  $selectedFilesCount,
  $moveFilesPending,
} from '../model/file-browser.view';
import {
  $search,
  searchChanged,
  $folderList,
} from '../model/folder-browser.model';
import { FolderBrowser } from './folder-browser';
import { FolderBrowserBreadcrumbs } from './folder-browser-breadcrumbs';
import { searchByValue } from '../lib/helpers';

const $filteredFolders = combine(
  $folderList,
  $search,
  $selectedFileIds,
  (folders, search, selectedFilesIds) => {
    const listWithoutMovedFile = folders.filter(
      folder => !selectedFilesIds.has(folder.id),
    );
    return searchByValue(listWithoutMovedFile, search);
  },
);

const $state = combine({
  open: $entityMoveDialogOpen,
  folders: $filteredFolders,
  selectedFilesCount: $selectedFilesCount,
  submitting: $moveFilesPending,
});

export function FileMoveDialog({ category, rootBreadcrumb }) {
  const { open, folders, selectedFilesCount, submitting } = useStore($state);
  const handleClose = () => toggleEntityMoveDialog(false);

  if (!open || (open && selectedFilesCount === 0)) return null;

  return (
    <Dialog
      width={[800]}
      title={i18n._(t`Move files to folder`)}
      onClose={handleClose}
    >
      <Typography fontSize={16}>
        <Trans>
          Select folder where you want to move your files (You have selected:{' '}
          {selectedFilesCount} files)
        </Trans>
      </Typography>
      <BlockHead>
        <FolderBrowserBreadcrumbs root={rootBreadcrumb} />
        <Box width="50%">
          <SearchFolder />
        </Box>
      </BlockHead>
      <FolderBrowser list={folders} category={category} />
      <Flex style={{ gap: 16 }} justifyContent="flex-end">
        <Button onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button
          variant="primary"
          onClick={onEntityMoveAccept}
          disabled={submitting}
        >
          <Trans>Move</Trans>
        </Button>
      </Flex>
    </Dialog>
  );
}

FileMoveDialog.propTypes = {
  category: PropTypes.string.isRequired,
  rootBreadcrumb: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.node,
  }).isRequired,
};

function SearchFolder() {
  const search = useStore($search);
  return (
    <SearchField
      value={search}
      onChange={e => searchChanged(e.target.value)}
      placeholder={i18n._(t`Search folders`)}
      fullWidth
    />
  );
}

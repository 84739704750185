import React from 'react';
import { SvgIcon } from './svg-icon';

export const Comments = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99996 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H5.99996L2.00996 22V4C2.00996 2.9 2.89996 2 3.99996 2ZM18 9H6V11H18V9ZM14 14H6V12H14V14ZM6 8H18V6H6V8Z"
    />
  </SvgIcon>
);

import { fetcher } from '@features/common';

const endpoint = '/api/v1/tasks/';

/**
 *
 * @param {import('axios').AxiosRequestConfig} config
 */
export function fetchTasks(config) {
  return fetcher.get(endpoint, config);
}

export function fetchTask(id) {
  return fetcher.get(`${endpoint + id}/`);
}

export function createTask(data) {
  return fetcher.post(endpoint, data);
}

export function updateTask(data) {
  return fetcher.patch(`${endpoint + data.id}/`, data);
}

export function deleteTask(id) {
  return fetcher.delete(`${endpoint + id}/`);
}

export function addTaskComment(id, data) {
  return fetcher.post(`${endpoint + id}/create_comment/`, data);
}

export function createChecklistItem({ taskId, data }) {
  return fetcher.post(`${endpoint + taskId}/checklist-items/`, data);
}

export function updateChecklistItem({ taskId, id, data }) {
  return fetcher.patch(`${endpoint + taskId}/checklist-items/${id}/`, data);
}

export function deleteChecklistItem({ taskId, id }) {
  return new Promise((resolve, reject) => {
    fetcher
      .delete(`${endpoint + taskId}/checklist-items/${id}/`)
      .then(() => resolve(true))
      .catch(reject);
  });
}

// -------------------------------------------

const projectsEndPoint = '/api/v1/projects/';
export function fetchProject({ id, params }) {
  return fetcher.get(`${projectsEndPoint + id}/`, { params });
}

export function fetchProjectAttendees(project) {
  return fetchProject(project).then(({ data }) =>
    data.attendees.map(item => item.employee),
  );
}

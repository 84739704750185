import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'rebass';
import { Label, Input } from '../atoms';

/**
 * @typedef {import('react').ReactNode} ReactNode
 * @typedef {Object} TextFieldBaseProps
 * @property {Boolean} disabled
 * @property {Boolean} error
 * @property {Boolean} required
 * @property {Boolean} spaceAfter
 * @property {Boolean} fullWidth
 * @property {ReactNode} helperText
 * @property {ReactNode} label
 * @property {string} name
 * @typedef {TextFieldBaseProps & import('../atoms/input').InputProps} TextFieldProps
 */

export const TextField = React.forwardRef(
  /**
   * @param {TextFieldProps} props
   */
  (props, ref) => {
    const {
      disabled,
      error,
      fullWidth,
      helperText,
      label,
      name,
      onChange,
      required,
      spaceAfter,
      ...other
    } = props;
    return (
      <Field as="label" marginBottom={spaceAfter ? 2 : 0} htmlFor={name}>
        {label && (
          <Label
            as="span"
            color={(disabled && 'disabled') || (error && 'error') || 'text'}
            required={required}
            fullWidth={fullWidth}
          >
            {label}
          </Label>
        )}
        <Input
          name={name}
          onChange={onChange}
          error={error}
          disabled={disabled}
          ref={ref}
          required={required}
          fullWidth={fullWidth}
          {...other}
        />
        {helperText && (
          <HelperText
            as="p"
            lineHeight={1}
            margin={0}
            marginTop={1}
            fontSize={0}
            color={error ? 'error' : 'textSecondary'}
          >
            {helperText}
          </HelperText>
        )}
      </Field>
    );
  },
);

TextField.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  spaceAfter: PropTypes.bool,
  required: PropTypes.bool,
};

TextField.defaultProps = {
  disabled: false,
  error: false,
  fullWidth: false,
  helperText: '',
  label: '',
  name: '',
  spaceAfter: false,
  required: false,
};

const Field = styled(Text)`
  display: flex;
  flex-direction: column;
`;

const HelperText = styled(Text)`
  min-height: 1em;
  text-align: left;
`;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Png = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#A066AA"
    />
    <path
      d="M4.35075 14.2103C4.35075 14.0123 4.506 13.7955 4.758 13.7955H6.144C6.92325 13.7955 7.62525 14.3175 7.62525 15.3188C7.62525 16.2668 6.92325 16.794 6.144 16.794H5.14125V17.586C5.14125 17.8508 4.97475 18 4.758 18C4.56 18 4.35075 17.8508 4.35075 17.586V14.2103ZM5.14125 14.5515V16.0448H6.14325C6.5445 16.0448 6.8625 15.6908 6.8625 15.3188C6.8625 14.8988 6.5445 14.5515 6.14325 14.5515H5.14125Z"
      fill="white"
    />
    <path
      d="M8.38947 14.2703C8.38947 14.0535 8.43748 13.8443 8.74948 13.8443C8.96473 13.8443 9.01347 13.8983 9.17472 14.0535L11.1592 16.5368V14.2103C11.1592 14.0123 11.3325 13.7955 11.5372 13.7955C11.7532 13.7955 11.9625 14.0123 11.9625 14.2103V17.586C11.9625 17.8508 11.8005 17.9513 11.6445 18C11.4352 18 11.3325 17.952 11.1592 17.7908L9.17472 15.2715V17.5868C9.17472 17.8515 9.01273 18.0008 8.79673 18.0008C8.58073 18.0008 8.38873 17.8515 8.38873 17.5868V14.2703H8.38947Z"
      fill="white"
    />
    <path
      d="M16.4738 17.5388C16.0478 17.892 15.5258 18.0428 14.9985 18.0428C13.7385 18.0428 12.8453 17.3228 12.8453 15.8955C12.8453 14.6843 13.7873 13.743 15.0518 13.743C15.5258 13.743 16.047 13.905 16.419 14.271C16.785 14.6303 16.257 15.1643 15.8978 14.847C15.6758 14.6303 15.3638 14.469 15.0518 14.469C14.3265 14.469 13.6253 15.0503 13.6253 15.8955C13.6253 16.7835 14.2125 17.3228 14.9985 17.3228C15.3638 17.3228 15.6758 17.2148 15.8978 17.0528V16.3163H14.9985C14.4645 16.3163 14.5185 15.5835 14.9985 15.5835H16.1978C16.4198 15.5835 16.623 15.7523 16.623 15.9383V17.2155C16.623 17.3228 16.5758 17.424 16.4738 17.5388Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

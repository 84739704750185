import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { ModeControl } from '../molecules/mode-control';
import { MonthPicker } from '../molecules/month-picker';

export function InlineMonthControl({ onChange, value, formatStr, disabled }) {
  const pickerRef = React.useRef(null);
  const label = value ? `${dayjs(new Date(value)).format(formatStr)}` : '---';

  const onMoveToPrevMonth = () =>
    pickerRef.current.setDate(
      dayjs(new Date(value))
        .subtract(1, 'month')
        .toDate(),
      true,
    );

  const onMoveToNextMonth = () =>
    pickerRef.current.setDate(
      dayjs(new Date(value))
        .add(1, 'month')
        .toDate(),
      true,
    );

  return (
    <MonthPicker
      onChange={onChange}
      onCreate={instance => {
        pickerRef.current = instance;
      }}
      render={(_, ref) => {
        return (
          <ModeControl
            onLeftClick={onMoveToPrevMonth}
            onRightClick={onMoveToNextMonth}
            leftDisabled={
              pickerRef.current
                ? !pickerRef.current.isEnabled(
                    dayjs(new Date(value))
                      .subtract(1, 'month')
                      .toDate(),
                  )
                : undefined
            }
            rightDisabled={
              pickerRef.current
                ? !pickerRef.current.isEnabled(
                    dayjs(new Date(value))
                      .add(1, 'month')
                      .toDate(),
                  )
                : undefined
            }
            disabled={disabled}
          >
            <div ref={ref}>{label}</div>
          </ModeControl>
        );
      }}
      value={value}
    />
  );
}

InlineMonthControl.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  formatStr: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
};

InlineMonthControl.defaultProps = {
  formatStr: 'MMMM YYYY',
};

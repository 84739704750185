/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { components } from 'react-select';
import { Flex, Box } from 'rebass';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { Row } from '@lib/grid';
import { ArrowLeft } from '@oca/icons';

import { IconButton } from '../atoms/icon-button';
import { Button } from '../atoms/button';
import { BaseSelect } from '../molecules/base-select';
import { ListItem } from '../molecules/list-item';
import { Checkbox } from '../molecules/checkbox';
import { createTransition } from '../theme';

export const GroupSelect = React.forwardRef(function GroupSelect(props, ref) {
  const {
    allExpanded,
    components: componentsProp,
    getGroupValue,
    isGroupDisabled,
    options,
    renderValue,
    styles: stylesProp,
    ...rest
  } = props;
  const [expandedGroups, setExpandedGroups] = useState(
    allExpanded ? options.map(option => getGroupValue(option)) : [],
  );

  const handleExpand = React.useCallback(
    (id, shouldExpand) =>
      setExpandedGroups(
        shouldExpand && !expandedGroups.includes(id)
          ? expandedGroups.concat(id)
          : expandedGroups.filter(groupId => groupId !== id),
      ),
    [expandedGroups],
  );
  const resetExpanded = React.useCallback(() => setExpandedGroups([]), []);

  return (
    <BaseSelect
      ref={ref}
      groupProps={{
        isGroupDisabled,
        getGroupValue,
        expandedGroups,
        setExpandedGroups,
        onExpand: handleExpand,
      }}
      components={{
        ...componentsProp,
        Menu,
        Option,
        GroupHeading,
        Group,
      }}
      onMenuClose={resetExpanded}
      {...rest}
      styles={{
        ...stylesProp,
        group: base => ({ ...base, padding: 0 }),
      }}
      options={options}
      tabSelectsValue={false}
      isMulti
    />
  );
});

GroupSelect.propTypes = {
  allExpanded: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  backspaceRemovesValue: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  components: PropTypes.objectOf(PropTypes.func),
  getGroupValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  isGroupDisabled: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      options: PropTypes.array,
    }),
  ),
  renderValue: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  styles: PropTypes.objectOf(PropTypes.any),
};

GroupSelect.defaultProps = {
  allExpanded: false,
  allowSelectAll: true,
  backspaceRemovesValue: false,
  closeMenuOnSelect: false,
  getGroupValue: group => group.id,
  getOptionLabel: option => option.label || option.name,
  getOptionValue: option => option.id,
  hideSelectedOptions: false,
  isGroupDisabled: group => group.disabled || false,
  renderValue: ({ defaultChildren }) => defaultChildren,
};

/**
 * Extended menu, contains 'select all' button if 'allowSelectAll' prop is passed
 */
const Menu = React.memo(function Menu({ children, ...props }) {
  const {
    allowSelectAll,
    isOptionDisabled,
    options,
    onChange,
    value: selected,
  } = props.selectProps;

  function handleSelectAll(event) {
    if (onChange) {
      const maybeNextValue = options.reduce(
        (acc, item) =>
          acc.concat(item.options.filter(option => !isOptionDisabled(option))),
        [],
      );

      // If all values are already selected then deselect them
      const nextValue =
        selected.length === maybeNextValue.length ? [] : maybeNextValue;

      onChange(nextValue, event);
    }
  }

  return (
    <components.Menu {...props}>
      <Box bg="white">
        <Box px={2} paddingTop={2}>
          {options && allowSelectAll && (
            <Button size="small" onClick={handleSelectAll} fullWidth>
              <Trans>Select all</Trans>
            </Button>
          )}
        </Box>
        <Box py={2}>{children}</Box>
      </Box>
    </components.Menu>
  );
});

Menu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
};

/**
 * Group 'container' component, handle all heavy lifting for GroupHeading
 */
const Group = React.memo(function Group({ children, headingProps, ...props }) {
  const { options } = props.data;

  const {
    groupProps,
    getOptionValue,
    isOptionDisabled,
    value: currentValue,
    inputValue,
    onChange,
  } = props.selectProps;
  const {
    expandedGroups,
    getGroupValue,
    isGroupDisabled,
    onExpand,
  } = groupProps;

  const groupValue = getGroupValue(props.data);
  const isDisabled = isGroupDisabled(props.data);
  const isExpanded = expandedGroups.includes(groupValue);
  const isOptionSelected = option =>
    currentValue &&
    currentValue.some(val => getOptionValue(val) === getOptionValue(option));
  const allChecked = options.every(isOptionSelected);
  const someChecked = options.some(isOptionSelected);

  const handleCheck = React.useCallback(
    (e, checked) => {
      onChange(
        checked
          ? currentValue.concat(
              options.filter(
                option =>
                  !isOptionDisabled(option) &&
                  currentValue.every(
                    val => getOptionValue(val) !== getOptionValue(option),
                  ),
              ),
            )
          : currentValue.filter(val =>
              options.every(
                option => getOptionValue(option) !== getOptionValue(val),
              ),
            ),
      );
    },
    [currentValue, getOptionValue, isOptionDisabled, onChange, options],
  );
  const handleToggleExpand = React.useCallback(
    () => onExpand(groupValue, !isExpanded),
    [groupValue, isExpanded, onExpand],
  );

  React.useEffect(() => {
    if (inputValue.length > 0 && !isExpanded) {
      onExpand(groupValue, true);
    }
  }, [groupValue, inputValue, isExpanded, onExpand]);

  const enhancedHeadingProps = {
    ...headingProps,
    isExpanded,
    isDisabled,
    totalCount: options.length,
    selectedCount: options.filter(isOptionSelected).length,
    checked: someChecked,
    indeterminate: !allChecked && someChecked,
    onExpand: handleToggleExpand,
    onCheck: handleCheck,
  };

  return (
    <components.Group {...props} headingProps={enhancedHeadingProps}>
      {isExpanded ? children : null}
    </components.Group>
  );
});

Group.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  headingProps: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    options: PropTypes.array,
  }).isRequired,
  selectProps: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
};

/**
 * Container for options, can be expanded, checkbox selects all nested options
 */
const GroupHeading = React.memo(function GroupHeading({
  checked,
  children,
  indeterminate,
  isDisabled,
  isExpanded,
  onExpand,
  onCheck,
  totalCount,
}) {
  const handleCheck = React.useCallback(event => onCheck(event, !checked), [
    checked,
    onCheck,
  ]);

  return (
    <ListItem
      as="div"
      bg="greys.0"
      actions={
        <React.Fragment>
          <IconButton
            padding={2}
            color="textSecondary"
            onClick={onExpand}
            disabled={isDisabled}
          >
            <ExpandIcon expanded={isExpanded} />
          </IconButton>
        </React.Fragment>
      }
      divider={!isExpanded}
    >
      <Flex alignItems="center" paddingLeft={2}>
        <Box mr={2}>
          <Checkbox
            as="div"
            checked={checked}
            indeterminate={indeterminate}
            disabled={isDisabled}
            onChange={handleCheck}
            value="kek"
          />
        </Box>
        <ListItem.Text variant="primary">
          {children} ({totalCount})
        </ListItem.Text>
      </Flex>
    </ListItem>
  );
});

GroupHeading.propTypes = {
  checked: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  indeterminate: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

/**
 * Single option component
 */
const Option = React.memo(function Option({ children, ...props }) {
  return (
    <components.Option {...props}>
      <Row alignItems="center">
        <Box mr={2}>
          <Checkbox
            as="div"
            checked={props.isSelected}
            disabled={props.isDisabled}
            onChange={e => false}
            value="opt"
          />
        </Box>
        {children}
      </Row>
    </components.Option>
  );
});

Option.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  isSelected: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  isDisabled: PropTypes.bool,
};

/**
 ***** Helper components and functions *****
 */

// eslint-disable-next-line react/prop-types
function ExpandIcon({ expanded }) {
  return (
    <IconWrapper expanded={expanded}>
      <ArrowLeft />
    </IconWrapper>
  );
}

const IconWrapper = styled.div.attrs(({ expanded }) => ({
  'data-expanded': expanded,
}))`
  transform: rotate(-90deg);
  transition: ${createTransition(['transform'])};

  &[data-expanded='true'] {
    transform: rotate(90deg);
  }

  & svg {
    display: block;
  }
`;

/**
 *
 * @param {{ options: [] }} group
 * @param {Array} selected
 * @param {Function} getOptionValue
 */
export function findSelectedInGroup(group, selected, getOptionValue = i => i) {
  return selected.filter(item =>
    group.options.some(
      option => getOptionValue(item) === getOptionValue(option),
    ),
  );
}

/**
 * Allows to render custom chips, e.g. group them in one
 */
export function GroupSelectValueContainer({
  children,
  getValue,
  renderValue,
  ...props
}) {
  const value = getValue();
  const { getGroupValue, getOptionValue } = props.selectProps;

  return (
    <components.ValueContainer {...props}>
      {value.length > 0
        ? renderValue({
            value,
            getOptionValue,
            options: props.options,
            defaultChildren: children[0],
            getGroupValue: getGroupValue || getOptionValue,
          })
        : children[0]}
      {React.cloneElement(children[1])}
    </components.ValueContainer>
  );
}

GroupSelectValueContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  getValue: PropTypes.func.isRequired,
  renderValue: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.arrayOf(PropTypes.any),
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const PaginationItem = props => {
  const { children, action, active, disabled } = props;

  return (
    <PageBtn onClick={action} isCurrent={active} canDirect={disabled}>
      {children}
    </PageBtn>
  );
};

PaginationItem.propTypes = {
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  action: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

PaginationItem.defaultProps = {
  active: false,
  disabled: false,
};

const PageBtn = styled.button.attrs(({ isCurrent }) => ({
  disabled: isCurrent,
}))`
  width: 34px;
  height: 34px;
  padding: 0 10px;
  outline: none;
  color: ${({ isCurrent }) => (isCurrent ? '#fff' : '#88909d')};
  background: ${({ isCurrent }) => (isCurrent ? '#0075f4' : '#fff')};
  border: none;
  border-right: 1px solid #dee2e6;
  cursor: ${({ isCurrent }) => (isCurrent ? 'default' : 'pointer')};
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  &:first-child {
    width: inherit;
    color: ${({ canDirect }) => (canDirect ? '#0075f4' : '#dee2e6')};
    cursor: ${({ canDirect }) => (canDirect ? 'pointer' : 'default')};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    width: inherit;
    color: ${({ canDirect }) => (canDirect ? '#0075f4' : '#dee2e6')};
    cursor: ${({ canDirect }) => (canDirect ? 'pointer' : 'default')};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: none;
  }
`;

const createButton = label => {
  const Button = props => {
    const { action, disabled } = props;
    return (
      <PaginationItem action={action} disabled={disabled}>
        {label}
      </PaginationItem>
    );
  };

  Button.propTypes = {
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  Button.defaultProps = {
    disabled: false,
  };

  return Button;
};

export const Previous = createButton(['Previous']);
export const Next = createButton(['Next']);
export const Ellipsis = createButton(['...']);

import { createEffect, createEvent, createStore, merge } from 'effector';
import { createFetching } from '@lib/effector-utils';
import { t } from '@lingui/macro';

import { diffArrays } from '@lib/help-fns';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { deleteComment, editComment } from '../api';
import { addTaskCommentRequest } from './add-task-comment';
import { addMeetingCommentRequest } from './add-meeting-comment';

// Deleting Comment
export const deleteCommentRequesting = createEffect('delete comment', {
  handler: deleteComment,
});

export const updateCommentRequesting = createEffect('send edited comment', {
  handler: ({ id, data }) => {
    const { prevAttachments, nextAttachments, ...rest } = data;
    const deleteAttachmentFiles = diffArrays(
      prevAttachments,
      nextAttachments,
      (prev, nextArr) => !nextArr.includes(prev),
    ).map(item => item.id);
    const attachmentFiles = diffArrays(
      nextAttachments,
      prevAttachments,
      (prev, nextArr) => !nextArr.includes(prev),
    );
    const payload = { ...rest, attachmentFiles, deleteAttachmentFiles };

    return editComment(id, payload);
  },
});
export const setCommentList = createEvent('set comment list');
export const resetList = createEvent('reset comment list');

export const $commentDeleteStatus = createFetching(deleteCommentRequesting);

// Comment Text For Editing
export const setCommentText = createEvent('delete comment');
export const resetCommentText = createEvent('reset comment');
export const $editingMessage = createStore(null)
  .on(setCommentText, (_, text) => text)
  .on(updateCommentRequesting.done, () => null)
  .reset(resetCommentText);

// Comment List

export const $commentList = createStore([])
  .on(setCommentList, (_, list) => list)
  .on(deleteCommentRequesting.done, (state, { params }) =>
    state.filter(comment => comment.id !== params),
  )
  .on(updateCommentRequesting.done, (state, { result }) =>
    state.map(comment =>
      result.data.id === comment.id ? result.data : comment,
    ),
  )
  .reset(resetList);

// Side effects
merge([addMeetingCommentRequest.fail, addTaskCommentRequest.fail]).watch(
  ({ error }) => {
    if (error.status && error.status === 403) {
      notify.error(i18n._(t`Sorry, you do not have permissions to comment`));
    }
  },
);

import { createEvent, createStore } from 'effector';

/**
 * Creates Effector store with media query tracking
 * @param {string} query Media query string
 * @returns {import('effector').Store<Boolean>} Effector Store
 * @example createMediaMatcher('(min-width: 768px)')
 */
export function createMediaMatcher(query) {
  const queryChange = createEvent('query change');
  const mediaQueryList = window.matchMedia(query);

  mediaQueryList.addListener(queryChange);

  const isQueryMatches = createStore(mediaQueryList.matches).on(
    queryChange,
    (state, e) => e.matches,
  );

  return isQueryMatches;
}

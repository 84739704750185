import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'rebass';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Person, TripleDotsHorizontal } from '@oca/icons';
import { getFullName } from '@lib/help-fns';
import { Avatar } from '../atoms/avatar';
import { Typography } from '../atoms/typography';

export const AssigneeList = ({ assignees, linkToAssignee }) => {
  const visible = assignees.slice(0, 4);
  const [firstAssignee] = visible;

  return (
    <Flex alignItems="center" flexWrap="nowrap">
      {visible.map((item, index) => (
        <AvatarWrap key={item.id} length={visible.length} index={index}>
          <LinkableAvatar
            link={linkToAssignee ? `${linkToAssignee}/${item.username}` : null}
            src={item.avatar}
            title={getFullName(item, { middleKey: null })}
          />
        </AvatarWrap>
      ))}
      {assignees.length === 1 && (
        <Typography ml={1}>
          {getFullName(firstAssignee, { middleKey: null })}
        </Typography>
      )}
      {assignees.length > 4 && (
        <AvatarWrap length={assignees.length}>
          <Avatar size={26} title="More than 4">
            <TripleDotsHorizontal />
          </Avatar>
        </AvatarWrap>
      )}
    </Flex>
  );
};

AssigneeList.propTypes = {
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  linkToAssignee: PropTypes.string,
};

AssigneeList.defaultProps = {
  assignees: [],
  linkToAssignee: '/hr/employees',
};

function LinkableAvatar({ link, src, title }) {
  const avatar = (
    <Avatar src={src} size={26} title={title}>
      <Person />
    </Avatar>
  );

  if (link) {
    return <Link to={link}>{avatar}</Link>;
  }

  return avatar;
}

const AvatarWrap = styled(Flex)`
  position: relative;
  z-index: ${({ index, length }) => length - index};
  border: 1px solid white;
  border-radius: 50%;
  margin-left: -10px;

  &:first-child {
    margin: 0;
  }

  &:hover {
    z-index: ${({ length }) => length + 1};
  }
`;

import React from 'react';
import { Form, Field } from 'react-final-form';
import { Box } from 'rebass';

import { Trans } from '@lingui/macro';
import { Dialog, Button } from '@oca/ui';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';

export const ExternalAttendeeForm = ({
  // eslint-disable-next-line react/prop-types
  setIsOpen,
  // eslint-disable-next-line react/prop-types
  newAttendees,
  // eslint-disable-next-line react/prop-types
  inviteAttendee,
}) => {
  return (
    <Dialog title="External attendee" onClickOnClose={() => setIsOpen(false)}>
      <Box width={260}>
        <Form
          validateOnBlur
          validate={validateAttendee}
          onSubmit={data =>
            handleAttendeeForm(data, newAttendees, inviteAttendee, setIsOpen)
          }
          render={({ handleSubmit, submitError }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box mb={20}>
                  <Field
                    name="fullName"
                    label={<Trans>Full name</Trans>}
                    spaceAfter
                    component={TextFieldWrapper}
                  />
                </Box>
                <Box mb={20}>
                  <Field
                    name="email"
                    label={<Trans>Email address</Trans>}
                    spaceAfter
                    component={TextFieldWrapper}
                  />
                </Box>
                <Box mb={20}>
                  <Field
                    name="company"
                    label={<Trans>Organization, company</Trans>}
                    spaceAfter
                    component={TextFieldWrapper}
                  />
                </Box>
                <Box>
                  <Button variant="primary" type="submit" fullWidth>
                    <Trans>Invite</Trans>
                  </Button>
                </Box>
              </form>
            );
          }}
        />
      </Box>
    </Dialog>
  );
};

const validateAttendee = values => {
  const emailRegexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const errors = {};

  if (!values.fullName) {
    errors.fullName = <Trans>This is required field</Trans>;
  }
  if (!values.email) {
    errors.email = <Trans>This is required field</Trans>;
  }
  if (values.email && !emailRegexp.test(values.email)) {
    errors.email = <Trans>This is required field</Trans>;
  }
  if (!values.company) {
    errors.company = <Trans>This is required field</Trans>;
  }

  return errors;
};

const handleAttendeeForm = (data, newAttendees, inviteAttendee, setIsOpen) => {
  inviteAttendee([
    ...newAttendees,
    { ...data, newOne: true, tempId: new Date() },
  ]);
  setIsOpen(false);
};

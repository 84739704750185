import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { Trans } from '@lingui/macro';

import {
  InlineMonthControl,
  InlineWeekControl,
  SelectBox,
  Typography,
} from '@oca/ui';
import { Calendar as CalendarIcon } from '@oca/icons';

const calendarByType = {
  month: InlineMonthControl,
  week: InlineWeekControl,
};

const defaultSelectOptions = [
  {
    icon: <CalendarIcon color="currentColor" />,
    value: 'week',
    label: <Trans>Week</Trans>,
  },
  {
    icon: <CalendarIcon color="currentColor" />,
    value: 'month',
    label: <Trans>Month</Trans>,
  },
];

export function VariousCalendar({
  onDateChange,
  onVariantSelect,
  value,
  variant,
  variantOptions,
  disabled,
}) {
  const Calendar = calendarByType[variant] || calendarByType.week;

  return (
    <Flex alignItems="center">
      <Box width={150}>
        <SelectBox
          styles={{
            control: base => ({ ...base, 'border-color': 'transparent ' }),
            indicatorSeparator: () => ({ visibility: 'hidden ' }),
          }}
          options={variantOptions}
          onChange={onVariantSelect}
          value={variantOptions.find(opt => opt.value === variant) || null}
          menuPortalTarget={document.body}
          getOptionValue={option => option.value}
          getOptionLabel={option => (
            <Typography color="inherit" lineHeight="20px">
              {option.icon}
              &nbsp;
              {option.label}
            </Typography>
          )}
          disabled={disabled}
        />
      </Box>
      <Calendar onChange={onDateChange} value={value} disabled={disabled} />
    </Flex>
  );
}

VariousCalendar.propTypes = {
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onDateChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onVariantSelect: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  variant: PropTypes.oneOf(['month', 'week']),
  variantOptions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};

VariousCalendar.defaultProps = {
  variant: 'week',
  variantOptions: defaultSelectOptions,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { Flex } from 'rebass';
import { Menu, IconButton, Typography, ConfirmationDialog } from '@oca/ui';
import { MenuDots, Share, Edit, Delete, Download, Transfer } from '@oca/icons';
import { i18n } from '@lib/i18n';

import { FileBrowserTypes } from '../constants';
import {
  deleteEntity,
  downloadEntity,
  setRenamingFileId,
  setSharingFileId,
  setMoveFileId,
} from '../model/file-browser.events';
import { isFile } from '../lib/helpers';

export function FileMenu({ id, isOwner, name, type, canMove }) {
  const [open, setOpen] = React.useState(false);
  const handleClick = React.useCallback(
    fn => () => {
      setOpen(false);
      fn();
    },
    [],
  );
  return (
    <Menu
      as={Flex}
      tooltipShown={open}
      onVisibilityChange={setOpen}
      anchor={
        <IconButton padding={1} color="text">
          <MenuDots />
        </IconButton>
      }
      usePortal
      closeOnItemClick
    >
      {isFile(type) && (
        <Menu.Item
          icon={<Download />}
          onClick={handleClick(() => downloadEntity({ id, type, name }))}
        >
          <Typography>
            <Trans>Download</Trans>
          </Typography>
        </Menu.Item>
      )}
      {isOwner && (
        <React.Fragment>
          <Menu.Item
            icon={<Share />}
            onClick={handleClick(() => setSharingFileId(id))}
          >
            <Typography>
              <Trans>Share</Trans>
            </Typography>
          </Menu.Item>
          <Menu.Item
            icon={<Edit />}
            onClick={handleClick(() => setRenamingFileId(id))}
          >
            <Typography>
              <Trans>Rename</Trans>
            </Typography>
          </Menu.Item>
          <ConfirmationDialog
            confirmText={<Trans>Delete</Trans>}
            title={i18n._(t`Delete ${type}?`)}
            description={
              <Trans>
                This action will delete <strong>{name}</strong>. Are you sure?
              </Trans>
            }
          >
            {confirm => (
              <Menu.Item
                icon={<Delete />}
                onClick={confirm(
                  handleClick(() => deleteEntity({ id, type, name })),
                )}
              >
                <Typography>
                  <Trans>Delete</Trans>
                </Typography>
              </Menu.Item>
            )}
          </ConfirmationDialog>
          {canMove && (
            <Menu.Item
              icon={<Transfer />}
              onClick={handleClick(() => setMoveFileId(id))}
            >
              <Typography>
                <Trans>Move</Trans>
              </Typography>
            </Menu.Item>
          )}
        </React.Fragment>
      )}
    </Menu>
  );
}

FileMenu.propTypes = {
  id: PropTypes.number.isRequired,
  isOwner: PropTypes.bool.isRequired,
  canMove: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(FileBrowserTypes)).isRequired,
};

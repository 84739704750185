import React from 'react';
import { t } from '@lingui/macro';
import { Dashboard } from '@oca/icons';
import { i18n } from '@lib/i18n';

const DashboardPage = React.lazy(() =>
  import('./pages/dashboard').then(module => ({
    default: module.DashboardPage,
  })),
);

export const routes = [
  {
    path: '/',
    component: DashboardPage,
    exact: true,
    navigation: {
      name: i18n._(t`Dashboard`),
      icon: <Dashboard />,
    },
  },
];

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Flatpickr } from '@lib/flatpickr';
import dayjs from 'dayjs';

import { TextField } from './text-field';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps}
 */
export function RangePickerInput({ inputProps, options, ...props }) {
  return (
    <Flatpickr
      {...props}
      onChange={([dateFrom, dateTo], _) => {
        if (dateFrom) {
          inputProps.form.change('dateFrom', dateFrom);
        }
        if (dateTo) {
          inputProps.form.change('dateTo', dateTo);
        }
      }}
      options={options}
      render={(_, ref) => <TextField {...inputProps} ref={ref} />}
    />
  );
}

RangePickerInput.propTypes = {
  inputProps: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  // eslint-disable-next-line react/require-default-props
  options: PropTypes.shape({
    dateFormat: PropTypes.string,
    mode: PropTypes.string,
  }),
};

RangePickerInput.defaultProps = {
  inputProps: {},
  options: {
    mode: 'range',
    formatDate: date => dayjs(date).format('L'),
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';

import { Avatar } from '../atoms/avatar';

export const SelectOptionLabel = React.forwardRef(function Option(props, ref) {
  const {
    avatar,
    avatarBackground,
    avatarUrlSigning,
    children,
    icon,
    ...rest
  } = props;

  return (
    <Flex alignItems="center" {...rest} ref={ref}>
      {(avatar || icon) && (
        <Box mr={2}>
          <Avatar
            src={avatar}
            size={18}
            background={avatarBackground}
            enableUrlSigning={avatarUrlSigning}
          >
            {icon}
          </Avatar>
        </Box>
      )}
      {children}
    </Flex>
  );
});

SelectOptionLabel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  avatar: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  avatarBackground: PropTypes.string,
  avatarUrlSigning: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  icon: PropTypes.node,
};

SelectOptionLabel.defaultProps = {
  avatarUrlSigning: true,
};

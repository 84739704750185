import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';
import {
  Meeting,
  FileCheck,
  File,
  PencilRuler,
  FileEdit,
  Clock2,
} from '@oca/icons';

export const notificationMsg = (title, action, domain, domainObjId) => {
  let notificationTitle = i18n._(t`Some Notification From OCA`);
  let notificationRoute = '/';
  let icon = File;

  if (domain && domain.includes('document')) {
    notificationTitle = i18n._(t`The ${title} is ${action} succesfully`);
  } else if (domain && domain.includes('ontime')) {
    notificationTitle = i18n._(t`You are successfully checked`);
  } else if (action && action === 'upcoming') {
    notificationTitle = i18n._(t`${title} is upcoming`);
  } else {
    notificationTitle = i18n._(t`The ${title} is ${action} succesfully`);
  }

  if (domain) {
    if (domain.includes('document')) {
      notificationRoute = '/hr/employees/me';
      icon = FileEdit;
    } else if (domain.includes('ontime')) {
      icon = Clock2;

      if (domainObjId) {
        notificationRoute = `/hr/leaves/${domainObjId}`;
      }
    } else if (domain.includes('meeting')) {
      notificationRoute = `/meetings/view/${domainObjId || '/'}`;
      icon = Meeting;
    } else if (domain.includes('task')) {
      notificationRoute = `/tasks/${domainObjId || '/'}`;
      icon = FileCheck;
    } else if (domain.includes('project')) {
      notificationRoute = `/projects/${domainObjId || '/'}`;
      icon = PencilRuler;
    }
  }

  return { notificationTitle, notificationRoute, icon };
};

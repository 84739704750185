import React from 'react';

import { Box } from 'rebass';

import { Wizard } from '@oca/ui';
import { AddPerson, Email, Documents, FileCheck } from '@oca/icons';

import { Trans } from '@lingui/macro';
import { resolveRejectedPromise } from '@lib/help-fns';
import { FORM_ERROR } from 'final-form';
import { Header } from '../molecules';
import {
  EmployeeCreateForm,
  FormBasic,
  FormContacts,
  FormDocuments,
  FormAllowances,
} from '../organisms';
import {
  validateBasic,
  validateContacts,
  validateDocuments,
  validateProperties,
} from '../model/validation';
import {
  createContactModel,
  DOCUMENTS,
  createDocumentModel,
} from '../model/static';
import { createEmployeeRequesting } from '../model/create';
import { CommonEmployeePageTemplate } from '../templates';

const formSteps = [
  {
    component: FormBasic,
    label: <Trans>Basic information</Trans>,
    icon: <AddPerson />,
    validate: validateBasic,
  },
  {
    component: FormContacts,
    label: <Trans>Contacts</Trans>,
    icon: <Email />,
    validate: validateContacts,
  },
  {
    component: FormDocuments,
    label: <Trans>Documents</Trans>,
    icon: <Documents />,
    validate: validateDocuments,
  },
  {
    component: FormAllowances,
    label: <Trans>Allowances</Trans>,
    icon: <FileCheck />,
    validate: validateProperties,
  },
];

const documents = [
  createDocumentModel(DOCUMENTS.PASSPORT),
  createDocumentModel(DOCUMENTS.CIVIL_ID),
];

const initialValues = {
  isResident: true,
  employmentStatus: 'full_time',
  position: 'expert',
  contacts: [createContactModel()],
  documents,
  contracts: [createDocumentModel(DOCUMENTS.CONTRACT)],
  curriculumVitae: [],
  educations: [],
  properties: [],
};

export function EmployeeCreatePage() {
  return (
    <CommonEmployeePageTemplate heading={<Trans>Add employee</Trans>}>
      <Wizard>
        <Header steps={formSteps} />
        <Box px={4}>
          <EmployeeCreateForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            steps={formSteps}
          />
        </Box>
      </Wizard>
    </CommonEmployeePageTemplate>
  );
}

EmployeeCreatePage.propTypes = {};

async function handleSubmit(values) {
  const error = await resolveRejectedPromise(createEmployeeRequesting(values));

  if (error && error.message) {
    return { [FORM_ERROR]: error.message };
  }
  return error && error.data ? error.data : null;
}

import {
  createStore,
  createEffect,
  createEvent,
  forward,
  sample,
} from 'effector';
import { createGate } from 'effector-react';
import { isFolder, normalizeFiles, normalizeBreadcrumbs } from '../lib/helpers';
import { fetchFiles } from '../api';

export const folderClicked = createEvent('folder clicked');
export const searchChanged = createEvent('search changed');
export const $search = createStore('');
export const $currentFolderId = createStore(null);
export const $folderList = createStore([]);
export const $folderBrowserBreadcrumbs = createStore([]);
export const fetchFolderListFx = createEffect('fetch folder list', {
  handler: fetchFiles,
});
export const FolderBrowserGate = createGate('folder browser gate');
export const $pending = fetchFolderListFx.pending;

$folderList
  .on(fetchFolderListFx.doneData, (_, { data }) => {
    const normalized = normalizeFiles(data);
    return normalized.filter(item => isFolder(item.type));
  })
  .reset(FolderBrowserGate.close);

$folderBrowserBreadcrumbs.on(fetchFolderListFx.doneData, (_, { data }) =>
  normalizeBreadcrumbs(data),
);

$currentFolderId
  .on(folderClicked, (_, id) => id)
  .reset(FolderBrowserGate.close);

$search
  .on(searchChanged, (_, value) => value)
  .reset([FolderBrowserGate.close, fetchFolderListFx.done]);

const onFolderClick = sample({
  clock: folderClicked,
  source: FolderBrowserGate.state,
  fn: ({ category }, id) => ({ category, id }),
});

forward({
  from: [FolderBrowserGate.open, onFolderClick],
  to: fetchFolderListFx,
});

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Jpg = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#50BEE8"
    />
    <path
      d="M6.65477 14.2103C6.65477 13.7063 7.44677 13.7063 7.44677 14.2103V16.5788C7.44677 17.5208 6.99677 18.0908 5.95952 18.0908C5.44952 18.0908 5.02427 17.952 4.65227 17.4728C4.34627 17.1068 4.92227 16.5795 5.23427 16.9628C5.48627 17.2748 5.75627 17.3468 6.01952 17.3348C6.35477 17.3228 6.64952 17.172 6.65552 16.5788V14.2103H6.65477Z"
      fill="white"
    />
    <path
      d="M8.50055 14.2103C8.50055 14.0123 8.65655 13.7963 8.90855 13.7963H10.2938C11.0738 13.7963 11.7758 14.3183 11.7758 15.3188C11.7758 16.2668 11.0738 16.7948 10.2938 16.7948H9.29255V17.5868C9.29255 17.8508 9.12455 18 8.90855 18C8.71055 18 8.50055 17.8508 8.50055 17.5868V14.2103ZM9.29255 14.5515V16.0455H10.2938C10.6958 16.0455 11.0138 15.6908 11.0138 15.3188C11.0138 14.8995 10.6958 14.5515 10.2938 14.5515H9.29255Z"
      fill="white"
    />
    <path
      d="M16.0583 17.5388C15.6323 17.8928 15.1103 18.0428 14.5831 18.0428C13.3231 18.0428 12.4298 17.3235 12.4298 15.8955C12.4298 14.685 13.3718 13.7438 14.6363 13.7438C15.1103 13.7438 15.6316 13.905 16.0036 14.271C16.3688 14.6303 15.8416 15.1643 15.4823 14.847C15.2603 14.631 14.9483 14.469 14.6363 14.469C13.9111 14.469 13.2098 15.0503 13.2098 15.8955C13.2098 16.7835 13.7971 17.3235 14.5831 17.3235C14.9483 17.3235 15.2603 17.2148 15.4823 17.0535V16.3163H14.5831C14.0491 16.3163 14.1031 15.5843 14.5831 15.5843H15.7823C16.0043 15.5843 16.2076 15.7523 16.2076 15.9383V17.2155C16.2076 17.3235 16.1603 17.424 16.0583 17.5388Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

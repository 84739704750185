import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Typography } from '../atoms/typography';
import { AssigneeList } from './assignee-list';

export const TaskCard = React.forwardRef(function TaskCard(props, ref) {
  const { assignees, color, dueDate, formatStr, priority, title } = props;

  return (
    <Wrapper bg={color} ref={ref}>
      <Backdrop padding={3}>
        <Typography width={1} variant="h4" gutterBottom>
          {title}
        </Typography>
        <Box marginBottom={2}>
          <AssigneeList assignees={assignees} linkToAssignee={null} />
        </Box>
        <Meta label={<Trans>Priority</Trans>} value={priority} />
        <Meta
          label={<Trans>Due date</Trans>}
          value={dueDate ? dayjs(new Date(dueDate)).format(formatStr) : '---'}
        />
      </Backdrop>
    </Wrapper>
  );
});

TaskCard.propTypes = {
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
    }),
  ),
  color: PropTypes.string,
  dueDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  formatStr: PropTypes.string,
  priority: PropTypes.string,
  title: PropTypes.node,
};

TaskCard.defaultProps = {
  assignees: [],
  color: 'primary',
  dueDate: null,
  formatStr: 'L LT',
  priority: null,
  title: null,
};

// eslint-disable-next-line react/prop-types
function Meta({ label, value }) {
  return (
    <Flex>
      <Box width={78}>
        <Typography color="textSecondary">{label}</Typography>
      </Box>
      <Typography style={{ textTransform: 'capitalize' }}>{value}</Typography>
    </Flex>
  );
}

const Wrapper = styled(Box)`
  border-radius: 2px;
  overflow: hidden;
  padding-left: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
`;

const Backdrop = styled(Box)`
  position: relative;
  background: rgba(255, 255, 255, 0.95);
`;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Doc = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#50BEE8"
    />
    <path
      d="M4.33948 18C4.14148 18 3.92548 17.8912 3.92548 17.628V14.2222C3.92548 14.0062 4.14148 13.8502 4.33948 13.8502H5.71273C8.45323 13.8502 8.39248 18 5.76598 18H4.33948ZM4.71748 14.5815V17.268H5.71273C7.33198 17.268 7.40323 14.5815 5.71273 14.5815H4.71748Z"
      fill="white"
    />
    <path
      d="M10.6875 18.0599C9.57821 18.1079 8.42621 17.3699 8.42621 15.9007C8.42621 14.4254 9.57746 13.7002 10.6875 13.7002C11.7367 13.7534 12.834 14.4794 12.834 15.9007C12.834 17.3227 11.7367 18.0599 10.6875 18.0599ZM10.6215 14.4802C9.94946 14.4802 9.21821 14.9542 9.21821 15.9014C9.21821 16.8434 9.95021 17.3234 10.6215 17.3234C11.3115 17.3234 12.0487 16.8434 12.0487 15.9014C12.0487 14.9534 11.3115 14.4802 10.6215 14.4802Z"
      fill="white"
    />
    <path
      d="M13.5397 15.8947C13.5397 14.7374 14.2657 13.7422 15.645 13.7422C16.167 13.7422 16.581 13.8982 17.0182 14.2762C17.1802 14.4254 17.1982 14.6894 17.0362 14.8574C16.8742 15.0007 16.6282 14.9834 16.4842 14.8394C16.239 14.5814 15.987 14.5102 15.645 14.5102C14.7217 14.5102 14.2785 15.1642 14.2785 15.8954C14.2785 16.6394 14.7157 17.3227 15.645 17.3227C15.987 17.3227 16.305 17.1839 16.581 16.9387C16.7662 16.7947 17.0242 16.8659 17.1322 17.0107C17.2282 17.1427 17.2755 17.3647 17.0662 17.5739C16.6462 17.9647 16.143 18.0427 15.6442 18.0427C14.1937 18.0427 13.5397 17.0519 13.5397 15.8947Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

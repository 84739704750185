import React from 'react';
import PropTypes from 'prop-types';

import { FieldArray } from 'react-final-form-arrays';
import styled from 'styled-components';
import usePrevious from 'react-use/lib/usePrevious';
import { Trans } from '@lingui/macro';

import { Row, Col } from '@lib/grid';
import { Typography, IconButton, Avatar, Chip } from '@oca/ui';
import { Add, Documents } from '@oca/icons';
import { FieldsetAllowance } from '../molecules';
import { createPropertyModel } from '../model/static';
import { fetchStateProperties } from '../api';

/**
 *
 * @param {{ form: import('react-final-form').FormRenderProps }}
 */
export function FormAllowances({ form, initialValues, editable }) {
  return (
    <React.Fragment>
      <Row spacing={1} alignItems="center">
        <Col>
          <Typography variant="h1">
            <Trans>Allowances</Trans>
          </Typography>
        </Col>
        {editable && (
          <Col>
            <IconButton
              color="primary"
              onClick={() =>
                form.mutators.push('properties', createPropertyModel())
              }
            >
              <Add />
            </IconButton>
          </Col>
        )}
      </Row>
      <FieldArray
        name="properties"
        component={AllowanceForm}
        form={form}
        employee={initialValues}
        editable={editable}
      />
    </React.Fragment>
  );
}

FormAllowances.propTypes = {
  form: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

function AllowanceForm({ fields, form, employee, editable }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [state, setState] = React.useState({ categories: [], items: [] });

  const currentFieldsLength = fields.value.length;
  const prevFieldsLength = usePrevious(currentFieldsLength);

  React.useEffect(() => {
    fetchStateProperties().then(data => setState(data));
  }, []);

  React.useEffect(() => {
    // Always set last element as active when adding new entry
    if (prevFieldsLength < currentFieldsLength) {
      setActiveIndex(currentFieldsLength - 1);
    }
  }, [currentFieldsLength, prevFieldsLength]);

  if (currentFieldsLength > 0) {
    return (
      <React.Fragment>
        <AllowanceChipList
          list={fields.value}
          items={state.items}
          activeIndex={editable ? activeIndex : undefined}
          onItemClick={setActiveIndex}
          onDelete={index => {
            fields.remove(index);
            if (index <= activeIndex) {
              setActiveIndex(activeIndex - 1);
            }
          }}
        />
        {editable && activeIndex >= 0 && state.categories.length > 0 && (
          <FieldsetAllowance
            {...state}
            name={`properties[${activeIndex}]`}
            key={`properties[${activeIndex}]`}
            employee={employee}
            form={form}
          />
        )}
      </React.Fragment>
    );
  }

  return (
    <Typography variant="body1" color="textSecondary">
      <Trans>No allowances yet</Trans>
    </Typography>
  );
}

AllowanceForm.propTypes = {
  fields: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
  form: PropTypes.shape({
    change: PropTypes.func,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
  employee: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

function AllowanceChipList({
  activeIndex,
  list,
  items,
  onItemClick,
  onDelete,
}) {
  return (
    <ChipWrapper>
      {list.map((item, index) => {
        const selectedItem =
          item.stateProperty && item.stateProperty.id
            ? item.stateProperty
            : items.find(({ id }) => id === item.stateProperty);

        return (
          <Chip
            avatar={
              <Avatar>
                <Documents />
              </Avatar>
            }
            key={index}
            color={activeIndex === index ? 'primary' : 'default'}
            label={`${
              selectedItem ? (
                `${selectedItem.mark} ${selectedItem.model} ${
                  selectedItem.serialNumber
                }`
              ) : (
                <Trans>Allowance #{index + 1}</Trans>
              )
            }`}
            onClick={() => onItemClick(index)}
            onDelete={() => onDelete(index)}
          />
        );
      })}
    </ChipWrapper>
  );
}

AllowanceChipList.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({ model: PropTypes.string }))
    .isRequired,
  onItemClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const ChipWrapper = styled.div`
  margin-bottom: 32px;
  & > * {
    margin: 4px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import TooltipTrigger from 'react-popper-tooltip';
import styled from 'styled-components';
import { boxShadow } from 'styled-system';
import { Flex, Box } from 'rebass';
import { theme } from '../theme';

/**
 *
 * @param {import('react-popper-tooltip/dist/types').TooltipTriggerProps & { containerComponent: React.ElementType, title: string | JSX.Element }}
 */
export const Tooltip = ({
  children,
  containerComponent,
  title,
  hideArrow,
  ...props
}) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <TooltipContainer
        px={2}
        py={2}
        {...getTooltipProps({
          ref: tooltipRef,
        })}
      >
        {!hideArrow && (
          <TooltipArrow
            color="text"
            {...getArrowProps({
              ref: arrowRef,
              'data-placement': placement,
            })}
          />
        )}
        {title}
      </TooltipContainer>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <TooltipTriggerWrapper
        as={containerComponent}
        {...getTriggerProps({
          ref: triggerRef,
        })}
      >
        {children}
      </TooltipTriggerWrapper>
    )}
  </TooltipTrigger>
);

Tooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hideArrow: PropTypes.bool,
  ...TooltipTrigger.propTypes,
};

Tooltip.defaultProps = {
  children: null,
  title: null,
  hideArrow: false,
};

const TooltipContainer = styled(Flex)`
  border-radius: 2px;
  flex-direction: column;
  font-size: 12px;
  margin: 0.4rem;
  transition: opacity 0.3s;
  z-index: 2147483647;
  line-height: 1;
  ${boxShadow};
`;

TooltipContainer.defaultProps = {
  theme,
  color: 'white',
  backgroundColor: 'text',
};

const TooltipArrow = styled(Box)`
  position: absolute;
  height: 16px;
  width: 16px;
  ::before {
    content: '';
    border-style: solid;
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }
  &[data-placement*='bottom'] {
    left: 0;
    top: 0;
    margin-top: -6px;
    &::before {
      border-color: transparent transparent currentColor transparent;
      border-width: 0 0.5rem 0.4rem 0.5rem;
      position: absolute;
    }
  }
  &[data-placement*='top'] {
    bottom: 0;
    left: 0;
    margin-bottom: -15px;
    &::before {
      border-color: currentColor transparent transparent transparent;
      border-width: 0.4rem 0.5rem 0 0.5rem;
      position: absolute;
    }
  }
  &[data-placement*='right'] {
    left: 0;
    margin-left: -11px;
    &::before {
      border-color: transparent currentColor transparent transparent;
      border-width: 0.5rem 0.4rem 0.5rem 0;
    }
  }
  &[data-placement*='left'] {
    margin-right: -9px;
    right: 0;
    &::before {
      border-color: transparent transparent transparent currentColor;
      border-width: 0.5rem 0 0.5rem 0.4em;
    }
  }
`;

const TooltipTriggerWrapper = styled.span``;

TooltipArrow.defaultProps = {
  theme,
};

import { createStore, createStoreObject, createEvent } from 'effector';

export const fetchStatuses = {
  loading: 'loading',
  fail: 'fail',
  initial: 'initial',
  done: 'done',
};

export function createFetching(
  effect,
  {
    status: initialStatus = fetchStatuses.initial,
    error: initialError = null,
    reset,
  } = {},
) {
  const resetEvent = reset || createEvent();

  const status = createStore(initialStatus)
    .on(effect, () => fetchStatuses.loading)
    .on(effect.done, () => fetchStatuses.done)
    .on(effect.fail, () => fetchStatuses.fail)
    .on(resetEvent, () => fetchStatuses.initial);

  const error = createStore(initialError)
    .reset(effect)
    .reset(effect.done)
    .reset(resetEvent)
    .on(effect.fail, (_, { error: errorValue }) => getError(errorValue));

  const isFail = status.map(state => state === fetchStatuses.fail);
  const isLoading = status.map(state => state === fetchStatuses.loading);
  const isDone = status.map(state => state === fetchStatuses.done);
  const isInitial = status.map(state => state === fetchStatuses.initial);

  return createStoreObject({
    status,
    error,
    isInitial,
    isFail,
    isLoading,
    isDone,
  });
}

/**
 *
 * @returns {{ data: Object<string>, status: number, statusText: string }}
 */
function getError(error) {
  if (error && error.data) {
    return error;
  }

  return {
    data: { error: 'Network error' },
    status: 0,
    statusText: 'Network error',
  };
}

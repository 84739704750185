import { lazy } from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';

const LeavesList = lazy(() =>
  import('./pages').then(module => ({
    default: module.LeavesList,
  })),
);

const LeaveView = lazy(() =>
  import('./pages/view').then(module => ({
    default: module.LeaveView,
  })),
);

export const routes = [
  {
    path: '/hr/leaves',
    component: LeavesList,
    exact: true,
    navigation: {
      name: i18n._(t`Leaves`),
    },
  },
  {
    path: '/hr/leaves/:id',
    component: LeaveView,
    exact: true,
    name: i18n._(t`Leave`),
  },
];

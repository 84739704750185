import { t } from '@lingui/macro';
import { OCALogo, AvisaLogo, Halcyon, Motaded } from '@oca/icons';
import heroImage from '../../assets/hero.jpg';
import officeImage from '../../assets/office-image.jpg';

const oca = {
  title: t`OCA - Olympic Council of Asia`,
  titleTemplate: '%s | OCA',
  fullName: t`Olympic Council of Asia`,
  logo: OCALogo,
  favicon: '/logo.png',
  bgImage: heroImage,
};

const avisa = {
  title: t`Avisa Tech`,
  titleTemplate: '%s | Avisa Tech',
  fullName: t`Avisa Tech`,
  logo: AvisaLogo,
  favicon: '/avisa-logo.png',
  bgImage: officeImage,
};

const halcyon = {
  title: t`Halcyon Incubator`,
  titleTemplate: '%s | Halcyon Incubator',
  fullName: t`Halcyon Incubator`,
  favicon: '/avisa-logo.png',
  logo: Halcyon,
  bgImage: officeImage,
};

const motaded = {
  title: t`Motaded`,
  titleTemplate: '%s | Motaded',
  fullName: t`Motaded`,
  favicon: '/motaded-logo.png',
  logo: Motaded,
  bgImage: officeImage,
};

const organizations = {
  oca,
  avisa,
  halcyon,
  motaded,
};

/**
 * @typedef {Object} OrganizationMeta
 * @property {string} title
 * @property {string} titleTemplate
 * @property {string} fullName
 * @property {string} logo
 * @property {string} bgImage
 * @property {string} [favicon]
 */

/**
 * @param {string} organization
 * @returns {OrganizationMeta}
 */
export function getOrganizationMeta(organization) {
  return organizations[organization] || organizations.oca;
}

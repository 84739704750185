import React from 'react';
import { SvgIcon } from './svg-icon';

export const Xml = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F7B84E"
    />
    <path
      d="M6.76574 15.2902L7.809 13.983C8.121 13.575 8.727 14.0962 8.385 14.4862C8.02575 14.9122 7.64775 15.3742 7.28775 15.8475L8.511 17.3707C8.841 17.8207 8.18175 18.252 7.85775 17.808L6.75374 16.3927L5.66849 17.8387C5.36249 18.276 4.68449 17.7847 5.02649 17.3767L6.23174 15.8475C5.85374 15.3735 5.48774 14.9122 5.12249 14.4862C4.74524 14.0362 5.44649 13.575 5.72249 13.9957L6.76574 15.2902Z"
      fill="white"
    />
    <path
      d="M14 14.2102C14 14.0122 14.168 13.8442 14.378 13.8442C14.57 13.8442 14.732 14.013 14.732 14.2102V17.2147H16.3632C16.9505 17.2147 16.9632 18 16.3632 18H14.3787C14.1687 18 14.0007 17.8507 14.0007 17.6347V14.2102H14Z"
      fill="white"
    />
    <path
      d="M9.79195 15.3675V17.586C9.79195 17.8508 9.57595 18 9.35995 18C9.16795 18 8.99995 17.8508 8.99995 17.586V14.2103C8.99995 13.8983 9.26395 13.7955 9.35995 13.7955C9.5332 13.7955 9.6352 13.8983 9.73795 14.0123L11.0565 15.7928L12.4237 13.9455C12.6225 13.7003 13.1077 13.7955 13.1077 14.2103V17.586C13.1077 17.8508 12.9405 18 12.7485 18C12.5325 18 12.3645 17.8508 12.3645 17.586V15.3675L11.3685 16.6268C11.1532 16.8915 10.8832 16.8915 10.6852 16.6268L9.79195 15.3675Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass';
import { useStore } from 'effector-react';
import { Trans } from '@lingui/macro';

import EmailNotFound from '@lib/illustrations/email-not-found.svg';
import { Comment } from '../molecules/comment';
import { $commentList, setCommentList, resetList } from '../model/comment-flow';

export const CommentsList = ({ comments, maxWidth }) => {
  React.useEffect(() => {
    setCommentList(comments);

    return () => resetList();
  }, [comments]);

  const commentList = useStore($commentList);

  return (
    <React.Fragment>
      {commentList && commentList.length ? (
        commentList.map(comment => (
          <Comment key={comment.id} comment={comment} maxWidth={maxWidth} />
        ))
      ) : (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          px={20}
          py={20}
        >
          <Box mb={20}>
            <img src={EmailNotFound} alt="no email found" />
          </Box>
          <Text color="#88909D" fontSize={12}>
            <Trans>Leave the first comment</Trans>
          </Text>
        </Flex>
      )}
    </React.Fragment>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.any),
  ]),
};

CommentsList.defaultProps = {
  maxWidth: [450, 550],
};

import React from 'react';
import PropTypes from 'prop-types';

import { BlockHead, Tabs, WizardContext, Tab } from '@oca/ui';

export function Header({ steps }) {
  const { activeStepIndex, maxVisitedStepIndex, moveToStep } = React.useContext(
    WizardContext,
  );
  const tabs = steps.map((step, index) => ({
    ...step,
    filled: index < maxVisitedStepIndex,
  }));

  return (
    <BlockHead justifyContent="flex-start" px={4} marginBottom={4}>
      <Tabs
        value={activeStepIndex}
        onChange={value =>
          value <= maxVisitedStepIndex ? moveToStep(value) : null
        }
      >
        {tabs.map(tab => (
          <Tab key={tab.value} {...tab} />
        ))}
      </Tabs>
    </BlockHead>
  );
}

Header.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.func,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    }),
  ).isRequired,
};

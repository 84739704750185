import {
  createEffect,
  createEvent,
  createStore,
  sample,
  combine,
  merge,
} from 'effector';
import { createGate } from 'effector-react';
import { t } from '@lingui/macro';
import qs from 'query-string';

import { projectListFetchRequesting } from '@features/common';
import {
  createFetching,
  debounceStore,
  createToggler,
} from '@lib/effector-utils';
import { $location, history } from '@lib/routing';
import { filterObject, isEmpty } from '@lib/help-fns';

import { i18n } from '@lib/i18n';
import { notify } from '@lib/notifier';
import { createTask, updateTask, deleteTask, fetchTasks } from '../api';

export const TaskListGate = createGate('TaskList gate');
export const TaskViewGate = createGate('TaskView gate');
export const TaskDialogGate = createGate('TaskDialogGate');

// Events
export const editTask = createEvent('task edited');
export const fetchData = createEvent('data fetched');
export const applyFilter = createEvent('filter applied');
export const changeSearchValue = createEvent('search value changed');

export const prefetchRelatedDataRequest = createEffect({
  name: 'prefetch related data request',
  handler: () => Promise.all([projectListFetchRequesting({ completed: 0 })]),
});

export const createTaskRequest = createEffect({
  name: 'create task request',
  handler: createTask,
});

export const updateTaskRequest = createEffect({
  name: 'create new task',
  handler: updateTask,
});

export const deleteTaskRequest = createEffect({
  name: 'delete task request',
  handler: deleteTask,
});

export const fetchTaskListRequest = createEffect({
  name: 'fetch tasks list request',
  handler: fetchTasks,
});

// Stores
export const [$createFormIsOpened, createFormToggle] = createToggler(
  false,
  createTaskRequest.done,
);
export const [$editFormIsOpened, editFormToggle] = createToggler(
  false,
  updateTaskRequest.done,
);

export const $search = createStore('');
export const $taskList = createStore([]);
export const $debouncedSearch = debounceStore(250, $search);
export const $createTaskStatus = createFetching(createTaskRequest);
export const $updateTaskStatus = createFetching(updateTaskRequest);
export const $tasksListFetching = createFetching(fetchTaskListRequest);
export const $relatedDataFetching = createFetching(prefetchRelatedDataRequest);

export const $fetchingList = combine(
  $relatedDataFetching,
  $tasksListFetching,
  (l, r) => ({
    isLoading: l.isLoading || r.isLoading,
    isDone: l.isDone && r.isDone,
  }),
);
export const $filteredTaskList = sample({
  source: $taskList,
  clock: $debouncedSearch,
  fn: (list, search) =>
    search
      ? list.filter(
          item =>
            item.title.toLowerCase().includes(search.toLowerCase()) ||
            item.id == search,
        )
      : list,
});

// Side effects
const onRootPageMount = sample($location, TaskListGate.open);
const onDataFetch = sample($location, fetchData);
const onFilterApplied = sample($location, applyFilter, (location, values) => ({
  location,
  values,
}));

// Reducers
$taskList
  .on(fetchTaskListRequest.done, (_, { result }) => result.data)
  .reset(fetchTaskListRequest.fail, TaskListGate.close);

$filteredTaskList
  .on(fetchTaskListRequest.done, (_, { result }) => result.data)
  .on(createTaskRequest.done, (state, { result }) => {
    return [...state, result.data];
  })
  .on(updateTaskRequest.done, (state, { result }) =>
    state.map(task => (task.id === result.data.id ? result.data : task)),
  )
  .on(deleteTaskRequest.done, (state, { params: id }) =>
    state.filter(task => task.id !== id),
  )
  .reset(fetchTaskListRequest.fail, TaskListGate.close);

$search
  .on(changeSearchValue, (state, value) => value)
  .reset(TaskListGate.close, applyFilter);

// Watchers
onRootPageMount.watch(({ pathname, search }) => {
  const { type, ...rest } = qs.parse(search, { arrayFormat: 'bracket' });

  if (!type) {
    const query = qs.stringify(
      { ...rest, type: 'for_me' },
      { arrayFormat: 'bracket' },
    );

    history.replace(`${pathname}?${query}`);
  }
  fetchData();
});

onDataFetch.watch(({ search }) => {
  const { type: task_type, ...values } = qs.parse(search, {
    arrayFormat: 'bracket',
  });
  fetchTaskListRequest({
    params: { ...values, task_type },
    paramsSerializer,
  });
  prefetchRelatedDataRequest();
});

onFilterApplied.watch(({ location, values }) => {
  const { type = 'for_me', ...rest } = filterObject(
    val => val !== null || !isEmpty(val),
    values,
  );
  const query = qs.stringify({ ...rest, type }, { arrayFormat: 'bracket' });

  history.replace(`${location.pathname}?${query}`);

  fetchTaskListRequest({
    params: { ...rest, task_type: type },
    paramsSerializer,
  });
});

sample({
  source: $location,
  clock: merge([createTaskRequest.done, updateTaskRequest.done]),
  fn: ({ search }, { params }) => ({ search, params }),
}).watch(({ params, search }) => {
  const message = t`Tasks: ${params.title} task is ${
    params.id ? 'updated' : 'created'
  }`;

  notify.success(i18n._(message));
});

merge([createTaskRequest.fail, updateTaskRequest.fail]).watch(({ error }) => {
  if ((error && error.data && error.data.message) || error.message) {
    notify.error(
      i18n._(t`Something went wrong with a server, please try again later`),
    );
  }
});

function paramsSerializer(params) {
  return qs.stringify(params, {
    arrayFormat: 'comma',
  });
}

import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'rebass';
import styled from 'styled-components';

import { ArrowLeft, ArrowRight2 } from '@oca/icons';
import { IconButton } from '../atoms/icon-button';

export const ModeControl = React.forwardRef(
  /**
   *
   * @param {import('./types').ModeControlProps} props
   * @param {React.Ref} ref
   */
  function ModeControl(props, ref) {
    const {
      children,
      disabled,
      leftDisabled,
      rightDisabled,
      onLeftClick,
      onRightClick,
    } = props;

    return (
      <Flex className="mode-control" alignItems="center" ref={ref}>
        <IconButton
          padding={1}
          color="black"
          onClick={onLeftClick}
          disabled={disabled || leftDisabled}
        >
          <ArrowLeft />
        </IconButton>
        <Container mx={2} disabled={disabled}>
          {children}
        </Container>
        <IconButton
          padding={1}
          color="black"
          onClick={onRightClick}
          disabled={disabled || rightDisabled}
        >
          <ArrowRight2 />
        </IconButton>
      </Flex>
    );
  },
);

ModeControl.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  onLeftClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onRightClick: PropTypes.func,
  disabled: PropTypes.bool,
  leftDisabled: PropTypes.bool,
  rightDisabled: PropTypes.bool,
};

ModeControl.defaultProps = {
  disabled: false,
  leftDisabled: false,
  rightDisabled: false,
};

const Container = styled(Box).attrs(({ disabled }) => ({
  'data-disabled': disabled,
}))`
  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
`;

import Axios from 'axios';
import { fetcher } from '@features/common';
import { saveFile } from '@lib/helpers';
import { FileBrowserCategories } from './constants';

const documentsEndpoint = '/api/v1/documents/';
const directoriesEndpoint = '/api/v1/directories/';

const fetchFilesMap = {
  [FileBrowserCategories.SELF]: fetchSelfFiles,
  [FileBrowserCategories.FAVORITES]: fetchFavoriteFiles,
  [FileBrowserCategories.SHARED_BY_ME]: fetchSharedByMeFiles,
  [FileBrowserCategories.SHARED_WITH_ME]: fetchSharedWithMeFiles,
  [FileBrowserCategories.COMPANY]: fetchCompanyFiles,
};

/**
 *
 * @param {{ category: 'self' | 'favorites' | 'shared_by_me' | 'shared_with_me' | 'company', id: number | null }}
 */
export function fetchFiles({ category, id = null }) {
  const fetch = fetchFilesMap[category];

  if (fetch) {
    return fetch(id);
  }

  return Promise.reject(new Error(`No such category: ${category}`));
}

export function fetchSelfFiles(id = null) {
  return fetcher.get('/api/v1/doc-composed/', { params: { directory: id } });
}

export function fetchSharedByMeFiles(id = null) {
  return fetcher.get('/api/v1/doc-shared-by-me/', {
    params: { directory: id },
  });
}

export function fetchSharedWithMeFiles(id = null) {
  return fetcher.get('/api/v1/doc-shared-with-me/', {
    params: { directory: id },
  });
}

export function fetchFavoriteFiles(id = null) {
  return fetcher.get('/api/v1/doc-favorites/', {
    params: { directory: id },
  });
}

export function fetchCompanyFiles(id = null) {
  return fetcher.get('/api/v1/doc-company/', {
    params: { directory: id },
  });
}

// CRUD OPERATIONS
export function updateFile({ id, ...data }) {
  return fetcher.patch(`${documentsEndpoint + id}/`, data);
}

export function deleteFile({ id }) {
  return fetcher.delete(`${documentsEndpoint + id}/`);
}

export function setFavoriteFile({ id, ...data }) {
  return fetcher.post(`${documentsEndpoint + id}/favorite/`, data);
}

export function shareFile({ id, ...data }) {
  return fetcher.post(`${documentsEndpoint + id}/users/`, data);
}

/**
 *
 * @param {{ data: any, config: import('axios').AxiosRequestConfig }}
 */
export async function uploadFile({ data, config }) {
  try {
    const res = await fetcher.post(documentsEndpoint, data, config);

    return res;
  } catch (error) {
    if (Axios.isCancel(error)) {
      console.log(error);
      return error;
    }
    return Promise.reject(error);
  }
}

export function createFolder(data) {
  return fetcher.post(directoriesEndpoint, data);
}

export function updateFolder({ id, ...data }) {
  return fetcher.patch(`${directoriesEndpoint + id}/`, data);
}

export function deleteFolder({ id }) {
  return fetcher.delete(`${directoriesEndpoint + id}/`);
}

export function setFavoriteFolder({ id, ...data }) {
  return fetcher.post(`${directoriesEndpoint + id}/favorite/`, data);
}

export function shareFolder({ id, ...data }) {
  return fetcher.post(`${directoriesEndpoint + id}/users/`, data);
}

export async function downloadFile({ id, name }) {
  try {
    const { data } = await fetcher.get(
      `/api/v1/document-versions/${id}/media/`,
      {
        responseType: 'blob',
      },
    );
    saveFile(data, name);
  } catch (error) {
    console.log(error);
  }
}

export function moveFiles({ id, data }) {
  return fetcher.post(`${directoriesEndpoint}${id}/put-into/`, data);
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export class Masonry extends React.Component {
  constructor(props) {
    super(props);

    this.reCalculateColumnCount = this.reCalculateColumnCount.bind(this);

    // default state
    let columnCount;
    if (this.props.breakpointCols && this.props.breakpointCols.default) {
      columnCount = this.props.breakpointCols.default;
    } else {
      columnCount = 2;
    }

    this.state = {
      columnCount,
    };
  }

  componentDidMount() {
    this.reCalculateColumnCount();

    // window may not be avaliable in some environments
    if (window) {
      window.addEventListener('resize', this.reCalculateColumnCount);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.reCalculateColumnCount();
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.reCalculateColumnCount);
    }
  }

  reCalculateColumnCount() {
    const windowWidth = (window && window.innerWidth) || Infinity;
    let breakpointColsObject = this.props.breakpointCols;

    // Allow passing a single number instead of an object
    if (parseInt(breakpointColsObject, 10) > 0) {
      breakpointColsObject = {
        default: breakpointColsObject,
      };
    }

    let matchedBreakpoint = Infinity;
    let columns = breakpointColsObject.default || 2;

    // eslint-disable-next-line guard-for-in
    for (const breakpoint in breakpointColsObject) {
      const optBreakpoint = parseInt(breakpoint, 10);
      const isCurrentBreakpoint =
        optBreakpoint > 0 && windowWidth <= optBreakpoint;

      if (isCurrentBreakpoint && optBreakpoint < matchedBreakpoint) {
        matchedBreakpoint = optBreakpoint;
        columns = breakpointColsObject[breakpoint];
      }
    }

    columns = Math.max(1, parseInt(columns, 10) || 1);

    if (this.state.columnCount !== columns) {
      this.setState({
        columnCount: columns,
      });
    }
  }

  itemsInColumns() {
    const currentColumnCount = this.state.columnCount;
    const itemsInColumns = new Array(currentColumnCount);
    const items = this.props.children || [];

    for (let i = 0; i < items.length; i += 1) {
      const columnIndex = i % currentColumnCount;

      if (!itemsInColumns[columnIndex]) {
        itemsInColumns[columnIndex] = [];
      }

      itemsInColumns[columnIndex].push(items[i]);
    }

    return itemsInColumns;
  }

  renderColumns() {
    const { gap } = this.props;
    const childrenInColumns = this.itemsInColumns();
    const w = `${100 / childrenInColumns.length}%`;

    return childrenInColumns.map((items, i) => {
      return (
        <MasonryColumn key={i} gap={gap || 24} style={{ width: w }}>
          {items}
        </MasonryColumn>
      );
    });
  }

  render() {
    const { gap } = this.props;

    return (
      <MasonryContainer gap={gap || 24}>
        {this.renderColumns()}
      </MasonryContainer>
    );
  }
}

Masonry.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  breakpointCols: PropTypes.objectOf(PropTypes.number),
  gap: PropTypes.number,
};

Masonry.defaultProps = {
  breakpointCols: {
    default: 4,
    1280: 3,
    960: 2,
  },
  gap: 30,
};

const MasonryContainer = styled.div`
  display: flex;
  margin-left: ${({ gap }) => -gap}px; /* gutter size offset */
  width: auto;
`;

const MasonryColumn = styled.div`
  padding-left: ${({ gap }) => gap}px;
  background-clip: padding-box;
  & > div {
    margin-bottom: ${({ gap }) => gap}px;
  }
`;

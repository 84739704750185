import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { getFileIcon } from '@features/common';
import { Folder } from '@oca/icons';

export function Filename({ name, ext, isFolder }) {
  const { icon: Icon } = isFolder ? { icon: Folder } : getFileIcon(ext);
  return (
    <Flex alignItems="center">
      <Box mr={3} color="textSecondary">
        <Icon fontSize={28} />
      </Box>
      {name}
    </Flex>
  );
}

Filename.propTypes = {
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  ext: PropTypes.string,
  isFolder: PropTypes.bool,
};

Filename.defaultProps = {
  isFolder: false,
};

import { createEffect } from 'effector';
import { createFetching } from '@lib/effector-utils';

import { addMeetingComment } from '../api';

// Events
export const addMeetingCommentRequest = createEffect('add meeting comment');

// Set thunks
addMeetingCommentRequest.use(({ id, data }) => {
  return addMeetingComment(id, data);
});

// Stores
export const $meetingCommentStatus = createFetching(addMeetingCommentRequest);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { fileIcon } from '@features/common';
import { bytesToSize } from '@lib/filesize';
import { UnknownFile, CloseX } from '@oca/icons';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { Typography, IconButton } from '../atoms';

/**
 *
 * @param {Object} props
 * @property {string} props.ext
 * @property {boolean} props.fullWidth
 * @property {string} props.name
 * @property {Function} props.onClick
 * @property {Function} props.onDelete
 * @property {number} props.size
 * @property {'small' | 'medium'} props.variant
 */
export function File(props) {
  const { fullWidth, name, ext, size, onClick, onDelete, variant } = props;
  const Icon = getFileIcon(ext);

  function handleClick(event) {
    onClick && onClick(props, event);
  }

  function handleDelete(event) {
    event.stopPropagation();

    if (onDelete) {
      onDelete(event);
    }
  }

  return (
    <FileWrapper
      backgroundColor="greys.1"
      alignItems="center"
      width={fullWidth ? 1 : undefined}
      px={3}
      variant={variant}
      onClick={handleClick}
    >
      <Box mr={2} title={i18n._(t`File extension: ${ext}`)}>
        <Icon className="file__icon" color="textSecondary" />
      </Box>
      <MetaContainer flex="1" flexDirection="column" title={name}>
        <Typography className="file__name" noWrap>
          {name}
        </Typography>
        <Typography className="file__size" color="textSecondary">
          {bytesToSize(size)}
        </Typography>
      </MetaContainer>
      {onDelete && (
        <Box className="file__delete-button" pl={2} mr={-2}>
          <IconButton
            color="text"
            size="small"
            padding={1}
            onClick={handleDelete}
          >
            <CloseX />
          </IconButton>
        </Box>
      )}
    </FileWrapper>
  );
}

File.propTypes = {
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  ext: PropTypes.string,
  size: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  variant: PropTypes.oneOf(['medium', 'large']),
};

File.defaultProps = {
  fullWidth: false,
  name: '',
  ext: '',
  size: 0,
  variant: 'medium',
};

const FileWrapper = styled(Flex).attrs(({ variant }) => ({
  'data-variant': variant,
}))`
  min-height: 60px;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  &[data-variant='medium'] {
    min-height: 48px;
    & .file {
      &__icon {
        font-size: 24px;
      }
      &__name {
        font-size: 12px;
      }
      &__size {
        font-size: 10px;
      }
    }
  }
  &[data-variant='large'] {
    min-height: 60px;
    & .file {
      &__icon {
        font-size: 32px;
      }
      &__name {
        font-size: 14px;
      }
      &__size {
        font-size: 12px;
      }
    }
  }
  & .file__delete-button {
    visibility: hidden;
  }
  &:hover {
    & .file__delete-button {
      visibility: visible;
    }
  }
`;

const MetaContainer = styled(Flex)`
  overflow: hidden;
`;

function getFileIcon(ext) {
  const file = fileIcon.find(item => item.ext === ext);
  return (file && file.icon) || UnknownFile;
}

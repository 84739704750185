import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box as BaseBox } from 'rebass';
import { Trans } from '@lingui/macro';

import { Avatar, IconButton, Tooltip, ImageCropDialog, Spinner } from '@oca/ui';
import { Camera, Edit } from '@oca/icons';
import { createTransition } from '@oca/ui/theme';

import { useFileUploading } from '../hooks/use-file-uploading';
import { signUrl } from '../lib/sign-url';

/**
 * @param {{ src: string, size: number, onUpload: Function }}
 */
export function AvatarUploader({ src, size, onUploaded }) {
  const { result, isUploading, onUpload } = useFileUploading();
  const [isOpen, setIsOpen] = React.useState(false);

  function handleRemove() {
    onUploaded(null);
  }

  React.useEffect(() => {
    if (result.length > 0) {
      const data = result[0];

      if (onUploaded) {
        onUploaded(data);
      }
    }
  }, [onUploaded, result]);

  function handleCropSave({ url, filename }) {
    setIsOpen(false);

    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const image = blob;
        image.lastModifiedDate = Date.now();
        image.name = filename;

        onUpload([image]);
      });
  }

  return (
    <Box color="primary">
      <Overlay>
        <Tooltip title={<Trans>Edit image</Trans>} placement="bottom">
          <IconButton color="white" padding={1} onClick={() => setIsOpen(true)}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Overlay>
      <Avatar src={src} size={size}>
        {isUploading ? <Spinner /> : <Camera />}
      </Avatar>
      {isOpen && (
        <ImageCropDialog
          title={<Trans>Adjust image</Trans>}
          src={src ? signUrl(src) : ''}
          onClose={() => setIsOpen(false)}
          onCrop={handleCropSave}
          onRemove={handleRemove}
        />
      )}
    </Box>
  );
}

AvatarUploader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  src: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onUploaded: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.number,
};

const Overlay = styled(BaseBox)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: ${createTransition(['opacity'])};
  &:hover {
    opacity: 1;
  }
  & > * {
    margin: 0 4px;
  }
`;

const Box = styled(BaseBox)`
  position: relative;
  outline: none;
  border-radius: 50%;
  box-shadow: 0 0 0 0px currentColor;
  &:focus {
    box-shadow: 0 0 0 2px currentColor;
  }
  transition: ${createTransition(['box-shadow'])};
`;

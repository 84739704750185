import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';

import { Row, Col } from '@lib/grid';
import {
  DayPickerInputWrapper,
  TextFieldWrapper,
} from '@lib/final-form-oca-ui';
import { FileUploaderField } from '@features/common';
import dayjs from 'dayjs';
import { dateTokens } from '@lib/i18n';

const datePickerOptions = {
  static: true,
  formatDate: date => dayjs(date).format('L'),
};

export function FieldsetDocument({ name, withUid }) {
  return (
    <Row spacing="10px" flexDirection="column" key={name}>
      <Col width={[1 / 3, 1 / 4, 1 / 5]}>
        <Field
          name={`${name}.expDate`}
          label={<Trans>Expire date</Trans>}
          parse={val => (val ? dayjs(val).format(dateTokens.ISO_DATE) : '')}
          format={val => (val ? new Date(val).toISOString() : null)}
          component={DayPickerInputWrapper}
          options={datePickerOptions}
        />
      </Col>
      {withUid && (
        <Col width={[1 / 3, 1 / 4, 1 / 5]}>
          <Field
            name={`${name}.uid`}
            component={TextFieldWrapper}
            label={<Trans>Number</Trans>}
          />
        </Col>
      )}
      <Col width={[1 / 2, 1 / 3]} marginBottom={4}>
        <FileUploaderField
          label={<Trans>Picture/Scan</Trans>}
          name={`${name}.files`}
        />
      </Col>
    </Row>
  );
}

FieldsetDocument.propTypes = {
  name: PropTypes.string.isRequired,
  withUid: PropTypes.bool.isRequired,
};

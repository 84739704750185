import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Typography, UserCard, Slider, Slide } from '@oca/ui';
import { Trans } from '@lingui/macro';
import { propOr, getFullName } from '@lib/help-fns';
import { SkeletonRenderer } from '@lib/skeleton-loader';
import { isRTL } from '@lib/i18n';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

const propOrNa = propOr(<Trans>N/A</Trans>);
const formatFull = date => dayjs(date).format('L LT');

const sliderOptions = {
  gap: '1rem',
  perPage: 6,
  breakpoints: {
    768: { perPage: 3 },
    1024: { perPage: 4 },
  },
  direction: isRTL() ? 'rtl' : 'ltr',
  pagination: false,
};

export function ViewGeneralSection({ isLoading, project }) {
  return (
    <Box width={1}>
      <Typography variant="h2" marginBottom={3}>
        <Trans>Description</Trans>
      </Typography>
      <Box width={1 / 2} style={{ minHeight: 80 }}>
        <Typography>{project ? project.description : ''}</Typography>
      </Box>
      <Typography variant="h2" marginBottom={3}>
        <Trans>General information</Trans>
      </Typography>
      <Flex marginBottom={3}>
        <Box width={1 / 3}>
          <InfoBlock
            label={<Trans>Created</Trans>}
            text={project ? formatFull(new Date(project.createdAt)) : 'N/A'}
          />
          <InfoBlock
            label={<Trans>Last update</Trans>}
            text={project ? formatFull(new Date(project.updatedAt)) : 'N/A'}
          />
          <InfoBlock
            label={<Trans>Members</Trans>}
            text={project ? project.attendees.length + 2 : 'N/A'}
          />
        </Box>
        <Box width={1 / 3}>
          <InfoBlock
            label={<Trans>Tasks</Trans>}
            text={propOrNa('tasksCount', project)}
          />
          <InfoBlock
            label={<Trans>Meetings</Trans>}
            text={propOrNa('meetingsCount', project)}
          />
          <InfoBlock
            label={<Trans>Attachments</Trans>}
            text={propOrNa('attachmentsCount', project)}
          />
        </Box>
      </Flex>
      <Box>
        <Typography variant="h2" marginBottom={3}>
          <Trans>Members</Trans>
        </Typography>
        <SkeletonRenderer
          height={280}
          loading={isLoading}
          render={() => (
            <Slider id="members" options={sliderOptions}>
              {(project ? project.members : []).map((user, index) => (
                <Slide key={user.id + user.role}>
                  <Link
                    to={`/hr/employees/${user.username}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <UserCard
                      style={{ height: '100%' }}
                      width={1}
                      avatar={user.avatar}
                      title={user.role}
                      name={getFullName(user, { middleKey: null })}
                      subtitle={
                        <span>
                          {user.department.name}
                          <br />
                          {user.jobTitle}
                        </span>
                      }
                    />
                  </Link>
                </Slide>
              ))}
            </Slider>
          )}
        />
      </Box>
    </Box>
  );
}

ViewGeneralSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    description: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

// eslint-disable-next-line react/prop-types
function InfoBlock({ label, text, ...rest }) {
  return (
    <Flex alignItems="center" {...rest}>
      <Typography color="textSecondary" mr={2}>
        {label}:
      </Typography>
      <Typography>{text}</Typography>
    </Flex>
  );
}

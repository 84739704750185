import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { createComponent } from 'effector-react';

import { history } from '@lib/routing';
import { createStoreObject, combine } from 'effector';
import { Typography } from '@oca/ui';
import { Trans } from '@lingui/macro';
import {
  $sessionFetching,
  sessionFetchRequesting,
  $isAuthenticated,
} from '../model/auth';
import { $accessToken } from '../model/token';
import { SplashScreen } from '../molecules';
import {
  basicDataFetchRequesting,
  $basicDataFetching,
} from '../model/common-data';

const organizationFullNamesMap = {
  oca: 'Olympic Council of Asia',
  avisa: 'Avisa Technologies',
};
/**
 * Fetches user information if token exists and prevent children to render
 */

const $shouldFetchSession = combine(
  $accessToken,
  $isAuthenticated,
  $sessionFetching,
  (accessToken, isAuthenticated, { isLoading, isFail }) =>
    accessToken !== null && !isAuthenticated && !isLoading && !isFail,
);

const $isDone = combine(
  $sessionFetching,
  $basicDataFetching,
  (l, r) => l.isDone && r.isDone,
);

const $error = combine(
  $sessionFetching,
  $basicDataFetching,
  (l, r) => l.error && r.error,
);

const $store = createStoreObject({
  accessToken: $accessToken,
  error: $error,
  isAuthenticated: $isAuthenticated,
  isDone: $isDone,
  shouldFetchSession: $shouldFetchSession,
});

export const AccountLoader = createComponent($store, (props, state) => (
  <AccountLoaderView {...state} {...props} />
));

function AccountLoaderView({
  accessToken,
  children,
  error,
  isDone,
  isAuthenticated,
  noRedirectPaths,
  shouldFetchSession,
}) {
  useEffect(() => {
    const currentRoute = history.location.pathname;
    const shouldRedirectToLogin =
      !accessToken &&
      !isAuthenticated &&
      !noRedirectPaths.includes(currentRoute);

    // Try to fetch user session, redirect to login otherwise
    if (shouldFetchSession) {
      sessionFetchRequesting(accessToken);
      basicDataFetchRequesting();
    } else if (shouldRedirectToLogin) {
      history.replace('/login', { referrer: currentRoute });
    }
  }, [accessToken, isAuthenticated, noRedirectPaths, shouldFetchSession]);

  if (accessToken && !isDone) {
    return (
      <SplashScreen animate={!error}>
        <Box px={3}>
          <Typography variant="h1">
            {error && (
              <Trans>
                Sorry, currently we are unable to reach the server. Please try
                to reload the page
              </Trans>
            )}
            {!error &&
              (organizationFullNamesMap[process.env.REACT_APP_ORGANIZATION] ||
                null)}
          </Typography>
        </Box>
      </SplashScreen>
    );
  }

  return children({ isAuthenticated: shouldFetchSession || isAuthenticated });
}

AccountLoaderView.propTypes = {
  children: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  session: PropTypes.objectOf(PropTypes.any),
};

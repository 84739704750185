import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import { transparentize } from 'polished';

import { Account } from '@features/common';
import { SkeletonRenderer } from '@lib/skeleton-loader';
import { Trans } from '@lingui/macro';
import { Edit, Comments } from '@oca/icons';
import { Typography, Button, AssigneeList } from '@oca/ui';

export const ViewHeader = ({
  isLoading,
  author,
  comments = [],
  members = [],
  onEdit,
  priority,
  title,
}) => {
  const theme = React.useContext(ThemeContext);
  const priorityColor = getPriorityColor(theme, priority);

  return (
    <HeaderContainer
      paddingTop={24}
      paddingLeft={4}
      paddingRight={3}
      paddingBottom={2}
      flexDirection="column"
      backgroundColor={transparentize(0.95, priorityColor)}
    >
      <Indicator color={priorityColor} />
      <SkeletonRenderer
        loading={isLoading}
        render={() => (
          <Typography variant="h2" marginBottom={2}>
            {title}
          </Typography>
        )}
        width={200}
        height={20}
        boxProps={{ marginBottom: 2 }}
      />
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Flex alignItems="center">
          <Box marginRight={2}>
            <SkeletonRenderer
              loading={isLoading}
              render={() => <AssigneeList assignees={members} />}
              width={60}
              height={26}
              viewBox="0 0 60 26"
            >
              <circle cx="13" cy="13" r="13" />
              <circle cx="26" cy="13" r="13" />
              <circle cx="39" cy="13" r="13" />
            </SkeletonRenderer>
          </Box>
          <Typography mr={120}>
            <Trans>Members</Trans>
            :&nbsp;
            {members && !isLoading ? members.length : '-'}
          </Typography>
          <Typography>
            <Comments fontSize={24} color="#0075f4" />
            &nbsp;
            <Trans>Comments</Trans>
            :&nbsp;
            {comments && !isLoading ? comments.length : '-'}
          </Typography>
        </Flex>
        <Account
          render={({ user }) => (
            <React.Fragment>
              {author && author.id === user.id && (
                <Button variant="primary" onClick={onEdit}>
                  <Edit />
                  <Trans>Edit</Trans>
                </Button>
              )}
            </React.Fragment>
          )}
        />
      </Flex>
    </HeaderContainer>
  );
};
ViewHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  author: PropTypes.shape({
    id: PropTypes.number,
  }),
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  priority: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
};
ViewHeader.defaultProps = {
  isLoading: false,
  comments: [],
  members: [],
  title: '',
};

const HeaderContainer = styled(Flex)`
  position: relative;
`;

const Indicator = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
  background: repeating-linear-gradient(
    26deg,
    currentColor,
    currentColor 5px,
    white 5px,
    white 10px
  );
`;

function getPriorityColor(theme, priority) {
  switch (priority) {
    case 'highest':
      return theme.colors.error;
    case 'high':
      return theme.colors.danger;
    case 'medium':
      return theme.colors.primary;
    case 'low':
      return theme.colors.success;
    default:
      return theme.colors.text;
  }
}

/* eslint-disable prefer-destructuring */
export const baseTheme = {
  fonts: {
    common: '"Roboto", Arial, Helvetica, sans-serif',
  },
  fontSizes: [12, 14, 16, 18, 20, 24],
  fontWeights: [400, 500],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  radii: [0, 2, 4, 16, 9999, '100%'],
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
    '0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  duration: {
    fast: 150,
    normal: 250,
    slow: 350,
  },
  easing: {
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  },
};

export const breakpoints = [1100, 1366, 1600, 1920];
export const breakpointsMap = {
  small: breakpoints[0],
  medium: breakpoints[1],
  large: breakpoints[2],
  xlarge: breakpoints[3],
};

export const colors = {
  white: '#fff',
  black: '#333',
  secondary: '#ff4216',
  text: '#424B68',
  textSecondary: '#88909D',
  error: '#FE2400',
  danger: '#FEA201',
  blues: [
    '#B9D9FC',
    '#8BC0FA',
    '#5CA7F8',
    '#2E8EF6',
    '#0075f4',
    '#0060C8',
    '#004B9C',
    '#00366F',
  ],
  greys: [
    '#fafafa',
    '#f5f5f5',
    '#eeeeee',
    '#e0e0e0',
    '#bdbdbd',
    '#9e9e9e',
    '#757575',
    '#616161',
  ],
  greens: [
    '#e8f5e9',
    '#c8e6c9',
    '#a5d6a7',
    '#81c784',
    '#66bb6a',
    '#4caf50',
    '#43a047',
    '#388e3c',
    '#2e7d32',
    '#1b5e20',
  ],
};

// Aliases
colors.primary = colors.blues[4];
colors.lightPrimary = colors.blues[2];
colors.green = colors.greens[5];
colors.success = colors.green;
colors.grey = colors.greys[1];
colors.disabled = colors.greys[3];

export const buttons = {
  primary: {
    color: colors.white,
    backgroundColor: colors.primary,
    outline: 'none',
    '&:disabled': {
      backgroundColor: colors.disabled,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: colors.blues[5],
    },
    '&:active:not(:disabled)': {
      backgroundColor: colors.blues[3],
    },
  },
  danger: {
    color: colors.white,
    backgroundColor: colors.secondary,
    '&:disabled': {
      backgroundColor: colors.disabled,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: '#de3109',
    },
    '&:active:not(:disabled)': {
      backgroundColor: '#ea5b3a',
    },
  },
  primaryOutlined: {
    color: colors.primary,
    border: `1px solid ${colors.primary}`,
    backgroundColor: 'transparent',
    '&:disabled': {
      color: colors.disabled,
      borderColor: colors.disabled,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: colors.primary,
      color: colors.white,
    },
  },
  errorOutlined: {
    color: colors.error,
    border: `1px solid ${colors.error}`,
    backgroundColor: 'transparent',
    '&:disabled': {
      color: colors.disabled,
      borderColor: colors.disabled,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: colors.error,
      color: colors.white,
    },
  },
  dangerOutlined: {
    color: colors.danger,
    border: `1px solid ${colors.danger}`,
    backgroundColor: 'transparent',
    '&:disabled': {
      color: colors.disabled,
      borderColor: colors.disabled,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: colors.danger,
      color: colors.white,
    },
  },
  default: {
    color: colors.textSecondary,
    border: `1px solid ${colors.disabled}`,
    backgroundColor: colors.white,
    outline: 'none',
    '&:disabled': {
      backgroundColor: colors.disabled,
    },
    '&:hover:not(:disabled)': {
      backgroundColor: colors.greys[1],
    },
    '&:active:not(:disabled)': {
      backgroundColor: colors.greys[0],
    },
  },
};

export const theme = {
  ...baseTheme,
  breakpoints,
  colors,
  buttons,
};

export function createTransition(
  props = ['all'],
  {
    duration = theme.duration.fast,
    easing = theme.easing.sharp,
    delay = 0,
  } = {},
) {
  const formatMs = milliseconds => `${Math.round(milliseconds)}ms`;

  return (Array.isArray(props) ? props : [props])
    .map(
      animatedProp =>
        `${animatedProp} ${
          typeof duration === 'string' ? duration : formatMs(duration)
        } ${easing} ${typeof delay === 'string' ? delay : formatMs(delay)}`,
    )
    .join(',');
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Box, Flex } from 'rebass';
import { Trans } from '@lingui/macro';

import {
  Link as UILink,
  TextField,
  IconButton,
  Tooltip,
  Spinner,
} from '@oca/ui';
import { Check } from '@oca/icons';
import { fileExtensionRegex } from '@lib/regex';

import { combine } from 'effector';
import { Filename } from '../molecules';
import { isFolder } from '../lib/helpers';
import { $fileRenameId } from '../model/file-browser.stores';
import {
  setRenamingFileId,
  renameEntity,
  updateFileRequest,
  updateFolderRequest,
  setFilePreviewId,
} from '../model/file-browser.events';

const $renameInProgress = combine(
  updateFileRequest.pending,
  updateFolderRequest.pending,
  (file, folder) => file || folder,
);

export function EditableFilename({ id, ext, link, name, type }) {
  const editId = useStore($fileRenameId);
  const editMode = editId === id;
  const nameProp = editMode ? (
    <EditField id={id} type={type} value={name} />
  ) : (
    <UILink
      color="text"
      as={link ? Link : 'button'}
      to={link}
      onClick={link ? undefined : () => setFilePreviewId(id)}
    >
      {name}
    </UILink>
  );

  return (
    <Filename
      name={<Box flex="1">{nameProp}</Box>}
      isFolder={isFolder(type)}
      ext={ext}
    />
  );
}

EditableFilename.propTypes = {
  id: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  link: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  ext: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

// eslint-disable-next-line react/prop-types
function EditField({ id, type, value }) {
  const updating = useStore($renameInProgress);
  const innerRef = React.useRef();
  const folderType = isFolder(type);
  const match = value.match(fileExtensionRegex());
  const ext = match ? match[0] : '';
  const filename = folderType ? value : value.replace(ext, '');

  React.useEffect(() => {
    if (innerRef.current) {
      innerRef.current.focus();
      innerRef.current.select();
    }
  }, []);

  const handleBlur = event => setRenamingFileId(null);
  const handleKeyUp = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      handleBlur(event);
    }
  };
  const handleSubmit = event => {
    event.preventDefault();
    const { value: newFilename } = event.target.filename;
    if (newFilename) {
      const name = `${newFilename}${ext}`;

      renameEntity({ id, type, name: folderType ? newFilename : name });
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <TextField
        name="filename"
        ref={innerRef}
        defaultValue={filename}
        disabled={updating}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        endAdornment={
          <Flex>
            {updating && <Spinner />}
            {!updating && (
              <Tooltip containerComponent={Flex} title={<Trans>Save</Trans>}>
                <IconButton type="submit" padding={1}>
                  <Check />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        }
        fullWidth
      />
    </form>
  );
}

/* eslint-disable react/prop-types */
import * as React from 'react';
import styled, { css } from 'styled-components';

const ScrollableWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  // Next line is important hack/fix for Firefox
  // https://stackoverflow.com/questions/28636832/firefox-overflow-y-not-working-with-nested-flexbox
  min-height: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${({ size }) => size}px;
    background: ${({ topShadowActiveColor }) => topShadowActiveColor};
    z-index: 10;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${({ size }) => size}px;
    background: ${({ bottomShadowActiveColor }) => bottomShadowActiveColor};
    z-index: 10;
  }
`;

const ScrollableContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  ${({ hideScrollbar }) =>
    hideScrollbar &&
    css`
      scrollbar-width: none;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: none !important;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 0px !important;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    `}

  &::before {
    content: '';
    height: ${({ size }) => size}px;
    width: 100%;
    background: ${({ topShadowInactiveColor }) => topShadowInactiveColor};
    flex-shrink: 0;

    z-index: 11;
    // Next line is important hack/fix for Safari
    // https://stackoverflow.com/questions/40895387/z-index-not-working-on-safari-fine-on-firefox-and-chrome
    transform: translate3d(0, 0, 0);
  }
  &::after {
    content: '';
    height: ${({ size }) => size}px;
    width: 100%;
    background: ${({ bottomShadowInactiveColor }) => bottomShadowInactiveColor};
    flex-grow: 1;
    flex-shrink: 0;

    z-index: 11;
    // Next line is important hack/fix for Safari
    // https://stackoverflow.com/questions/40895387/z-index-not-working-on-safari-fine-on-firefox-and-chrome
    transform: translate3d(0, 0, 0);
  }
`;

/**
 *
 * @typedef {{ active: string, inactive: string }} Colors
 */

/**
 *
 * @param {{ children: ReactNode, shadowSize: number, topShadowColors: Colors, bottomShadowColors: Colors, height: number, hideScrollbar: Boolean }} props
 */
export function ScrollShadow(props) {
  const {
    children,
    shadowSize,
    topShadowColors,
    bottomShadowColors,
    height,
    hideScrollbar,
  } = props;

  return (
    <ScrollableWrapper
      size={shadowSize}
      topShadowActiveColor={topShadowColors.active}
      bottomShadowActiveColor={bottomShadowColors.active}
    >
      <ScrollableContent
        hideScrollbar={hideScrollbar}
        size={shadowSize}
        topShadowInactiveColor={topShadowColors.inactive}
        bottomShadowInactiveColor={bottomShadowColors.inactive}
        style={{ height }}
      >
        {children}
      </ScrollableContent>
    </ScrollableWrapper>
  );
}

ScrollShadow.defaultProps = {
  shadowSize: 2,
  bottomShadowColors: {
    inactive: 'white',
    active: 'gray',
  },
  topShadowColors: {
    inactive: 'white',
    active: 'gray',
  },
};

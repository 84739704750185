/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';
import { background, height, minHeight } from 'styled-system';

/**
 * @typedef {import('rebass').FlexProps} FlexProps
 */

/**
 * @param {FlexProps & {children: ReactNode}}
 */
export const BlockHead = ({ children, ...rest }) => (
  <Head {...rest}>{children}</Head>
);

BlockHead.propTypes = {
  ...Flex.propTypes,
  children: PropTypes.node.isRequired,
};

BlockHead.defaultProps = {
  bg: 'white',
  px: 3,
  alignItems: 'center',
  justifyContent: 'space-between',
};

const Head = styled(Flex)`
  border-bottom: 1px solid #dadada;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  z-index: 1;
  ${height};
  ${minHeight};
  ${background};
`;

Head.propTypes = {
  ...height.propTypes,
  ...minHeight.propTypes,
};

Head.defaultProps = {
  minHeight: 60,
};

import { createEffect } from 'effector';
import { createFetching } from '@lib/effector-utils';

import { addTaskComment } from '../api';

// Events
export const addTaskCommentRequest = createEffect('add task comment');

// Set thunks
addTaskCommentRequest.use(({ id, data }) => {
  return addTaskComment(id, data);
});

// Stores
export const $taskCommentStatus = createFetching(addTaskCommentRequest);

import { createEffect, createStore, createEvent, forward } from 'effector';

import { createFetching } from '@lib/effector-utils';
import { fetchProject } from '../api';
import { TaskDialogGate } from './main';

// Events
export const fetchProjectAssignees = createEvent('fetch project assignees');
export const getProjectRequest = createEffect('get tasks list');

// Set thunks
getProjectRequest.use(id => fetchProject(id));

// Stores
export const $projectFetching = createFetching(getProjectRequest);
export const $projectAssignees = createStore([]);

// Side Effects
$projectAssignees
  .on(getProjectRequest.done, (_, { result }) =>
    transformAssignees(result.data.attendees),
  )
  .reset(TaskDialogGate.close, fetchProjectAssignees);

forward({
  from: fetchProjectAssignees,
  to: getProjectRequest,
});

function transformAssignees(list = []) {
  return list.reduce(
    (acc, item) =>
      item.employee.status !== 'terminate' ? acc.concat(item.employee) : acc,
    [],
  );
}

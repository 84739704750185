/**
 * Returns password strength indication. 0 - weak, 1 - normal, 2 - strong
 * @param {string} password
 * @returns {0|1|2}
 */
export function checkPasswordStrength(password) {
  // eslint-disable-next-line no-useless-escape
  const strong = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  const medium = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/;

  if (strong.test(password)) {
    return 2;
  }

  if (medium.test(password)) {
    return 1;
  }

  return 0;
}

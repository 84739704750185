import React from 'react';
import PropTypes from 'prop-types';
import { SelectOptionLabel } from '@oca/ui';
import { Person } from '@oca/icons';
import { getFullName, isNil } from '@lib/help-fns';

export const EmployeeOptionLabel = React.forwardRef(
  /**
   *
   * @param {{ employee: { avatar: string, firstName: string, lastName: string }}}
   * @param {import('react').Ref} ref
   */
  function EmployeeOptionLabel({ employee }, ref) {
    const name = getFullName(employee, { middleKey: null });

    return (
      <SelectOptionLabel
        ref={ref}
        avatar={employee.avatar}
        icon={isNil(employee.avatar) ? <Person /> : null}
      >
        {name}
      </SelectOptionLabel>
    );
  },
);

EmployeeOptionLabel.propTypes = {
  employee: PropTypes.shape({
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

EmployeeOptionLabel.defaultProps = {
  employee: {},
};

export function filterEmployeeOption(option, str) {
  const name = getFullName(option.data, { middleKey: null }) || '';
  const { id } = option.data;

  return name.toLowerCase().includes(str.toLowerCase()) || (id && id == str);
}

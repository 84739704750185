import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'effector-react';

import { Box, Flex } from 'rebass';
import { OverflowList, Menu, IconButton, Link } from '@oca/ui';
import { ArrowRight2, UnwrapFolder } from '@oca/icons';
import {
  $folderBrowserBreadcrumbs,
  folderClicked,
} from '../model/folder-browser.model';

export function FolderBrowserBreadcrumbs({
  root = { id: null, name: 'Root' },
}) {
  const breadcrumbs = useStore($folderBrowserBreadcrumbs);
  const items = [root, ...breadcrumbs];

  return (
    <OverflowList
      observeParents
      collapseFrom="start"
      minVisibleItems={1}
      tagName="nav"
      items={items}
      overflowRenderer={overflowed => (
        <Menu
          usePortal
          anchor={
            <IconButton>
              <UnwrapFolder />
            </IconButton>
          }
        >
          {overflowed.map(item => (
            <Menu.Item key={item.name} onClick={() => folderClicked(item.id)}>
              {item.name}
            </Menu.Item>
          ))}
        </Menu>
      )}
      visibleItemRenderer={item => {
        const lastItem = item === items[items.length - 1];

        return (
          <Flex alignItems="center" key={item.id}>
            <Link
              as="button"
              color={lastItem ? 'primary' : 'text'}
              onClick={() => folderClicked(item.id)}
            >
              {item.name}
            </Link>
            {!lastItem && (
              <Box mx={1}>
                <ArrowRight2 />
              </Box>
            )}
          </Flex>
        );
      }}
    />
  );
}

FolderBrowserBreadcrumbs.propTypes = {
  root: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.node,
  }).isRequired,
};

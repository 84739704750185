import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';
import { useStore } from 'effector-react';
import dayjs from 'dayjs';

import { Radio, Label, Typography, Checkbox } from '@oca/ui';

import {
  TextFieldWrapper,
  SelectWrapper,
  DayPickerInputWrapper,
} from '@lib/final-form-oca-ui';
import { Row, Col } from '@lib/grid';

import { $departmentList, $employeeList } from '@features/common';

import { unique } from '@lib/help-fns';
import { AvatarUploader } from '@features/common/organisms/avatar-uploader';
import { OnChange } from '@lib/react-final-form-on-change';
import { notify } from '@lib/notifier';
import { DebouncingValidatingField } from '@lib/debouncing-validating-field';
import { dateTokens } from '@lib/i18n';
import { MARITAL_STATUSES, GENDERS, SHIRT_SIZES } from '../model/static';
import { validateUsername } from '../model/validation';

/**
 *
 * @param {{ initialValues: import('../model/profile').Employee }}
 */
export function FormBasic({
  initialValues,
  maritalStatuses,
  genders,
  shirtSizes,
}) {
  const departments = useStore($departmentList);
  const employeeList = useStore($employeeList);

  return (
    <Row flexDirection="column">
      <Col>
        <Typography variant="h2" paragraph>
          <Trans>Basic information</Trans>
        </Typography>
        <Field
          name="avatar"
          format={val => (val ? val.fileUrl || val : '')}
          render={({ input }) => {
            return (
              <Row alignItems="center" mb={3}>
                <AvatarUploader
                  size={86}
                  src={input.value}
                  onUploaded={input.onChange}
                />
              </Row>
            );
          }}
        />
      </Col>
      <Col>
        <Label>
          <Trans>Type</Trans>
        </Label>
        <Row>
          <Col>
            <Field
              name="isResident"
              render={({ input }) => (
                <Radio
                  checked={input.value}
                  value="resident"
                  onChange={event => input.onChange(event.target.checked)}
                  label={<Trans>Resident</Trans>}
                />
              )}
            />
          </Col>
          <OnChange name="isResident">
            {(value, prevValue) => {
              if (value === false && prevValue === true) {
                notify.warn(
                  <Trans>
                    Please fill in employee{"'"}s Visa and Work permit
                    information in documents tab
                  </Trans>,
                  { autoClose: 10000 },
                );
              }
            }}
          </OnChange>
          <Col>
            <Field
              name="isResident"
              render={({ input }) => (
                <Radio
                  checked={input.value === false}
                  value="non_resident"
                  onChange={event => input.onChange(!event.target.checked)}
                  label={<Trans>Non Resident</Trans>}
                />
              )}
            />
          </Col>
        </Row>
      </Col>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <DebouncingValidatingField
            name="username"
            timeout={700}
            component={TextFieldWrapper}
            label={<Trans>Username</Trans>}
            validate={val => usernameAvailable(val, initialValues)}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="rfid"
            component={TextFieldWrapper}
            label={<Trans>RFID</Trans>}
            validate={val => {
              return initialValues.rfid !== val
                ? unique({
                    list: employeeList,
                    value: val,
                    propGetter: ({ rfid }) => rfid,
                    message: (
                      <Trans>This RFID is already assigned to someone</Trans>
                    ),
                  })
                : undefined;
            }}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="birthday"
            label={<Trans>Birthday</Trans>}
            parse={val => (val ? dayjs(val).format(dateTokens.ISO_DATE) : '')}
            format={val => (val ? new Date(val).toISOString() : null)}
            component={DayPickerInputWrapper}
            options={{
              static: true,
              formatDate: date => dayjs(date).format('L'),
            }}
            required
          />
        </Col>
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="firstName"
            component={TextFieldWrapper}
            label={<Trans>First name</Trans>}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="middleName"
            label={<Trans>Middle name</Trans>}
            component={TextFieldWrapper}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="lastName"
            component={TextFieldWrapper}
            label={<Trans>Last name</Trans>}
            required
          />
        </Col>
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="gender"
            component={SelectWrapper}
            label={<Trans>Gender</Trans>}
            parse={val => (val ? val.value : '')}
            format={val =>
              val ? genders.find(({ value }) => val === value) : ''
            }
            options={genders}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="maritalStatus"
            component={SelectWrapper}
            parse={val => (val ? val.value : '')}
            format={val =>
              val ? maritalStatuses.find(({ value }) => val === value) : ''
            }
            label={<Trans>Marital status</Trans>}
            options={maritalStatuses}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="nationality"
            component={TextFieldWrapper}
            label={<Trans>Nationality</Trans>}
          />
        </Col>
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="department"
            component={SelectWrapper}
            label={<Trans>Department</Trans>}
            options={departments}
            parse={val => (val ? val.id : '')}
            format={val =>
              val ? departments.find(({ id }) => (val.id || val) === id) : ''
            }
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="jobTitle"
            component={TextFieldWrapper}
            label={<Trans>Job title</Trans>}
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="shirtSize"
            component={SelectWrapper}
            parse={val => (val ? val.value : '')}
            format={val =>
              val ? shirtSizes.find(({ value }) => val === value) : ''
            }
            label={<Trans>Shirt size</Trans>}
            options={shirtSizes}
          />
        </Col>
      </Row>
      <Row mb={3}>
        <Field
          name="position"
          render={({ input }) => {
            const isManager = input.value === 'manager';
            const isDirector = input.value === 'director';

            return (
              <div style={{ visibility: isDirector ? 'hidden' : 'visible' }}>
                <Checkbox
                  onChange={e =>
                    input.onChange(isManager ? 'expert' : 'manager')
                  }
                  value="manager"
                  checked={isManager}
                  disabled={isDirector}
                  label={<Trans>Set user as department manager</Trans>}
                />
              </div>
            );
          }}
        />
      </Row>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Label>
            <Trans>Employment status</Trans>
          </Label>
          <Field
            type="radio"
            name="employmentStatus"
            value="full_time"
            render={({ input }) => (
              <Radio {...input} label={<Trans>Full time</Trans>} />
            )}
          />
          <Field
            type="radio"
            name="employmentStatus"
            value="part_time"
            render={({ input }) => (
              <Radio {...input} label={<Trans>Part time</Trans>} />
            )}
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Field
            name="hiredDate"
            label={<Trans>Hired date</Trans>}
            parse={val => (val ? dayjs(val).format(dateTokens.ISO_DATE) : '')}
            format={val => (val ? new Date(val).toISOString() : null)}
            component={DayPickerInputWrapper}
            options={{ formatDate: date => dayjs(date).format('LL') }}
          />
        </Col>
      </Row>
      <Row>
        <hr style={{ width: '100%', opacity: 0.25 }} />
      </Row>
      <Row mb={3}>
        <Field
          type="checkbox"
          name="isMatrixActive"
          render={({ input }) => (
            <Checkbox
              {...input}
              label={<Trans>Access to OCA messenger</Trans>}
            />
          )}
        />
      </Row>
    </Row>
  );
}

FormBasic.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  maritalStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  shirtSizes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};

FormBasic.defaultProps = {
  maritalStatuses: [
    { value: MARITAL_STATUSES.MARRIED, label: <Trans>Married</Trans> },
    { value: MARITAL_STATUSES.SINGLE, label: <Trans>Single</Trans> },
  ],
  genders: [
    { value: GENDERS.MALE, label: <Trans>Male</Trans> },
    { value: GENDERS.FEMALE, label: <Trans>Female</Trans> },
  ],
  shirtSizes: Object.values(SHIRT_SIZES).map(value => ({
    value,
    label: value,
  })),
};

async function usernameAvailable(value, initialValues) {
  if (!value) {
    return <Trans>This field is required</Trans>;
  }

  if (initialValues && initialValues.username !== value) {
    const error = await validateUsername(value);

    return error || undefined;
  }
  return undefined;
}

import React from 'react';
import { renderRoutes } from 'react-router-config';
import { t, Trans } from '@lingui/macro';
import { Meeting } from '@oca/icons';
import { i18n } from '@lib/i18n';
import { MeetingViewGate } from './model/single-meeting-flow';

const CreateMeeting = React.lazy(() =>
  import('./pages/create').then(module => ({
    default: module.CreateMeeting,
  })),
);

const MeetingsListPage = React.lazy(() =>
  import('./pages/list').then(module => ({
    default: module.MeetingsListPage,
  })),
);

const MeetingViewPage = React.lazy(() =>
  import('./pages/view').then(module => ({
    default: module.MeetingViewPage,
  })),
);

const MeetingEditPage = React.lazy(() =>
  import('./pages/edit').then(module => ({
    default: module.MeetingEditPage,
  })),
);

export const routes = [
  {
    path: '/meetings',
    component: MeetingsListPage,
    exact: true,
    navigation: {
      name: i18n._(t`Meetings`),
      icon: <Meeting />,
      onlyTopLevelLink: true,
    },
  },
  {
    path: '/meetings/create',
    component: CreateMeeting,
    name: <Trans>Create Meeting</Trans>,
    exact: true,
  },
  {
    path: '/meetings/:id',
    component: SingleMeeting,
    routes: [
      {
        path: '/meetings/:id',
        component: MeetingViewPage,
        name: <Trans>Meeting View</Trans>,
        exact: true,
      },
      {
        path: '/meetings/:id/edit',
        component: MeetingEditPage,
        name: <Trans>Edit Meeting</Trans>,
        exact: true,
      },
    ],
  },
];

// eslint-disable-next-line react/prop-types
function SingleMeeting({ match, route }) {
  return (
    <React.Fragment>
      <MeetingViewGate id={match.params.id} />
      {renderRoutes(route.routes)}
    </React.Fragment>
  );
}

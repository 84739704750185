import React from 'react';
import { t, Trans } from '@lingui/macro';
import { emailRegex } from '@lib/regex';
import { i18n } from '@lib/i18n';
import { DOCUMENTS } from './static';
import { checkUsername } from '../api';

const requiredMessage = <Trans>This field is required</Trans>;

const required = (fields, values, message = requiredMessage) => {
  return fields.reduce((acc, field) => {
    if (
      values[field] === undefined ||
      values[field] === null ||
      values[field] === ''
    ) {
      acc[field] = message;
    }
    return acc;
  }, {});
};

const max = (length, message = '') => value =>
  value && value.length > length ? message : undefined;

const maxPhoneLength = max(
  15,
  i18n._(t`Phone number must be no longer than 15 characters`),
);

function email(value, message = <Trans>Please enter a valid email</Trans>) {
  return emailRegex().test(value) ? undefined : message;
}

export function validateBasic(values) {
  const requiredFields = required(
    ['firstName', 'lastName', 'birthday', 'department', 'jobTitle'],
    values,
  );

  return requiredFields;
}

export function validateContacts(values) {
  let errors = {};
  const basicContacts = required(
    ['street', 'country', 'city', 'mobilePhone', 'email'],
    values,
  );
  const phoneLength = maxPhoneLength(values.mobilePhone);
  const workPhoneLength = maxPhoneLength(values.workPhone);

  if (phoneLength) {
    basicContacts.mobilePhone = phoneLength;
  }

  if (workPhoneLength) {
    errors.workPhone = workPhoneLength;
  }

  if (!basicContacts.email && email(values.email)) {
    basicContacts.email = email(values.email);
  }

  const contacts = values.contacts.reduce(
    (acc, contact) =>
      acc.concat([required(['relationship', 'mobilePhone'], contact)]),
    [],
  );

  if (Object.keys(basicContacts).length > 0) {
    errors = { ...errors, ...basicContacts };
  }
  if (contacts.length > 0) {
    errors.contacts = contacts;
  }

  return errors;
}

export function validateDocuments(...args) {
  const values = args[0];
  const errors = {};
  const validations = {
    documents: validateDocument(
      values.documents,
      ['expDate', 'docType'],
      [DOCUMENTS.VISA],
    ),
    contracts: validateDocument(values.contracts, ['expDate']),
    curriculumVitae: validateDocument(values.curriculumVitae, []),
    educations: validateDocument(values.educations, ['institution']),
  };

  Object.entries(validations).forEach(([key, value]) => {
    if (value && value.length > 0) {
      errors[key] = value;
    }
  });

  return errors;
}

export function validateProperties(values) {
  const errors = {};
  const propertiesErrors = validateDocument(values.properties, [
    'stateProperty',
  ]);
  if (propertiesErrors.length > 0) {
    errors.properties = propertiesErrors;
  }
  return errors;
}

function validateDocument(list, requiredFields, skip = []) {
  return list.reduce((acc, document) => {
    // If some field is 'optional' skip it by returning empty object
    if (requiredFields.some(field => skip.includes(document[field]))) {
      return acc.concat([{}]);
    }
    const err = required(requiredFields, document);

    if (
      document.files &&
      (document.files.length === 0 ||
        document.files.every(file => file.deleted === true))
    ) {
      err.files = requiredMessage;
    }
    return acc.concat([err]);
  }, []);
}

export async function validateUsername(username) {
  const { data } = await checkUsername(username);

  return data.allowed ? null : <Trans>Username is already exists</Trans>;
}

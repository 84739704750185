import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { useGate } from 'effector-react';

import { i18n } from '@lib/i18n';

import { useRouteBreadcrumb } from '../lib/use-route-breadcrumb';
import { useTableColumns } from '../lib/use-table-columns';
import { isFile } from '../lib/helpers';
import { CommonDocumentsTemplate } from '../templates';
import { Header, FileBrowser } from '../organisms';
import { FileBrowserCategories } from '../constants';
import { FileBrowserGate } from '../model/file-browser.view';

export function SharedWithMePage({ route, match }) {
  const rootBreadcrumb = useRouteBreadcrumb(route);
  const columns = useTableColumns({
    rootPath: rootBreadcrumb.path,
    menuVisibilityPredicate,
  });
  const { id = null } = match.params;

  useGate(FileBrowserGate, {
    id,
    category: FileBrowserCategories.SHARED_WITH_ME,
  });

  return (
    <CommonDocumentsTemplate title={i18n._(t`Documents: Shared with me`)}>
      <Header rootBreadcrumb={rootBreadcrumb} />
      <FileBrowser columns={columns} />
    </CommonDocumentsTemplate>
  );
}

SharedWithMePage.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

function menuVisibilityPredicate({ type }) {
  return isFile(type);
}

import { createEffect } from 'effector';

import { history } from '@lib/routing';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { getFullName, omit } from '@lib/help-fns';

import { employeeListFetchRequesting } from '@features/common';
import {
  createEmployeeDocument,
  createEmployee,
  createEmployeeProperty,
  createEmployeeEducation,
  createEmployeeContact,
  updateEmployee,
} from '../api';

export const createEmployeeRequesting = createEffect(
  'employee create requested',
);

createEmployeeRequesting.done.watch(({ params }) => {
  const name = getFullName(params);

  employeeListFetchRequesting();
  history.replace('/hr/employees');
  notify.success(i18n._(t`User ${name} is successfully created!`));
});

createEmployeeRequesting.fail.watch(({ error, params }) => {
  const name = getFullName(params);
  const fields = Object.keys(error && error.data ? error.data : {});
  const errorText =
    (error && error.data && error.data.nonFieldsError) ||
    error.message ||
    'unknown error';

  let message = i18n._(t`Unable to create ${name}, ${errorText}`);

  if (fields.length > 0) {
    const fieldsNames = fields.join(', ');

    message = i18n._(
      t`Cannot create user ${name}. Please fill out missing information in required fields: ${fieldsNames}`,
    );
  }

  notify.error(message);
});

createEmployeeRequesting.use(handleEmployeeCreate);

async function handleEmployeeCreate(data) {
  const {
    avatar,
    contacts,
    contracts,
    curriculumVitae,
    documents,
    educations,
    properties,
    ...employeeBasicInfo
  } = data;

  try {
    const { data: employee } = await createEmployee({
      ...employeeBasicInfo,
      ...(avatar ? { avatar: avatar.file } : {}),
    });

    if (employee) {
      const entities = await handleCreateEmployeeEntities({
        contacts,
        educations,
        properties,
        documents: [...documents, ...contracts, ...curriculumVitae],
        employeeId: employee.id,
      });
      return updateEmployee({
        ...omit(['properties'], entities),
        id: employee.id,
      });
    }

    return null;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function handleCreateEmployeeEntities({
  employeeId,
  contacts,
  documents,
  educations,
  properties,
}) {
  try {
    const contactsResList = await processRequestsSequentially(
      createEmployeeContact,
      contacts,
    );
    const documentsResList = await processRequestsSequentially(
      createEmployeeDocument,
      documents.map(item => filterDeletedFiles(item)),
    );
    const educationsResList = await processRequestsSequentially(
      createEmployeeEducation,
      educations.map(item => filterDeletedFiles(item)),
    );
    const propertiesResList = await processRequestsSequentially(
      createEmployeeProperty,
      properties.map(item =>
        filterDeletedFiles({ ...item, employee: employeeId }),
      ),
    );

    return {
      contacts: contactsResList.map(mapId),
      documents: documentsResList.map(mapId),
      educations: educationsResList.map(mapId),
      properties: propertiesResList.map(mapId),
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

async function processRequestsSequentially(reqFn, arr) {
  const resList = [];

  for (const payload of arr) {
    // eslint-disable-next-line no-await-in-loop
    const res = await reqFn(payload);

    resList.push(res);
  }

  return resList;
}

function mapId({ data }) {
  return data ? data.id : null;
}

function filterDeletedFiles(item) {
  return {
    ...item,
    files: item.files ? item.files.filter(file => file.deleted !== true) : [],
  };
}

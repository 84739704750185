import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import dayjs from 'dayjs';

import { Typography } from '@oca/ui';
import { Card } from '../molecules';

export function MeetingsSection({ date, list, linkTo, onItemClick }) {
  return (
    <Box as="section" marginBottom={3}>
      <Typography variant="h2" marginBottom={3}>
        {date ? dayjs(new Date(date)).format('MMMM DD, dddd') : '---'}
      </Typography>
      <Flex flexWrap="wrap">
        {list.map((item, idx) => {
          const lastItem = idx === list.length - 1;

          return (
            <Box key={item.id} mr={lastItem ? 0 : 3} marginBottom={3}>
              <Link
                to={`${linkTo}/${item.id}`}
                style={{ textDecoration: 'none ' }}
              >
                <Card
                  onClick={
                    onItemClick ? event => onItemClick(item, event) : undefined
                  }
                  title={item.title}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  location={item.location}
                  project={item.project ? item.project.name : '---'}
                  status={item.status}
                  assignees={item.invitedEmployees}
                />
              </Link>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}

MeetingsSection.propTypes = {
  // eslint-disable-next-line react/require-default-props
  date: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  // eslint-disable-next-line react/require-default-props
  onItemClick: PropTypes.func,
  linkTo: PropTypes.string.isRequired,
};

MeetingsSection.defaultProps = {
  list: [],
};

import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Typography, IconButton, Link } from '@oca/ui';
import { Row, Col } from '@lib/grid';
import { Add } from '@oca/icons';
import { Trans } from '@lingui/macro';

import { FileUploaderField } from '@features/common';
import {
  DOCUMENTS,
  EDUCATION_LEVELS,
  createDocumentModel,
  createEducationModel,
} from '../model/static';
import {
  FieldsetDocument,
  FieldsetContract,
  FieldsetEducation,
  SectionTitle,
} from '../molecules';

const titles = {
  [DOCUMENTS.PASSPORT]: <Trans>Passport</Trans>,
  [DOCUMENTS.CIVIL_ID]: <Trans>Civil ID</Trans>,
  [DOCUMENTS.VISA]: <Trans>Visa</Trans>,
  [DOCUMENTS.WORK_PERM]: <Trans>Work permission</Trans>,
  [DOCUMENTS.CONTRACT]: <Trans>Employment Contract</Trans>,
  [DOCUMENTS.NDA]: <Trans>NDA</Trans>,
  [EDUCATION_LEVELS.MASTER]: <Trans>Master</Trans>,
  [EDUCATION_LEVELS.BACHELOR]: <Trans>Bachelor</Trans>,
  [EDUCATION_LEVELS.CERTIFICATE]: <Trans>Certificate</Trans>,
};

const contractOptions = [
  {
    id: DOCUMENTS.CONTRACT,
    label: titles[DOCUMENTS.CONTRACT],
  },
  {
    id: DOCUMENTS.NDA,
    label: titles[DOCUMENTS.NDA],
  },
];

export function FormDocuments({ educationOptions, editable }) {
  return (
    <React.Fragment>
      <FieldArray
        name="documents"
        render={({ fields }) =>
          fields.map((fieldName, index) => (
            <React.Fragment key={fieldName}>
              <Field
                name={`${fieldName}.docType`}
                render={({ input: { value } }) => (
                  <Typography variant="h2" gutterBottom>
                    {titles[value]}
                    &nbsp;
                    {value === DOCUMENTS.VISA ? <Trans>(optional)</Trans> : '*'}
                  </Typography>
                )}
              />
              <FieldsetDocument
                key={fieldName}
                name={fieldName}
                withUid={index < 2}
              />
            </React.Fragment>
          ))
        }
      />
      <FieldArray
        name="contracts"
        render={({ fields }) => (
          <React.Fragment>
            <SectionTitle
              title={<Trans>Signed contracts</Trans>}
              action={
                editable && (
                  <AddButton
                    onClick={() =>
                      fields.push(createDocumentModel(DOCUMENTS.NDA))
                    }
                    // Only two contracts now
                    disabled={fields.value.length === 2}
                  />
                )
              }
            />
            {fields.map((fieldName, index) => (
              <FieldsetContract
                key={fieldName}
                index={index + 1}
                name={fieldName}
                onDelete={index > 0 ? fields.pop : undefined}
                options={contractOptions}
              />
            ))}
          </React.Fragment>
        )}
      />
      <FieldArray name="curriculumVitae">
        {({ fields }) => (
          <Row flexDirection="column" mb={4}>
            <Col width={1} mb={2}>
              <SectionTitle
                title={<Trans>Curriculum vitae (CV)</Trans>}
                action={
                  editable && (
                    <AddButton
                      disabled={fields.value.length >= 1}
                      onClick={() =>
                        fields.push(createDocumentModel(DOCUMENTS.CV))
                      }
                    />
                  )
                }
              />
            </Col>
            <Col width={[1 / 2, 1 / 3]}>
              {fields.value.length < 1 && (
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  <Trans>No CV was added yet</Trans>
                </Typography>
              )}
              {fields.map(
                (name, index) =>
                  index < 1 && (
                    <React.Fragment key={name}>
                      {editable && (
                        <Link
                          as="button"
                          type="button"
                          mb={2}
                          onClick={() => fields.remove(index)}
                        >
                          <Trans>Remove</Trans>
                        </Link>
                      )}
                      <FileUploaderField key={name} name={`${name}.files`} />
                    </React.Fragment>
                  ),
              )}
            </Col>
          </Row>
        )}
      </FieldArray>
      <FieldArray name="educations">
        {({ fields }) => (
          <React.Fragment>
            <SectionTitle
              title={<Trans>Education</Trans>}
              action={
                editable && (
                  <AddButton
                    onClick={() =>
                      fields.push(createEducationModel(EDUCATION_LEVELS.MASTER))
                    }
                  />
                )
              }
            />
            {fields.value.length === 0 && (
              <Row mb={4}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  <Trans>No educations were added yet</Trans>
                </Typography>
              </Row>
            )}
            {fields.map((field, index) => (
              <FieldsetEducation
                key={field}
                name={field}
                index={index}
                options={educationOptions}
                onDelete={editable ? () => fields.remove(index) : undefined}
              />
            ))}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
}

FormDocuments.propTypes = {
  educationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
  form: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  editable: PropTypes.bool.isRequired,
};

FormDocuments.defaultProps = {
  educationOptions: Object.values(EDUCATION_LEVELS).map(value => ({
    value,
    name: titles[value],
  })),
};

function AddButton(props) {
  return (
    <IconButton color="primary" {...props}>
      <Add />
    </IconButton>
  );
}

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from 'rebass';

export const BtnLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
export const BodySection = styled(Box)`
  border-right: 1px solid #d9d9d9;
  &:last-child {
    border-right: none;
  }
`;
export const BodyBlocks = styled(Box)`
  border-bottom: 1px solid #d9d9d9;
  &:last-child {
    border-bottom: none;
  }
  &.pb0 {
    padding-bottom: 0;
  }
`;

BodyBlocks.defaultProps = {
  p: 30,
};

import React from 'react';
import PropTypes from 'prop-types';
import { GroupSelect, FormControl } from '@oca/ui';

/**
 *
 * @param {import('../../ui/organisms/group-select.d').GroupSelectProps & import('react-final-form').FieldRenderProps}
 */
export function GroupSelectWrapper({
  label,
  input,
  meta,
  helperText,
  required,
  spaceAfter,
  ...rest
}) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl
      label={label}
      error={showError}
      helperText={showError ? meta.error || meta.submitError : helperText}
      required={required}
      spaceAfter={spaceAfter}
    >
      <GroupSelect {...rest} {...input} error={showError} />
    </FormControl>
  );
}

GroupSelectWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.node,
  meta: PropTypes.shape({
    submitError: PropTypes.any,
    dirtySinceLastSubmit: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  required: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  spaceAfter: PropTypes.bool,
};

import dayjs from 'dayjs';
import { fileExtensionRegex } from '@lib/regex';
import { FileBrowserTypes, FileEntityAliases } from '../constants';

/**
 *
 * @param {[]} array
 * @param {string} value
 * @param {string[]} fields
 * @returns {[]}
 */
export const searchByValue = (array, value, fields = ['name', 'updatedAt']) => {
  /**
   *
   * @param {string} l
   * @param {string} r
   */
  const isIncludes = (l, r) => {
    if (l && r) {
      return l.toLowerCase().includes(r.toLowerCase());
    }
    return false;
  };
  if (value) {
    return array.filter(item =>
      fields.some(field => {
        const fieldValue = item[field];
        if (fieldValue && typeof fieldValue === 'string') {
          if (dayjs(new Date(fieldValue)).isValid()) {
            return isIncludes(
              dayjs(new Date(fieldValue)).format('MMMM DD, YYYY'),
              value,
            );
          }

          return isIncludes(fieldValue, value);
        }
        return false;
      }),
    );
  }
  return array;
};

export function isFile(type) {
  return type === FileBrowserTypes.FILE;
}

export function isFolder(type) {
  return type === FileBrowserTypes.FOLDER;
}

/**
 * Normalize data from backend. Flatten object values to array.
 * Augment each item with meta information such as 'type', consistent pointer to a parent directory
 */
export function normalizeFiles({ documents = [], directories = [] } = {}) {
  const extendedDirectories = directories.map(dir => ({
    ...dir,
    isFile: false,
    isDir: true,
    parentId: dir.parentDirectory || null,
    type: FileBrowserTypes.FOLDER,
  }));
  const extendedDocuments = documents.map(doc => {
    const extensionMatch = doc.name
      ? doc.name.match(fileExtensionRegex())
      : null;
    const ext = extensionMatch ? extensionMatch[1] : 'default';
    const media = doc.versions[doc.versions.length - 1];

    return {
      ...doc,
      media,
      ext,
      isFile: true,
      isDir: false,
      parentId: doc.directory || null,
      type: FileBrowserTypes.FILE,
    };
  });

  return [
    ...extendedDirectories.sort(sortByName),
    ...extendedDocuments.sort(sortByName),
  ];
}

/**
 * Flatten backend response data to array.
 * Make array of 'nested' directories
 */
export function normalizeBreadcrumbs(data) {
  const list = [];

  return data && data.info
    ? list
        .concat([...data.info.parentDirectories, data.info.directory])
        .filter(Boolean)
    : list;
}

export function createRouteBreadcrumb(route = {}) {
  const defaultName = 'N/A';

  return {
    path: route.parentPath || route.path,
    name: route
      ? route.name || (route.navigation ? route.navigation.name : defaultName)
      : defaultName,
  };
}

/**
 * Transforms entity list data
 * @param {(list: [], data: { params: { id: number, type: 'folder'|'file' }, data: Any }) => []} fn
 * @param {'directories' | 'documents' } entityType
 */
export function transformFiles(fn, entityType) {
  /**
   *
   * @param {{ documents: [], directories: []}} files
   * @param {'folder' | 'file'} type
   * @param {(list: []) => []} fn
   */
  return (files, { params = {}, result } = {}) => {
    const { type } = params;
    const alias = FileEntityAliases[entityType || type];
    const listToUpdate = files[alias] || [];

    return {
      ...files,
      [alias]: fn(listToUpdate, { params, data: result.data }),
    };
  };
}

function sortByName(a, b) {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

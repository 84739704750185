import { createEffect, createEvent, split } from 'effector';

import { isFile, isFolder } from '../lib/helpers';

export const changeSearchInput = createEvent('search input changed');
export const onFolderCreateSubmit = createEvent('on folder create submit');
export const onEntityShareSubmit = createEvent('on file share submit');
export const onEntityMoveAccept = createEvent('on entity move accept');
export const onEntityMoveReject = createEvent('on entity move reject');
export const downloadEntity = createEvent('entity downloaded');
export const setRenamingFileId = createEvent('renaming file id set');
export const setSharingFileId = createEvent('sharing file id set');
export const setMoveFileId = createEvent('move file id set');
export const startFileUploading = createEvent('file uploading started');
export const cancelFileUploading = createEvent('file uploading cancelled');
export const setUploadProgress = createEvent('upload progress set');
export const setFilePreviewId = createEvent('preview id set');

export const toggleSelectFile = createEvent('file select toggled');
export const toggleSelectAllFiles = createEvent('all files select toggled');
export const clearSelectedFiles = createEvent('selected files cleared');

export const moveEntityTo = createEvent('entity moved to');
export const renameEntity = createEvent('entity renamed');
export const deleteEntity = createEvent('entity deleted');
export const toggleFavoriteEntity = createEvent('entity favorite toggled');

export const fetchFilesRequest = createEffect('files fetch requested');

export const createFolderRequest = createEffect('create folder requested');
export const deleteFolderRequest = createEffect('delete folder requested');
export const updateFolderRequest = createEffect('update folder requested');
export const setFavoriteFolderRequest = createEffect(
  'set favorite folder requested',
);
export const shareFolderRequest = createEffect('share folder requested');

export const deleteFileRequest = createEffect('delete file requested');
export const updateFileRequest = createEffect('update file requested');
export const uploadFileRequest = createEffect('upload file requested');
export const downloadFileRequest = createEffect('download file requested');
export const shareFileRequest = createEffect('share file requested');
export const setFavoriteFileRequest = createEffect(
  'set favorite file requested',
);
export const moveFilesToFolderRequest = createEffect(
  'move files to folder requested',
);

export const { renameFile, renameFolder } = split(renameEntity, {
  renameFile: ({ type }) => isFile(type),
  renameFolder: ({ type }) => isFolder(type),
});

export const { deleteFile, deleteFolder } = split(deleteEntity, {
  deleteFile: ({ type }) => isFile(type),
  deleteFolder: ({ type }) => isFolder(type),
});

export const { toggleFavoriteFile, toggleFavoriteFolder } = split(
  toggleFavoriteEntity,
  {
    toggleFavoriteFile: ({ type }) => isFile(type),
    toggleFavoriteFolder: ({ type }) => isFolder(type),
  },
);

export const { onFileShareSubmit, onFolderShareSubmit } = split(
  onEntityShareSubmit.map(({ id, users, name, type }) => ({
    id,
    type,
    name,
    users: users.map(userId => ({ user: userId })),
  })),
  {
    onFileShareSubmit: ({ type }) => isFile(type),
    onFolderShareSubmit: ({ type }) => isFolder(type),
  },
);

export const {
  toggleSelectDefault,
  toggleSelectGroup,
  toggleSelectOne,
} = split(toggleSelectFile, {
  toggleSelectOne: ({ isRow = false, shiftKeyPressed, ctrlKeyPressed }) =>
    isRow && !(shiftKeyPressed || ctrlKeyPressed),
  toggleSelectGroup: ({ shiftKeyPressed }) => shiftKeyPressed,
  toggleSelectDefault: ({ isRow = false, ctrlKeyPressed }) =>
    ctrlKeyPressed || !isRow,
});

import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from 'rebass';
import { Link } from '@oca/ui';
import { Trans } from '@lingui/macro';

export function ChecklistItemActions({ onSave, onClose }) {
  return (
    <Flex my={2} justifyContent="space-between">
      <Link as="button" onClick={onSave}>
        <Trans>Save</Trans>
      </Link>
      <Link as="button" color="text" onClick={onClose}>
        <Trans>Close</Trans>
      </Link>
    </Flex>
  );
}

ChecklistItemActions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onSave: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
};

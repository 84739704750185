import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'rebass';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { t, Trans } from '@lingui/macro';

import { Typography, Checkbox, Button, IconButton, Tooltip } from '@oca/ui';
import { Refresh } from '@oca/icons';
import { SelectWrapper } from '@lib/final-form-oca-ui';
import { animated, useSpring, config } from 'react-spring';
import { hasOwnProperty, isEmpty } from '@lib/help-fns';
import { i18n } from '@lib/i18n';
import { statuses, priorities } from '../datas';

const initialFormValues = {
  project: null,
  statuses: [],
  priorities: [],
};

export function TaskFilterView({ projects, initialValues, onSubmit }) {
  const [styleProps, setStyleProps] = useSpring(() => ({
    transform: 'scale(0)',
    config: config.stiff,
  }));

  return (
    <FilterWrap bg="white">
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, values }) => {
          const hasValues = hasFilter(values);
          setStyleProps({ transform: hasValues ? 'scale(1)' : 'scale(0)' });

          return (
            <form onSubmit={handleSubmit}>
              <ResetFiltersWrapper>
                <animated.div style={styleProps}>
                  <Tooltip title={<Trans>Reset filters</Trans>}>
                    <IconButton
                      onClick={() => form.initialize(initialFormValues)}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                </animated.div>
              </ResetFiltersWrapper>
              <FilterSection label={<Trans>Project</Trans>}>
                <Field
                  name="project"
                  placeholder={i18n._(t`Select the project ...`)}
                  isClearable
                  options={projects}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  component={SelectWrapper}
                  parse={val => val && val.id}
                  format={val =>
                    val ? projects.find(({ id }) => val === id) : []
                  }
                />
              </FilterSection>
              <FilterSection label={<Trans>Status</Trans>}>
                {statuses.map(status => (
                  <Box key={status.value} mb={10}>
                    <Field
                      type="checkbox"
                      name="statuses"
                      value={status.value}
                      render={({ input }) => (
                        <Checkbox {...input} label={status.label} />
                      )}
                    />
                  </Box>
                ))}
              </FilterSection>
              <FilterSection label={<Trans>Task priority</Trans>}>
                {priorities.map(priority => (
                  <Box key={priority.value} mb={10}>
                    <Field
                      type="checkbox"
                      name="priorities"
                      value={priority.value}
                      render={({ input }) => (
                        <Checkbox {...input} label={priority.label} />
                      )}
                    />
                  </Box>
                ))}
              </FilterSection>
              <Button variant="primary" type="submit" fullWidth>
                <Trans>Apply</Trans>
              </Button>
            </form>
          );
        }}
      />
    </FilterWrap>
  );
}

TaskFilterView.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  initialValues: PropTypes.shape({
    project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    statuses: PropTypes.arrayOf(PropTypes.string),
    priorities: PropTypes.arrayOf(PropTypes.string),
  }),
  onSubmit: PropTypes.func.isRequired,
};

TaskFilterView.defaultProps = {
  initialValues: initialFormValues,
};

export const TaskFilter = withRouter(TaskFilterView);

const FilterWrap = styled(Box)`
  position: relative;
  padding: 25px;
  width: 280px;
  border-radius: 6px;
  .filter-section:last-child {
    margin-bottom: 0;
  }
`;

const ResetFiltersWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
`;

const FilterSection = ({ children, label, ...props }) => {
  return (
    <Box {...props} mb={30} className="filter-section">
      <Typography variant="h3" fontSize={16} paragraph>
        {label}
      </Typography>
      {children}
    </Box>
  );
};
FilterSection.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
};

function hasFilter(values) {
  return Object.keys(values).some(key => {
    if (hasOwnProperty(initialFormValues, key)) {
      const value = values[key];

      return Array.isArray(value) ? !isEmpty(value) : value !== null;
    }
    return false;
  });
}

import React from 'react';
import PropTypes from 'prop-types';
import ReactImageCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

/**
 * @param {import('react-image-crop').ReactCropProps & { cropOnImageLoad: Boolean }}
 */
export function Crop({
  crop: initialCrop,
  onImageLoaded,
  cropOnImageLoad,
  ...props
}) {
  const [crop, setCrop] = React.useState(initialCrop);

  function handleImageLoaded(image) {
    onImageLoaded && onImageLoaded(image);

    if (!props.disabled && cropOnImageLoad) {
      const size = Math.min(image.width / 2, image.height / 2);

      const pixel = {
        aspect: 1,
        x: image.width / 4,
        y: image.height / 4,
        width: size,
        height: size,
        unit: 'px',
      };

      const halfWidthPercentage = (size / image.width) * 100;
      const halfHeightPercentage = (size / image.height) * 100;

      const percent = {
        aspect: 1,
        width: halfWidthPercentage,
        height: halfHeightPercentage,
        x: (100 - halfWidthPercentage) / 2,
        y: (100 - halfHeightPercentage) / 2,
        unit: '%',
      };

      setCrop(percent);

      if (props.onComplete) {
        props.onComplete(pixel, percent);
      }

      return false;
    }

    return null;
  }

  return (
    <ReactImageCrop
      crop={crop}
      onChange={(pixel, percent) => setCrop(percent)}
      onImageLoaded={handleImageLoaded}
      {...props}
    />
  );
}

Crop.propTypes = {
  ...ReactImageCrop.propTypes,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  cropOnImageLoad: PropTypes.bool,
};

Crop.defaultProps = {
  cropOnImageLoad: true,
};

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Flv = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F7B84E"
    />
    <path
      d="M6.249 14.6295V15.5827H7.77825C7.99425 15.5827 8.21025 15.7987 8.21025 16.0087C8.21025 16.2067 7.99425 16.368 7.77825 16.368H6.249V17.6272C6.249 17.8372 6.09975 17.9992 5.88975 17.9992C5.62575 17.9992 5.4645 17.8372 5.4645 17.6272V14.2215C5.4645 14.0055 5.6265 13.8495 5.88975 13.8495H7.995C8.259 13.8495 8.415 14.0055 8.415 14.2215C8.415 14.4135 8.259 14.6287 7.995 14.6287H6.249V14.6295Z"
      fill="white"
    />
    <path
      d="M9.144 14.2102C9.144 14.0122 9.312 13.8442 9.522 13.8442C9.714 13.8442 9.876 14.013 9.876 14.2102V17.2147H11.5073C12.0945 17.2147 12.1073 18 11.5073 18H9.52275C9.31275 18 9.14475 17.8507 9.14475 17.634V14.2102H9.144Z"
      fill="white"
    />
    <path
      d="M13.4475 17.7307L11.8642 14.4142C11.6302 13.9402 12.3382 13.551 12.6015 14.0482L13.1355 15.2535L13.8247 16.8367L14.502 15.2535L15.036 14.0482C15.276 13.593 15.9712 13.8802 15.768 14.3782L14.2387 17.7307C14.1007 18.1087 13.6747 18.198 13.4475 17.7307Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/prop-types */
import React from 'react';
import { findDOMNode } from 'react-dom';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import { safeInvoke } from '../help-fns';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export class ResizeSensor extends React.Component {
  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.element = null;
    this.observer = new ResizeObserver(entries =>
      safeInvoke(this.props.onResize, entries),
    );
  }

  componentDidMount() {
    this.observeElement();
  }

  componentDidUpdate(prevProps) {
    this.observeElement(this.props.observeParents !== prevProps.observeParents);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  getElement() {
    try {
      // using findDOMNode for two reasons:
      // 1. cloning to insert a ref is unwieldy and not performant.
      // 2. ensure that we resolve to an actual DOM node (instead of any JSX ref instance).
      return findDOMNode(this);
    } catch (_a) {
      // swallow error if findDOMNode is run on unmounted component.
      return null;
    }
  }

  /**
   * Observe the DOM element, if defined and different from the currently
   * observed element. Pass `force` argument to skip element checks and always
   * re-observe.
   */
  observeElement(force = false) {
    const element = this.getElement();
    if (!(element instanceof Element)) {
      // stop everything if not defined
      this.observer.disconnect();
      return;
    }
    if (element === this.element && !force) {
      // quit if given same element -- nothing to update (unless forced)
      return;
    }

    // clear observer list if new element
    this.observer.disconnect();
    // remember element reference for next time
    this.element = element;

    // observer callback is invoked immediately when observing new elements
    this.observer.observe(element);
    if (this.props.observeParents) {
      let parent = element.parentElement;
      while (parent != null) {
        this.observer.observe(parent);
        parent = parent.parentElement;
      }
    }
  }

  render() {
    // pass-through render of single child
    return React.Children.only(this.props.children);
  }
}

import { lazy } from 'react';
import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';

const AttendanceRootPage = lazy(() =>
  import('./pages/root' /* webpackChunkName: "attendance" */).then(module => ({
    default: module.AttendanceRootPage,
  })),
);

export const routes = [
  {
    path: '/hr/attendance',
    component: AttendanceRootPage,
    navigation: {
      name: i18n._(t`Attendance`),
      shortName: 'AT',
      onlyTopLevelLink: true,
    },
  },
];

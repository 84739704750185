import React from 'react';
import { PencilRuler } from '@oca/icons';
import { t } from '@lingui/macro';
import { i18n } from '@lib/i18n';
import {
  ViewGeneralSection,
  ViewTasksSection,
  ViewMeetingsSection,
  ViewAttachmentsSection,
} from './organisms';

const ProjectListPage = React.lazy(() =>
  import('./pages/list').then(module => ({
    default: module.ProjectListPage,
  })),
);

const ProjectViewPage = React.lazy(() =>
  import('./pages/view').then(module => ({
    default: module.ProjectViewPage,
  })),
);

export const routes = [
  {
    path: '/projects',
    component: ProjectListPage,
    navigation: {
      icon: <PencilRuler />,
      name: i18n._(t`Projects`),
      onlyTopLevelLink: true,
    },
    exact: true,
  },
  {
    path: '/projects/:id',
    component: ProjectViewPage,
    name: i18n._(t`Project Single`),
    routes: [
      {
        path: '/projects/:id',
        component: ViewGeneralSection,
        exact: true,
      },
      {
        path: '/projects/:id/tasks',
        component: ViewTasksSection,
        exact: true,
      },
      {
        path: '/projects/:id/meetings',
        component: ViewMeetingsSection,
        exact: true,
      },
      {
        path: '/projects/:id/attachments',
        component: ViewAttachmentsSection,
        exact: true,
      },
    ],
  },
];

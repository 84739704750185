import React from 'react';

import { Check, Close, ArrowRightCircle } from '@oca/icons';

export const StatusIcon = (status, size = 16) => {
  switch (status) {
    case 'completed':
      return <Check color="#009031" fontSize={size} />;
    case 'cancelled':
      return <Close color="#FE2400" fontSize={size} />;
    case 'upcoming':
      return <ArrowRightCircle color="#FEA201" fontSize={size} />;
    default:
      return <Check color="#009031" fontSize={size} />;
  }
};

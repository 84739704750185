/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'effector-react';

import { NavLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { Link as UILink, OverflowList, Menu, IconButton } from '@oca/ui';
import { ArrowRight2, UnwrapFolder } from '@oca/icons';

import { $breadcrumbs } from '../model/file-browser.stores';
import { HighlightBox } from '../atoms';
import { useFileDrop } from '../lib/use-file-drop';
import { moveEntityTo } from '../model/file-browser.events';

export function Breadcrumbs({ root }) {
  const breadcrumbs = useStore($breadcrumbs);
  const items = [root, ...breadcrumbs];

  function getPath(item = {}) {
    return item.id ? `${root.path}/${item.id}` : root.path;
  }

  return (
    <OverflowList
      observeParents
      collapseFrom="start"
      minVisibleItems={1}
      tagName="nav"
      items={items}
      overflowRenderer={overflowed => (
        <DroppableMenu>
          {overflowed.map(item => (
            <DroppableMenuItem id={item.id} to={getPath(item)} key={item.name}>
              {item.name}
            </DroppableMenuItem>
          ))}
        </DroppableMenu>
      )}
      visibleItemRenderer={item => {
        const path = getPath(item);
        const lastItem = item === items[items.length - 1];

        return (
          <Flex alignItems="center" key={path}>
            <DroppableLink
              to={path}
              color={lastItem ? 'primary' : 'text'}
              id={item.id || null}
            >
              {item.name}
            </DroppableLink>
            {!lastItem && (
              <Box mx={1}>
                <ArrowRight2 />
              </Box>
            )}
          </Flex>
        );
      }}
    />
  );
}

Breadcrumbs.propTypes = {
  root: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.node,
  }).isRequired,
};

const DroppableMenu = React.memo(function DroppableMenu(props) {
  const [open, setOpen] = React.useState(false);
  const [state, ref] = useFileDrop(null, {
    canDrop: () => true,
    hover: (item, monitor) => {
      const over = monitor.isOver();

      if (open !== over) {
        setOpen(over);
      }
    },
  });

  return (
    <HighlightBox {...state}>
      <Menu
        {...props}
        usePortal
        onVisibilityChange={setOpen}
        tooltipShown={open}
        anchor={
          <IconButton ref={ref}>
            <UnwrapFolder />
          </IconButton>
        }
      />
    </HighlightBox>
  );
});

const DroppableMenuItem = React.memo(function DroppableMenuItem(props) {
  const { children, id, ...rest } = props;
  const [state, ref] = useFileDrop(id);

  return (
    <HighlightBox ref={ref} {...state}>
      <Menu.Item as={NavLink} flex="1" {...rest}>
        {children}
      </Menu.Item>
    </HighlightBox>
  );
});

DroppableMenuItem.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.number,
};

const DroppableLink = React.memo(function Link(props) {
  const { id, ...rest } = props;
  const [{ highlighted, hovered }, ref] = useFileDrop(id, {
    drop: () => {
      moveEntityTo(id);
    },
  });

  return (
    <HighlightBox ref={ref} highlighted={highlighted} hovered={hovered}>
      <UILink
        as={NavLink}
        color="text"
        fontSize={20}
        wrap={false}
        lineHeight={1.2}
        {...rest}
      />
    </HighlightBox>
  );
});

DroppableLink.propTypes = {
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.number,
};

import React from 'react';
import { t } from '@lingui/macro';

import { SearchField } from '@oca/ui';
import { i18n } from '@lib/i18n';
import { useStore } from 'effector-react';

import { $search, changeSearchValue } from '../models/main';

export function TaskSearch() {
  const search = useStore($search);

  return (
    <SearchField
      placeholder={i18n._(t`Type name`)}
      onChange={event => changeSearchValue(event.target.value)}
      value={search}
      fullWidth
    />
  );
}

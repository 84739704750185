import React from 'react';
import { space } from 'styled-system';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createTransition } from '../theme';

export const TextArea = React.forwardRef(
  /**
   *
   * @param {TextAreaProps} props
   * @param {import('react').Ref} ref
   */
  function TextArea(props, ref) {
    return (
      <Wrapper>
        <TextAreaBase py={2} px={3} {...props} ref={ref} />
        <FocusIndicator />
      </Wrapper>
    );
  },
);

TextArea.propTypes = {
  autoComplete: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  autoComplete: 'off',
};

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const FocusIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  transform: scaleX(0);
  transition: ${createTransition('transform')};
  transform-origin: left;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const TextAreaBase = styled.textarea`
  display: block;
  box-sizing: border-box;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.theme.fontSizes[1]}px;
  width: 100%;
  border: 1px solid;
  resize: vertical;
  border-color: ${props =>
    (props.error && props.theme.colors.error) ||
    (props.disabled && props.theme.colors.disabled) ||
    'rgba(0, 0, 0, 0.23)'};
  border-radius: 4px;
  color: ${props => props.theme.colors.text};
  transition: border-color 100ms linear;
  -webkit-tap-highlight-color: transparent;
  :focus {
    border-color: ${getFocusColor};
    & ~ ${FocusIndicator} {
      transform: scaleX(1);
      background-color: ${getFocusColor};
    }
  }
  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.disabled};
  }
  ::-moz-placeholder {
    color: ${props => props.theme.colors.disabled};
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.colors.disabled};
  }
  :-moz-placeholder {
    color: ${props => props.theme.colors.disabled};
    opacity: 1;
  }
  ${space}
`;

function getFocusColor(props) {
  return props.error ? props.theme.colors.error : props.theme.colors.primary;
}

/**
 * @typedef {import('styled-system').SpaceProps} SpaceProps
 * @typedef {HTMLTextAreaElement & SpaceProps} TextAreaProps
 */

import React from 'react';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { useStore } from 'effector-react';
import { Flex, Box, Text } from 'rebass';
import snakeCaseKeys from 'snakecase-keys';
import dayjs from 'dayjs';
import {
  FileUploaderField,
  EmployeeOptionLabel,
  filterEmployeeOption,
} from '@features/common';
import { notify } from '@lib/notifier/notify';
import { t, Trans } from '@lingui/macro';
import {
  TextFieldWrapper,
  SelectWrapper,
  DayPickerInputWrapper,
} from '@lib/final-form-oca-ui';
import { Button, Typography, IconButton, Avatar } from '@oca/ui';
import { AddCircleOutlined, Person, Delete } from '@oca/icons';
import { resolveRejectedPromise, toISOWithTimezone } from '@lib/help-fns';

import {
  $employeeList,
  $projectList,
} from '@features/common/model/common-data';

import { i18n } from '@lib/i18n';
import { FormSection } from './form-section';
import { BtnLink } from './body-items';
import { ExternalAttendeeForm } from './external-attendee-form';
import { durationTimeSelect } from '../data';
import { remindTimeOptions } from '../helpers';

import {
  $updateMeetingFetching,
  updateMeetingRequesting,
} from '../model/meeting-flow';

const BoxRow = props => <Box {...props} width={[1, 1 / 2, 1 / 3]} />;

// eslint-disable-next-line react/prop-types
export const FormEdit = ({ initialValues }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [newAttendees, inviteAttendee] = React.useState(
    initialValues.externalAttendees,
  );
  const [deletedAttendees, pushAttendeeId] = React.useState([]);

  const projectList = useStore($projectList);
  const employeeList = useStore($employeeList);
  const { isLoading } = useStore($updateMeetingFetching);

  return (
    <React.Fragment>
      {isOpen ? (
        <ExternalAttendeeForm
          setIsOpen={setIsOpen}
          newAttendees={newAttendees}
          inviteAttendee={inviteAttendee}
        />
      ) : (
        ''
      )}

      <Form
        subscription={{ submitting: true, pristine: true, values: true }}
        validate={validate}
        onSubmit={data =>
          handleFormSubmit(
            data,
            newAttendees,
            inviteAttendee,
            deletedAttendees,
            pushAttendeeId,
          )
        }
        initialValues={initialValues}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Flex flexDirection="column" p={30}>
                <FormSection title={<Trans>Head information</Trans>}>
                  <BoxRow>
                    <Field
                      name="title"
                      label={<Trans>Title</Trans>}
                      placeholder={i18n._(t`Write a name to your meeting`)}
                      spaceAfter
                      helperText=" "
                      component={TextFieldWrapper}
                    />
                    <Field
                      name="description"
                      label={<Trans>Description</Trans>}
                      placeholder={i18n._(t`Describe your project`)}
                      spaceAfter
                      helperText=" "
                      component={TextFieldWrapper}
                    />
                    <Field
                      name="project"
                      label={<Trans>Project</Trans>}
                      spaceAfter
                      helperText=" "
                      options={projectList}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => option.name}
                      component={SelectWrapper}
                      parse={val => val && val.id}
                      format={val =>
                        val && projectList.find(({ id }) => val.id === id)
                      }
                    />
                  </BoxRow>
                </FormSection>
                <FormSection
                  title={<Trans>What type of agenda you want to add?</Trans>}
                >
                  <BoxRow my="10px">
                    <FileUploaderField name="agenda" />
                  </BoxRow>
                </FormSection>
                <FormSection title={<Trans>Date & location</Trans>}>
                  <BoxRow>
                    <Flex>
                      <Box mr={2} flex={1}>
                        <Field
                          type="date"
                          name="startTime"
                          placeholder={i18n._(t`April 11 2019`)}
                          label={<Trans>Due date</Trans>}
                          component={DayPickerInputWrapper}
                          options={{
                            enableTime: true,
                            formatDate: date => dayjs(date).format('lll'),
                            static: true,
                          }}
                          onOpen={(selectedDates, dateStr, instance) =>
                            instance.set({
                              minDate: 'today',
                            })
                          }
                          spaceAfter
                          helperText=" "
                        />
                      </Box>
                      <Box flex={1}>
                        <Field
                          name="durationTime"
                          label={<Trans>Duration</Trans>}
                          spaceAfter
                          helperText=" "
                          placeholder={i18n._(t`Choose time`)}
                          options={durationTimeSelect}
                          defaultValue={durationTimeSelect[0]}
                          getOptionValue={option => option.value}
                          getOptionLabel={option => option.label}
                          component={SelectWrapper}
                          parse={val => val && val.value}
                          format={val =>
                            val &&
                            durationTimeSelect.find(
                              ({ value }) => val.value || val === value,
                            )
                          }
                        />
                      </Box>
                    </Flex>
                    <Field
                      name="location"
                      label={<Trans>Specify location</Trans>}
                      placeholder={i18n._(t`Meeting room, hall etc.`)}
                      spaceAfter
                      helperText=" "
                      component={TextFieldWrapper}
                    />
                  </BoxRow>
                </FormSection>
                <FormSection title={<Trans>Attendees</Trans>}>
                  <BoxRow>
                    <Field
                      name="invitedEmployees"
                      label={<Trans>Internal attendees</Trans>}
                      spaceAfter
                      helperText=" "
                      isMulti
                      options={employeeList}
                      getOptionValue={option => option.id}
                      getOptionLabel={option => (
                        <EmployeeOptionLabel employee={option} />
                      )}
                      filterOption={filterEmployeeOption}
                      component={SelectWrapper}
                      parse={selectValue => {
                        return selectValue
                          ? selectValue.map(({ id }) => id)
                          : [];
                      }}
                      format={val => {
                        return val
                          ? employeeList.filter(({ id }) =>
                              val.some(item => id === (item.id || item)),
                            )
                          : [];
                      }}
                    />
                  </BoxRow>
                  <BoxRow>
                    {newAttendees && newAttendees.length ? (
                      <Box>
                        <Text fontSize={14} color="#424B68" mb={10} mt={20}>
                          <Trans>External attendees</Trans>
                        </Text>

                        {newAttendees.map(attendee => {
                          return (
                            <Flex key={Math.random()}>
                              <Avatar size={30}>
                                <Person color="#B8B8B8" />
                              </Avatar>
                              <Flex flex={1} ml={10}>
                                <Box flex={1}>
                                  <Text fontSize={16} color="#0076F4" mb="5px">
                                    {attendee.fullName}
                                  </Text>
                                  <Text color="#88909D" fontSize={12}>
                                    {attendee.company}
                                  </Text>
                                </Box>
                                <Flex alignItems="center">
                                  <IconButton
                                    size="small"
                                    color="#424B68"
                                    onClick={() => {
                                      if (!attendee.newOne) {
                                        pushAttendeeId([
                                          ...deletedAttendees,
                                          attendee.id,
                                        ]);
                                      }

                                      inviteAttendee(
                                        newAttendees.filter(
                                          item =>
                                            item.id !== attendee.id &&
                                            item.tempId !== attendee.tempId,
                                        ),
                                      );
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Flex>
                              </Flex>
                            </Flex>
                          );
                        })}
                      </Box>
                    ) : (
                      ''
                    )}

                    <Flex
                      alignItems="center"
                      mt={15}
                      onClick={() => setIsOpen(true)}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Flex mr="5px" alignItems="center">
                        <AddCircleOutlined fontSize={30} color="#0076F4" />
                      </Flex>
                      <Typography fontSize={16} color="primary">
                        <Trans>Invite external attendee</Trans>
                      </Typography>
                    </Flex>
                  </BoxRow>
                </FormSection>
                <FormSection
                  title={
                    <Trans>
                      What time reminder should be send to attendees before
                      meeting starts?
                    </Trans>
                  }
                >
                  <Box width={1 / 5}>
                    <Field
                      name="remindTime"
                      label={<Trans>Remind in</Trans>}
                      spaceAfter
                      helperText=" "
                      placeholder={i18n._(t`Choose time`)}
                      options={remindTimeOptions(values.startTime)}
                      getOptionValue={option => option.value}
                      getOptionLabel={option => option.label}
                      component={SelectWrapper}
                      parse={val => val && val.enumVal}
                      format={val =>
                        val &&
                        remindTimeOptions(values.startTime).find(
                          ({ enumVal }) => val.enumVal || val === enumVal,
                        )
                      }
                    />
                  </Box>
                </FormSection>
                <Flex>
                  <Box mr="5px">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isLoading}
                    >
                      <Trans>Update</Trans>
                    </Button>
                  </Box>
                  <Box ml="5px">
                    <BtnLink to={`/meetings/view/${initialValues.id}`}>
                      <Button variant="primaryOutlined" type="button">
                        <Trans>Cancel</Trans>
                      </Button>
                    </BtnLink>
                  </Box>
                </Flex>
              </Flex>
            </form>
          );
        }}
      />
    </React.Fragment>
  );
};

async function handleFormSubmit(
  data,
  newAttendees,
  inviteAttendee,
  deletedAttendees,
  pushAttendeeId,
) {
  const dataObject = {
    ...data,
    comments: [],
    creator: data.creator.id,
    project: data.project ? data.project.id || data.project : null,
  };

  dataObject.invitedEmployees = data.invitedEmployees
    ? data.invitedEmployees.map(employee => employee.id || employee)
    : [];

  dataObject.externalAttendees =
    newAttendees && newAttendees.length ? snakeCaseKeys(newAttendees) : [];

  if (deletedAttendees.length) {
    dataObject.delete_external_attendees = deletedAttendees;
  }

  if (data.startTime) {
    dataObject.startTime = toISOWithTimezone(new Date(data.startTime));
  }

  if (data.agenda) {
    dataObject.agenda_files = data.agenda
      .filter(attachment => attachment.temp && !attachment.deleted)
      .map(attachment => attachment.file);

    dataObject.delete_attachment_files = data.agenda
      .filter(attachment => !attachment.temp && attachment.deleted)
      .map(attachment => attachment.id);
  }

  const error = await resolveRejectedPromise(
    updateMeetingRequesting(dataObject),
  );
  if (error === null) {
    notify.success(i18n._(t`Meetings: ${dataObject.title} is updated`));
  }

  if (error && error.message) {
    return {
      [FORM_ERROR]: notify.error(
        i18n._(t`Something went wrong with a server, please try again later`),
      ),
    };
  }

  return error && error.data ? error.data : null;
}

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = <Trans>This is required field</Trans>;
  }
  if (!values.location) {
    errors.location = <Trans>This is required field</Trans>;
  }
  if (!values.project) {
    errors.project = <Trans>This is required field</Trans>;
  }
  if (!values.startTime) {
    errors.startTime = <Trans>This is required field</Trans>;
  } else if (dayjs().isAfter(new Date(values.startTime))) {
    errors.startTime = <Trans>Start date should be future</Trans>;
  }

  if (!values.invitedEmployees) {
    errors.invitedEmployees = <Trans>This is required field</Trans>;
  }

  return errors;
};

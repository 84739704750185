import React from 'react';
import PropTypes from 'prop-types';
import { combine } from 'effector';
import { useStore, useGate } from 'effector-react';
import { DataTable, Link, AssigneeList } from '@oca/ui';

import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { format } from 'date-fns';
import {
  $pending,
  folderClicked,
  FolderBrowserGate,
} from '../model/folder-browser.model';
import { Filename } from '../molecules';

const $state = combine({
  fetching: $pending,
});

export const FolderBrowser = React.forwardRef(
  /**
   *
   * @param {import('@oca/ui').DataTableProps} props
   */
  function FolderBrowser({ category, list, ...props }, ref) {
    const { fetching } = useStore($state);
    useGate(FolderBrowserGate, { category });

    return (
      <DataTable
        data={list}
        pageSize={10}
        columns={[
          {
            Header: i18n._(t`Name`),
            id: 'name',
            accessor: 'name',
            // eslint-disable-next-line react/prop-types
            Cell: ({ original }) => {
              return original ? (
                <Filename
                  isFolder
                  name={
                    <Link
                      as="button"
                      onClick={() => folderClicked(original.id)}
                    >
                      {original.name}
                    </Link>
                  }
                />
              ) : null;
            },
            minWidth: 200,
            sortMethod: () => undefined,
          },
          {
            Header: i18n._(t`Created`),
            accessor: 'createdAt',
            Cell: ({ value }) => format(new Date(value), 'dd.MM.yyyy hh:mm'),
          },
          {
            Header: i18n._(t`Access`),
            accessor: 'users',
            // eslint-disable-next-line react/prop-types
            Cell: ({ value }) =>
              value.length ? <AssigneeList assignees={value} /> : 'You',
          },
        ]}
        {...props}
        loading={fetching}
        ref={ref}
      />
    );
  },
);

FolderBrowser.propTypes = {
  loading: PropTypes.bool,
  category: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
};

FolderBrowser.defaultProps = {
  loading: false,
  list: [],
};

import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled, { css, keyframes } from 'styled-components';
import { OCALogoColored, AvisaLogoColored, Halcyon, Motaded } from '@oca/icons';
import { CenterContentTemplate } from '@oca/ui';

const logosMap = {
  oca: OCALogoColored,
  avisa: AvisaLogoColored,
  halcyon: Halcyon,
  motaded: Motaded,
};

const Logo = logosMap[process.env.REACT_APP_ORGANIZATION] || OCALogoColored;

export function SplashScreen({ children, animate }) {
  return (
    <CenterContentTemplate>
      <Flex width={1} flexDirection="column" alignItems="center">
        <LogoWrapper animate={animate}>
          <Logo fontSize={192} />
        </LogoWrapper>
        {children}
      </Flex>
    </CenterContentTemplate>
  );
}

SplashScreen.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node,
};

SplashScreen.defaultProps = {
  animate: true,
  children: null,
};

const fadeInOut = keyframes`
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.3;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 16px;
  filter: grayscale(${({ animate }) => (animate ? 0 : 1)});
  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeInOut} 2s infinite;
    `}
`;

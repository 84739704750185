/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

import { RangePickerInput } from '@oca/ui';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps}
 */
export function RangePickerInputWrapper({
  label,
  input,
  meta,
  helperText,
  required,
  placeholder,
  spaceAfter,
  form,
  disabled,
  ...rest
}) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  const { onChange, onBlur, onFocus, value, name } = input;

  return (
    <RangePickerInput
      // {...rest}
      inputProps={{
        form,
        label,
        disabled,
        onBlur,
        onFocus,
        required,
        placeholder,
        name,
        spaceAfter,
        helperText: showError ? meta.error || meta.submitError : helperText,
        error: showError,
      }}
      onChange={([date]) => onChange(date || null)}
      value={value}
    />
  );
}

RangePickerInputWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.any,
    dirtySinceLastSubmit: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  required: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  spaceAfter: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Trans } from '@lingui/macro';

import { Row, Col } from '@lib/grid';
import { SelectWrapper, DayPickerInputWrapper } from '@lib/final-form-oca-ui';
import { FileUploaderField } from '@features/common';
import { Typography, IconButton } from '@oca/ui';
import { Delete } from '@oca/icons';
import dayjs from 'dayjs';
import { dateTokens } from '@lib/i18n';

const datePickerOptions = {
  static: true,
  formatDate: date => dayjs(date).format('L'),
};

export function FieldsetContract({ name, index, onDelete, options }) {
  return (
    <Row flexDirection="column" mb={4}>
      <Col>
        {index && <Typography variant="body1">#{index}</Typography>}
        <Row spacing="10px" mx={0}>
          <Col width={[1 / 3, 1 / 4, 1 / 5]}>
            <Field
              name={`${name}.docType`}
              component={SelectWrapper}
              label={<Trans>Type</Trans>}
              parse={value => (value ? value.id : '')}
              format={value => options.find(({ id }) => value === id)}
              options={options}
              required
            />
          </Col>
          <Col width={[1 / 3, 1 / 4, 1 / 5]}>
            <Field
              name={`${name}.expDate`}
              component={DayPickerInputWrapper}
              parse={val => (val ? dayjs(val).format(dateTokens.ISO_DATE) : '')}
              format={val => (val ? new Date(val).toISOString() : null)}
              label={<Trans>Expire date</Trans>}
              options={datePickerOptions}
              required
            />
          </Col>
          {onDelete && (
            <Col width={[1 / 4]} alignSelf="flex-end">
              <IconButton color="error" onClick={() => onDelete(index)}>
                <Delete />
              </IconButton>
            </Col>
          )}
        </Row>
      </Col>
      <Col width={[1 / 2, 1 / 3]}>
        <FileUploaderField
          label={<Trans>Picture/Scan</Trans>}
          name={`${name}.files`}
        />
      </Col>
    </Row>
  );
}

FieldsetContract.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  index: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
};

FieldsetContract.defaultProps = {
  options: [],
};

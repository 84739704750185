import { createEffect, createStore } from 'effector';

import { createFetching } from '@lib/effector-utils';
import { getNotificationsList, controlNotificationsList } from '../api';

// Effects
export const getNotifications = createEffect('get notifications list');
export const deleteNotification = createEffect('remove notification');
export const deleteAllNotifications = createEffect('remove all notifications');
export const readNotification = createEffect('read notification');
export const readAllNotifications = createEffect('read all notification');

// Set thunks
getNotifications.use(() => getNotificationsList());

deleteNotification.use((notifications = []) =>
  controlNotificationsList({
    notifications,
    action_type: 'delete',
    for_all: false,
  }),
);

deleteAllNotifications.use(() =>
  controlNotificationsList({
    action_type: 'delete',
    for_all: true,
  }),
);

readNotification.use((notifications = []) =>
  controlNotificationsList({
    notifications,
    action_type: 'read',
    for_all: false,
  }),
);

readAllNotifications.use(() =>
  controlNotificationsList({
    action_type: 'read',
    for_all: true,
  }),
);

// Stores
export const $notificationList = createStore({});
export const $getListStatus = createFetching(getNotifications);
export const $deleteAllStatus = createFetching(deleteAllNotifications);
export const $readAllStatus = createFetching(readAllNotifications);

// Side Effects
$notificationList
  .on(getNotifications.done, (_, { result }) => {
    return result.data;
  })
  .reset(deleteAllNotifications.done)
  .reset(getNotifications.fail);

readNotification.done.watch(() => getNotifications());
readAllNotifications.done.watch(() => getNotifications());
deleteNotification.done.watch(() => getNotifications());

import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled, { css } from 'styled-components';
import { maxWidth, minWidth } from 'styled-system';
import AriaModal from 'react-aria-modal';
import { LightClose } from '../icons/light-close';
import { IconButton } from '../atoms/icon-button';
import { Typography } from '../atoms';

const DialogWrapper = styled(Box)`
  position: relative;
  border-radius: 6px;
  background: white;
  ${maxWidth};
  ${minWidth};
`;

const DialogHeader = styled(Flex)`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
`;
const DialogBody = styled.div`
  padding: 30px;
  min-height: 150px;
  text-align: left;

  ${({ scrollBody }) =>
    scrollBody
      ? css`
          max-height: 85vh;
          overflow-y: auto;
        `
      : ''}
`;

const ActionBtns = ({ onClickOnClose }) => {
  return (
    <Box ml={15}>
      <IconButton
        color="white"
        tabIndex="0"
        onClick={onClickOnClose}
        size="small"
      >
        <LightClose />
      </IconButton>
    </Box>
  );
};

ActionBtns.propTypes = {
  onClickOnClose: PropTypes.func.isRequired,
};

export const Dialog = ({
  children,
  title,
  onClose,
  onClickOnClose,
  underlayClickExits,
  scrollBody,
  maxWidth: maxWidthProp,
  minWidth: minWidthProp,
  width,
  ...other
}) => {
  const handleClose = onClose || onClickOnClose;

  return (
    <AriaModal
      focusTrapPaused
      titleText={title}
      onExit={handleClose}
      underlayClickExits={underlayClickExits}
      verticallyCenter
      {...other}
    >
      <DialogWrapper
        width={width}
        minWidth={minWidthProp}
        maxWidth={maxWidthProp}
      >
        <DialogHeader
          alignItems="center"
          justifyContent="space-between"
          bg="#009eff"
          pl={30}
          pr={18}
          py="5px"
        >
          <Typography variant="h2" lineHeight={1.1} color="white" noWrap>
            {title}
          </Typography>
          <ActionBtns onClickOnClose={handleClose} />
        </DialogHeader>
        <DialogBody scrollBody={scrollBody}>{children}</DialogBody>
      </DialogWrapper>
    </AriaModal>
  );
};

Dialog.propTypes = {
  ...AriaModal.propTypes,
  scrollBody: PropTypes.bool,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onClickOnClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  underlayClickExits: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  maxWidth: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  // eslint-disable-next-line react/require-default-props
  minWidth: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  // eslint-disable-next-line react/require-default-props
  width: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
};

Dialog.defaultProps = {
  underlayClickExits: true,
  scrollBody: false,
};

import React from 'react';
import ReactFlatpickr from 'react-flatpickr';
import { createGlobalStyle } from 'styled-components';
import 'flatpickr/dist/themes/light.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps} props
 */
export function Flatpickr(props) {
  const ref = React.useRef();
  const locale = React.useContext(FlatpickrContext);
  const options = React.useMemo(() => {
    const defaultOptions = { ...props.options, disableMobile: true };
    if (locale) {
      defaultOptions.locale = locale;
    }
    return defaultOptions;
  }, [locale, props.options]);

  // Force flatpickr to use 'en' language (can't handle time change with hindu numbers)
  React.useLayoutEffect(() => {
    const container = ref.current.flatpickr.calendarContainer;
    if (container) {
      container.setAttribute('lang', 'en');
    }
  }, []);

  return <ReactFlatpickr {...props} options={options} ref={ref} />;
}

Flatpickr.propTypes = ReactFlatpickr.propTypes;

export const FlatpickrContext = React.createContext(null);

// eslint-disable-next-line react/prop-types
export function FlatpickrProvider({ children, locale }) {
  return (
    <FlatpickrContext.Provider value={locale}>
      {children}
    </FlatpickrContext.Provider>
  );
}

export const FlatpickrStyles = createGlobalStyle`
  body {
    .flatpickr-calendar {
      box-shadow: 0 3px 13px rgba(0,0,0,0.2);
      &.arrowTop {
        &::before,
        &::after {
          border-bottom-color: white;
        }
      }
      &.arrowBottom {
        &::before,
        &::after {
          border-top-color: white;
        }
      }
    }
    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month,
    .flatpickr-months .flatpickr-month,
    .flatpickr-current-month {
      position: static;
    }

    .flatpickr-months .flatpickr-prev-month,
    .flatpickr-months .flatpickr-next-month {
      height: auto;
      & svg {
        vertical-align: middle;
      }
    }

    .flatpickr-months {
      align-items: center;
      padding-top: 8px;
      border-radius: 4px 4px 0 0;
      background: white;
    }

    .flatpickr-weekdays {
      background: white;
      margin: 8px 0;
    }

    span.flatpickr-weekday {
      background: white;
      font-weight: 300;
    }

    .flatpickr-months .flatpickr-month {
      background: white;
      height: auto;
      overflow: visible;
    }

    .flatpickr-current-month {
      padding: 0;
      width: auto;
      height: auto;
    }

    .flatpickr-day.selected,
    .flatpickr-day.startRange,
    .flatpickr-day.endRange,
    .flatpickr-day.selected.inRange,
    .flatpickr-day.startRange.inRange,
    .flatpickr-day.endRange.inRange,
    .flatpickr-day.selected:focus,
    .flatpickr-day.startRange:focus,
    .flatpickr-day.endRange:focus,
    .flatpickr-day.selected:hover,
    .flatpickr-day.startRange:hover,
    .flatpickr-day.endRange:hover,
    .flatpickr-day.selected.prevMonthDay,
    .flatpickr-day.startRange.prevMonthDay,
    .flatpickr-day.endRange.prevMonthDay,
    .flatpickr-day.selected.nextMonthDay,
    .flatpickr-day.startRange.nextMonthDay,
    .flatpickr-day.endRange.nextMonthDay {
      background: #fea201;
      border-color: #fea201;
    }

    .flatpickr-months .flatpickr-prev-month:hover svg,
    .flatpickr-months .flatpickr-next-month:hover svg {
      fill: #fea201;
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
      height: auto;
      margin-right: 16px;
      background: white;
      border: 1px solid #eee;
    }

    .flatpickr-days,
    .flatpickr-innerContainer {
      border: none;
    }

    .flatpickr-innerContainer {
      border-radius: 0 0 4px 4px;
    }

    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
      border-radius: 0;
    }

    .flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
      border-radius: 0 0 4px 4px;
      border: none;
      border-top: 1px solid #eee;
    }

    /* Week/month picker styles */
    .flatpickr-day.week.selected {
      width: 48px;
      background: #ffd18d;
      border-color: transparent;
      box-shadow: -5px 0 0 #ffd18d, 5px 0 0 #ffd18d;
      font-weight: normal;
    }
    .flatpickr-day.week.selected,
    .flatpickr-monthSelect-month.selected {
      color: #484848;
    }
    .flatpickr-day.week.selected.today,
    .flatpickr-monthSelect-month.selected {
      background: rgb(254, 162, 1, 1);
    }
  }
`;

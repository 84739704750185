import { createEffect } from 'effector';
import { createFetching } from '@lib/effector-utils';

import { history } from '@lib/routing';
import * as commonApi from '../api';

// Events
export const createMeetingRequesting = createEffect('meeting create requested');
export const updateMeetingRequesting = createEffect('meeting update requested');

// Set thunks
createMeetingRequesting.use(data => commonApi.createMeeting(data));
updateMeetingRequesting.use(data => commonApi.updateMeeting(data));

// Stores
export const $createMeetingFetching = createFetching(createMeetingRequesting);
export const $updateMeetingFetching = createFetching(updateMeetingRequesting);

// Side Effects
createMeetingRequesting.done.watch(({ result }) => {
  history.goBack();
});

updateMeetingRequesting.done.watch(({ result }) => {
  history.goBack();
});

/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { Router } from 'react-router-dom';
import { Normalize } from 'styled-normalize';
import { I18nProvider } from '@lingui/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer, toast } from 'react-toastify';
import { renderRoutes } from 'react-router-config';
import { useStore } from 'effector-react';
import rtlPlugin from 'stylis-plugin-rtl';

import { history } from '@lib/routing';
import { i18n, isRTL, locales } from '@lib/i18n';

import { NotificationsPanel } from '@features/notifications';
import { FlatpickrStyles, FlatpickrProvider } from '@lib/flatpickr';
import { AppErrorHandler } from '@lib/app-error-handler';
import {
  AccountLoader,
  Navigation,
  Header,
  NotFoundPage,
  $mediaQuery,
  PageLoader,
  $locale,
} from './features/common';
import { BasicTemplate } from './ui';
import { GlobalStyles } from './global-styles';
import { privateRoutes, publicRoutes } from './routes';
import { theme } from './ui/theme';

const publicPaths = publicRoutes.reduce(
  (acc, item) => (item.path ? acc.concat([item.path]) : acc),
  [],
);

const errorHandlerMap = {
  404: <NotFoundPage />,
};

// Always shift scrolling on top, when user changes page
history.listen((_, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0);
  }
});

export function App() {
  return (
    <Providers>
      <Styles />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={4000} />
      <AccountLoader noRedirectPaths={publicPaths}>
        {({ isAuthenticated }) => {
          if (isAuthenticated) {
            return (
              <PrivateAppContainer>
                {renderRoutes(privateRoutes)}
              </PrivateAppContainer>
            );
          }

          return renderRoutes(publicRoutes);
        }}
      </AccountLoader>
    </Providers>
  );
}

const Providers = React.memo(function Providers({ children }) {
  const locale = useStore($locale);
  const rtl = React.useMemo(() => (isRTL(locale) ? [rtlPlugin] : []), [locale]);
  const flatpickrLocale = React.useMemo(() => {
    const currentLocale = locales[locale];
    if (currentLocale) {
      return currentLocale.flatpickr;
    }
    return undefined;
  }, [locale]);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <I18nProvider i18n={i18n}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
          >
            <FlatpickrProvider locale={flatpickrLocale}>
              <StyleSheetManager stylisPlugins={rtl} target={document.head}>
                <React.Fragment>{children}</React.Fragment>
              </StyleSheetManager>
            </FlatpickrProvider>
          </GoogleReCaptchaProvider>
        </I18nProvider>
      </ThemeProvider>
    </Router>
  );
});

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

const Styles = React.memo(function Styles() {
  return (
    <React.Fragment>
      <FlatpickrStyles />
      <GlobalStyles />
      <Normalize />
    </React.Fragment>
  );
});

const PrivateAppContainer = React.memo(function AppContainer({ children }) {
  const { large, xlarge } = useStore($mediaQuery);

  return (
    <React.Fragment>
      <NotificationsPanel />
      <BasicTemplate
        nav={<Navigation routes={privateRoutes} />}
        header={<Header />}
        isLargeScreen={large || xlarge}
      >
        <AppErrorHandler renderMap={errorHandlerMap}>
          <React.Suspense fallback={<PageLoader />}>{children}</React.Suspense>
        </AppErrorHandler>
      </BasicTemplate>
    </React.Fragment>
  );
});

PrivateAppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

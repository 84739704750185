/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { safeInvoke, shallowCompareKeys } from '@lib/help-fns';
import { ResizeSensor } from '@lib/resize-sensor';

const Boundary = {
  START: 'start',
  END: 'end',
};

const OverflowDirection = {
  0: 'NONE',
  1: 'GROW',
  2: 'SHRINK',
  NONE: 0,
  GROW: 1,
  SHRINK: 2,
};

export class OverflowList extends React.Component {
  static ofType() {
    return OverflowList;
  }

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.state = {
      direction: OverflowDirection.NONE,
      lastOverflowCount: 0,
      overflow: [],
      visible: this.props.items,
    };
    /** A cache containing the widths of all elements being observed to detect growing/shrinking */
    this.previousWidths = new Map();
    this.spacer = null;
    this.resize = entries => {
      // if any parent is growing, assume we have more room than before
      const growing = entries.some(entry => {
        const previousWidth = this.previousWidths.get(entry.target) || 0;
        return entry.contentRect.width > previousWidth;
      });
      this.repartition(growing);
      entries.forEach(entry =>
        this.previousWidths.set(entry.target, entry.contentRect.width),
      );
    };
  }

  componentDidMount() {
    this.repartition(false);
  }

  shouldComponentUpdate(_nextProps, nextState) {
    // We want this component to always re-render, even when props haven't changed, so that
    // changes in the renderers' behavior can be reflected.
    // The following statement prevents re-rendering only in the case where the state changes
    // identity (i.e. setState was called), but the state is still the same when
    // shallow-compared to the previous state.
    return !(
      this.state !== nextState && shallowCompareKeys(this.state, nextState)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.observeParents !== this.props.observeParents) {
      console.warn('OVERFLOW_LIST_OBSERVE_PARENTS_CHANGED');
    }
    if (
      prevProps.collapseFrom !== this.props.collapseFrom ||
      prevProps.items !== this.props.items ||
      prevProps.minVisibleItems !== this.props.minVisibleItems ||
      prevProps.overflowRenderer !== this.props.overflowRenderer ||
      prevProps.visibleItemRenderer !== this.props.visibleItemRenderer
    ) {
      // reset visible state if the above props change.
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        direction: OverflowDirection.GROW,
        lastOverflowCount: 0,
        overflow: [],
        visible: this.props.items,
      });
    }
    if (!shallowCompareKeys(prevState, this.state)) {
      this.repartition(false);
    }
    const { direction, overflow, lastOverflowCount } = this.state;
    if (
      // if a resize operation has just completed (transition to NONE)
      direction === OverflowDirection.NONE &&
      direction !== prevState.direction &&
      overflow.length !== lastOverflowCount
    ) {
      safeInvoke(this.props.onOverflow, overflow);
    }
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const {
      collapseFrom,
      observeParents,
      style,
      tagName = 'div',
      visibleItemRenderer,
    } = this.props;
    const overflow = this.maybeRenderOverflow();
    const list = React.createElement(
      List,
      {
        as: tagName,
        style,
      },
      collapseFrom === Boundary.START ? overflow : null,
      this.state.visible.map(visibleItemRenderer),
      collapseFrom === Boundary.END ? overflow : null,

      React.createElement(ListSpacer, {
        ref: ref => (this.spacer = ref),
      }),
    );

    return React.createElement(
      ResizeSensor,
      { onResize: this.resize, observeParents },
      list,
    );
  }

  maybeRenderOverflow() {
    const { overflow } = this.state;
    if (overflow.length === 0) {
      return null;
    }
    return this.props.overflowRenderer(overflow);
  }

  repartition(growing) {
    if (this.spacer == null) {
      return;
    }
    if (growing) {
      this.setState(state => ({
        direction: OverflowDirection.GROW,
        // store last overflow if this is the beginning of a resize (for check in componentDidUpdate).
        lastOverflowCount:
          state.direction === OverflowDirection.NONE
            ? state.overflow.length
            : state.lastOverflowCount,
        overflow: [],
        visible: this.props.items,
      }));
    } else if (this.spacer.getBoundingClientRect().width < 0.9) {
      // spacer has flex-shrink and width 1px so if it's much smaller then we know to shrink
      this.setState(state => {
        if (state.visible.length <= this.props.minVisibleItems) {
          return null;
        }
        const collapseFromStart = this.props.collapseFrom === Boundary.START;
        const visible = state.visible.slice();
        const next = collapseFromStart ? visible.shift() : visible.pop();
        if (next === undefined) {
          return null;
        }
        const overflow = collapseFromStart
          ? [...state.overflow, next]
          : [next, ...state.overflow];
        return {
          // set SHRINK mode unless a GROW is already in progress.
          // GROW shows all items then shrinks until it settles, so we
          // preserve the fact that the original trigger was a GROW.
          direction:
            state.direction === OverflowDirection.NONE
              ? OverflowDirection.SHRINK
              : state.direction,
          overflow,
          visible,
        };
      });
    } else {
      // repartition complete!
      this.setState({ direction: OverflowDirection.NONE });
    }
  }
}

OverflowList.displayName = 'OverflowList';

OverflowList.defaultProps = {
  collapseFrom: Boundary.START,
  minVisibleItems: 0,
};

const List = styled(Flex)`
  flex-wrap: nowrap;
  min-width: 0;
`;

const ListSpacer = styled.div`
  flex-shrink: 1;
  width: 1px;
`;

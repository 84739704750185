import React from 'react';
import PropTypes from 'prop-types';

import ReactTable from 'react-table-6';
import { Flex, Image } from 'rebass';
import { Trans } from '@lingui/macro';
import 'react-table-6/react-table.css';

import { ReactTablePagination } from '@lib/react-table-pagination';
import noDataFound from '@lib/illustrations/no-data-found.svg';
import { Pagination } from '../molecules/pagination';

import { Progress } from '../atoms/progress';
import { Typography } from '../atoms/typography';
import './data-table.css';

export const DataTable = React.forwardRef(function DataTable(props, ref) {
  const {
    data,
    noDataText,
    loading,
    loadingText,
    showProgress,
    ...rest
  } = props;
  return (
    <ReactTable
      ref={ref}
      data={data}
      loading={loading}
      loadingText={loadingText}
      TheadComponent={Thead}
      getTheadProps={state => ({ loading: state.loading, showProgress })}
      noDataText={
        !loading && data && data.length === 0 ? <div>{noDataText}</div> : null
      }
      {...rest}
    />
  );
});

DataTable.propTypes = {
  ...ReactTable.propTypes,
  // eslint-disable-next-line react/require-default-props
  noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  PaginationComponent: PropTypes.func,
  showProgress: PropTypes.bool,
};

DataTable.defaultProps = {
  PaginationComponent: function PaginationWrapper(props) {
    return (
      <ReactTablePagination components={{ Container: Pagination }} {...props} />
    );
  },
  showProgress: true,
  noDataText: <NoData />,
};

function Thead(props) {
  // eslint-disable-next-line react/prop-types
  const { children, style, className, loading, showProgress } = props;
  return (
    <div className={`rt-thead ${className}`} style={style}>
      {children}
      {showProgress && loading && <Progress className="rt-thead__progress" />}
    </div>
  );
}

function NoData() {
  return (
    <Flex flexDirection="column" alignItems="center">
      <Image width={250} height="auto" src={noDataFound} alt="noDataFound" />
      <Typography fontSize={20}>
        <Trans>No data was found</Trans>
      </Typography>
    </Flex>
  );
}

import React from 'react';
import { useStore } from 'effector-react';

import { BaseSelect } from '@oca/ui';
import { locales } from '@lib/i18n';

import { $locale, setLocale } from '../model/i18n';

const localeOptions = Object.values(locales).map(({ code, name }) => ({
  label: name,
  value: code,
}));

const styles = { container: () => ({ width: '100%' }) };

export function LocaleSelect(props) {
  const locale = useStore($locale);
  const handleSelect = React.useCallback(({ value }) => setLocale(value), []);
  const selected = localeOptions.find(({ value }) => value === locale) || {};

  return (
    <BaseSelect
      options={localeOptions}
      onChange={handleSelect}
      menuPortalTarget={document.body}
      styles={styles}
      value={selected}
      {...props}
    />
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Text as BaseText } from 'rebass';
import styled, { css } from 'styled-components';
import { theme } from '../theme';

const componentMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'p',
  body2: 'p',
  caption: 'p',
};

const styleProps = {
  h1: createStyle(5, 1, 1),
  h2: createStyle(4, 1, 1),
  h3: createStyle(3, 1, 1.04),
  h4: createStyle(2, 1, 1.04),
  body1: createStyle(1, 0, 1.5),
  body2: createStyle(1, 0, 1.43),
  caption: createStyle(0, 0, 1.66),
};

export const Typography = ({
  align,
  as,
  children,
  color,
  fontSize,
  fontWeight,
  gutterBottom,
  noWrap,
  paragraph,
  variant,
  ...other
}) => {
  const { size, weight, lineHeight } = styleProps[variant] || {};

  return (
    <Text
      as={as || componentMapping[variant]}
      margin={0}
      marginBottom={(paragraph && 3) || (gutterBottom && '0.35em') || 0}
      fontSize={fontSize || size}
      fontWeight={fontWeight || weight}
      fontFamily="common"
      color={color}
      textAlign={align}
      lineHeight={lineHeight}
      noWrap={noWrap}
      {...other}
    >
      {children}
    </Text>
  );
};

Typography.propTypes = {
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  // eslint-disable-next-line react/require-default-props
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'caption',
    'body1',
    'body2',
  ]),
  color: PropTypes.oneOf(Object.keys(theme.colors).concat(['inherit'])),
  // eslint-disable-next-line react/require-default-props
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/require-default-props
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gutterBottom: PropTypes.bool,
  paragraph: PropTypes.bool,
  noWrap: PropTypes.bool,
};

Typography.defaultProps = {
  align: 'left',
  // as: 'p',
  variant: 'body1',
  color: 'text',
  gutterBottom: false,
  paragraph: false,
  noWrap: false,
};

const Text = styled(BaseText)`
  ${({ noWrap }) =>
    noWrap &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

/**
 * Creates size and weight object
 * @param {number} size Font size index in theme
 * @param {number} weight Font weight index in theme
 */
function createStyle(size = 3, weight = 0, lineHeight = 1) {
  return { size, weight, lineHeight };
}

import {
  Aiff,
  Avi,
  Bmp,
  Cad,
  Dbf,
  Doc,
  Eps,
  Csv,
  Dng,
  Jpg,
  Html,
  Flac,
  Flv,
  Gif,
  Mp3,
  Mov,
  Mp4,
  Mxf,
  Pdf,
  Png,
  Ppt,
  Tab,
  Xls,
  Rar,
  Svg,
  Tiff,
  Txt,
  Xml,
  Zip,
  Wav,
  UnknownFile,
} from '@oca/icons';

export const fileIcon = [
  {
    ext: 'aiff',
    icon: Aiff,
    type: 'audio',
  },
  {
    ext: 'avi',
    icon: Avi,
    type: 'video',
  },
  {
    ext: 'bmp',
    icon: Bmp,
    type: 'img',
  },
  {
    ext: 'cad',
    icon: Cad,
    type: 'img',
  },
  {
    ext: 'dbf',
    icon: Dbf,
    type: 'db',
  },
  {
    ext: 'dng',
    icon: Dng,
    type: 'img',
  },
  {
    ext: 'flac',
    icon: Flac,
    type: 'audio',
  },
  {
    ext: 'doc',
    icon: Doc,
    type: 'doc',
  },
  {
    ext: 'docx',
    icon: Doc,
    type: 'doc',
  },
  {
    ext: 'ppt',
    icon: Ppt,
    type: 'doc',
  },
  {
    ext: 'pptx',
    icon: Ppt,
    type: 'doc',
  },
  {
    ext: 'csv',
    icon: Csv,
    type: 'doc',
  },
  {
    ext: 'html',
    icon: Html,
    type: 'doc',
  },
  {
    ext: 'gif',
    icon: Gif,
    type: 'img',
  },
  {
    ext: 'tab',
    icon: Tab,
    type: 'other',
  },
  {
    ext: 'wav',
    icon: Wav,
    type: 'audio',
  },
  {
    ext: 'mov',
    icon: Mov,
    type: 'video',
  },
  {
    ext: 'mp3',
    icon: Mp3,
    type: 'audio',
  },
  {
    ext: 'mxf',
    icon: Mxf,
    type: 'video',
  },
  {
    ext: 'eps',
    icon: Eps,
    type: 'other',
  },
  {
    ext: 'flv',
    icon: Flv,
    type: 'other',
  },
  {
    ext: 'jpg',
    icon: Jpg,
    type: 'img',
  },
  {
    ext: 'jpeg',
    icon: Jpg,
    type: 'img',
  },
  {
    ext: 'png',
    icon: Png,
    type: 'img',
  },
  {
    ext: 'rar',
    icon: Rar,
    type: 'other',
  },
  {
    ext: 'svg',
    icon: Svg,
    type: 'img',
  },
  {
    ext: 'tiff',
    icon: Tiff,
    type: 'other',
  },
  {
    ext: 'txt',
    icon: Txt,
    type: 'doc',
  },
  {
    ext: 'mp4',
    icon: Mp4,
    type: 'video',
  },
  {
    ext: 'zip',
    icon: Zip,
    type: 'other',
  },
  {
    ext: 'pdf',
    icon: Pdf,
    type: 'doc',
  },
  {
    ext: 'xls',
    icon: Xls,
    type: 'doc',
  },
  {
    ext: 'xlsx',
    icon: Xls,
    type: 'doc',
  },
  {
    ext: 'xml',
    icon: Xml,
    type: 'doc',
  },
  {
    ext: 'default',
    icon: UnknownFile,
    type: 'other',
  },
];

/**
 * Finds an associated extension object by extension
 * @param {string} ext File extension
 */
export function getFileIcon(ext) {
  return (
    fileIcon.find(icon => icon.ext === ext) || fileIcon[fileIcon.length - 1]
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Dropzone, Button, Previewer } from '@oca/ui';

import usePrevious from 'react-use/lib/usePrevious';
import saveFile from 'file-saver';
import { useFileUploading } from '../hooks';
import { UploadedFileList } from '../molecules/uploaded-file-list';
import { ProcessingFileList, RejectedFileList } from '../molecules';
import { fetcher } from '../lib/fetcher';

const maxSize = process.env.REACT_APP_MAX_UPLOAD_FILESIZE * 1024 * 1024;
/**
 * @typedef {Object} FileUploaderProps
 * @property {string} accept
 * @property {Boolean} disabled
 * @property {Boolean} error
 * @property {Function} onChange
 * @property {Array<{file: string, filename: string, temp: Boolean}>} value
 * @property {boolean} uploadOnFileDrop
 */

export const FileUploader = React.memo(
  /**
   *
   * @param {FileUploaderProps}
   */
  function FileUploader({
    accept,
    error,
    disabled,
    onChange,
    onComplete,
    value: uploaded = [],
    uploadOnFileDrop,
  }) {
    const [processingFiles, setProcessingFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const [preview, setPreview] = useState(null);
    const {
      result,
      processing,
      isUploading,
      onUpload,
      onCancelUploads,
    } = useFileUploading({ onComplete });
    const prevResultLength = usePrevious(result.length);

    useEffect(() => {
      if (prevResultLength !== result.length && result.length > 0) {
        const newFile = result[result.length - 1];
        setProcessingFiles(files =>
          files.filter(file => file.name !== newFile.originalName),
        );
        onChange(uploaded.concat([newFile]));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result, prevResultLength]);

    useEffect(() => {
      if (uploadOnFileDrop && processingFiles.length > 0) {
        onUpload(processingFiles);
      }
    }, [onUpload, processingFiles, uploadOnFileDrop]);

    const handleProcessingFilesDelete = ids =>
      setProcessingFiles(files =>
        files.filter(({ name }) => !ids.includes(name)),
      );

    const handleUploadedFilesDelete = ids =>
      onChange(
        uploaded.map(item =>
          ids.includes(item.file) ? { ...item, deleted: true } : item,
        ),
      );

    const handleUploadCancel = name => {
      handleProcessingFilesDelete([name]);
      onCancelUploads([name]);
    };

    const handleOnDrop = (accepted, rejected) => {
      const filtered = accepted.filter(item => {
        if (uploaded.length > 0) {
          return uploaded.every(file => {
            const hasMatch = (file.originalName || file.fileName) === item.name;

            return file.deleted && hasMatch ? true : !hasMatch;
          });
        }
        if (processingFiles.length > 0) {
          return processingFiles.every(file => file.name !== item.name);
        }
        return true;
      });

      setProcessingFiles(files => files.concat(filtered));
      setRejectedFiles(rejectedFiles.concat(rejected));
    };

    const hasUploadedFiles = uploaded.length > 0;
    const hasProcessingFiles = processingFiles.length > 0;
    const hasRejectedFiles = rejectedFiles.length > 0;
    return (
      <div>
        <Dropzone
          error={error}
          maxSize={maxSize}
          accept={accept}
          disabled={isUploading || disabled}
          onDrop={handleOnDrop}
        />
        {hasRejectedFiles && (
          <RejectedFileList
            items={rejectedFiles}
            validation={{ maxSize, accept }}
            onDelete={id =>
              setRejectedFiles(files => files.filter(({ name }) => id !== name))
            }
          />
        )}
        {hasUploadedFiles && (
          <UploadedFileList
            items={uploaded}
            onDelete={id => handleUploadedFilesDelete([id])}
            onDownload={onDownload}
            onPreview={value => setPreview(value)}
          />
        )}
        {hasProcessingFiles && (
          <ProcessingFileList
            items={processingFiles}
            processing={processing}
            onDelete={
              !isUploading ? id => handleProcessingFilesDelete([id]) : undefined
            }
            onCancel={isUploading ? handleUploadCancel : undefined}
          />
        )}
        {preview && <Previewer {...preview} onClose={() => setPreview(null)} />}
        {processingFiles.length > 0 && !uploadOnFileDrop && (
          <Button
            tabIndex={0}
            type="button"
            variant="primary"
            onClick={() => onUpload(processingFiles)}
          >
            Upload
          </Button>
        )}
      </div>
    );
  },
);

FileUploader.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      filename: PropTypes.string,
      temp: PropTypes.bool,
    }),
  ).isRequired,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  onComplete: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  maxSize: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  accept: PropTypes.string,
  uploadOnFileDrop: PropTypes.bool,
};

FileUploader.defaultProps = {
  error: false,
  uploadOnFileDrop: false,
};

function onDownload({ url, filename }) {
  fetcher
    .get(url, { responseType: 'blob' })
    .then(res => saveFile(res.data, filename));
}

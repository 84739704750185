import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Flex } from 'rebass';
import { useSpring, config, animated } from 'react-spring';
import { height as heightSystem } from 'styled-system';
import styled from 'styled-components';

import { PageHeading, ContentBlock } from '@oca/ui';
import { i18n } from '@lib/i18n';
import { getOrganizationMeta } from '../lib/get-meta';

/**
 *
 * @param {CommonPageTemplateProps}
 */
export function CommonPageTemplate({
  animateOnMount,
  children,
  title,
  heading,
  actions,
  height,
  sidebar,
  contentBackground,
  contentMaxWidth,
  contentWidth,
  contentFlex,
}) {
  const meta = getOrganizationMeta(process.env.REACT_APP_ORGANIZATION);
  const defaultTitle = i18n._(meta.documentTitle);
  const styleProps = useSpring({
    transform: 'translateY(0)',
    opacity: 1,
    from: { transform: 'translateY(30px)', opacity: 0 },
    config: config.stiff,
  });

  return (
    <MainContainer
      flexDirection="column"
      alignItems="flex-start"
      mx="auto"
      px={[32, 0, 0, 0]}
      paddingTop={30}
      height={height}
    >
      <Helmet defaultTitle={defaultTitle} titleTemplate={meta.titleTemplate}>
        {title && <title>{title}</title>}
        {meta.favicon && <link rel="icon" href={meta.favicon} />}
      </Helmet>
      {(heading || actions) && (
        <PageHeading title={heading} actions={actions} width={1} />
      )}
      <Container
        style={animateOnMount ? styleProps : null}
        width={1}
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        paddingBottom={3}
      >
        <ContentBlock
          flex={contentFlex}
          width={contentWidth}
          maxWidth={contentMaxWidth}
          bg={contentBackground}
        >
          {children}
        </ContentBlock>
        {sidebar}
      </Container>
    </MainContainer>
  );
}

CommonPageTemplate.propTypes = {
  animateOnMount: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actions: PropTypes.node,
  sidebar: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  contentBackground: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  contentMaxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  // eslint-disable-next-line react/require-default-props
  contentWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  contentFlex: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

CommonPageTemplate.defaultProps = {
  animateOnMount: true,
  children: null,
  contentBackground: 'white',
  contentFlex: '1 0 auto',
  title: null,
  heading: null,
  actions: null,
  sidebar: null,
};

const MainContainer = styled(Flex)`
  width: 100%;
  max-width: var(--content-width);
  ${heightSystem};
`;

const StyledContainer = styled(Flex)`
  height: inherit;
`;

const Container = animated(StyledContainer);

/**
 * @typedef {import('react').ReactNode} ReactNode
 *
 * @typedef {Object} CommonPageTemplateProps
 * @property {ReactNode} actions
 * @property {Boolean} animateOnMount
 * @property {ReactNode} children
 * @property {string} contentBackground
 * @property {Boolean} fullHeight
 * @property {ReactNode} heading
 * @property {ReactNode} sidebar
 * @property {string} title
 */

import React from 'react';
import { SvgIcon } from './svg-icon';

export const PencilRuler = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.89999 4.50002L5.69999 8.80002L8.79999 5.70002L4.49999 3.90002C4.39999 3.90002 4.19999 3.90002 3.99999 4.00002C3.89999 4.20002 3.89999 4.40002 3.89999 4.50002ZM18.4 4.40001L19.7 5.70001C20.2 6.20001 20.2 7.10001 19.8 7.70001L16.1 11.4L12.7 8.00001L14 6.70001L15 7.70001C15.2 7.90001 15.5 7.90001 15.7 7.70001C15.9 7.50001 15.9 7.20001 15.7 7.00001L14.7 6.00001L15.3 5.30001L17 7.00001C17.2 7.20001 17.5 7.20001 17.7 7.00001C17.9 6.80001 17.9 6.50001 17.7 6.30001L16 4.60001L16.4 4.40001C16.9 3.80001 17.8 3.80001 18.4 4.40001ZM7.70001 19.7C7.20001 20.3 6.30001 20.3 5.70001 19.7L4.30001 18.2999C3.70001 17.7999 3.70001 16.9 4.30001 16.2999L4.60001 16L6.20001 17.7999C6.40001 18 6.70001 18 6.90001 17.7999C7.10001 17.6 7.10001 17.3 6.90001 17.1L5.20001 15.4L5.90001 14.7L6.90001 15.7C7.10001 15.9 7.40001 15.9 7.60001 15.7C7.80001 15.5 7.80001 15.2 7.60001 15L6.60001 14L8.00001 12.7L11.3 16.1L7.70001 19.7ZM18.3 15L17 16.3L8.3 7.69999L9.6 6.29999L18.3 15ZM16.3 17L7.6 8.29999L6.3 9.69999L15 18.4L16.3 17ZM19 15.6L19.7 16.3C20.2 16.9 20.2 17.8 19.6 18.4L18.3 19.7C17.8 20.3 16.9 20.3 16.3 19.7L15.6 19L19 15.6Z"
    />
  </SvgIcon>
);

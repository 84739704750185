import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card as BaseCard } from 'rebass';
import styled from 'styled-components';

import { Person } from '@oca/icons';

import { Typography, Avatar } from '../atoms';

export const UserCard = React.forwardRef(
  /**
   *
   * @param {UserCardProps} props
   * @param {React.Ref} ref
   */
  function UserCard(props, ref) {
    const { avatar, title, name, subtitle, ...rest } = props;

    return (
      <Card width={215} py={3} px={2} bg="greys.1" {...rest} ref={ref}>
        {title && <Typography variant="h3">{title}</Typography>}
        <Box my={3}>
          <Avatar size={100} src={avatar}>
            <Person />
          </Avatar>
        </Box>
        {name && (
          <Typography
            width={1}
            fontSize={16}
            lineHeight={1}
            align="center"
            marginBottom={3}
          >
            {name}
          </Typography>
        )}
        {subtitle && (
          <Typography color="textSecondary" align="center" lineHeight={1.3}>
            {subtitle}
          </Typography>
        )}
      </Card>
    );
  },
);

UserCard.propTypes = {
  ...BaseCard.propTypes,
  // eslint-disable-next-line react/require-default-props
  avatar: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  subtitle: PropTypes.node,
};

const Card = styled(BaseCard)`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
`;

/**
 * @typedef {Object} CardProps
 * @property {string} avatar
 * @property {React.ReactNode} title
 * @property {React.ReactNode} subtitle
 * @property {React.ReactNode} name
 */

/**
 * @typedef {CardProps & import('rebass').BoxProps} UserCardProps
 */

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Close = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12ZM8.57574 9.42426L11.1515 12L8.57574 14.5757L9.42426 15.4243L12 12.8485L14.5757 15.4243L15.4243 14.5757L12.8485 12L15.4243 9.42426L14.5757 8.57574L12 11.1515L9.42426 8.57574L8.57574 9.42426Z"
    />
  </SvgIcon>
);

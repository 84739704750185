import React from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';
import { ThemeContext } from 'styled-components';
import { FilledClose } from '@oca/icons';

export const BaseSelect = React.forwardRef(
  /**
   * @param {import('./base-select').BaseSelectProps<T>} props
   * @param {import('react').Ref} ref
   */
  function BaseSelect(props, ref) {
    const { styles, error, components: componentsProp, ...rest } = props;
    const theme = React.useContext(ThemeContext);

    return (
      <Select
        {...rest}
        ref={ref}
        components={{
          IndicatorSeparator: null,
          MultiValueRemove,
          ...componentsProp,
        }}
        styles={{
          input: base => ({
            ...base,
            color: theme.colors.text,
            fontSize: 14,
          }),
          control: (base, { isFocused }) => ({
            ...base,
            paddingLeft: 8,
            ...(error
              ? {
                  borderColor: theme.colors.error,
                  ':hover': {
                    borderColor: theme.colors.error,
                  },
                }
              : {}),
            ...(isFocused && error
              ? { boxShadow: `0 0 0 1px ${theme.colors.error}` }
              : {}),
          }),
          menuPortal: base => ({ ...base, zIndex: 3 }),
          menu: base => ({ ...base, zIndex: 3 }),
          multiValue: base => ({
            ...base,
            padding: '4px 0 4px 4px',
            backgroundColor: theme.colors.primary,
            color: theme.colors.white,
            borderRadius: 4,
            alignItems: 'center',
          }),
          multiValueLabel: base => ({
            ...base,
            color: theme.colors.white,
            fontSize: 12,
            padding: 0,
          }),
          multiValueRemove: base => {
            return {
              ...base,
              cursor: 'pointer',
              ':hover': { backgroundColor: 'none', opacity: 0.8 },
            };
          },
          singleValue: (base, { isDisabled }) => ({
            ...base,
            fontSize: 14,
            color: isDisabled ? theme.colors.textSecondary : theme.colors.text,
          }),
          valueContainer: (base, { isMulti }) => ({
            ...base,
            ...(isMulti ? { overflowY: 'auto', maxHeight: 150 } : {}),
          }),
          option: (base, { isSelected, isFocused, isDisabled }) => {
            let color = theme.colors.text;
            let backgroundColor =
              isSelected || isFocused ? theme.colors.blues[0] : 'none';
            if (isDisabled) {
              // eslint-disable-next-line prefer-destructuring
              color = theme.colors.greys[4];
              // eslint-disable-next-line prefer-destructuring
              backgroundColor = theme.colors.greys[1];
            }
            const hoverStyle = {
              backgroundColor:
                isSelected || isFocused || isDisabled
                  ? 'none'
                  : theme.colors.blues[0],
            };

            return {
              ...base,
              color,
              backgroundColor,
              fontSize: theme.fontSizes[1],
              '&:hover': hoverStyle,
              '&:active': hoverStyle,
            };
          },
          ...styles,
        }}
      />
    );
  },
);

BaseSelect.propTypes = {
  // eslint-disable-next-line react/require-default-props
  styles: PropTypes.objectOf(PropTypes.func),
  // eslint-disable-next-line react/require-default-props
  components: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  ),
  error: PropTypes.bool,
};

BaseSelect.defaultProps = {
  error: false,
};

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <FilledClose color="currentColor" fontSize={18} />
    </components.MultiValueRemove>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';

import { Typography } from '../atoms';

const Circle = styled.span`
  visibility: hidden;
  width: 12px;
  height: 12px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.textSecondary : theme.colors.primary};
  border-radius: 50%;
  flex-shrink: 0;
`;

const IconContainer = styled(Flex)`
  width: 18px;
  height: 18px;
  border-radius: 2px;
`;

const Input = styled.input`
  opacity: 0;
  z-index: -1;
  position: absolute;
  &:checked:not(:disabled) + ${IconContainer} {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:checked + ${IconContainer} > ${Circle} {
    visibility: visible;
  }
  &:focus + ${IconContainer} {
    border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  }
  &:active:not(:disabled) + ${IconContainer} {
    border-color: ${({ theme }) => theme.colors.text};
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fontFamily};
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  ${IconContainer} {
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.textSecondary};
    border-radius: 50%;
  }
  &:hover ${IconContainer} {
    border-color: ${({ theme, disabled, checked }) =>
      !disabled && !checked && theme.colors.text};
  }
`;

export const Radio = React.forwardRef(
  (
    { checked, disabled, label, name, onChange, tabIndex, readOnly, value },
    ref,
  ) => {
    return (
      <Label disabled={disabled}>
        <Input
          type="radio"
          disabled={disabled}
          name={name}
          tabIndex={tabIndex}
          checked={checked}
          onChange={onChange}
          ref={ref}
          readOnly={readOnly}
          value={value}
        />
        <IconContainer margin={2} alignItems="center" justifyContent="center">
          <Circle disabled={disabled} />
        </IconContainer>
        {label && (
          <Typography color="text" variant="body2">
            {label}
          </Typography>
        )}
      </Label>
    );
  },
);

Radio.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

Radio.defaultProps = {
  checked: false,
  disabled: false,
  label: null,
  name: '',
  readOnly: false,
  tabIndex: 0,
};

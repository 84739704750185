export const FileBrowserTypes = {
  FILE: 'file',
  FOLDER: 'folder',
};

export const FileBrowserCategories = {
  SELF: 'self',
  COMPANY: 'company',
  FAVORITES: 'favorites',
  SHARED_WITH_ME: 'shared_with_me',
  SHARED_BY_ME: 'shared_by_me',
};

export const FileUploaderTypes = {
  UPLOADER: 'uploader',
};

export const FileEntityAliases = {
  [FileBrowserTypes.FILE]: 'documents',
  [FileBrowserTypes.FOLDER]: 'directories',
};

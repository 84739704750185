import styled from 'styled-components';
import { Flex } from 'rebass';

export const HighlightBox = styled(Flex).attrs(({ highlighted, hovered }) => ({
  'data-highlighted': highlighted,
  'data-hovered': hovered,
}))`
  &[data-highlighted='true'] {
    background-color: ${({ theme }) => theme.colors.greens[0]};
  }
  &[data-hovered='true'] {
    background-color: ${({ theme }) => theme.colors.greens[1]};
  }
`;

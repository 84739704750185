import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';

import {
  BlockHead,
  Typography,
  Avatar,
  Button,
  ConfirmationDialog,
} from '@oca/ui';

import { AccessControl, LocaleSelect } from '@features/common';
import { Person, Edit, PersonDelete, Key, Earth } from '@oca/icons';
import { Trans } from '@lingui/macro';
import { history } from '@lib/routing';

import profileBackground from '../assets/profile-bg.jpg';

const PositionText = (department, position) => {
  switch (position) {
    case 'director':
      return <Trans>Director</Trans>;
    case 'manager':
      return <Trans>{department} department manager</Trans>;
    default:
      return <Trans>{department} expert</Trans>;
  }
};

export function ProfileHeader({
  children,
  profile,
  profilePath,
  onChangePassword,
  onTerminate,
  viewerId,
}) {
  return (
    <BlockHead
      px={0}
      alignItems="stretch"
      background={`url(${profileBackground}) no-repeat`}
      minHeight={300}
    >
      <Wrapper
        width={1}
        paddingTop={3}
        alignItems="flex-start"
        justifyContent="flex-end"
        flexDirection="column"
      >
        <Flex px={4} width={1} alignItems="center">
          <Box mr={4} mb={[25, -28]} style={{ zIndex: 1 }}>
            <Avatar src={profile.avatar} borderRadius="6px" size={200}>
              <Person />
            </Avatar>
          </Box>
          <Box width={1}>
            <Typography variant="h1" color="white" gutterBottom>
              {profile.fullName}
            </Typography>
            <Flex>
              <PositionHandler>
                {PositionText(profile.department.name, profile.position)}
              </PositionHandler>
            </Flex>
            {profile.position !== 'director' && (
              <Typography variant="h3" fontWeight={300} color="white">
                {profile.jobTitle}
              </Typography>
            )}
            <Flex marginTop={3} justifyContent="space-between">
              <Flex>
                <AccessControl allowed={['admin']}>
                  <Button
                    mr={3}
                    variant="primary"
                    onClick={() => history.push(`${profilePath}/edit`)}
                  >
                    <Edit />
                    <Trans>Edit</Trans>
                  </Button>
                  {viewerId !== profile.id && (
                    <ConfirmationDialog
                      title={<Trans>Terminate {profile.fullName}?</Trans>}
                      description={
                        <Trans>
                          This employee will be marked as terminated and will
                          disappear from employee list.
                        </Trans>
                      }
                      confirmText={<Trans>Terminate</Trans>}
                    >
                      {confirm => (
                        <Button
                          variant="danger"
                          onClick={confirm(onTerminate)}
                          disabled={profile.status === 'terminate'}
                        >
                          <PersonDelete /> <Trans>Terminate</Trans>
                        </Button>
                      )}
                    </ConfirmationDialog>
                  )}
                </AccessControl>
                {viewerId === profile.id && (
                  <Button mr={3} variant="primary" onClick={onChangePassword}>
                    <Key />
                    <Trans>Change password</Trans>
                  </Button>
                )}
              </Flex>
              <Flex alignItems="center" width={2 / 12}>
                <Box mr={1}>
                  <Earth fontSize="32px" color="white" />
                </Box>
                <LocaleSelect />
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <Box width={1} py={3} paddingLeft={[2, 250]} bg="white">
          {children}
        </Box>
      </Wrapper>
    </BlockHead>
  );
}

ProfileHeader.propTypes = {
  children: PropTypes.node,
  profile: PropTypes.shape({
    fullName: PropTypes.string,
    avatar: PropTypes.string,
    department: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  profilePath: PropTypes.string.isRequired,
  onTerminate: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  viewerId: PropTypes.number.isRequired,
};

ProfileHeader.defaultProps = {
  children: null,
};

const Wrapper = styled(Flex)`
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.6) 35%,
    rgba(0, 0, 0, 0.45) 100%
  );
`;

const PositionHandler = styled(Flex)`
  background-color: #ffcd00;
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  padding: 5px 10px;
  margin: 10px 0;
`;

import { createEvent, createStore, combine } from 'effector';
import { getWeekRange, getMonthRange } from '@lib/help-fns';

const rangeTransformMap = {
  week: getWeekRange,
  month: getMonthRange,
};

export function createMeetingsFilters({
  mode: initialMode = 'week',
  date: initialDate = new Date(),
  type: initialType = 'all',
  resetEvents = [],
} = {}) {
  const setMode = createEvent();
  const setDate = createEvent();
  const setType = createEvent();

  const $date = createStore(initialDate);
  const $mode = createStore(initialMode);
  const $type = createStore(initialType);

  const $range = combine(
    {
      mode: $mode,
      date: $date,
    },
    ({ mode, date }) => {
      const transform = rangeTransformMap[mode] || rangeTransformMap.week;

      return transform(date);
    },
  );

  $mode.on(setMode, (_, nextMode) => nextMode).reset(...resetEvents);
  $date.on(setDate, (_, date) => date).reset(...resetEvents);
  $type.on(setType, (_, type) => type).reset(...resetEvents);

  return {
    $mode,
    $range,
    $date,
    $type,
    setMode,
    setDate,
    setType,
  };
}

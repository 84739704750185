import React from 'react';
import PropTypes from 'prop-types';
import ReactTable, { ReactTableDefaults } from 'react-table-6';
import { Flex } from 'rebass';
import { useStore, useStoreMap } from 'effector-react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';

import { useKey } from 'react-use';

import { Checkbox } from '@oca/ui';
import { isEscape } from '@lib/help-fns';
import { useFileDrop } from '../lib/use-file-drop';
import { HighlightBox } from '../atoms';
import {
  toggleSelectAllFiles,
  toggleSelectFile,
  clearSelectedFiles,
  moveEntityTo,
} from '../model/file-browser.events';
import {
  $files,
  $entityMoveConfirmationDialogOpen,
} from '../model/file-browser.view';
import { FileBrowser } from './file-browser';
import { isFolder } from '../lib/helpers';
import { FileBrowserDndPreview } from './file-browser-dnd-preview';
import { FileMoveConfirmation } from './file-move-confirmation';

export function FileBrowserDND(props) {
  const columns = useColumnsWithCheckbox(props.columns);
  const tableRef = React.useRef(null);
  const getAdditionalProps = React.useCallback(
    (_, rowInfo) => ({
      id: rowInfo ? rowInfo.original.id : null,
      instance: tableRef.current,
    }),
    [],
  );

  return (
    <FileBrowserWrapper>
      <FileBrowser
        {...props}
        className="-highlight"
        ref={tableRef}
        columns={columns}
        TrComponent={DraggableTableRow}
        getTrProps={getAdditionalProps}
        getTdProps={getAdditionalProps}
      />
      <FileBrowserDndPreview />
      <FileMoveConfirmation />
    </FileBrowserWrapper>
  );
}

FileBrowserDND.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

FileBrowserDND.defaultProps = {
  loading: false,
  columns: [],
};

function FileBrowserWrapper(props) {
  const ref = useElementLeave(clearSelectedFiles);

  return <div ref={ref} {...props} />;
}

const DraggableTableRow = React.memo(function DraggableTableRow({
  id,
  className,
  instance,
  ...props
}) {
  const { item, drag, preview } = useDraggableItem(id);
  const handleSelect = React.useCallback(
    e =>
      toggleSelectFile({
        id,
        shiftKeyPressed: e.shiftKey,
        ctrlKeyPressed: e.ctrlKey || e.metaKey,
        sortedData: instance ? instance.state.sortedData : [],
        isRow: true,
      }),
    [id, instance],
  );
  const cn = `${className} -no-text-selection`;
  const RowComponent = isFolder(item.type)
    ? DroppableRow
    : ReactTableDefaults.TrComponent;

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      ref={id ? drag : undefined}
      bg={item.selected ? 'blues.0' : 'transparent'}
    >
      <RowComponent
        id={id}
        className={cn}
        selected={item.selected}
        onClick={id ? handleSelect : undefined}
        {...props}
      />
    </Flex>
  );
});

DraggableTableRow.propTypes = {
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  instance: PropTypes.instanceOf(ReactTable),
};

function DroppableRow({ id, selected, ...props }) {
  const [{ highlighted, hovered }, ref] = useFileDrop(id, {
    canDrop: item => item.id !== id && !selected,
    drop: () => {
      moveEntityTo(id);
    },
  });

  return (
    <HighlightBox
      width={1}
      ref={ref}
      highlighted={highlighted}
      hovered={hovered}
    >
      <ReactTableDefaults.TrComponent {...props} />
    </HighlightBox>
  );
}

DroppableRow.propTypes = {
  id: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
};

function SelectCheckbox({ id, sortedData }) {
  const checked = useStoreMap({
    store: $files,
    keys: [id],
    fn: ({ selected }, [itemId]) => selected.has(itemId),
  });

  return (
    <Checkbox
      checked={checked}
      onChange={() => undefined}
      onClick={event => {
        event.stopPropagation();

        toggleSelectFile({
          id,
          sortedData,
          isRow: false,
          shiftKeyPressed: event.shiftKey,
        });
      }}
      value={String(id)}
    />
  );
}

SelectCheckbox.propTypes = {
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.number,
  sortedData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
};

SelectCheckbox.defaultProps = {
  sortedData: [],
};

function SelectAllCheckbox(props) {
  const { list, selected, isAllSelected } = useStore($files);
  const handleSelect = React.useCallback(() => {
    const ids = isAllSelected ? [] : list.map(item => item.id);
    toggleSelectAllFiles(ids);
  }, [isAllSelected, list]);

  let indeterminate = false;

  if (list.length && selected.size > 0) {
    indeterminate = list.length > selected.size;
  }

  return (
    <Checkbox
      checked={indeterminate || isAllSelected}
      indeterminate={indeterminate}
      onChange={handleSelect}
      value="all"
      disabled={props.data.length < 1}
    />
  );
}

SelectAllCheckbox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const emptyType = { type: 'empty' };

function useDraggableItem(id) {
  const item = useStoreMap({
    store: $files,
    keys: [id],
    fn: ({ list, selected }, [itemId]) => {
      const candidate = list.find(v => v.id === itemId);

      if (candidate) {
        return { ...candidate, selected: selected.has(itemId) };
      }
      return emptyType;
    },
  });

  const [_, drag, preview] = useDrag({
    item,
    begin: () => {
      if (!item.selected) {
        toggleSelectFile({
          id,
          shiftKeyPressed: false,
        });
      }
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return { item, drag, preview };
}

function useColumnsWithCheckbox(columns) {
  const augmented = React.useMemo(
    () => [
      {
        id: '',
        accessor: 'id',
        width: 50,
        sortable: false,
        Header: SelectAllCheckbox,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: id, tdProps }) => {
          const sortedData = tdProps.rest.instance
            ? tdProps.rest.instance.state.sortedData
            : [];

          return <SelectCheckbox id={id} sortedData={sortedData} />;
        },
      },
      ...columns,
    ],
    [columns],
  );
  return augmented;
}

function useElementLeave(cb) {
  const open = useStore($entityMoveConfirmationDialogOpen);
  const ref = React.useRef(null);
  const handleCallback = () => {
    if (cb && !open) {
      cb();
    }
  };

  useKey(isEscape, handleCallback);

  return ref;
}

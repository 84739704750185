import React from 'react';
import PropTypes from 'prop-types';

export function ReactTablePagination(props) {
  const { canNext, canPrevious, page, onPageChange, pages, noDataText } = props;
  const { Container } = props.components;

  if (pages < 2) return '';

  if (pages === 0 && noDataText) {
    return <div>{noDataText}</div>;
  }

  const countOfShownItems = 5;

  const paginateTo = pageNumber => {
    return () => {
      onPageChange(pageNumber);
    };
  };

  const renderFirstPage = () => {
    if (page >= countOfShownItems && pages > 11) {
      return (
        <React.Fragment>
          <Container.Item action={paginateTo(0)}>{[1]}</Container.Item>
          <Container.Ellipsis action />
        </React.Fragment>
      );
    }
    return '';
  };

  const renderLastPage = () => {
    if (page < pages - countOfShownItems && pages > 11) {
      return (
        <React.Fragment>
          <Container.Ellipsis action />
          <Container.Item action={paginateTo(pages - 1)}>
            {[pages]}
          </Container.Item>
        </React.Fragment>
      );
    }
    return '';
  };

  const renderPages = () => {
    const paginationItems = [];

    let start = 0;
    if (page >= countOfShownItems) {
      start = page - 2;
    }
    if (page > pages - countOfShownItems) {
      start = pages - countOfShownItems - 2;
    }

    let end = pages - 1;
    if (page < pages - countOfShownItems) {
      end = page + 2;
    }
    if (page < countOfShownItems) {
      end = countOfShownItems + 1;
    }

    if (pages <= 11) {
      start = 0;
      end = pages - 1;
    }

    for (let i = start; i <= end; i += 1) {
      paginationItems.push(i);
    }

    return paginationItems.map(el => {
      return (
        <Container.Item
          key={Math.random()}
          action={paginateTo(el)}
          active={el === page}
        >
          {[el + 1]}
        </Container.Item>
      );
    });
  };

  return (
    <Container>
      <Container.Prev disabled={canPrevious} action={paginateTo(page - 1)} />
      {renderFirstPage()}
      {renderPages()}
      {renderLastPage()}
      <Container.Next disabled={canNext} action={paginateTo(page + 1)} />
    </Container>
  );
}

ReactTablePagination.propTypes = {
  components: PropTypes.objectOf(PropTypes.any).isRequired,
  canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

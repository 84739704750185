import { useEffect, useReducer, useRef, useCallback } from 'react';
import Axios from 'axios';

import { createFormData } from '@lib/create-form-data';
import { uploadFile } from '../api';

const initialState = {
  isUploading: false,
  processing: {},
  result: [],
};

/**
 *
 * @typedef {Object} Processing
 * @property {number} progress
 * @property {'loading'|'done'|'fail'} status
 * @property {Object | string} error
 */
/**
 * Hook for uploading files to server, supports cancellation
 * @param {Object} props
 * @param {Function} [props.onUpload]
 * @param {Function} [props.onComplete]
 * @returns {{ isUploading: Boolean, result: Array<{ file: string, originalName: string }>, processing: Object<string, Processing> }}
 */
export function useFileUploading({ onComplete } = {}) {
  const [state, dispatch] = useReducer(fileUploadReducer, initialState);
  const { result, processing, isUploading } = state;
  const cancelMap = useRef({});

  useEffect(() => {
    // Abort all requests on unmount
    return () => {
      if (isUploading && cancelMap.current) {
        handleUploadCancel(Object.keys(processing));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadCancel = ids => {
    if (cancelMap.current) {
      Object.entries(cancelMap.current).forEach(([key, cancelFn]) => {
        if (ids.includes(key)) {
          cancelFn('Cancelled by user');
        }
      });
    }
  };

  const handleUpload = useCallback(
    files => {
      dispatch({ type: 'uploading_start' });

      const uploadList = files.map(file => {
        dispatch({ type: 'file_upload_start', payload: { id: file.name } });
        const { cancel, token } = Axios.CancelToken.source();
        cancelMap.current[file.name] = cancel;

        return processUploading({
          file,
          dispatch,
          cancelToken: token,
        });
      });

      return Promise.all(uploadList).finally(() => {
        cancelMap.current = {};
        if (onComplete) {
          onComplete();
        }
        dispatch({ type: 'uploading_done' });
      });
    },
    [onComplete],
  );

  return {
    result,
    processing,
    isUploading,
    onUpload: handleUpload,
    onCancelUploads: handleUploadCancel,
  };
}

/**
 * @param {Object} props
 * @param {File} [props.file]
 * @param {import('axios').CancelToken} [props.cancelToken]
 * @param {Function} [props.dispatch]
 * @returns {Promise}
 */
async function processUploading({ file, cancelToken, dispatch }) {
  const formData = createFormData({ file });
  const config = {
    cancelToken,
    onUploadProgress: event => {
      dispatch({
        type: 'update_file_uploading_progress',
        payload: {
          id: file.name,
          progress: Math.round((event.loaded * 100) / event.total),
        },
      });
    },
  };
  try {
    const { data } = await uploadFile(formData, config);

    dispatch({
      type: 'file_upload_done',
      payload: {
        id: file.name,
        data: { ...data, originalName: file.name, temp: true },
      },
    });
    return Promise.resolve(data);
  } catch (err) {
    let errorText = err.message;

    if (err.response) {
      errorText = err.response.data;
    }

    dispatch({
      type: 'file_upload_fail',
      payload: { id: file.name, error: errorText },
    });

    return err;
  }
}

function fileUploadReducer(state, { type, payload } = {}) {
  switch (type) {
    case 'uploading_start':
      return { ...state, isUploading: true, result: [] };
    case 'uploading_done':
      return { ...state, isUploading: false };
    case 'update_file_uploading_progress': {
      const { id, progress } = payload;
      return {
        ...state,
        processing: {
          ...state.processing,
          [id]: {
            ...state.processing[id],
            progress,
          },
        },
      };
    }
    case 'file_upload_start': {
      const { id } = payload;
      return {
        ...state,
        processing: {
          ...state.processing,
          [id]: { progress: 0, error: null, status: 'loading' },
        },
      };
    }
    case 'file_upload_fail': {
      const { id, error } = payload;
      return {
        ...state,
        processing: {
          ...state.processing,
          [id]: { progress: 0, error, status: 'fail' },
        },
      };
    }
    case 'file_upload_done': {
      const { id, data } = payload;
      return {
        ...state,
        result: state.result.concat([data]),
        processing: {
          ...state.processing,
          [id]: { progress: 100, error: null, status: 'done' },
        },
      };
    }
    default:
      return state;
  }
}

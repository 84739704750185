import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';

/**
 *
 * @typedef {{ children: import('react').ReactNode, position: 'start'|'end' } & import('rebass').FlexProps} InputAdornmentProps
 */
export const InputAdornment = React.forwardRef(
  /**
   *
   * @param {InputAdornmentProps} props
   * @param {React.Ref} ref
   */
  function InputAdornment(props, ref) {
    const { children, position, ...rest } = props;
    return (
      <Adornment
        ref={ref}
        {...rest}
        ml={position === 'end' ? 1 : 0}
        mr={position === 'start' ? 1 : 0}
      >
        {children}
      </Adornment>
    );
  },
);

InputAdornment.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['start', 'end']).isRequired,
};

const Adornment = styled(Flex)`
  height: 0.01em;
  max-height: 2em;
  align-items: center;
`;

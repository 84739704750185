import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'effector-react';
import { Flex, Box, Text } from 'rebass';
import styled from 'styled-components';
import { t } from '@lingui/macro';

import { i18n } from '@lib/i18n';
import { IconButton } from '@oca/ui';
import { Send, CloseX, Attachment } from '@oca/icons';
import { useDropzone } from 'react-dropzone';
import useList from 'react-use/lib/useList';

import {
  $editingMessage,
  resetCommentText,
  updateCommentRequesting,
} from '../model/comment-flow';
import { RejectedFileList } from '../../../molecules';
import { CommentAttachments } from '../molecules/comment-attachments';

/**
 *
 * @param {{onSubmit: () => Promise }}
 */
export const CommentForm = ({
  id,
  onSubmit,
  submitStatus,
  onUpdate,
  ...props
}) => {
  const editingMessage = useStore($editingMessage);
  const { isLoading } = useStore(submitStatus);

  const [commentary, setCommentary] = React.useState('');
  const [rejected, { set: setRejected, remove: removeRejected }] = useList([]);
  const [
    attachments,
    { set: setAttachments, remove: removeAttachment },
  ] = useList([]);

  const inputRef = React.useRef(null);
  const { open, acceptedFiles, getInputProps } = useDropzone({
    onDropRejected: setRejected,
    maxSize: 50 * 1024 * 1024,
  });

  const handleDeleteAttachment = (val, idx) => removeAttachment(idx);
  const handleAttachmentUploading = React.useCallback(
    uploaded => {
      const filtered = uploaded.filter(i => !attachments.includes(i));

      if (filtered.length > 0) {
        setAttachments(attachments.concat(filtered));
      }
    },
    [attachments, setAttachments],
  );

  React.useEffect(() => {
    setCommentary('');
    resetCommentText();
  }, []);

  React.useEffect(() => {
    if (editingMessage) {
      setCommentary(editingMessage.text);
      setAttachments(editingMessage.attachmentFiles);
      inputRef.current.focus();
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setCommentary('');
      setAttachments([]);
    }
  }, [editingMessage, inputRef, setAttachments]);

  const handleCommentSubmit = async e => {
    e.preventDefault();
    const data = {
      text: commentary,
      ...(editingMessage
        ? {
            nextAttachments: attachments,
            prevAttachments: editingMessage.attachmentFiles,
          }
        : { attachmentFiles: attachments }),
    };
    (editingMessage
      ? updateCommentRequesting({ id: editingMessage.id, data })
      : onSubmit({ id, data })
    )
      .finally(() => {
        setCommentary('');
        setAttachments([]);
        resetCommentText();
      })
      .catch(result => console.log('Unable to edit', result));
  };

  return (
    <FormWrap onSubmit={handleCommentSubmit} {...props}>
      <input type="text" {...getInputProps()} />
      <Flex alignItems="center">
        <InputWrap>
          {editingMessage && (
            <EditingContainer>
              <EditingMessage>{editingMessage.text}</EditingMessage>
              <IconButton
                color="white"
                size="small"
                padding="5px"
                onClick={resetCommentText}
              >
                <CloseX />
              </IconButton>
            </EditingContainer>
          )}
          <TextInput
            name="text"
            value={commentary}
            placeholder={i18n._(t`Type your comment...`)}
            onChange={e => setCommentary(e.target.value)}
            autoComplete="off"
            ref={inputRef}
            required
          />
          <AttachmentWrapper>
            <IconButton color="textSecondary" padding="6px" onClick={open}>
              <Attachment />
            </IconButton>
          </AttachmentWrapper>
        </InputWrap>
        <Box>
          <IconButton
            type="submit"
            padding={8}
            disabled={
              (commentary === '' && attachments.length === 0) || isLoading
            }
            color="primary"
          >
            <Send />
          </IconButton>
        </Box>
      </Flex>
      {rejected && rejected.length > 0 && (
        <RejectedFileList
          items={rejected}
          onDelete={(name, idx) => removeRejected(idx)}
          validation={{ maxSize: 50 * 1024 * 1024 }}
        />
      )}
      <CommentAttachments
        accepted={acceptedFiles}
        list={attachments}
        onDelete={handleDeleteAttachment}
        onUpload={handleAttachmentUploading}
      />
    </FormWrap>
  );
};
CommentForm.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitStatus: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  onUpdate: PropTypes.func,
};

const FormWrap = styled.form`
  width: 100%;
  flex: 1;
`;

const InputWrap = styled(Box)`
  flex: 1;
  margin-right: 10px;
  position: relative;
`;

const TextInput = styled.input`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px 40px 10px 20px;
  width: 100%;
  outline: none;
`;

const EditingContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 5px));
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  padding: 5px 10px;
  align-items: center;
`;

const EditingMessage = styled(Text)`
  flex: 1;
  color: white;
  padding: 0 10px;
  font-size: 14px;
`;

const AttachmentWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  & svg {
    transform: rotate(-45deg);
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

export class DebouncingValidatingField extends React.Component {
  static propTypes = {
    timeout: PropTypes.number,
  };

  static defaultProps = {
    timeout: 500,
  };

  validate = (value, values, fieldState) => {
    if (fieldState.active) {
      return new Promise(resolve => {
        if (this.clearTimeout) this.clearTimeout();
        const timerId = setTimeout(() => {
          // eslint-disable-next-line react/prop-types
          resolve(this.props.validate(value, values, fieldState));
        }, this.props.timeout);
        this.clearTimeout = () => {
          clearTimeout(timerId);
          resolve();
        };
      });
    }
    // eslint-disable-next-line react/prop-types
    return this.props.validate(value, values, fieldState);
  };

  render() {
    return <Field {...this.props} validate={this.validate} />;
  }
}

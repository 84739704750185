/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import { overflow } from 'styled-system';

import { Typography } from '../atoms/typography';

export const ListItem = React.forwardRef(function ListItem(props, ref) {
  const {
    actions,
    alignItems,
    avatar,
    children,
    divider,
    textContainerOverflow,
    ...rest
  } = props;

  return (
    <ListItemContainer
      divider={divider}
      {...rest}
      alignItems={alignItems}
      ref={ref}
    >
      {avatar && (
        <ListItemAvatar mt={alignItems === 'flex-start' ? 2 : 0} mr={1}>
          {avatar}
        </ListItemAvatar>
      )}
      <ListItemTextContainer
        flex="1 1 auto"
        overflow={textContainerOverflow ? 'hidden' : undefined}
      >
        {children}
      </ListItemTextContainer>
      {actions && (
        <ListItemActions>
          {React.Children.map(actions, (action, index) => (
            <li key={index}>{action}</li>
          ))}
        </ListItemActions>
      )}
    </ListItemContainer>
  );
});

ListItem.propTypes = {
  ...Flex.propTypes,
  actions: PropTypes.node,
  avatar: PropTypes.node,
  divider: PropTypes.bool,
  textContainerOverflow: PropTypes.bool,
};
ListItem.defaultProps = {
  as: 'li',
  alignItems: 'center',
  py: 2,
  actions: null,
  avatar: null,
  divider: false,
  textContainerOverflow: false,
};

// eslint-disable-next-line react/no-multi-comp
const ListItemText = React.forwardRef(function ListItemText(props, ref) {
  const { children, color, variant, ...rest } = props;
  const primaryVariant = variant === 'primary' ? 'body1' : 'body2';
  const primaryColor = variant === 'primary' ? 'text' : 'textSecondary';
  return (
    <Typography
      variant={primaryVariant}
      color={color || primaryColor}
      ref={ref}
      {...rest}
    >
      {children}
    </Typography>
  );
});

ListItemText.propTypes = {
  ...Typography.propTypes,
  variant: PropTypes.string,
};

ListItemText.defaultProps = {
  variant: 'primary',
};

ListItem.Text = ListItemText;

const ListItemContainer = styled(Flex).attrs(({ divider }) => ({
  'data-divider': divider,
}))`
  outline: none;
  &[data-divider='true'] {
    border-bottom: 1px solid ${({ theme }) => theme.colors.disabled};
  }
`;

const ListItemAvatar = styled(Box)`
  min-width: 56px;
  flex-shrink: 0;
`;

const ListItemTextContainer = styled(Box)`
  ${overflow};
`;

const ListItemActions = styled.ul`
  flex: 0 0 auto;
  align-self: center;
  margin-left: 24px;
  padding: 0;
  font-size: 0;
  list-style: none;
  & > li {
    position: relative;
    display: inline-block;
    padding: 0 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
    &:first-child {
      padding-left: 0;
    }
  }
`;

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Flac = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#F7B84E"
    />
    <path
      d="M3.78444 14.6296V15.5829H5.31369C5.52969 15.5829 5.74569 15.7989 5.74569 16.0089C5.74569 16.2069 5.52969 16.3681 5.31369 16.3681H3.78444V17.6274C3.78444 17.8374 3.63519 17.9994 3.42519 17.9994C3.16119 17.9994 2.99994 17.8374 2.99994 17.6274V14.2216C2.99994 14.0056 3.16194 13.8496 3.42519 13.8496H5.53044C5.79444 13.8496 5.95044 14.0056 5.95044 14.2216C5.95044 14.4136 5.79444 14.6289 5.53044 14.6289H3.78444V14.6296Z"
      fill="white"
    />
    <path
      d="M6.54999 14.2104C6.54999 14.0124 6.71799 13.8444 6.92799 13.8444C7.11999 13.8444 7.28199 14.0131 7.28199 14.2104V17.2149H8.91324C9.50049 17.2149 9.51324 18.0001 8.91324 18.0001H6.92874C6.71874 18.0001 6.55074 17.8509 6.55074 17.6341V14.2104H6.54999Z"
      fill="white"
    />
    <path
      d="M10.2399 18.0023C10.0479 17.8936 9.92786 17.6784 10.0479 17.4264L11.7691 14.0626C11.9311 13.7446 12.3624 13.7326 12.5124 14.0626L14.2036 17.4264C14.4496 17.8936 13.7236 18.2656 13.5076 17.7976L13.2436 17.2704H11.0311L10.7739 17.7984C10.6779 18.0024 10.4559 18.0444 10.2399 18.0023ZM12.8184 16.4791L12.1404 14.9979L11.4031 16.4791H12.8184Z"
      fill="white"
    />
    <path
      d="M14.55 15.8948C14.55 14.7376 15.276 13.7423 16.6552 13.7423C17.1772 13.7423 17.5912 13.8983 18.0285 14.2763C18.1905 14.4256 18.2085 14.6896 18.0465 14.8576C17.8845 15.0008 17.6385 14.9836 17.4945 14.8396C17.2492 14.5816 16.9972 14.5103 16.6552 14.5103C15.732 14.5103 15.2887 15.1643 15.2887 15.8956C15.2887 16.6396 15.726 17.3228 16.6552 17.3228C16.9972 17.3228 17.3152 17.1841 17.5912 16.9388C17.7765 16.7948 18.0345 16.8661 18.1425 17.0108C18.2385 17.1428 18.2857 17.3648 18.0765 17.5741C17.6565 17.9648 17.1532 18.0428 16.6545 18.0428C15.204 18.0428 14.55 17.0521 14.55 15.8948Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

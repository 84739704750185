import { useDrop } from 'react-dnd';
import { FileBrowserTypes } from '../constants';

/**
 *
 * @param {number} id current draggable item id
 * @param {import('react-dnd').DropTargetHookSpec} options React DND drop options
 */
export function useFileDrop(id, options = {}) {
  return useDrop({
    accept: Object.values(FileBrowserTypes),
    canDrop: item => item.parentId !== id,
    collect: monitor => ({
      highlighted: monitor.canDrop(),
      hovered: monitor.canDrop() && monitor.isOver(),
    }),
    ...options,
  });
}

import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, Field } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';

import { createComponent } from 'effector-react';

import { Button, Checkbox, Typography, Link } from '@oca/ui';
import { i18n } from '@lib/i18n';

import { FORM_ERROR } from 'final-form';
import { getDeviceId } from '@lib/device-id';
import { resolveRejectedPromise } from '@lib/help-fns';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';
import { emailRegex } from '@lib/regex';
import { $loginFetching, loginRequesting } from '../model/auth';
import { $isTokenPersistent, setTokenPersistence } from '../model/token';

export const LoginForm = createComponent(
  { fetching: $loginFetching, isTokenPersistent: $isTokenPersistent },
  (_, { fetching, isTokenPersistent }) => {
    return (
      <Form
        validate={validate}
        onSubmit={handleLoginSubmit}
        render={({ handleSubmit, submitError }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Field
                name="email"
                label={<Trans>Email</Trans>}
                component={TextFieldWrapper}
                placeholder={i18n._(t`Example: j.smith@oca.com`)}
                helperText=" "
                autoComplete="nope"
                required
                fullWidth
                spaceAfter
              />
              <Field
                name="password"
                type="password"
                component={TextFieldWrapper}
                helperText=" "
                label={<Trans>Password</Trans>}
                required
                spaceAfter
                fullWidth
              />
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Checkbox
                    checked={isTokenPersistent}
                    onChange={e => {
                      setTokenPersistence(!isTokenPersistent);
                    }}
                    label={<Trans>Remember me</Trans>}
                  />
                </Box>
                <Link as={RouterLink} to="/forgot-password">
                  <Trans>Forgot password?</Trans>
                </Link>
              </Flex>
              <Box my={3}>
                {submitError && (
                  <Box p={2}>
                    <Typography variant="caption" color="error">
                      {submitError}
                    </Typography>
                  </Box>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  disabled={fetching.isLoading}
                >
                  <Trans>Login</Trans>
                </Button>
              </Box>
            </form>
          );
        }}
      />
    );
  },
);

async function handleLoginSubmit({ email, password }) {
  const error = await resolveRejectedPromise(
    loginRequesting({ email, password, device_id: getDeviceId() }),
  );

  if (error) {
    if (error.message || error.data.nonFieldErrors) {
      return {
        [FORM_ERROR]:
          error.message ||
          (error.data.nonFieldErrors && (
            <Trans>Email not found or password is wrong</Trans>
          )),
      };
    }

    return error.data;
  }
  return null;
}

function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = <Trans>This is required field</Trans>;
  }
  if (values.email && !emailRegex().test(values.email)) {
    errors.email = <Trans>Please enter valid email address</Trans>;
  }

  if (!values.password) {
    errors.password = <Trans>This field is required</Trans>;
  }
  return errors;
}

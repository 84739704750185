import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { Trans } from '@lingui/macro';
import { useStore } from 'effector-react';
import styled from 'styled-components';

import { statuses, priorities } from '@features/tasks';
import { TaskCard, Typography, Spinner, Button } from '@oca/ui';
import { $location } from '@lib/routing';
import { Add } from '@oca/icons';
import { useToggle } from 'react-use';
import { $projectList } from '@features/common';

import { $tasksByStatus, $taskListFetching } from '../model/project-view-flow';
import { CreateProjectTask } from './create-project-task';

const $hasProjects = $projectList.map(list => list.length > 0);

export const ViewTasksSection = React.memo(function ViewTasksSection({
  isLoading,
  project,
}) {
  const fetching = useStore($taskListFetching);

  return (
    <Wrapper>
      <Box width={1} mx="auto" style={{ minWidth: 1073, minHeight: 'inherit' }}>
        <Statuses paddingBottom={3} list={statuses} />
        <TasksWrapper>
          {(isLoading || fetching.isLoading) && (
            <ProgressWrapper
              justifyContent="center"
              alignItems="center"
              bg="rgba(255, 255, 255, 0.5)"
            >
              <Spinner />
            </ProgressWrapper>
          )}
          {statuses.map((status, idx) => (
            <Box
              width={1 / statuses.length}
              key={status.value}
              padding={2}
              marginRight={1}
              bg="greys.0"
            >
              <TasksColumn status={status.value} />
              {project && idx === 0 && <CreateTask project={project} />}
            </Box>
          ))}
        </TasksWrapper>
      </Box>
    </Wrapper>
  );
});

ViewTasksSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    description: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
};

// eslint-disable-next-line react/no-multi-comp
const Statuses = React.memo(function Statuses({ list = [], ...rest }) {
  return (
    <Flex {...rest}>
      {list.map(status => (
        <Box key={status.value} width={1 / list.length}>
          <Typography fontSize={16}>{status.label}</Typography>
        </Box>
      ))}
    </Flex>
  );
});

Statuses.propTypes = {
  // eslint-disable-next-line react/require-default-props
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.string,
    }),
  ),
};

// eslint-disable-next-line react/no-multi-comp
const TasksColumn = React.memo(function TasksColumn({ status }) {
  const tasksByStatus = useStore($tasksByStatus);
  const location = useStore($location);
  const list = tasksByStatus[status] || [];

  return list.map(task => {
    const { color } =
      priorities.find(priority => priority.value === task.priority) || {};

    return (
      <Box key={task.id} marginBottom={2}>
        <Link
          to={{
            pathname: `/tasks/${task.id}`,
            search: `referrer=${location.pathname}`,
          }}
          style={{ textDecoration: 'none' }}
        >
          <TaskCard
            assignees={task.assigneeEmployees}
            dueDate={task.dueDate}
            priority={task.priority}
            title={task.title}
            color={color}
          />
        </Link>
      </Box>
    );
  });
});

TasksColumn.propTypes = {
  // eslint-disable-next-line react/require-default-props
  status: PropTypes.oneOf(statuses.map(({ value }) => value)),
};

function CreateTask({ project }) {
  const hasProjects = useStore($hasProjects);
  const [on, toggle] = useToggle(false);

  return (
    <React.Fragment>
      <Button
        variant="primary"
        onClick={toggle}
        disabled={!hasProjects}
        fullWidth
      >
        <Add />
        <Trans>Create task</Trans>
      </Button>
      {on && hasProjects && (
        <CreateProjectTask project={project} onClose={toggle} />
      )}
    </React.Fragment>
  );
}

CreateTask.propTypes = {
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    id: PropTypes.number,
  }),
};

const Wrapper = styled(Box)`
  overflow: auto;
  min-height: calc(100vh - 292px);
`;

const TasksWrapper = styled(Flex)`
  position: relative;
  min-height: inherit;
`;

const ProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

import { merge, forward } from 'effector';
import { t } from '@lingui/macro';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { $filesRaw } from './file-browser.stores';
import {
  deleteFolderRequest,
  deleteFileRequest,
  deleteFile,
  deleteFolder,
} from './file-browser.events';
import { transformFiles } from '../lib/helpers';
import * as api from '../api';

const successfulDeleting = merge([
  deleteFolderRequest.done,
  deleteFileRequest.done,
]);

$filesRaw.on(
  successfulDeleting,
  transformFiles((list, { params }) =>
    list.filter(file => file.id !== params.id),
  ),
);

// Delete folder
forward({
  from: deleteFolder,
  to: deleteFolderRequest,
});

// Delete file
forward({
  from: deleteFile,
  to: deleteFileRequest,
});

deleteFolderRequest.done.watch(({ params }) => {
  notify.success(i18n._(t`Folder ${params.name} is deleted successfully`));
});

deleteFolderRequest.fail.watch(({ params, error }) => {
  notify.error(
    i18n._(t`Could not delete folder ${params.name}. ${error.message}`),
  );
});

deleteFileRequest.done.watch(({ params }) => {
  notify.success(i18n._(t`File ${params.name} is deleted successfully`));
});

deleteFileRequest.fail.watch(({ params, error }) => {
  notify.error(
    i18n._(t`Could not delete file ${params.name}. ${error.message}`),
  );
});

deleteFolderRequest.use(api.deleteFolder);
deleteFileRequest.use(api.deleteFile);

import React from 'react';
import { useEvent } from 'react-use';

function getVisibilityPropertyNames() {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== 'undefined') {
    return ['hidden', 'visibilitychange'];
  }

  if (typeof document.msHidden !== 'undefined') {
    return ['msHidden', 'msvisibilitychange'];
  }

  if (typeof document.webkitHidden !== 'undefined') {
    return ['webkitHidden', 'webkitvisibilitychange'];
  }

  return ['hidden', 'visibilitychange'];
}

const [hidden, visibilityChange] = getVisibilityPropertyNames();

function isDocumentHidden() {
  return document[hidden];
}

export function useDocumentVisibility(callback) {
  const onChange = React.useCallback(() => {
    callback(isDocumentHidden());
  }, [callback]);

  useEvent(visibilityChange, onChange, document);
}

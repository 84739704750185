import { merge, forward, guard, sample } from 'effector';
import { t } from '@lingui/macro';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { not, filterObject, mapObject } from '@lib/help-fns';
import {
  $filesRaw,
  $fileRenameId,
  $fileShareId,
  $selectedFileIds,
  $folderMoveId,
} from './file-browser.stores';
import {
  fetchFilesRequest,
  setFavoriteFolderRequest,
  setFavoriteFileRequest,
  updateFileRequest,
  updateFolderRequest,
  uploadFileRequest,
  shareFolderRequest,
  shareFileRequest,
  toggleFavoriteFile,
  toggleFavoriteFolder,
  onFileShareSubmit,
  setSharingFileId,
  setRenamingFileId,
  renameFile,
  onFolderShareSubmit,
  renameFolder,
  onEntityMoveAccept,
  moveFilesToFolderRequest,
} from './file-browser.events';
import { transformFiles } from '../lib/helpers';
import { FileEntityAliases, FileBrowserTypes } from '../constants';
import { toggleOpenEntityShareDialog } from './file-browser.view';
import * as api from '../api';

const successfulEntityUpdate = merge([
  setFavoriteFolderRequest.done,
  setFavoriteFileRequest.done,
  updateFileRequest.done,
  updateFolderRequest.done,
]);

const successfulSharing = merge([
  shareFolderRequest.done,
  shareFileRequest.done,
]);

const failedSharing = merge([shareFolderRequest.fail, shareFileRequest.fail]);

$filesRaw
  .on(fetchFilesRequest.doneData, (_, { data }) => data)
  .on(
    successfulEntityUpdate,
    transformFiles((list, { data }) =>
      list.map(file => (file.id === data.id ? data : file)),
    ),
  )
  .on(
    uploadFileRequest.doneData,
    transformFiles(
      (list, { data }) => list.concat([data]),
      FileEntityAliases[FileBrowserTypes.FILE],
    ),
  )
  .on(
    successfulSharing,
    transformFiles((list, { params, data }) =>
      list.map(item =>
        item.id === params.id
          ? { ...item, users: data.map(({ user }) => user) }
          : item,
      ),
    ),
  )
  .on(moveFilesToFolderRequest.done, (files, { params }) =>
    mapObject(
      (list, key) =>
        key !== 'info'
          ? list.filter(item => {
              const removeIds = params.data[key];
              return !removeIds.includes(item.id);
            })
          : list,
      files,
    ),
  );

$fileRenameId
  .on(setRenamingFileId, (_, id) => id)
  .reset(updateFolderRequest.done, updateFileRequest.done);

$fileShareId
  .on(setSharingFileId, (_, id) => id)
  .reset(updateFolderRequest.done, updateFileRequest.done);

// RENAMING
guard({
  source: renameFile,
  filter: updateFileRequest.pending.map(not),
  target: updateFileRequest,
});

guard({
  source: renameFolder,
  filter: updateFolderRequest.pending.map(not),
  target: updateFolderRequest,
});

// FAVORITE
// Favorite file
guard({
  source: toggleFavoriteFile.map(mapFavorite),
  filter: setFavoriteFileRequest.pending.map(not),
  target: setFavoriteFileRequest,
});

// Favorite folder
guard({
  source: toggleFavoriteFolder.map(mapFavorite),
  filter: setFavoriteFolderRequest.pending.map(not),
  target: setFavoriteFolderRequest,
});

// SHARING
// Share file or folder
forward({
  from: setSharingFileId,
  to: toggleOpenEntityShareDialog,
});

guard({
  source: onFileShareSubmit,
  filter: shareFileRequest.pending.map(not),
  target: shareFileRequest,
});

guard({
  source: onFolderShareSubmit,
  filter: shareFolderRequest.pending.map(not),
  target: shareFolderRequest,
});

forward({
  from: successfulSharing.map(() => false),
  to: toggleOpenEntityShareDialog,
});

// Move entities
sample({
  clock: onEntityMoveAccept,
  source: { selected: $selectedFileIds, id: $folderMoveId, files: $filesRaw },
  fn: transformMovedFiles,
  target: moveFilesToFolderRequest,
});

successfulSharing.watch(({ params }) =>
  notify.success(
    i18n._(
      t`Members of ${params.name} ${params.type} is successfully updated `,
    ),
  ),
);

failedSharing.watch(({ params, error }) =>
  notify.error(
    i18n._(t`Could not share ${params.name} ${params.type}. ${error.message}`),
  ),
);

moveFilesToFolderRequest.done.watch(() =>
  notify.success(i18n._(t`Files moved successfully `)),
);

moveFilesToFolderRequest.fail.watch(({ error }) =>
  notify.error(i18n._(t`Could not move files. ${error.message}`)),
);

function mapFavorite({ id, isFavorite, type }) {
  return { id, isFavorite: !isFavorite, type };
}

function transformMovedFiles({ id, files, selected }) {
  const filtered = filterObject((_, key) => key !== 'info', files);
  const data = mapObject(
    items =>
      items.reduce(
        (acc, item) => (selected.has(item.id) ? acc.concat([item.id]) : acc),
        [],
      ),
    filtered,
  );
  return { id, data };
}

updateFolderRequest.use(api.updateFolder);
setFavoriteFolderRequest.use(api.setFavoriteFolder);
shareFolderRequest.use(api.shareFolder);
shareFileRequest.use(api.shareFile);
updateFileRequest.use(api.updateFile);
setFavoriteFileRequest.use(api.setFavoriteFile);
moveFilesToFolderRequest.use(api.moveFiles);

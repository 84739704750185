import { createEffect, createStore, forward } from 'effector';
import { createGate } from 'effector-react';

import { createFetching } from '@lib/effector-utils';
import { sleep } from '@lib/help-fns';
import { fetchMeeting } from '../api';

export const MeetingViewGate = createGate('Gate for single meeting view');

// Events
export const fetchSingleMeetingRequesting = createEffect({
  name: 'employees list requested',
  handler: async id => {
    await sleep(1000);
    return fetchMeeting(id);
  },
});

// Stores
export const $singleMeetingFetching = createFetching(
  fetchSingleMeetingRequesting,
  { reset: MeetingViewGate.close, status: 'loading' },
);
export const $singleMeeting = createStore({});

// Side Effects
$singleMeeting
  .on(fetchSingleMeetingRequesting.done, (_, { result }) => result.data)
  .reset(MeetingViewGate.close);

forward({
  from: MeetingViewGate.open.map(({ id }) => id),
  to: fetchSingleMeetingRequesting,
});

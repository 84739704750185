import axios from 'axios';
import camelCase from 'camelcase-keys';
import snakeCase from 'snakecase-keys';

import { createAuthRefreshInterceptor } from '@lib/axios-auth-refresh';
import {
  isEmpty,
  isNil,
  getAxiosErrorResponse,
  compose,
  normalizeErrorResponse,
} from '@lib/help-fns';
import { history } from '@lib/routing';
import {
  $accessToken,
  eraseTokens,
  $refreshToken,
  changeTokens,
} from '../../model/token';
import { $locale } from '../../model/i18n';

const processErrors = compose(
  normalizeErrorResponse,
  camelizeResponse,
);
/**
 * Axios meta object constant. Determines whether request data should be transformed to 'snake_case'
 * Default is true
 * @example fetcher.post(url, data, { meta: { [TRANSFORM_REQUEST_TO_SNAKE_CASE]: false } })
 */
export const TRANSFORM_REQUEST_TO_SNAKE_CASE =
  'TRANSFORM_REQUEST_TO_SNAKE_CASE';

/**
 * Axios meta object constant.Prevents from rendering error page at AppErrorHandler
 * Default is true
 * @example fetcher.get(url, { meta: { [SHOW_ERROR_PAGE_ON_FETCH_FAIL]: false } })
 */
export const SHOW_ERROR_PAGE_ON_FETCH_FAIL = 'SHOW_ERROR_PAGE_ON_FETCH_FAIL';

/**
 * Axios meta object constant. Determines whether response data should be transformed to 'snake_case'
 * Default is true
 * @example fetcher.post(url, data, { meta: { [TRANSFORM_RESPONSE_TO_CAMEL_CASE]: false } })
 */
export const TRANSFORM_RESPONSE_TO_CAMEL_CASE =
  'TRANSFORM_RESPONSE_TO_CAMEL_CASE';

export const fetcher = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_PORT}`,
});

createAuthRefreshInterceptor(fetcher, () =>
  refreshToken($refreshToken.getState()),
);

// Convert backend snake_case responses into camelCase
fetcher.interceptors.response.use(
  response => {
    if (response.config.meta[TRANSFORM_RESPONSE_TO_CAMEL_CASE]) {
      return camelizeResponse(response);
    }
    return response;
  },
  error => {
    let err;

    if (axios.isCancel(error)) {
      err = error;
    } else {
      // Handle 404 and similar errors
      if (
        error.response &&
        error.response.status &&
        error.response.status > 400 &&
        error.config.method === 'get'
      ) {
        const shouldSetErrorStatusCode =
          error.config.meta[SHOW_ERROR_PAGE_ON_FETCH_FAIL] === true;

        if (shouldSetErrorStatusCode) {
          history.replace(history.location.pathname, {
            errorStatusCode: error.response.status,
          });
        }
      }
      const preError = getAxiosErrorResponse(error);
      err = typeof preError !== 'string' ? processErrors(preError) : preError;
    }

    return Promise.reject(err);
  },
);

fetcher.interceptors.request.use(req => {
  // Set default meta attributes for all requests
  req.meta = {
    [TRANSFORM_REQUEST_TO_SNAKE_CASE]: true,
    [TRANSFORM_RESPONSE_TO_CAMEL_CASE]: true,
    [SHOW_ERROR_PAGE_ON_FETCH_FAIL]: true,
    ...req.meta,
  };

  const token = $accessToken.getState();
  const shouldTransform = req.meta[TRANSFORM_REQUEST_TO_SNAKE_CASE] === true;
  const locale = $locale.getState();

  // Set token to each request if exists
  if (token && req.headers.Authorization === undefined) {
    req.headers.Authorization = `Token ${token}`;
  }

  if (locale && req.headers['Accept-Language'] === undefined) {
    req.headers['Accept-Language'] = locale;
  }

  // Convert request body data back to snake case
  if (!isEmpty(req.data) && !isNil(req.data)) {
    if (
      !(req.data instanceof Blob || req.data instanceof FormData) &&
      shouldTransform
    ) {
      req.data = snakeCase(req.data, { deep: true });
    }
  }

  return req;
});

function camelizeResponse(response) {
  if (
    !isEmpty(response.data) &&
    !isNil(response.data) &&
    !(response.data instanceof Blob) &&
    typeof response.data === 'object'
  ) {
    response.data = camelCase(response.data, { deep: true });
  }

  return response;
}

function refreshToken(token) {
  return new Promise((resolve, reject) =>
    fetcher
      .post('/api/v1/refresh-token/', {
        refresh_token: token,
      })
      .then(({ data }) => {
        changeTokens(data);
        resolve(data.accessToken);
      })
      .catch(err => {
        eraseTokens();
        reject(err);
      }),
  );
}

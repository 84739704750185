import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export function AppErrorHandler({ children, renderMap }) {
  const location = useLocation();
  const { errorStatusCode } = location.state || {};

  const component = renderMap[errorStatusCode] || children;

  return component;
}

AppErrorHandler.propTypes = {
  children: PropTypes.node,
  renderMap: PropTypes.objectOf(PropTypes.node).isRequired,
};

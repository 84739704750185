import React from 'react';
import { Form, Field, FormSpy } from 'react-final-form';
import { useStore } from 'effector-react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';

import { Trans, t } from '@lingui/macro';
import { Button, Typography, Progress } from '@oca/ui';
import {
  SelectWrapper,
  TextFieldWrapper,
  GroupSelectWrapper,
} from '@lib/final-form-oca-ui';
import { Row } from '@lib/grid';
import { i18n } from '@lib/i18n';
import { AvatarUploader } from '@features/common/organisms';
import { $employeeList } from '@features/common/model/common-data';

import { FORM_ERROR } from 'final-form';
import { EmployeeOptionLabel } from '@features/common';
import { getFullName, resolveRejectedPromise } from '@lib/help-fns';
import {
  projectUpdateRequesting,
  $employeeByDepartmentOptions,
} from '../model/project-flow';

// eslint-disable-next-line react/prop-types
export const FormEdit = React.memo(({ data }) => {
  const employeeList = useStore($employeeList);
  const employeeByDepartmentList = useStore($employeeByDepartmentOptions);

  let selectedProjectLead = -1;

  const oldAttendees = data.attendees.map(attendee => attendee.employee.id);
  const oldPermissions = data.attendees
    .filter(attendee => attendee.tasksPerm)
    .map(attendee => attendee.employee.id);

  return (
    <Wrapper>
      {employeeList && employeeList.length ? (
        <Form
          validate={validate}
          initialValues={{
            avatar: data.avatar,
            id: data.id,
            name: data.name,
            description: data.description,
            projectLead: data.projectLead.id,
            attendeesSelect: oldAttendees,
            permissions: oldPermissions,
          }}
          onSubmit={formData => handleFormSubmit(formData, data.attendees)}
          render={({
            handleSubmit,
            submitError,
            submitting,
            values: formValues,
          }) => {
            return (
              <Box style={{ minWidth: 450, maxWidth: 450 }}>
                <form onSubmit={handleSubmit} noValidate>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    width={1}
                  >
                    <Box mb={10}>
                      <Typography fontSize={14}>Project Image</Typography>
                    </Box>
                    <Field
                      name="avatar"
                      format={val => (val ? val.fileUrl || val : '')}
                      render={({ input }) => {
                        return (
                          <Row alignItems="center" mb={3}>
                            <AvatarUploader
                              size={96}
                              src={input.value}
                              onUploaded={input.onChange}
                            />
                          </Row>
                        );
                      }}
                    />
                  </Flex>
                  <Field
                    name="name"
                    label={<Trans>Project name</Trans>}
                    placeholder={i18n._(t`Name your project`)}
                    spaceAfter
                    helperText=" "
                    component={TextFieldWrapper}
                    required
                  />
                  <Field
                    name="description"
                    label={<Trans>Description</Trans>}
                    placeholder={i18n._(t`Describe your project`)}
                    spaceAfter
                    helperText=" "
                    component={TextFieldWrapper}
                    required
                  />
                  <Field
                    name="projectLead"
                    label={<Trans>Project Lead</Trans>}
                    component={SelectWrapper}
                    options={employeeList}
                    parse={val => val && val.id}
                    format={val =>
                      employeeList.find(employee => employee.id === val)
                    }
                    getOptionValue={option => option.id}
                    getOptionLabel={option => (
                      <EmployeeOptionLabel employee={option} />
                    )}
                    getOptionAvatar={option => option.avatar}
                    required
                    spaceAfter
                    helperText=" "
                    fullWidth
                  />
                  <Field
                    name="attendeesSelect"
                    parse={items => (items ? items.map(({ id }) => id) : [])}
                    format={items =>
                      items
                        ? employeeList.filter(({ id }) => items.includes(id))
                        : []
                    }
                    label={<Trans>Attendees</Trans>}
                    placeholder={i18n._(t`Select attendees...`)}
                    options={employeeByDepartmentList}
                    isOptionDisabled={item => item.id === selectedProjectLead}
                    getOptionLabel={item => (
                      <EmployeeOptionLabel employee={item} />
                    )}
                    filterOption={({ data: employee }, str) => {
                      const fullName = getFullName(employee, {
                        middleKey: null,
                      });

                      return fullName
                        ? fullName.toLowerCase().includes(str)
                        : false;
                    }}
                    helperText=" "
                    isDisabled={!formValues.projectLead}
                    component={GroupSelectWrapper}
                    spaceAfter
                    required
                  />
                  <FormSpy>
                    {({ values, form }) => {
                      const { change } = form;
                      const {
                        attendeesSelect,
                        permissions,
                        projectLead,
                      } = values;

                      if (selectedProjectLead !== projectLead) {
                        selectedProjectLead = projectLead;
                        const nextAttendeesSelect = attendeesSelect.filter(
                          attendee => attendee !== projectLead,
                        );

                        change('attendeesSelect', nextAttendeesSelect);
                      }

                      const attendeesAccessCandidates = employeeList.filter(
                        ({ id }) => attendeesSelect.includes(id),
                      );
                      const nextPermissions = permissions.filter(id =>
                        attendeesSelect.includes(id),
                      );

                      if (permissions.length !== nextPermissions.length) {
                        change('permissions', nextPermissions);
                      }

                      return (
                        <Field
                          name="permissions"
                          label={<Trans>Manage access</Trans>}
                          component={SelectWrapper}
                          placeholder={i18n._(t`Select access...`)}
                          options={attendeesAccessCandidates}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => (
                            <EmployeeOptionLabel employee={option} />
                          )}
                          getOptionAvatar={option => option.avatar}
                          parse={val => val && val.map(({ id }) => id)}
                          format={val =>
                            val
                              ? employeeList.filter(({ id }) =>
                                  val.includes(id),
                                )
                              : []
                          }
                          isDisabled={formValues.attendeesSelect.length < 1}
                          isMulti
                          required
                          spaceAfter
                          helperText=" "
                          fullWidth
                        />
                      );
                    }}
                  </FormSpy>
                  <Box mt={3}>
                    {submitError && (
                      <Box p={2}>
                        <Typography variant="caption" color="error">
                          {submitError}
                        </Typography>
                      </Box>
                    )}
                    <Button
                      style={{ cursor: 'pointer' }}
                      disabled={submitting}
                      variant="primary"
                      type="submit"
                      fullWidth
                    >
                      <Trans>Update</Trans>
                    </Button>
                  </Box>
                </form>
              </Box>
            );
          }}
        />
      ) : (
        <Progress />
      )}
    </Wrapper>
  );
});

async function handleFormSubmit(
  { avatar, attendeesSelect, permissions, ...formData },
  oldAttendees,
) {
  const dataObject = { ...formData };
  if (avatar && avatar.file) {
    dataObject.avatar = avatar.file;
  }

  if (attendeesSelect.length) {
    dataObject.attendees = attendeesSelect.map(id => {
      return {
        employee: id,
        tasksPerm: permissions.includes(id),
      };
    });
  }

  const forDelete = oldAttendees
    .filter(item => !attendeesSelect.includes(item.employee.id))
    .map(item => item.id);
  if (forDelete.length) {
    dataObject.deleteAttendees = forDelete;
  }

  const error = await resolveRejectedPromise(
    projectUpdateRequesting(dataObject),
  );

  if (error && error.message) {
    return { [FORM_ERROR]: error.message };
  }

  return error ? error.data : error;
}

function validate(values) {
  const errors = {};
  if (!values.name) {
    errors.name = <Trans>Please enter name</Trans>;
  }
  if (!values.description) {
    errors.description = <Trans>Please enter description</Trans>;
  }
  if (!values.projectLead) {
    errors.projectLead = <Trans>Please select project lead</Trans>;
  }

  if (!values.attendeesSelect || values.attendeesSelect.length === 0) {
    errors.attendeesSelect = <Trans>Please select attendees</Trans>;
  }

  return errors;
}

const Wrapper = styled(Box)`
  min-width: 450px;
  min-height: 510px;
`;

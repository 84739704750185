import React from 'react';
import { SvgIcon } from './svg-icon';

export const Txt = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#576D7E"
    />
    <path
      d="M6.22425 14.6H5.175C4.653 14.6 4.653 13.8328 5.175 13.8328H8.0115C8.5455 13.8328 8.5455 14.6 8.0115 14.6H7.0155V17.6278C7.0155 18.149 6.2235 18.1618 6.2235 17.6278V14.6H6.22425Z"
      fill="white"
    />
    <path
      d="M10.5195 15.2895L11.5635 13.9822C11.8755 13.575 12.4808 14.0962 12.1395 14.4862C11.7795 14.9122 11.4023 15.3742 11.0415 15.8467L12.2655 17.37C12.5948 17.82 11.9355 18.252 11.6115 17.808L10.5083 16.3927L9.42301 17.838C9.11701 18.2752 8.43901 17.784 8.78176 17.376L9.98626 15.8467C9.60901 15.3735 9.24301 14.9122 8.87701 14.4862C8.49901 14.0362 9.20101 13.575 9.47626 13.995L10.5195 15.2895Z"
      fill="white"
    />
    <path
      d="M13.9822 14.6003H12.9337C12.4117 14.6003 12.4117 13.833 12.9337 13.833H15.7695C16.3035 13.833 16.3035 14.6003 15.7695 14.6003H14.7742V17.628C14.7742 18.1493 13.9822 18.162 13.9822 17.628V14.6003Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

import Compressor from 'compressorjs';
/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {import('react-image-crop').Crop} crop - crop object provided by react-image-crop
 * @param {string} fileName - Name of the returned file in Promise
 * @returns {Promise<Blob>}
 */
export function cropCanvasImage(image, crop, filename) {
  const canvas = document.createElement('canvas');
  const croppedWidth = (image.naturalWidth * crop.width) / 100;
  const croppedHeight = (image.naturalHeight * crop.height) / 100;
  canvas.width = croppedWidth;
  canvas.height = croppedHeight;
  const ctx = canvas.getContext('2d');

  const roundParam = param => Math.max(1, Math.floor(param));
  ctx.drawImage(
    image,
    (image.naturalWidth * crop.x) / 100,
    (image.naturalHeight * crop.y) / 100,
    roundParam(croppedWidth),
    roundParam(croppedHeight),
    0,
    0,
    roundParam(croppedWidth),
    roundParam(croppedHeight),
  );

  return new Promise(resolve => {
    canvas.toBlob(file => {
      if (file) {
        // eslint-disable-next-line
        file.name = filename || image.name;
        // eslint-disable-next-line no-new
        new Compressor(file, { quality: 0.5, success: blob => resolve(blob) });
      }
    }, 'image/jpeg');
  });
}

import { guard, sample, forward } from 'effector';
import { t } from '@lingui/macro';
import { notify } from '@lib/notifier';
import { i18n } from '@lib/i18n';
import { not } from '@lib/help-fns';

import { $filesRaw } from './file-browser.stores';
import {
  uploadFileRequest,
  createFolderRequest,
  onFolderCreateSubmit,
} from './file-browser.events';
import { FileBrowserGate, toggleFolderCreateDialog } from './file-browser.view';
import { FileBrowserTypes } from '../constants';
import { transformFiles } from '../lib/helpers';
import { createFolder } from '../api';

$filesRaw
  .on(
    createFolderRequest.done,
    transformFiles(
      (list, { data }) => list.concat([data]),
      FileBrowserTypes.FOLDER,
    ),
  )
  .on(
    uploadFileRequest.done,
    transformFiles(
      (list, { data }) => list.concat([data]),
      FileBrowserTypes.FILE,
    ),
  );

guard({
  source: sample(
    FileBrowserGate.state,
    onFolderCreateSubmit,
    ({ id: parentDirectory }, name) => ({ parentDirectory, name }),
  ),
  filter: createFolderRequest.pending.map(not),
  target: createFolderRequest,
});

forward({
  from: createFolderRequest.done,
  to: toggleFolderCreateDialog,
});

createFolderRequest.done.watch(({ params }) => {
  notify.success(i18n._(t`Folder ${params.name} is created successfully`));
});

createFolderRequest.fail.watch(({ params, error }) => {
  notify.error(
    i18n._(t`Could not create folder ${params.name}. ${error.message}`),
  );
});

createFolderRequest.use(createFolder);

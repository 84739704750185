import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import {
  Typography,
  Menu,
  IconButton,
  Avatar,
  ConfirmationDialog,
} from '@oca/ui';
import { MenuDots, Edit, Check, Delete, OCALogoColored } from '@oca/icons';
import { Trans, t } from '@lingui/macro';
import { SkeletonRenderer } from '@lib/skeleton-loader';
import { i18n } from '@lib/i18n';

export function ViewHeader({
  title,
  project,
  isLoading,
  onComplete,
  onArchive,
  onEdit,
}) {
  // eslint-disable-next-line no-nested-ternary
  const status = project
    ? project.completed
      ? i18n._(t`Completed`)
      : i18n._(t`Open`)
    : i18n._(t`N/A`);

  return (
    <Flex bg="white" alignItems="center">
      <Flex alignItems="center" flex="1 0 auto">
        <SkeletonRenderer
          width={80}
          height={80}
          boxProps={{ mr: 3 }}
          loading={isLoading}
          render={() => (
            <Avatar
              src={project ? project.avatar : null}
              size={80}
              borderRadius="4px"
              mr={3}
            >
              <OCALogoColored />
            </Avatar>
          )}
        />
        <Box>
          <SkeletonRenderer
            width={180}
            height={24}
            loading={isLoading}
            boxProps={{ mb: 2 }}
            render={() => (
              <Typography variant="h1" gutterBottom>
                {title}
              </Typography>
            )}
          />
          <Typography>
            <Trans>Status</Trans>: {status}
          </Typography>
        </Box>
      </Flex>
      {project && project.isOwner && (
        <Menu
          usePortal
          anchor={
            <IconButton color="text" padding={1}>
              <MenuDots />
            </IconButton>
          }
        >
          <Menu.Item icon={<Edit />} onClick={onEdit}>
            <Typography>
              <Trans>Edit</Trans>
            </Typography>
          </Menu.Item>
          {canCompleteProject(project) && (
            <ConfirmationDialog
              title={i18n._(t`Complete ${project.name}?`)}
              description={
                <Trans>
                  This action will complete this project. Are you sure you want
                  to continue?
                </Trans>
              }
              confirmText={<Trans>Complete</Trans>}
            >
              {confirm => (
                <Menu.Item
                  icon={<Check />}
                  onClick={confirm(() => onComplete(project))}
                >
                  <Typography>
                    <Trans>Complete</Trans>
                  </Typography>
                </Menu.Item>
              )}
            </ConfirmationDialog>
          )}
          {project && project.completed && (
            <ConfirmationDialog
              title={i18n._(t`Archive ${project.name}?`)}
              description={
                <Trans>
                  This action will archive this project. Are you sure you want
                  to continue?
                </Trans>
              }
            >
              {confirm => (
                <Menu.Item
                  icon={<Delete />}
                  onClick={confirm(() => onArchive(project))}
                >
                  <Typography>
                    <Trans>Archive</Trans>
                  </Typography>
                </Menu.Item>
              )}
            </ConfirmationDialog>
          )}
        </Menu>
      )}
    </Flex>
  );
}

ViewHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  project: PropTypes.shape({
    avatar: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function canCompleteProject(project) {
  return (
    project &&
    project.completed !== true &&
    project.tasksCount === project.tasksStatuses.done
  );
}

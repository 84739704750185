import { fetcher } from '@features/common';

const endpoint = '/api/v1/projects/';
const endpointEmployees = '/api/v1/employees/';

// project create
export function createProject(data) {
  return fetcher.post(endpoint, data);
}

// project update
export function updateProject(data) {
  return fetcher.patch(`${endpoint + data.id}/`, data);
}
// project delete
export function deleteProject(id) {
  return fetcher.delete(`${endpoint + id}/`);
}

// project get list
export function fetchProjectList(data) {
  return fetcher.get(endpoint, { params: data });
}

// project get single
export function fetchProjectSingle(id) {
  return fetcher.get(`${endpoint + id}/`);
}

// projet get task list
export function fetchTaskList(id) {
  return fetcher.get(`${endpoint + id}/tasks/`);
}

// project get meeting list
export function fetchMeetingList(params) {
  return fetcher.get(`/api/v1/meetings/`, { params });
}

// employee get list
export function fetchEmployeeList() {
  return fetcher.get(endpointEmployees);
}

export function fetchAttachments({ id }) {
  return fetcher.get(`${endpoint + id}/attachments/`);
}

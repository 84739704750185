import React from 'react';
import { i18n } from '@lib/i18n';
import { t } from '@lingui/macro';
import { Box } from 'rebass';
import { Form, Field } from 'react-final-form';
import { useStore } from 'effector-react';
import { combine } from 'effector';

import { Dialog, Button } from '@oca/ui';
import { GroupSelectWrapper } from '@lib/final-form-oca-ui';
import {
  $employeeByDepartmentList,
  EmployeeOptionLabel,
  $employeeList,
} from '@features/common';
import { getFullName } from '@lib/help-fns';

import {
  $entityShareDialogOpen,
  toggleOpenEntityShareDialog,
  $sharedFile,
  $shareEntityFetching,
} from '../model/file-browser.view';
import { onEntityShareSubmit } from '../model/file-browser.events';

const $state = combine({
  open: $entityShareDialogOpen,
  file: $sharedFile.map(file =>
    file ? { ...file, users: file.users.map(user => user.id) } : null,
  ),
  employeeList: $employeeList,
  employeesByDepartment: $employeeByDepartmentList.map(list =>
    list.map(item => ({
      id: item.id,
      label: item.name,
      options: item.employees,
      value: item.id,
    })),
  ),
  submitting: $shareEntityFetching,
});

export function FileShareDialog() {
  const {
    open,
    employeeList,
    employeesByDepartment,
    file,
    submitting,
  } = useStore($state);
  const users = file ? file.users : [];
  const initialValues = React.useMemo(() => ({ users }), [users]);

  const onSubmit = React.useCallback(
    values => {
      onEntityShareSubmit({ ...(file || {}), users: values.users });

      return null;
    },
    [file],
  );

  if (!(open && file.users)) {
    return null;
  }

  return (
    <Dialog
      width={[400, 500]}
      title={i18n._(t`Share ${file.type}: ${file.name}`)}
      onClose={() => toggleOpenEntityShareDialog(false)}
    >
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box paddingBottom={3}>
              <Field
                name="users"
                component={GroupSelectWrapper}
                parse={parseUsers}
                format={items => formatUsers(items, employeeList)}
                placeholder={i18n._(t`Select employees...`)}
                getOptionLabel={item => <EmployeeOptionLabel employee={item} />}
                filterOption={filterOption}
                options={employeesByDepartment}
              />
            </Box>
            <Button
              type="submit"
              variant="primary"
              disabled={submitting}
              fullWidth
            >
              {i18n._(t`Submit`)}
            </Button>
          </form>
        )}
      />
    </Dialog>
  );
}

function parseUsers(items) {
  return items ? items.map(({ id }) => id) : [];
}

function formatUsers(items, users = []) {
  return items ? users.filter(({ id }) => items.includes(id)) : [];
}

function filterOption({ data }, str) {
  const fullName = getFullName(data, { middleKey: null });

  return fullName ? fullName.toLowerCase().includes(str) : false;
}

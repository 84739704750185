import React from 'react';
import { SvgIcon } from './svg-icon';

export const Mp3 = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.3258 5.175 24 6 24H21C21.825 24 22.5 23.3258 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#50BEE8"
    />
    <path
      d="M5.49305 15.3675V17.5868C5.49305 17.8508 5.2778 18.0008 5.06105 18.0008C4.86905 18.0008 4.7018 17.8508 4.7018 17.5868V14.2103C4.7018 13.899 4.9658 13.7963 5.06105 13.7963C5.23505 13.7963 5.33705 13.899 5.43905 14.0123L6.75905 15.7935L8.12555 13.9463C8.3243 13.7003 8.80955 13.7963 8.80955 14.2103V17.5868C8.80955 17.8508 8.6408 18.0008 8.44955 18.0008C8.2343 18.0008 8.06555 17.8508 8.06555 17.5868V15.3675L7.0703 16.6275C6.85505 16.8915 6.58505 16.8915 6.38705 16.6275L5.49305 15.3675Z"
      fill="white"
    />
    <path
      d="M9.85727 14.2103C9.85727 14.0123 10.0133 13.7963 10.2653 13.7963H11.6505C12.4305 13.7963 13.1325 14.3183 13.1325 15.3195C13.1325 16.2675 12.4305 16.7948 11.6505 16.7948H10.6493V17.5868C10.6493 17.8508 10.4813 18.0008 10.2653 18.0008C10.0673 18.0008 9.85727 17.8508 9.85727 17.5868V14.2103ZM10.6485 14.5523V16.0455H11.6498C12.0518 16.0455 12.3698 15.6915 12.3698 15.3195C12.3698 14.8995 12.0518 14.5523 11.6498 14.5523H10.6485Z"
      fill="white"
    />
    <path
      d="M15.0968 14.6003H14.0716C13.5683 14.6003 13.5983 13.8511 14.0716 13.8511H15.9068C16.2721 13.8511 16.4401 14.2711 16.1708 14.5411L15.1756 15.4703C15.9076 15.4163 16.4828 15.9616 16.4828 16.6336C16.4828 17.3708 15.9608 18.0008 14.8636 18.0008C14.3836 18.0008 13.9103 17.8028 13.6343 17.3708C13.3463 16.9456 13.9636 16.5376 14.2876 16.9568C14.4436 17.1608 14.6956 17.2628 14.9648 17.2628C15.3308 17.2628 15.6908 17.1068 15.6908 16.6328C15.6908 16.0096 14.9288 16.1056 14.5156 16.1476C14.0116 16.2436 13.8623 15.6976 14.1563 15.4703L15.0968 14.6003Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

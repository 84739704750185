import React from 'react';
import { SvgIcon } from './svg-icon';

export const Bmp = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6 0C5.175 0 4.5 0.675 4.5 1.5V22.5C4.5 23.325 5.175 24 6 24H21C21.825 24 22.5 23.325 22.5 22.5V6L16.5 0H6Z"
      fill="#E2E5E7"
    />
    <path d="M18 6H22.5L16.5 0V4.5C16.5 5.325 17.175 6 18 6Z" fill="#B0B7BD" />
    <path d="M22.5 10.5L18 6H22.5V10.5Z" fill="#CAD1D8" />
    <path
      d="M19.5 19.5C19.5 19.9125 19.1625 20.25 18.75 20.25H2.25C1.8375 20.25 1.5 19.9125 1.5 19.5V12C1.5 11.5875 1.8375 11.25 2.25 11.25H18.75C19.1625 11.25 19.5 11.5875 19.5 12V19.5Z"
      fill="#84BD5A"
    />
    <path
      d="M4.68598 18.0001C4.46998 18.0001 4.31998 17.8381 4.31998 17.6281V14.2223C4.31998 14.0063 4.46998 13.8503 4.68598 13.8503H6.37123C7.15723 13.8503 7.58248 14.3843 7.58248 14.9956C7.58248 15.2648 7.50448 15.5296 7.25323 15.7516C7.72648 15.9376 7.93648 16.3028 7.93648 16.7468C7.93573 17.4361 7.46773 18.0001 6.56848 18.0001H4.68598ZM6.37048 14.5816H5.10523V15.4748H6.37048C6.62848 15.4748 6.79048 15.3128 6.79048 14.9948C6.79048 14.7976 6.62848 14.5816 6.37048 14.5816ZM5.10523 17.2681H6.56848C7.26448 17.2681 7.26448 16.2068 6.56848 16.2068C6.11323 16.2068 5.57923 16.2068 5.10523 16.2068V17.2681Z"
      fill="white"
    />
    <path
      d="M9.44321 15.3675V17.586C9.44321 17.8508 9.22721 18 9.01196 18C8.81996 18 8.65196 17.8508 8.65196 17.586V14.2103C8.65196 13.8983 8.91671 13.7955 9.01196 13.7955C9.18521 13.7955 9.28721 13.8983 9.38921 14.0123L10.7092 15.7928L12.0757 13.9455C12.2745 13.7003 12.7597 13.7955 12.7597 14.2103V17.586C12.7597 17.8508 12.5917 18 12.4005 18C12.1845 18 12.0165 17.8508 12.0165 17.586V15.3675L11.0205 16.6268C10.8052 16.8915 10.5352 16.8915 10.3372 16.6268L9.44321 15.3675Z"
      fill="white"
    />
    <path
      d="M13.602 14.2103C13.602 14.0123 13.758 13.7955 14.01 13.7955H15.3952C16.1752 13.7955 16.8772 14.3175 16.8772 15.3188C16.8772 16.2668 16.1752 16.794 15.3952 16.794H14.394V17.586C14.394 17.8508 14.226 18 14.01 18C13.812 18 13.602 17.8508 13.602 17.586V14.2103ZM14.3932 14.5515V16.0448H15.3945C15.7965 16.0448 16.1145 15.6908 16.1145 15.3188C16.1145 14.8988 15.7965 14.5515 15.3945 14.5515H14.3932Z"
      fill="white"
    />
    <path
      d="M18.75 20.25H4.5V21H18.75C19.1625 21 19.5 20.6625 19.5 20.25V19.5C19.5 19.9125 19.1625 20.25 18.75 20.25Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);

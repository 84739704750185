import {
  routes as commonRoutes,
  ForgotPasswordPage,
  NotFoundPage,
  LoginPage,
  ResetPasswordPage,
} from './features/common';
import { routes as dashboardRoutes } from './features/dashboard';
import { routes as projectRoutes } from './features/projects';
import { routes as documentsRoutes } from './features/documents';
import { routes as meetingRoutes } from './features/meetings';
import { routes as taskRoutes } from './features/tasks';
import { routes as hrRoutes } from './features/hr';

export const publicRoutes = [
  { path: '/login', exact: true, component: LoginPage },
  { path: '/forgot-password', exact: true, component: ForgotPasswordPage },
  { path: '/reset-password', exact: true, component: ResetPasswordPage },
];

export const privateRoutes = [
  ...dashboardRoutes,
  ...projectRoutes,
  ...taskRoutes,
  ...meetingRoutes,
  ...hrRoutes,
  ...documentsRoutes,
  ...commonRoutes,
  { component: NotFoundPage },
];

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Box } from 'rebass';
import { List, UploadItem, Menu, File } from '@oca/ui';
import { fileExtensionRegex } from '@lib/regex';
import { MenuDots } from '@oca/icons';

export const UploadedFileList = React.memo(function UploadedFileList({
  items,
  statusText,
  onPreview,
  onDownload,
  onDelete,
  ...rest
}) {
  return (
    <List
      {...rest}
      as="div"
      items={items}
      renderItem={(
        { file, originalName, fileName, fileExt, size, deleted },
        index,
      ) => {
        const maybeExt = fileName.match(fileExtensionRegex());
        const extension = fileExt || (maybeExt ? maybeExt[1] : 'default');

        if (deleted) {
          return null;
        }

        return (
          <Box marginBottom={2}>
            <File
              name={originalName || fileName}
              size={size}
              ext={extension}
              onClick={() =>
                onPreview({
                  file,
                  fileName,
                  fileExt: extension,
                })
              }
              onDelete={() => onDelete(file, index)}
              variant="large"
              fullWidth
            />
          </Box>
        );
      }}
    />
  );
});

UploadedFileList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string.isRequired,
      filename: PropTypes.string,
      temp: PropTypes.bool,
    }),
  ).isRequired,
  statusText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  // eslint-disable-next-line react/require-default-props
  onPreview: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDownload: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  onDelete: PropTypes.func,
};

UploadedFileList.defaultProps = {
  statusText: <Trans>Successfully uploaded</Trans>,
};

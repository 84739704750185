import React from 'react';
import { SvgIcon } from './svg-icon';

export const FileCheck = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.69999 2.70001C9.99999 2.70001 10.2 2.90001 10.2 3.20001V4.70001H6.59999V3.20001C6.59999 2.90001 6.79999 2.70001 7.09999 2.70001H9.69999ZM5.89999 21.2999C5.59999 21.2999 5.39999 21.0999 5.39999 20.7999V19.2H18.5V20.7999C18.5 21.0999 18.3 21.2999 18 21.2999H5.89999ZM17.5 3.20001C17.5 2.90001 17.3 2.70001 17 2.70001H14.4C14.1 2.70001 13.9 2.90001 13.9 3.20001V4.70001H17.5V3.20001ZM5.99999 5.79999H18C18.3 5.79999 18.6 5.99999 18.6 6.29999V18H5.39999V6.29999C5.39999 5.99999 5.69999 5.79999 5.99999 5.79999ZM8.79999 16.6L9.89999 15.5C10.1 15.3 10.1 14.9 9.99999 14.8C9.79999 14.6 9.39999 14.6 9.19999 14.8L8.49999 15.5L8.39999 15.4C8.19999 15.2 7.79999 15.2 7.59999 15.4C7.39999 15.6 7.39999 16 7.59999 16.2L7.99999 16.6C8.09999 16.7 8.19999 16.8 8.39999 16.8C8.59999 16.8 8.69999 16.7 8.79999 16.6ZM8.79999 13.4L9.89999 12.3C10.1 12 10.1 11.7 9.99999 11.5C9.79999 11.3 9.39999 11.3 9.19999 11.5L8.49999 12.2L8.39999 12.1C8.19999 11.9 7.79999 11.9 7.59999 12.1C7.39999 12.3 7.39999 12.7 7.59999 12.9L7.99999 13.3C8.0236 13.3236 8.04721 13.3528 8.07213 13.3836C8.15278 13.4833 8.24721 13.6 8.39999 13.6C8.59999 13.6 8.69999 13.5 8.79999 13.4ZM8.79999 10.1L9.89999 8.99999C10.1 8.79999 10.1 8.39999 9.89999 8.19999C9.69999 7.99999 9.29999 7.99999 9.19999 8.19999L8.49999 8.89999L8.39999 8.79999C8.19999 8.59999 7.79999 8.59999 7.59999 8.79999C7.39999 8.99999 7.39999 9.39999 7.59999 9.59999L7.99999 10.1C8.09999 10.2 8.19999 10.3 8.39999 10.3C8.59999 10.3 8.69999 10.2 8.79999 10.1ZM11.6 16.2H15.8C16.1 16.2 16.4 16 16.3 15.7C16.3 15.4 16.1 15.2 15.8 15.2H11.6C11.3 15.2 11.1 15.4 11.1 15.7C11.1 16 11.3 16.2 11.6 16.2ZM11.6 13H15.8C16.1 13 16.3 12.8 16.3 12.5C16.3 12.2 16.1 12 15.8 12H11.6C11.3 12 11.1 12.2 11.1 12.5C11.1 12.8 11.3 13 11.6 13ZM11.6 9.69999H15.8C16.1 9.69999 16.3 9.49999 16.3 9.19999C16.3 8.89999 16.1 8.69999 15.8 8.69999H11.6C11.3 8.69999 11.1 8.89999 11.1 9.19999C11.1 9.49999 11.3 9.69999 11.6 9.69999Z"
    />
  </SvgIcon>
);

import React from 'react';
import { CommonPageTemplate } from '@features/common';
import { Trans } from '@lingui/macro';

/**
 *
 * @param {import('@features/common/templates/common-page').CommonPageTemplateProps} props
 */
export function CommonDocumentsTemplate(props) {
  return (
    <CommonPageTemplate
      heading={<Trans>Documents</Trans>}
      animateOnMount={false}
      {...props}
    />
  );
}

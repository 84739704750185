import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useStore } from 'effector-react';

import { Typography, IconButton, Link } from '@oca/ui';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';
import { Row, Col } from '@lib/grid';
import { Trans } from '@lingui/macro';
import { Add } from '@oca/icons';
import { unique } from '@lib/help-fns';
import { $employeeList } from '@features/common';

import { SectionTitle, FieldsetContact } from '../molecules';
import { createContactModel } from '../model/static';

export function FormContacts({ initialValues }) {
  const employeeList = useStore($employeeList);

  return (
    <React.Fragment>
      <Row spacing="10px">
        <Col width={[1 / 3, 1 / 4]}>
          <Typography variant="h1" gutterBottom>
            <Trans>Contacts</Trans>
          </Typography>
          <Field
            type="tel"
            name="mobilePhone"
            placeholder="+965 1234 1234"
            validate={value =>
              initialValues.mobilePhone !== value
                ? unique({
                    value,
                    list: employeeList,
                    propGetter: ({ mobilePhone }) => mobilePhone,
                    message: <Trans>This mobile phone is already exists</Trans>,
                  })
                : undefined
            }
            component={TextFieldWrapper}
            label={<Trans>Phone</Trans>}
            helperText={<Trans>Your personal phone</Trans>}
            spaceAfter
            required
          />
          <Field
            type="tel"
            name="workPhone"
            component={TextFieldWrapper}
            label={<Trans>Work phone</Trans>}
            placeholder="+965 1234 1234"
            helperText=" "
            spaceAfter
          />
          <Field
            type="tel"
            name="homePhone"
            component={TextFieldWrapper}
            label={<Trans>Home phone</Trans>}
            placeholder="+965 1234 1234"
            helperText=" "
            spaceAfter
          />
          <Field
            type="email"
            name="email"
            component={TextFieldWrapper}
            label={<Trans>Email</Trans>}
            validate={value =>
              initialValues.email !== value &&
              unique({
                value,
                list: employeeList,
                propGetter: ({ email }) => email,
                message: <Trans>This email is already exists</Trans>,
              })
            }
            helperText=" "
            spaceAfter
            required
          />
        </Col>
        <Col width={[1 / 3, 1 / 4]}>
          <Typography variant="h1" gutterBottom>
            <Trans>Address</Trans>
          </Typography>
          <Field
            name="street"
            label={<Trans>Street</Trans>}
            component={TextFieldWrapper}
            helperText=" "
            required
            spaceAfter
          />
          <Field
            name="city"
            label={<Trans>City</Trans>}
            component={TextFieldWrapper}
            helperText=" "
            spaceAfter
            required
          />
          <Field
            name="country"
            label={<Trans>Country</Trans>}
            component={TextFieldWrapper}
            helperText=" "
            spaceAfter
            required
          />
        </Col>
      </Row>
      <FieldArray
        name="contacts"
        render={({ fields }) => (
          <React.Fragment>
            <SectionTitle
              title={<Trans>Emergency contacts</Trans>}
              action={
                <IconButton onClick={() => fields.push(createContactModel())}>
                  <Add />
                </IconButton>
              }
            />
            {fields.value && fields.value.length < 1 && (
              <Typography variant="body1" color="textSecondary" gutterBottom>
                <Trans>There is no emergency contacts yet...</Trans>
              </Typography>
            )}
            <Row spacing="10px" flexWrap="wrap">
              {fields.map((fieldName, index) => (
                <Col key={fieldName} width={[1 / 3, 1 / 4]}>
                  <ContactHeader
                    index={index}
                    onRemove={() => fields.remove(index)}
                  />
                  <FieldsetContact name={fieldName} />
                </Col>
              ))}
            </Row>
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
}

FormContacts.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

// eslint-disable-next-line react/prop-types
function ContactHeader({ index, onRemove }) {
  return (
    <Row justifyContent="space-between" alignItems="center">
      <Typography>#{index + 1}</Typography>
      {index > 0 && (
        <Link as="button" type="button" onClick={onRemove}>
          <Trans>Remove contact</Trans>
        </Link>
      )}
    </Row>
  );
}

import React from 'react';
import { SvgIcon } from './svg-icon';

export const Motaded = props => (
  <SvgIcon {...props} viewBox="0 0 450 424">
    <path
      d="M0 423.77L91.9277 182.482L162.203 0H206.82H262.59L115.376 423.77H56.9954H0Z"
      fill="#235755"
    />
    <path
      d="M450 423.814L349.613 178.422L282.479 178.422H249.226L342.334 423.417L375.586 423.814H450Z"
      fill="#147D73"
    />
  </SvgIcon>
);

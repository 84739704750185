import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Box, Image } from 'rebass';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import { Typography, Link } from '@oca/ui';
import { i18n } from '@lib/i18n';
import notFoundIllustration from '@lib/illustrations/not-found.svg';

import { CommonPageTemplate } from '../../templates';

export function NotFoundPage() {
  return (
    <CommonPageTemplate
      title={i18n._(t`404: Page not found`)}
      contentBackground="transparent"
      containerOverflowY
      fullHeight
    >
      <Container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box marginBottom={2}>
          <Image src={notFoundIllustration} alt="Not found illustration" />
        </Box>
        <Typography variant="h1" gutterBottom>
          <Trans>404 Page not found</Trans>
        </Typography>
        <Box>
          <Typography variant="caption">
            <Trans>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable
            </Trans>
          </Typography>
          <Typography align="center">
            <Link as={RouterLink} to="/">
              <Trans>Go to homepage</Trans>
            </Link>
          </Typography>
        </Box>
      </Container>
    </CommonPageTemplate>
  );
}

const Container = styled(Flex)`
  height: 100%;
`;

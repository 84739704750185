import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { WeekPicker } from '../molecules/week-picker';
import { ModeControl } from '../molecules/mode-control';

export function InlineWeekControl({ onChange, value, formatStr, disabled }) {
  const pickerRef = React.useRef(null);
  const [startDay, endDay] = getWeekRange(new Date(value));
  const label =
    startDay && endDay
      ? `${dayjs(startDay).format(formatStr)} - ${dayjs(endDay).format(
          formatStr,
        )}`
      : '---';

  const onMoveToPrevWeek = () =>
    pickerRef.current.setDate(
      dayjs(startDay)
        .subtract(1, 'week')
        .toDate(),
      true,
    );

  const onMoveToNextWeek = () =>
    pickerRef.current.setDate(
      dayjs(startDay)
        .add(1, 'week')
        .toDate(),
      true,
    );

  return (
    <WeekPicker
      onChange={onChange}
      onReady={(dates, current, instance) => {
        pickerRef.current = instance;
      }}
      render={(_, ref) => (
        <ModeControl
          onLeftClick={onMoveToPrevWeek}
          onRightClick={onMoveToNextWeek}
          leftDisabled={
            pickerRef.current
              ? !pickerRef.current.isEnabled(
                  dayjs(new Date(value))
                    .subtract(1, 'week')
                    .toDate(),
                )
              : undefined
          }
          rightDisabled={
            pickerRef.current
              ? !pickerRef.current.isEnabled(
                  dayjs(new Date(value))
                    .add(1, 'week')
                    .toDate(),
                )
              : undefined
          }
          disabled={disabled}
        >
          <div ref={ref}>{label}</div>
        </ModeControl>
      )}
      value={startDay}
    />
  );
}

InlineWeekControl.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  formatStr: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
};

InlineWeekControl.defaultProps = {
  formatStr: 'MMM DD',
};

function getWeekRange(date) {
  const weekStartDay = dayjs(date)
    .startOf('week')
    .toDate();
  const weekEndDay = dayjs(date)
    .endOf('week')
    .toDate();

  return [weekStartDay, weekEndDay];
}

import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from '@lingui/macro';
import { Form, Field } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import { FORM_ERROR } from 'final-form';

import { useStore } from 'effector-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Button, Typography, Link, Checkbox } from '@oca/ui';
import { resolveRejectedPromise } from '@lib/help-fns';
import { TextFieldWrapper } from '@lib/final-form-oca-ui';
import { OnChange } from '@lib/react-final-form-on-change';

import { checkPasswordStrength } from '@lib/password-strength';
import {
  $setNewPasswordFetching,
  setNewPasswordRequesting,
} from '../model/auth';

const initialFormValues = {
  newPassword: '',
  confirmPassword: '',
  sendReminderEmail: true,
};

const passwordStrengthDescriptionMap = {
  0: <Trans>Strength: Weak</Trans>,
  1: <Trans>Strength: Normal</Trans>,
  2: <Trans>Strength: Strong</Trans>,
};

/**
 *
 * @param {{ token: string }}
 */
export function ResetPasswordForm({ token }) {
  const fetching = useStore($setNewPasswordFetching);
  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Form
      initialValues={initialFormValues}
      validate={validate}
      onSubmit={values =>
        handleFormSubmit({ ...values, token }, executeRecaptcha)
      }
      render={({ handleSubmit, submitting, submitError, values, form }) => {
        const strength = checkPasswordStrength(values.newPassword);

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Field
              type="password"
              name="newPassword"
              label={<Trans>New password</Trans>}
              component={TextFieldWrapper}
              helperText={
                <Text
                  as="span"
                  fontSize={12}
                  color={
                    (strength === 2 && 'green') ||
                    (strength === 1 && 'textSecondary')
                  }
                >
                  {values.newPassword && strength > 0
                    ? passwordStrengthDescriptionMap[strength]
                    : ' '}
                </Text>
              }
              autoComplete="nope"
              required
              fullWidth
              spaceAfter
              autoFocus
            />
            <Field
              type="password"
              name="confirmPassword"
              label={<Trans>Confirm password</Trans>}
              component={TextFieldWrapper}
              disabled={
                !values.newPassword ||
                (values.newPassword && values.newPassword.length < 8)
              }
              helperText={
                <Text as="span" color="green" fontSize={12}>
                  {values.newPassword &&
                  values.newPassword === values.confirmPassword ? (
                    <Trans>Password matches</Trans>
                  ) : (
                    ' '
                  )}
                </Text>
              }
              autoComplete="nope"
              required
              fullWidth
              spaceAfter
            />
            <Field
              type="checkbox"
              name="sendReminderEmail"
              render={({ input }) => (
                <Checkbox label={<Trans>Send to my email</Trans>} {...input} />
              )}
            />
            <OnChange name="newPassword">
              {(value, prevValue) => {
                if (prevValue && !value) {
                  form.change('confirmPassword', '');
                }
              }}
            </OnChange>
            <Box marginTop={4}>
              {submitError && (
                <Box paddingBottom={2}>
                  <Typography variant="caption" color="error">
                    {submitError}
                  </Typography>
                </Box>
              )}
              {!submitting && fetching.isDone && (
                <Box paddingBottom={2}>
                  <Typography variant="caption" color="green">
                    <Trans>
                      An email with instructions has been sent to you
                    </Trans>
                  </Typography>
                </Box>
              )}
              <Button
                variant="primary"
                type="submit"
                disabled={submitting}
                fullWidth
              >
                <Trans>Reset password</Trans>
              </Button>
              <Flex marginTop={4} justifyContent="center">
                <Link as={RouterLink} to="/login">
                  <Trans>Back to login</Trans>
                </Link>
              </Flex>
            </Box>
          </form>
        );
      }}
    />
  );
}

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};

/**
 *
 * @param {Object} values
 * @param {(string) => Promise<string>} executeRecaptcha
 */
async function handleFormSubmit(values, executeRecaptcha) {
  try {
    const captcha = await executeRecaptcha('forgot_password');
    const error = await resolveRejectedPromise(
      setNewPasswordRequesting({ ...values, captcha }),
    );

    if (error) {
      const message =
        error.status === 404 ? (
          <Trans>
            New password registration time (3 hours) was expired.{' '}
            <Link as={RouterLink} to="/forgot-password" fontSize={12}>
              Request new reset link?
            </Link>
          </Trans>
        ) : (
          error.message
        );

      if (message) {
        return {
          [FORM_ERROR]: message,
        };
      }

      return error.data;
    }
    return null;
  } catch (error) {
    return { [FORM_ERROR]: 'Something went wrong, please try again later' };
  }
}

function validate(values) {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = <Trans>This is required field</Trans>;
  }

  if (values.newPassword) {
    if (values.newPassword.length >= 8 && !values.confirmPassword) {
      errors.confirmPassword = <Trans>This is required field</Trans>;
    }

    if (checkPasswordStrength(values.newPassword) === 0) {
      errors.newPassword = (
        <Trans>Should consist both letters and numbers</Trans>
      );
    }

    const max = <Trans>Password can be max 128 characters long</Trans>;
    const min = <Trans>Password should be at least 8 characters long</Trans>;

    if (values.newPassword.length < 8) {
      errors.newPassword = min;
    }

    if (values.newPassword.length > 128) {
      errors.newPassword = max;
    }
  }
  if (
    values.newPassword &&
    values.confirmPassword &&
    values.confirmPassword !== values.newPassword
  ) {
    errors.confirmPassword = <Trans>Password does not match</Trans>;
  }

  return errors;
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { t, Trans } from '@lingui/macro';

import { BlockHead, SearchField, Typography, Button } from '@oca/ui';
import { i18n } from '@lib/i18n';

import { useStore } from 'effector-react';
import { combine } from 'effector';
import { Breadcrumbs } from './breadcrumbs';
import { changeSearchInput } from '../model/file-browser.events';
import { $search } from '../model/file-browser.stores';
import {
  $fileList,
  $selectedFilesCount,
  toggleEntityMoveDialog,
} from '../model/file-browser.view';

const $state = combine({
  files: $fileList,
  selectedFilesCount: $selectedFilesCount,
});

export function Header({ rootBreadcrumb }) {
  return (
    <BlockHead flexDirection="column" py={2}>
      <Flex width={1} justifyContent="space-between" alignItems="center" pb={2}>
        <Breadcrumbs root={rootBreadcrumb} />
        <Box width="50%">
          <SearchFiles />
        </Box>
      </Flex>
      <Toolbar />
    </BlockHead>
  );
}

Header.propTypes = {
  rootBreadcrumb: PropTypes.shape({
    path: PropTypes.string,
    name: PropTypes.node,
  }).isRequired,
};

function Toolbar() {
  const { files, selectedFilesCount } = useStore($state);

  return (
    <Flex
      width={1}
      justifyContent="space-between"
      alignItems="center"
      style={{ minHeight: 28 }}
    >
      <Box>
        <Typography color="textSecondary">
          <Trans>Total files: {files.length}</Trans>
        </Typography>
      </Box>
      {selectedFilesCount > 0 && (
        <Flex style={{ gap: 8 }} alignItems="center">
          <Typography color="textSecondary">
            <Trans>Files selected: {selectedFilesCount}</Trans>
          </Typography>
          <Button
            size="small"
            variant="primary"
            onClick={toggleEntityMoveDialog}
          >
            Move selected
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

function SearchFiles() {
  const search = useStore($search);
  return (
    <SearchField
      value={search}
      onChange={e => changeSearchInput(e.target.value)}
      placeholder={i18n._(t`Search documents`)}
      fullWidth
    />
  );
}

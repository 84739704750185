import React from 'react';
import { Flex } from 'rebass';
import { format } from 'date-fns';
import { t } from '@lingui/macro';

import { AssigneeList } from '@oca/ui';
import { bytesToSize } from '@lib/filesize';
import { StarChecked } from '@oca/icons';
import { i18n } from '@lib/i18n';

import { EditableFilename, FileMenu, ToggleFavoriteFile } from '../organisms';

const T = () => true;

/**
 *
 * @param {{ rootPath: string, canMoveFile: boolean, menuVisibilityPredicate: (document: {id: number, isOwner: boolean, type: string}) => boolean }}
 * @returns {import('react-table').Column[]}
 */
export function useTableColumns({
  rootPath,
  menuVisibilityPredicate = T,
  canMoveFile = false,
} = {}) {
  const columns = React.useMemo(
    () => [
      {
        Header: i18n._(t`Name`),
        id: 'name',
        accessor: 'name',
        // eslint-disable-next-line react/prop-types
        Cell: ({ original }) => {
          return original ? (
            <EditableFilename
              id={original.id}
              // eslint-disable-next-line react/prop-types
              link={original.isDir ? `${rootPath}/${original.id}` : null}
              name={original.name}
              ext={original.ext}
              type={original.type}
            />
          ) : null;
        },
        minWidth: 200,
        sortMethod: () => undefined,
      },
      {
        Header: i18n._(t`Created`),
        accessor: 'createdAt',
        Cell: ({ value }) => format(new Date(value), 'dd.MM.yyyy hh:mm'),
      },
      {
        Header: i18n._(t`Access`),
        accessor: 'users',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) =>
          value.length ? <AssigneeList assignees={value} /> : 'You',
      },
      {
        Header: i18n._(t`Size`),
        accessor: 'versions.0.size',
        Cell: ({ value }) => (value ? bytesToSize(value) : null),
      },
      {
        width: 38,
        getHeaderProps: () => ({ style: { textAlign: 'center' } }),
        Header: <StarChecked />,
        disableResizing: true,
        accessor: 'isFavorite',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, original }) => (
          <Flex justifyContent="center" onClick={stopEventBubbling}>
            <ToggleFavoriteFile
              id={original.id}
              selected={value}
              type={original.type}
            />
          </Flex>
        ),
      },
      {
        width: 48,
        disableResizing: true,
        disableSortBy: true,
        Header: '',
        accessor: 'isOwner',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value: isOwner, original }) => {
          if (isOwner && original && menuVisibilityPredicate(original)) {
            return (
              <Flex justifyContent="flex-end" onClick={stopEventBubbling}>
                <FileMenu
                  isOwner={isOwner}
                  id={original.id}
                  name={original.name}
                  type={original.type}
                  canMove={canMoveFile}
                />
              </Flex>
            );
          }

          return null;
        },
      },
    ],
    [rootPath, menuVisibilityPredicate, canMoveFile],
  );

  return columns;
}

function stopEventBubbling(e) {
  e.stopPropagation();
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const FileUpload = props => {
  const { onChange, ...rest } = props;

  const onInputClick = event => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const handleFileChange = e => {
    e.preventDefault();

    const reader = new FileReader();
    let file = e.target.files[0];

    if (!file) return;

    reader.readAsBinaryString(file);

    reader.onloadend = () => {
      onChange(file);
      file = null;
    };
  };

  return (
    <Upload>
      <input
        type="file"
        accept="*"
        onChange={handleFileChange}
        onClick={onInputClick}
        {...rest}
      />
    </Upload>
  );
};

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const Upload = styled.div`
  & input[type='file'] {
    position: absolute;
    text-indent: -9999px;
    color: transparent;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
    z-index: 2;
    :disabled {
      pointer-events: none;
      cursor: initial;
    }
  }
`;

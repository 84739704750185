import React, { lazy } from 'react';
import { Trans } from '@lingui/macro';

const HolidaysPage = lazy(() =>
  import('./pages/list').then(module => ({
    default: module.Holidays,
  })),
);

export const routes = [
  {
    path: '/hr/holidays',
    component: HolidaysPage,
    navigation: {
      name: <Trans>Public holidays</Trans>,
      shortName: 'PH',
      onlyTopLevelLink: true,
    },
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { i18n } from '@lib/i18n';
import { Favorite } from '../molecules';
import { toggleFavoriteEntity } from '../model/file-browser.events';

export function ToggleFavoriteFile({ id, selected, type }) {
  const handleClick = React.useCallback(
    () => toggleFavoriteEntity({ id, isFavorite: selected, type }),
    [id, selected, type],
  );

  const title = selected
    ? i18n._(t`Remove from favorite`)
    : i18n._(t`Mark as favorite`);

  return <Favorite title={title} selected={selected} onClick={handleClick} />;
}

ToggleFavoriteFile.propTypes = {
  // eslint-disable-next-line react/require-default-props
  id: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  selected: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.string,
};

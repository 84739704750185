/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Flex, Box } from 'rebass';

import { Typography } from '../atoms';

export const RadialBar = ({
  width,
  total,
  amount,
  content,
  bgColor,
  showOverall,
}) => {
  const overflow = total - amount < 0;
  let percentage = overflow ? 100 : 0;

  if (total && amount) {
    percentage = (amount / total) * 100;
  }

  return (
    <Chart width={width} bg={bgColor}>
      <svg viewBox="0 0 36 36">
        <path
          stroke="#e6e6e6"
          strokeWidth="3"
          fill="none"
          d="M18 2.0845a 15.9155 15.9155 0 0 1 0 31.831a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="filledCircle"
          stroke={overflow ? 'green' : '#424B68'}
          strokeWidth="3"
          fill="none"
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${overflow ? 100 : percentage}, 100`}
        />
      </svg>
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        p={20}
      >
        <Typography
          mb="5px"
          fontSize={20}
          fontWeight={700}
          lineHeight={1}
          textAlign="center"
        >
          {showOverall
            ? `${amount || 0}/${total}`
            : `${Math.round(percentage)}%`}
        </Typography>
        <Typography fontSize={10} textAlign="center">
          {content}
        </Typography>
      </Flex>
    </Chart>
  );
};

RadialBar.defaultProps = {
  width: 112,
  total: 0,
  amount: 0,
  content: '',
  bgColor: 'rgba(255, 255, 255, 0)',
};

RadialBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.node,
  bgColor: PropTypes.string,
  showOverall: PropTypes.bool,
};

RadialBar.defaultProps = {
  showOverall: true,
  width: 112,
};

const percentageAnimation = keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
`;

const Chart = styled(Box)`
  position: relative;
  border-radius: 50%;
  svg {
    width: 100%;
    height: 100%;
    & .filledCircle {
      animation: ${percentageAnimation} 1s ease-out forwards;
    }
    position: relative;
    display: block;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
`;

import React from 'react';
import { useStore } from 'effector-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';

import { history } from '@lib/routing';
import { OCALogoColored } from '@oca/icons';
import { Avatar, DataTable } from '@oca/ui';

import { Trans } from '@lingui/macro';
import { $projectListFetching } from '../model/project-flow';

export function ListTable({ data }) {
  const { isLoading } = useStore($projectListFetching);

  return (
    <DataTable
      data={data}
      loading={isLoading}
      loadingText={<Trans>Loading projects</Trans>}
      noDataText={<Trans>No projects found</Trans>}
      columns={tableColumns}
      className="-striped -highlight"
      defaultPageSize={20}
      pageSize={(data && data.length > 5 && data.length) || 5}
      showPagination={false}
      getTrProps={(state, rowInfo, column) => {
        return {
          onClick: e => {
            history.push(`/projects/${rowInfo.original.id}`);
          },
        };
      }}
    />
  );
}
ListTable.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
};

const Circle = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
`;

// eslint-disable-next-line react/prop-types
const StatusHead = ({ color, text }) => (
  <Flex alignItems="center" justifyContent="center">
    <Circle bg={color} />
    {text}
  </Flex>
);

const statusFieldStyles = {
  width: 120,
  headerStyle: {
    color: '#424B68',
  },
  style: {
    textAlign: 'center',
  },
};

const tableColumns = [
  {
    id: 'avatar',
    accessor: d => (d.avatar ? d.avatar : ''),
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (
      <Avatar size={40} src={value}>
        <OCALogoColored />
      </Avatar>
    ),
    width: 90,
  },
  {
    Header: <Trans>Project</Trans>,
    accessor: 'name',
  },
  {
    id: 'projectLead',
    Header: <Trans>Lead</Trans>,
    accessor: d =>
      d.projectLead
        ? `${d.projectLead.firstName} ${d.projectLead.lastName}`
        : 'N/A',
    width: 180,
  },
  {
    id: 'todo',
    Header: () => <StatusHead color="#009EFF" text={<Trans>Todo</Trans>} />,
    accessor: d => d.tasksStatistic.tasksStatuses.todo || 0,
    ...statusFieldStyles,
  },
  {
    id: 'inProgress',
    Header: () => (
      <StatusHead color="#FEA201" text={<Trans>In progress</Trans>} />
    ),
    accessor: d => d.tasksStatistic.tasksStatuses.inProgress || 0,
    ...statusFieldStyles,
  },
  {
    id: 'inReview',
    Header: () => (
      <StatusHead color="#FE2400" text={<Trans>In Review</Trans>} />
    ),
    accessor: d => d.tasksStatistic.tasksStatuses.inReview || 0,
    ...statusFieldStyles,
  },
  {
    id: 'done',
    Header: () => <StatusHead color="#2BBF11" text={<Trans>Done</Trans>} />,
    accessor: d => d.tasksStatistic.tasksStatuses.done || 0,
    width: 100,
    ...statusFieldStyles,
  },
  {
    id: 'tasksCount',
    Header: <Trans>Total</Trans>,
    accessor: d => d.tasksCount || 0,
    width: 132,
    headerStyle: {
      textAlign: 'center',
    },
    style: {
      textAlign: 'center',
    },
  },
];

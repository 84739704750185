import React from 'react';
import PropTypes from 'prop-types';

import { DayPickerInput } from '@oca/ui';

/**
 *
 * @param {import('react-flatpickr').DateTimePickerProps & import('../../ui/molecules/text-field').TextFieldProps}
 */
export function DayPickerInputWrapper({
  label,
  input,
  meta,
  helperText,
  required,
  placeholder,
  spaceAfter,
  startAdornment,
  endAdornment,
  ...rest
}) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  const { onChange, onBlur, onFocus, value, name } = input;

  return (
    <DayPickerInput
      {...rest}
      inputProps={{
        endAdornment,
        label,
        onBlur,
        onFocus,
        required,
        placeholder,
        name,
        spaceAfter,
        startAdornment,
        helperText: showError ? meta.error || meta.submitError : helperText,
        error: showError,
      }}
      onChange={([date]) => onChange(date || null)}
      value={value}
    />
  );
}

DayPickerInputWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.any,
    dirtySinceLastSubmit: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  required: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  spaceAfter: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  startAdornment: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  endAdornment: PropTypes.node,
};

import { createGate } from 'effector-react';
import { combine } from 'effector';
import { createToggler, debounceStore } from '@lib/effector-utils';
import {
  $uploadFile,
  $uploadProgress,
  $search,
  $filesRaw,
  $fileShareId,
  $filePreviewId,
  $selectedFileIds,
  $folderMoveId,
  $breadcrumbs,
} from './file-browser.stores';
import {
  uploadFileRequest,
  shareFolderRequest,
  shareFileRequest,
  moveFilesToFolderRequest,
} from './file-browser.events';
import { normalizeFiles, searchByValue } from '../lib/helpers';

export const FileBrowserGate = createGate('FileBrowserGate');

export const $uploadFileModel = combine({
  file: $uploadFile,
  progress: $uploadProgress,
  uploading: uploadFileRequest.pending,
});

export const [
  $folderCreateDialogOpen,
  toggleFolderCreateDialog,
] = createToggler(false);

export const [
  // prettier-ignore
  $entityShareDialogOpen,
  toggleOpenEntityShareDialog,
] = createToggler(false);

export const [
  // prettier-ignore
  $entityMoveConfirmationDialogOpen,
  toggleMoveEntityConfirmationDialog,
] = createToggler(false);

export const [
  // prettier-ignore
  $entityMoveDialogOpen,
  toggleEntityMoveDialog,
] = createToggler(false);

export const $debouncedSearch = debounceStore(300, $search);
export const $fileList = $filesRaw.map(normalizeFiles);
export const $shareEntityFetching = combine(
  shareFolderRequest.pending,
  shareFileRequest.pending,
  (l, r) => l || r,
);
export const $moveFilesPending = moveFilesToFolderRequest.pending;

export const $filteredFileList = combine(
  $fileList,
  $debouncedSearch,
  searchByValue,
);

export const $sharedFile = combine(
  $fileShareId,
  $fileList,
  (id, list) => list.find(item => item.id === id) || null,
);

export const $selectedFiles = combine(
  $filteredFileList,
  $selectedFileIds,
  (list, selected) => list.filter(item => selected.has(item.id)),
);

export const $filePreview = combine($fileList, $filePreviewId, (list, id) => {
  if (id !== null) {
    const file = list.find(item => item.id === id);

    return file
      ? { file: file.media.mediaUrl, fileName: file.name, fileExt: file.ext }
      : null;
  }

  return null;
});

export const $selectedFilesCount = $selectedFileIds.map(s => s.size);

export const $files = combine(
  $filteredFileList,
  $selectedFileIds,
  $selectedFilesCount,
  (list, selected, selectedCount) => ({
    list,
    selected,
    isAllSelected: list.length > 0 && list.length === selectedCount,
  }),
);

export const $moveConfirmation = combine(
  $folderMoveId,
  $filteredFileList,
  $breadcrumbs,
  $selectedFileIds,
  $entityMoveConfirmationDialogOpen,
  (moveToId, list, breadcrumbs, selected, open) => {
    const moveList = list.concat(breadcrumbs);
    return {
      open,
      moveTo: moveList.find(item => item.id === moveToId) || { name: 'Root' },
      selected: list.filter(item => selected.has(item.id)),
    };
  },
);

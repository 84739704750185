import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';

import { Typography } from '../atoms/typography';

export function SectionBlock({ children, title, ...props }) {
  return (
    <Box p={30} bg="white" {...props}>
      {title && (
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
}

SectionBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SectionBlock.defaultProps = {
  children: null,
  title: null,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { Trans } from '@lingui/macro';

import {
  SelectWrapper,
  TextFieldWrapper,
  DayPickerInputWrapper,
} from '@lib/final-form-oca-ui';
import { Row, Col } from '@lib/grid';
import { FileUploaderField } from '@features/common';
import { SelectBox } from '@oca/ui';
import { OnChange } from '@lib/react-final-form-on-change';
import dayjs from 'dayjs';
import { dateTokens } from '@lib/i18n';

export const FieldsetAllowance = React.memo(
  /**
   *
   * @param {{ form: import('final-form').FormApi }}
   */
  function Allowance({ name, categories, items, form, employee }) {
    const [category, setCategory] = React.useState(null);
    const filteredItems = items.filter(item => {
      const freeOrAssignedToEmployee =
        item.assignedTo === null || item.assignedTo.id === employee.id;

      if (category === null) {
        return freeOrAssignedToEmployee;
      }

      return item.category.id === category.id && freeOrAssignedToEmployee;
    });

    const statePropertyField = `${name}.stateProperty`;

    const clearDependantFields = () =>
      form.batch(() => {
        form.change(`${name}.assignDate`, null);
        form.change(`${name}.returnDate`, null);
        form.change(`${name}.note`, null);
        form.change(`${name}.files`, []);
      });

    return (
      <Row flexDirection="column">
        <Col>
          <Row spacing="10px">
            <Col width={[1 / 3, 1 / 4, 1 / 5]}>
              <SelectBox
                value={category}
                onChange={val => {
                  setCategory(val);
                  if (category && val.id !== category.id) {
                    form.change(statePropertyField, null);
                    clearDependantFields();
                  }
                }}
                label={<Trans>Category</Trans>}
                options={categories}
              />
              <OnChange name={statePropertyField}>
                {(value, prevValue) => {
                  if (value && prevValue && value !== prevValue) {
                    clearDependantFields();
                  }
                  if (value && items && items.length > 0) {
                    const item = items.find(i => i.id === (value.id || value));
                    if (item) {
                      setCategory(item.category);
                    }
                  }
                }}
              </OnChange>
            </Col>
            <Col width={[1 / 3, 1 / 4, 1 / 5]}>
              <Field name="properties">
                {({ input }) => {
                  const { value } = input;
                  const selectedItems = value.reduce(
                    (acc, item) =>
                      item.stateProperty !== null
                        ? acc.concat(
                            item.stateProperty.id || item.stateProperty,
                          )
                        : acc,
                    [],
                  );

                  return (
                    <Field
                      name={statePropertyField}
                      label={<Trans>Item</Trans>}
                      component={SelectWrapper}
                      getOptionLabel={option => {
                        return option
                          ? `${option.mark} ${option.model} ${
                              option.serialNumber
                            }`
                          : '';
                      }}
                      isOptionDisabled={option =>
                        option && selectedItems.includes(option.id)
                      }
                      parse={val => val && val.id}
                      format={val =>
                        val
                          ? items.find(({ id }) => id === (val.id || val))
                          : ''
                      }
                      options={filteredItems}
                      required
                    />
                  );
                }}
              </Field>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row spacing="10px">
            <Col width={1 / 2}>
              <Field
                name={`${name}.description`}
                component={TextFieldWrapper}
                label={<Trans>Note</Trans>}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <FormSpy>
            {({ values }) => {
              const contractExpirationDate =
                values &&
                values.contracts &&
                values.contracts.length > 0 &&
                values.contracts[0].expDate
                  ? new Date(values.contracts[0].expDate)
                  : dayjs(new Date())
                      .add(1, 'day')
                      .toDate();

              const options = {
                formatDate: date => dayjs(date).format('LL'),
                static: true,
                maxDate: contractExpirationDate.toISOString(),
              };

              return (
                <Row spacing="10px">
                  <Col width={[1 / 3, 1 / 4, 1 / 5]}>
                    <Field
                      name={`${name}.assignDate`}
                      component={DayPickerInputWrapper}
                      parse={val =>
                        val ? dayjs(val).format(dateTokens.ISO_DATE) : ''
                      }
                      format={val => (val ? new Date(val).toISOString() : null)}
                      label={<Trans>Assign date</Trans>}
                      options={options}
                    />
                  </Col>
                  <Col width={[1 / 3, 1 / 4, 1 / 5]}>
                    <Field
                      name={`${name}.returnDate`}
                      component={DayPickerInputWrapper}
                      parse={val =>
                        val ? dayjs(val).format(dateTokens.ISO_DATE) : ''
                      }
                      format={val => (val ? new Date(val).toISOString() : null)}
                      options={options}
                      label={<Trans>Return date</Trans>}
                      helperText={
                        <Trans>
                          Can not be further than employment contract date -
                          {dayjs(contractExpirationDate).format('LL')}
                        </Trans>
                      }
                    />
                  </Col>
                </Row>
              );
            }}
          </FormSpy>
        </Col>
        <Col width={1 / 2}>
          <FileUploaderField
            name={`${name}.files`}
            label={<Trans>Signed allowances form from assignee</Trans>}
          />
        </Col>
      </Row>
    );
  },
);

FieldsetAllowance.propTypes = {
  employee: PropTypes.shape({
    id: PropTypes.number,
  }),
  name: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  form: PropTypes.shape({
    change: PropTypes.func,
  }).isRequired,
};

FieldsetAllowance.defaultProps = {
  categories: [],
  items: [],
  employee: {},
};

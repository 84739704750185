/**
 * Regular expression for matching file names, with or without extension.
 */
export const filenameRegex = () => /([^\\/]+)$/;

export const emailRegex = () => /^[^\s@]+@[^\s@]+\.([^\s@]{2,63})+$/;

export const guidRegex = () =>
  /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/;

export const fileExtensionRegex = () => /\.(\w+)$/;

import React from 'react';
import PropTypes from 'prop-types';
import { SelectBox } from '@oca/ui';

/**
 *
 * @param {import('../../ui/molecules/types').SelectBoxProps}
 */
export function SelectWrapper({ input, meta, helperText, ...rest }) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <SelectBox
      {...rest}
      {...input}
      error={showError}
      helperText={showError ? meta.error || meta.submitError : helperText}
    />
  );
}

SelectWrapper.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  meta: PropTypes.shape({
    submitError: PropTypes.any,
    dirtySinceLastSubmit: PropTypes.bool,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

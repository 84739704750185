import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { useDropzone } from 'react-dropzone';
import { t } from '@lingui/macro';

import { Button } from '@oca/ui';
import { AddFolder, AddDocument } from '@oca/icons';
import { i18n } from '@lib/i18n';
import { notify } from '@lib/notifier';
import { bytesToSize } from '@lib/filesize';

import { toggleFolderCreateDialog } from '../model/file-browser.view';
import { startFileUploading } from '../model/file-browser.events';

const MAX_SIZE = process.env.REACT_APP_MAX_UPLOAD_FILESIZE * 1024 * 1024; // bytes

export const PrimaryActions = React.memo(function PrimaryActions({
  currentFolder,
}) {
  return (
    <Flex>
      <Box mr={2}>
        <Button variant="primary" onClick={toggleFolderCreateDialog}>
          <AddFolder />
          {i18n._(t`Create Folder`)}
        </Button>
      </Box>
      <UploadButton folder={currentFolder} />
    </Flex>
  );
});

PrimaryActions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  currentFolder: PropTypes.number,
};

function UploadButton({ folder }) {
  const handleAccepted = React.useCallback(
    files => startFileUploading({ file: files[0], folder }),
    [folder],
  );
  const handleRejected = React.useCallback(
    () => notify.error(i18n._(t`Max file size is ${bytesToSize(MAX_SIZE)}`)),
    [],
  );

  const { inputRef, getInputProps, open } = useDropzone({
    maxSize: MAX_SIZE,
    noDrag: true,
    multiple: false,
    onDropAccepted: handleAccepted,
    onDropRejected: handleRejected,
  });

  return (
    <React.Fragment>
      <Button variant="primary" onClick={open}>
        <AddDocument />
        {i18n._(t`Upload a file`)}
      </Button>
      <input {...getInputProps()} ref={inputRef} />
    </React.Fragment>
  );
}

UploadButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  folder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

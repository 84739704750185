import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components';

import { Typography } from '../atoms/typography';

/**
 *
 * @typedef {import('react').ReactNode} ReactNode
 */

/**
 *
 * @param {import('rebass').FlexProps & { title: string | ReactNode, actions: ReactNode }}
 */
export function PageHeading({ title, actions, ...rest }) {
  return (
    <Flex alignItems="center" justifyContent="space-between" mb={3} {...rest}>
      {title && <Typography variant="h1">{title}</Typography>}
      {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
    </Flex>
  );
}

PageHeading.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // eslint-disable-next-line react/require-default-props
  actions: PropTypes.node,
};

const ActionsWrapper = styled(Flex)`
  & > *:not(:last-child) {
    margin-right: 8px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { Box } from 'rebass';
import { height as heightProp } from 'styled-system';
import styled from 'styled-components';

export const SkeletonLoader = React.forwardRef(
  /**
   *
   * @param {SkeletonLoaderProps} props
   */
  function SkeletonLoader(props, ref) {
    const { width, height, responsive, style, boxProps, ...rest } = props;

    return (
      <Wrapper {...boxProps} ref={ref} width={width} height={height}>
        <ContentLoader
          {...rest}
          {...(responsive ? {} : { width, height })}
          style={
            responsive ? { ...style, width: '100%', height: '100%' } : style
          }
        />
      </Wrapper>
    );
  },
);

SkeletonLoader.propTypes = {
  ...ContentLoader.propTypes,
  responsive: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  boxProps: PropTypes.shape(Box.propTypes),
};

SkeletonLoader.defaultProps = {
  responsive: true,
};

/**
 *
 * @param {SkeletonLoaderProps & { loading: Boolean, render: Function }}
 */
export function SkeletonRenderer({ loading, render, ...rest }) {
  if (render && typeof render !== 'function') {
    throw new Error('render should be a function');
  }

  return loading ? <SkeletonLoader {...rest} /> : render();
}

SkeletonRenderer.propTypes = {
  loading: PropTypes.bool,
  render: PropTypes.func,
};

SkeletonRenderer.defaultProps = { loading: false, render: () => null };

const Wrapper = styled(Box)`
  svg {
    display: block;
  }

  ${heightProp}
`;

/**
 * @typedef {import('react-content-loader').IContentLoaderProps & { responsive: Boolean, boxProps: import('rebass').BoxProps }} SkeletonLoaderProps
 */

import { createEvent, createStore } from 'effector';

/**
 * Creates boolean store and event to 'toggle' it
 */
export function createToggler(initialState = false, resetEvent) {
  const reset = resetEvent || createEvent();
  const toggle = createEvent();
  const $on = createStore(initialState)
    .on(toggle, (prev, next) => (typeof next === 'boolean' ? next : !prev))
    .reset(reset);

  return [$on, toggle];
}

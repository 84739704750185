import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@oca/ui';

export const ChecklistItemTextarea = React.forwardRef(
  /**
   *
   * @param {import('@oca/ui/atoms/textarea').TextAreaProps & { selectTextOnMount: boolean }} props
   * @param {import('react').Ref} ref
   */
  function ChecklistItemTextarea({ selectTextOnMount, ...props }, ref) {
    const innerRef = React.useRef();

    React.useEffect(() => {
      if (selectTextOnMount && innerRef.current) {
        innerRef.current.focus();
        innerRef.current.select();
      }
    }, [selectTextOnMount]);

    function handleRef(node) {
      if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = node;
      }
      innerRef.current = node;
    }

    return <TextArea {...props} ref={handleRef} />;
  },
);

ChecklistItemTextarea.propTypes = {
  selectTextOnMount: PropTypes.bool,
};

ChecklistItemTextarea.defaultProps = {
  selectTextOnMount: false,
};

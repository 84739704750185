import React from 'react';
import { SvgIcon } from './svg-icon';

export const CheckboxIndeterminate = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <defs>
      <path id="a" d="M0 0h24v24H0z" />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path
      clipPath="url(#b)"
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z"
    />
  </SvgIcon>
);
